import React from 'react';
import Section from './Section';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import SectionHeader from './SectionHeader';
import Button from '@material-ui/core/Button';

import { Link } from 'react-router-dom';

function HeroSection(props) {
  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Box
          textAlign="center"
          style={{ color: props.textColor, fontWeight: 'bold' }}
        >
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            size={2}
          />

          <Link to={props.getStartedLink}>
            <Button
              style={{
                backgroundColor: '#002067',
                color: '#FFFFFF',
                padding: '18px 36px',
              }}
            >
              {props.buttonText}
            </Button>
          </Link>
        </Box>
      </Container>
    </Section>
  );
}

export default HeroSection;
