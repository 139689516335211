import React, { useEffect, useState } from "react";
import { withSnackbar } from "notistack";
import ReactGA from "react-ga";
import axios from "axios";
import {
  Typography,
  Breadcrumbs,
  Paper,
  Grid,
  ButtonBase,
  Button,
  Box,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import HomeIcon from "@material-ui/icons/Home";
import DeleteIcon from "@material-ui/icons/Delete";
import Skeleton from "@material-ui/lab/Skeleton";
import emtyCart from "./emty.png";
import { useDispatch, useSelector } from "react-redux";
import {
  listGuides,
  tempGuidesDetails,
} from "../../store/actions/guidesActions";
import { urls } from "../../utils/urls";
import axiosInstance from "../../axios";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 700,
    // background: "#f7f9fa",
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  link: {
    display: "flex",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  coupon: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400,
    marginTop: "10px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

const CartPage = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [cart, setCart] = useState([]);

  const guidesList = useSelector((state) => state.guidesList);
  const { loading, guideList, error } = guidesList;

  const guidesTemp = useSelector((state) => state.guidesTemp);
  const { loadingTemp, errorTemp, guide } = guidesTemp;

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    document.title = "Cart / Notting Hill Law";
    getCart();
  }, [dispatch, history]);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const handleRemoveItem = (itemID) => {
    authAxios.delete(urls.deleteGuideUrl(itemID)).then((res) => {
      props.enqueueSnackbar(`Remove item from cart Successfully`, {
        variant: "warning",
        autoHideDuration: 5000,
      });
      getCart();
      // window.location.reload(false);
    });
    // .catch(err => {
    //   this.setState({ error: err });
    // });
  };

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      Authorization: `Bearer ${userInfo && userInfo.access}`,
    },
  });
  const getCart = () => {
    authAxios.get(urls.getCartUrl).then((res) => {
      setCart(res.data);
    });
  };

  return (
    <>
      <div>
        <Breadcrumbs className={classes.paper} aria-label="breadcrumb">
          <Link
            color="inherit"
            to="/dashboard#dashboard"
            className={classes.link}
          >
            <HomeIcon className={classes.icon} />
            HOME
          </Link>
          {/* <Link color="inherit" to="/v2/goprogress" className={classes.link}>
            BOOKS
          </Link> */}
         
          <Typography color="textPrimary" className={classes.link}>
            CART
          </Typography>
        </Breadcrumbs>
        <Typography className={classes.paper} gutterBottom variant="subtitle1">
          {cart.guides && cart.guides.length} Books In Cart
        </Typography>

        <>
          {cart.guides ? (
            cart.guides.map((v, i) => (
              <>
                <Paper
                  style={{ background: "#f7f9fa" }}
                  className={classes.paper}
                >
                  <Grid container spacing={2}>
                    <Grid item>
                      <ButtonBase className={classes.image}>
                        <img
                          className={classes.img}
                          alt="no picture"
                          src={`http://127.0.0.1:8000${v.get_image}`}
                        />
                      </ButtonBase>
                    </Grid>

                    <Grid item xs={12} sm container>
                      <Grid item xs container direction="column" spacing={2}>
                        <Grid item xs>
                          <Typography gutterBottom variant="h6">
                            {v.guide}
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            {v.get_heading}
                          </Typography>
                          <Typography variant="subtitle1" color="textSecondary">
                            £ {v.get_final_price}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Button
                            onClick={() => handleRemoveItem(v.id)}
                            variant="contained"
                            color="secondary"
                          >
                            <DeleteIcon />
                            Remove
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
                <br></br>
              </>
            ))
          ) : (
            <>
              {/* // Array.from(Array(3).keys()).map((i) => ( //{" "}
            <Box padding={3} key={i}>
              // <Skeleton variant="rect" width={250} height={200} />
              // <Skeleton animation="wave" />
              //{" "}
            </Box>
            // )) */}

              <Box
                display="flex"
                height="100%"
                marginTop="1vh"
                alignItems="center"
                flexDirection="column"
                justifyContent="center"
              >
                <img
                  style={{
                    maxWidth: "150px",
                  }}
                  src={emtyCart}
                ></img>
                <Typography variant="h4">Your Cart is Emty</Typography>
              </Box>
            </>
          )}

          {cart.guides && cart.guides.length === 0 && (
            <Box
              display="flex"
              height="100%"
              marginTop="1vh"
              alignItems="center"
              flexDirection="column"
              justifyContent="center"
            >
              <img
                style={{
                  maxWidth: "150px",
                }}
                src={emtyCart}
              ></img>
              <Typography variant="h4">Your Cart is Emty</Typography>
            </Box>
          )}

          <br />
        </>

        <br />

        <div className={classes.paper}>
          <Typography variant="h6">Total: £ {cart.get_total}</Typography>

          {cart.guides && cart.guides.length === 0 ? (
            <Link to="/visa-guides">
              <Button
                style={{ marginTop: "10px", marginBottom: "10px" }}
                variant="contained"
                color="primary"
              >
                Buy Visa Guide
              </Button>
            </Link>
          ) : (
            <Link to="/checkout">
              <Button
                style={{ marginTop: "10px", marginBottom: "10px" }}
                variant="contained"
                color="primary"
              >
                Go To Checkout
              </Button>
            </Link>
          )}
        </div>
        <hr />
      </div>
    </>
  );
};
export default withSnackbar(CartPage);
