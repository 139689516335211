import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import ReactGA from "react-ga";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  Typography,
  Card,
  CardContent,
  CardActionArea,
  Button,
  CardActions,
  CardHeader,
  Badge,
  CardMedia,
  makeStyles,
  Chip,
  Paper,
  Tabs,
  Tab,
  Container,
  Grid,
  ButtonBase,
  Box,
} from "@material-ui/core";

import emtyCart from "../book/emty.png";
import { urls } from "../../utils/urls";

import CustomRegularAccordion from "../../components/CustomRegularAccordian";
import Subscription from "../dashboard/subscription/Subscription";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 700,
    // background: "#f7f9fa",
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  link: {
    display: "flex",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  coupon: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400,
    marginTop: "10px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  reBox: {
    // flex: 1,
    margin: "8px",
    marginTop: "0px",
    // maxWidth: '1200px',
    // minWidth: '800px',
    [theme.breakpoints.down("sm")]: {
      width: 400,
    },
    marginBottom: "15px",
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
}));

const PurchaseHistory = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [purchase, setPurchase] = useState([]);
  const [purchaseItem, setPurchaseItem] = useState(false);
  const history = useHistory();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const dispatch = useDispatch();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    document.title = "My Account / Notting Hill Law";
    getPurchase();
  }, [dispatch, history]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      Authorization: `Bearer ${userInfo && userInfo.access}`,
    },
  });
  const getPurchase = () => {
    authAxios.get(urls.getPurchaseUrl).then((res) => {
      setPurchase(res.data);
      setPurchaseItem(true);
    });
  };
  const handleDownload = (pdf, guide) => {
    const link = document.createElement("a");
    link.href = `${process.env.REACT_APP_BACKEND_URL}${pdf}`;
    link.download = guide;
    link.click();
  };
  return (
    <>
      <Container>
        <Grid
          style={{ marginTop: "10px", minWidth: "1000px" }}
          container
          spacing={4}
        >
          <Grid item md={7}>
            {/* <Box className={classes.reBox}> */}
            <CustomRegularAccordion
              title="My Plan"
              content={
                <>
                  {/* <Paper
                    style={{ background: "#f7f9fa" }}
                    className={classes.paper}
                  > */}
                  {/* <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Card style={{ marginBottom: "50px" }}>
                        <CardHeader
                          title={`Standard`}
                          subheader={`Most popular`}
                          titleTypographyProps={{ align: "center" }}
                          subheaderTypographyProps={{ align: "center" }}
                          className={classes.cardHeader}
                        />
                        <CardContent>
                          <div className={classes.cardPricing}>
                            <Typography
                              component="h2"
                              variant="h3"
                              color="textPrimary"
                            >
                              £21
                            </Typography>
                            <Typography variant="h6" color="textSecondary">
                              /mo
                            </Typography>
                          </div>
                          <ul>
                            <Typography
                              component="li"
                              variant="subtitle1"
                              align="center"
                            >
                              20 templates included
                            </Typography>
                          </ul>
                        </CardContent>
                        <CardActions>
                          <Button
                            variant="contained"
                            fullWidth
                            color="primary"
                            onClick={() => history.push(`/pricing`)}
                          >
                            Upgrade Plan
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  </Grid> */}
                  {/* </Paper> */}
                  <Subscription></Subscription>
                </>
              }
            />
            {/* </Box> */}
          </Grid>

          <Grid item md={5}>
            <CustomRegularAccordion
              title="Purchase Guide"
              content={
                <>
                  {purchaseItem ? (
                    purchase.guides &&
                    purchase.guides.map((v, i) => (
                      <>
                        <Grid
                          // style={{ marginLeft: "200px", marginRight: "200px" }}
                          item
                          md={12}
                        >
                          <Paper
                            style={{ background: "#f7f9fa" }}
                            className={classes.paper}
                            key={i}
                          >
                            <Grid container spacing={2}>
                              <Grid item>
                                <ButtonBase className={classes.image}>
                                  <img
                                    className={classes.img}
                                    alt="no picture"
                                    src={`${process.env.REACT_APP_BACKEND_URL}${v.get_image}`}
                                  />
                                </ButtonBase>
                              </Grid>

                              <Grid item xs={12} sm container>
                                <Grid
                                  item
                                  xs
                                  container
                                  direction="column"
                                  spacing={2}
                                >
                                  <Grid item xs>
                                    <Typography gutterBottom variant="h6">
                                      {v.guide}
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                      {v.get_heading}
                                    </Typography>
                                    <Typography
                                      variant="subtitle1"
                                      color="textSecondary"
                                    >
                                      £ {v.get_final_price}
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Button
                                      onClick={() =>
                                        handleDownload(v.get_pdf, v.guide)
                                      }
                                      variant="contained"
                                      color="secondary"
                                    >
                                      <GetAppIcon />
                                      Download
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Paper>
                          <br></br>
                        </Grid>
                      </>
                    ))
                  ) : (
                    <>
                      <Grid item md={12}>
                        <Box
                          height="100%"
                          marginTop="1vh"
                          alignItems="center"
                          flexDirection="column"
                          justifyContent="center"
                        >
                          <img
                            style={{
                              maxWidth: "150px",
                              display: "block",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            src={emtyCart}
                          ></img>
                          <Typography
                            style={{ textAlign: "center" }}
                            variant="h4"
                          >
                            {" "}
                            You did not buy any book yet!
                          </Typography>
                        </Box>
                      </Grid>
                    </>
                  )}
                </>
              }
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default PurchaseHistory;
