import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function BasicSelect(props) {
  const handleChange = (event) => {
    props.setValue(event.target.value);
    var activeObj = props.canvas?.getActiveObject();

    if (activeObj?.type === "i-text") {
      if (props.label === "Font Color") {
        activeObj?.set({ fill: event.target.value });
        props?.setValue(event.target.value);
        props.canvas?.renderAll();
      } else if (props.label === "Font Size") {
        activeObj?.set({ fontSize: event.target.value });
        props?.setValue(event.target.value);
        props.canvas?.renderAll();
      } else if (props.label === "Font Family") {
        activeObj?.set({ fontFamily: event.target.value });
        props?.setValue(event.target.value);
        props.canvas?.renderAll();
      } else if (props.label === "Text Decoration") {
        if (event.target.value === "strike") {
          activeObj?.set({ linethrough: true });
          props.canvas?.renderAll();
        } else if (event.target.value === "no strike") {
          activeObj?.set({ linethrough: false });
          props.canvas?.renderAll();
        } else {
          activeObj?.set({
            underline: event.target.value === "underline" ? true : false,
          });
          props.canvas?.renderAll();
        }
      } else if (props.label === "Font Style") {
        if (event.target.value === "italic") {
          activeObj?.set({ fontStyle: "italic" });
          props?.setValue(event.target.value);
          props.canvas?.renderAll();
        } else if (event.target.value === "normal") {
          activeObj?.set({ fontStyle: "normal" });
          props?.setValue(event.target.value);
          props.canvas?.renderAll();
        } else if (event.target.value === "bold") {
          activeObj?.set({ fontWeight: "bold" });
          props?.setValue(event.target.value);
          props.canvas?.renderAll();
        } else if (event.target.value === "no bold") {
          activeObj?.set({ fontWeight: "normal" });
          props?.setValue(event.target.value);
          props.canvas?.renderAll();
        }
      }
    }
  };

  return (
    <Box
      sx={{
        minWidth: 120,
        width: props.width ? props.width : null,
        flex: props.flex && props.flex,
      }}
    >
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">
          {props.label ? props.label : null}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={props.value}
          label={props.label ? props.label : null}
          onChange={(e) => {
            handleChange(e);
          }}
        >
          {props.values &&
            props.values.map((item, index) => (
              <MenuItem
                style={{
                  fontFamily:
                    item === "Rouge Script"
                      ? "Rouge Script"
                      : item === "Reddit Serif"
                      ? "Reddit Serif"
                      : item === "Roboto"
                      ? "Roboto"
                      : "",
                }}
                key={index}
                value={item}
              >
                {item}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
}
