import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton,
} from "@material-ui/core";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AddIcon from "@material-ui/icons/Add";
import DescriptionIcon from "@material-ui/icons/Description";
import EmailIcon from "@material-ui/icons/Email";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
  },
  content: {
    padding: 0,
  },
  image: {
    height: 48,
    width: 48,
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

const Notification = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader subtitle={`12 in total`} title="Notification" />
      <Divider />
      <CardContent className={classes.content}>
        <List>
          <ListItem divider={1}>
            <ListItemAvatar>
              <DescriptionIcon></DescriptionIcon>
            </ListItemAvatar>
            <ListItemText
              primary={`created new documents`}
              secondary={`12 hours ago`}
            />
            <IconButton edge="end" size="small">
              <MoreVertIcon />
            </IconButton>
          </ListItem>
          <ListItem divider={1}>
            <ListItemAvatar>
              <EmailIcon></EmailIcon>
            </ListItemAvatar>
            <ListItemText
              primary={`created new documents`}
              secondary={`12 hours ago`}
            />
            <IconButton edge="end" size="small">
              <MoreVertIcon />
            </IconButton>
          </ListItem>
          <ListItem divider={1}>
            <ListItemAvatar>
              <DescriptionIcon></DescriptionIcon>
            </ListItemAvatar>
            <ListItemText
              primary={`created new documents`}
              secondary={`12 hours ago`}
            />
            <IconButton edge="end" size="small">
              <MoreVertIcon />
            </IconButton>
          </ListItem>
          <ListItem divider={1}>
            <ListItemAvatar>
              <EmailIcon></EmailIcon>
            </ListItemAvatar>
            <ListItemText
              primary={`created new documents`}
              secondary={`12 hours ago`}
            />
            <IconButton edge="end" size="small">
              <MoreVertIcon />
            </IconButton>
          </ListItem>
          {/* <ListItem divider={1}>
            <ListItemAvatar>
              <DescriptionIcon></DescriptionIcon>
            </ListItemAvatar>
            <ListItemText
              primary={`created new documents`}
              secondary={`12 hours ago`}
            />
            <IconButton edge="end" size="small">
              <MoreVertIcon />
            </IconButton>
          </ListItem>
          <ListItem divider={1}>
            <ListItemAvatar>
              <DescriptionIcon></DescriptionIcon>
            </ListItemAvatar>
            <ListItemText
              primary={`created new documents`}
              secondary={`12 hours ago`}
            />
            <IconButton edge="end" size="small">
              <MoreVertIcon />
            </IconButton>
          </ListItem> */}
        </List>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button color="primary" size="small" variant="text">
          View all <ArrowRightIcon />
        </Button>
      </CardActions>
    </Card>
  );
};

Notification.propTypes = {
  className: PropTypes.string,
};

export default Notification;
