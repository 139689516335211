import { Box, Typography } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import ListItemText from "@material-ui/core/ListItemText";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

import PropTypes from "prop-types";
import styles from "./style.css";

function WelcomeBackCard({ image, userData, color, title }) {
  const date = new Date().toLocaleDateString();
  const [time, setTime] = useState(new Date());
  const location = useLocation();

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => {
      clearInterval(timer);
      setTime(null); // Return a funtion to clear the timer so that it will stop being called on unmount
    };
  }, []);

  return (
    <Box
      style={{
        color: color || "white",
        backgroundImage: `url("${image}")`,
        marginBottom: "2vw",
        boxSizing: "cover",

        marginTop:
          location.pathname.split("/")[1] === "progresslms" ? "3vw" : "1vw",

        padding: "10px",
        borderRadius: "20px",
        backgroundPosition: "top",
        backgroundSize: "cover",
        minHeight: "15vw",
        // minWidth: "1070px",
      }}
      // className={styles.welcomeBackCard}
    >
      <List orientation="vertical">
        <ListItem>
          <Typography
            style={{ fontWeight: 500, fontFamily: "system-ui" }}
            variant="h4"
          >
            Welcome Back
          </Typography>
        </ListItem>
        <ListItem>
          <Typography
            style={{ fontWeight: 600, fontFamily: "system-ui" }}
            variant="h4"
          >
            {title}
          </Typography>
        </ListItem>
        <ListItem>
          <CalendarTodayIcon sx={{ color: "white" }} />
          <ListItemText inset primary={`Today's Date is ${date}`} />
        </ListItem>

        <ListItem>
          <AccessTimeIcon sx={{ color: "white" }} />
          <ListItemText
            inset
            primary={`The Time is ${time&&time.toLocaleTimeString()}`}
          />
        </ListItem>
      </List>
      {/* search ber */}
      {/* <CustomSearch /> */}
    </Box>
  );
}

WelcomeBackCard.propTypes = {
  image: PropTypes.string.isRequired,
  userData: PropTypes.object,
  color: PropTypes.string,
  title: PropTypes.string,
};
export default WelcomeBackCard;
