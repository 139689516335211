import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import ReactGA from "react-ga";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Grid,
  Box,
  Container,
  makeStyles,
} from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import HeroSection from "../../components/HeroSection";
import Navbar from "../../components/Navbar";
//Navbar icons:
import goProgressNavIcon from "./goProgressNavIcon.png";

import Goprogresscover from "./Goprogresscover.png";
import Guide from "../../components/Guide";
import {
  listGuides,
  tempGuidesDetails,
} from "../../store/actions/guidesActions";

const useStyles = makeStyles((theme) => ({
  border: {
    content: "",
    position: "absolute",
    height: "4px",
    backgroundColor: "#002067",
    width: "200px",
    marginTop: "10px",

    marginLeft: "20px",
  },
}));

const BookLandingPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const guidesList = useSelector((state) => state.guidesList);
  const { loading, guideList, error } = guidesList;

  const guidesTemp = useSelector((state) => state.guidesTemp);
  const { loadingTemp, errorTemp, guide } = guidesTemp;

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    document.title = "Guide Book / Docelerate";
    dispatch(listGuides());
    dispatch(tempGuidesDetails());
  }, [dispatch, history]);

  return (
    <div>
      {/* <Navbar
        bgColor="#D8D1F2"
        logoHeight="50PX"
        logoWidth="50px"
        color="#002067"
        logo={goProgressNavIcon}
        logoLink="/v2/goprogress"
        secondButtonText="GET STARTED!"
        secondButtonLink="/v2/goprogress/courses"
      /> */}
      <Navbar
        color="light"
        bgColor="#D8D1F2"
        // bgColor="#edabaa"
        // logoHeight="60PX"
        // logoWidth="60px"
        logo={null}
        // logoLink="/v2/progresstutors"
        firstButtonText="Visa Guides"
        firstButtonLink="/visa-guides"
        secondButtonText="Letter Templates"
        secondButtonLink="/letter-templates"
      />

      {/* // Hero: */}
      <HeroSection
        variant="h6"
        color="white"
        bgColor="#white"
        size="large"
        textColor="#0a0a55"
        bgImage={Goprogresscover}
        bgImageOpacity={1}
        title="NHL"
        subtitle="We provide quick and easy expert legal advice!"
        buttonText="Get Started"
        buttonColor="white"
        getStartedLink="/guide-lading-page/guides"
      />

      {/* Go Progress courses */}
      <Container>
        <Grid
          style={{ marginTop: "30px", marginBottom: "30px" }}
          container
          spacing={2}
          justifyContent="center"
        >
          {/* <Grid item md={3} xs={12}>
          
          </Grid>  */}

          <Grid item md={12} xs={8} sm={8}>
            <Typography
              style={{ color: "#0a0a55", fontWeight: "bold" }}
              variant="h4"
            >
              OUR TOP GUIDES
            </Typography>

            <div className={classes.border} />
            <br />
            <br />
            <Grid container spacing={3}>
              {guideList
                ? guideList.map((v, i) => (
                    <Grid key={i} item lg={4} md={6} xs={12}>
                      <Guide guideList={v}></Guide>
                    </Grid>
                  ))
                : Array.from(Array(3).keys()).map((i) => (
                    <Box padding={3} key={i}>
                      <Skeleton variant="rect" width={250} height={200} />
                      <Skeleton animation="wave" />
                    </Box>
                  ))}
            </Grid>
          </Grid>
        </Grid>

        <Grid
          style={{ marginTop: "30px", marginBottom: "30px" }}
          container
          spacing={2}
          justifyContent="center"
        >
          {/* <Grid item md={3} xs={12}>
          
          </Grid>  */}

          <Grid item md={12} xs={8} sm={8}>
            <Typography
              style={{ color: "#0a0a55", fontWeight: "bold" }}
              variant="h4"
            >
              OUR TOP LETTER TEMPLATES
            </Typography>

            <div className={classes.border} />
            <br />
            <br />
            <Grid container spacing={3}>
              {guideList
                ? guideList.map((v, i) => (
                    <Grid key={i} item lg={4} md={6} xs={12}>
                      <Guide guideList={v}></Guide>
                    </Grid>
                  ))
                : Array.from(Array(3).keys()).map((i) => (
                    <Box padding={3} key={i}>
                      <Skeleton variant="rect" width={250} height={200} />
                      <Skeleton animation="wave" />
                    </Box>
                  ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>

      {/* <CtaSection
        size="medium"
        bgImage={Goprogresscover}
        bgImageOpacity={1}
        title="Find your Progress Books today!"
        subtitle=""
        buttonText="Get Started"
        buttonColor="default"
        getStartedLink="/v2/goprogress/courses"
      /> */}

      {/* <HeroSection2
        bgColor="default"
        size="small"
        bgImage=""
        bgImageOpacity={1}
        title="Features"
      />
      <Tabz tabName={tabName} data={tabData} /> */}
      {/* <HeroBlock
        bgColor="goprogress"
        size="medium"
        bgImage={Progressdata_Hero}
        bgImageOpacity={0.3}
        title="Progress Your Way"
        subtitle="Unlock new skills today!"
        buttonText="Get Started"
        buttonColor="#002067 "
        getStartedLink="/v2/goprogress/courses"
      /> */}

      {/* <StepsSection
        title={'Progress your way in just a few steps'}
        step1={'1. Find your course today'}
        step2={'2. Sign up to get started'}
        step3={'3. Learn at your own pace'}
        step4={'4. Unlock your certificate!'}
        buttontext={'Get started today!'}
      /> */}
      {/* <FaqSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Frequently Asked Questions"
        subtitle=""
        items={items}
      /> */}

      {/* <CtaSection
        size="medium"
        bgImage={Goprogresscover}
        bgImageOpacity={1}
        title="Find your Progress Course today!"
        subtitle=""
        buttonText="Get Started"
        buttonColor="default"
        getStartedLink="/v2/goprogress/courses"
      /> */}
    </div>
  );
};

export default BookLandingPage;
