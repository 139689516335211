import React, { useEffect, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import clsx from "clsx";
import moment from "moment";
import { useSelector } from "react-redux";
import { withSnackbar } from "notistack";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
  Button,
  LinearProgress,
} from "@material-ui/core";

import { urls } from "../../../../utils/urls";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  details: {
    display: "flex",
  },
  avatar: {
    // marginRight: "auto",
    marginLeft: "auto",
    height: 100,
    width: 100,

    flexShrink: 0,
    flexGrow: 0,
    // borderRadius: "100%",
  },
  progress: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    marginRight: theme.spacing(2),
  },
  fileInput: {
    display: "none",
  },
  tooltip: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    zIndex: theme.zIndex.tooltip,
    opacity: 0,
    transition: "opacity 0.3s ease",
  },
  detailsHovered: {
    "&:hover $tooltip": {
      opacity: 1,
    },
  },
  roundedImage: {
    borderRadius: "50%", // Make the image rounded
    width: 120, // Set the width as needed
    height: 120, // Set the height as needed
    objectFit: "cover", // Ensure the image covers the entire container
    border: "2px solid #ccc", // Add a border to the image
    cursor: "pointer",
    margin: "5px",
    // Add a pointer cursor to indicate clickability
  },
}));

const MyAccount = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [userData, setUserData] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    fetchUserData();
    fetchUser();
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleAvatarClick = () => {
    document.getElementById("upload-avatar").click();
  };

  const handleUpload = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("image", selectedFile);

      const apiUrl = urls.userImagePut(userInfo.id);

      fetch(apiUrl, {
        method: "PUT",
        body: formData,
        headers: {
          // Include any additional headers needed for your API
          // "Authorization": "Bearer YOUR_ACCESS_TOKEN",
        },
      })
        .then((response) => {
          setUserData(response.data);
        })
        .then((data) => {
          // Handle the response from the server

          console.log("Avatar uploaded successfully:", data);
        })
        .catch((error) => {
          console.error("Error uploading avatar:", error);
        });
    }
  };

  const [val, setVal] = useState({
    date_of_birth: "",
    email: "",
    first_name: "",
    last_name: "",
    name: "",
    // image: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setVal({ ...val, [name]: value });
  };

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      Authorization: `Bearer ${userInfo && userInfo.access}`,
    },
  });

  const fetchUser = async (search = null) => {
    await authAxios.get(urls.userGetOrPut(userInfo.id)).then((res) => {
      setUserData(res.data);
    });
  };

  const fetchUserData = async (search = null) => {
    await authAxios.get(urls.userGetOrPut(userInfo.id)).then((res) => {
      setVal({
        first_name: res.data.first_name,
        last_name: res.data.last_name,
        name: res.data.name,
        email: res.data.email,
        date_of_birth: res.data.date_of_birth,
        // image: res.data.image,
      });
      //   setUserData(res.data);
    });
  };
  const { register, errors, handleSubmit, setValue, reset } = useForm({
    defaultValues: useMemo(() => {
      return val;
    }, [setVal]),
  });
  const handleSubmitData = async (e) => {
    await authAxios.put(urls.userGetOrPut(userInfo.id), e).then((res) => {
      props.enqueueSnackbar(`Profile Updated Successfully`, {
        variant: "success",
        autoHideDuration: 5000,
      });
      setUserData(res.data);
    });
  };

  const onSubmit = (e) => {
    const payload = {
      ...e,
      ...val,
    };
    handleSubmitData(payload);
  };
  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={4} md={6} xl={4} xs={12}>
          <Card {...rest} className={clsx(classes.root, className)}>
            <CardContent>
              <div style={{ textAlign: "center" }}>
                <input
                  accept="image/*"
                  className={classes.fileInput}
                  id="upload-avatar"
                  type="file"
                  onChange={handleFileChange}
                />

                {/* {userData && userData.image ? ( */}
                <Tooltip title="Upload Image" placement="middle">
                  <img
                    onClick={handleAvatarClick}
                    src={
                      selectedFile
                        ? URL.createObjectURL(selectedFile)
                        : userData && userData.image
                    }
                    
                    className={clsx(
                      classes.roundedImage,
                      classes.noImageBorder
                    )}
                  ></img>
                </Tooltip>

                {/* ) : ( */}
                {/* <Tooltip title="Upload Image" placement="middle">
                    <img
                      onClick={handleAvatarClick}
                      alt="Upload Image"
                      src={
                        selectedFile
                          ? URL.createObjectURL(selectedFile)
                          : userData && userData.image
                      }
                      className={clsx(
                        classes.roundedImage,
                        classes.noImageBorder
                      )}
                    ></img>
                  </Tooltip>
                )} */}
                <div>
                  <Typography gutterBottom variant="h5">
                    {userData && userData.name}
                  </Typography>

                  <Typography
                    className={classes.dateText}
                    color="textSecondary"
                    variant="body1"
                  >
                    {moment().format("hh:mm A")}
                  </Typography>
                </div>
              </div>
              <div className={classes.progress}>
                <Typography variant="body1">
                  Profile Completeness:
                  {userData && userData.completeness_percentage}%
                </Typography>
                <LinearProgress
                  value={userData && userData.completeness_percentage}
                  variant="determinate"
                />
              </div>
            </CardContent>
            <Divider />
            <CardActions>
              <Button
                className={classes.uploadButton}
                color="primary"
                size="small"
                variant="contained"
                onClick={handleUpload}
              >
                Upload picture
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item lg={8} md={6} xl={8} xs={12}>
          <Card {...rest} className={clsx(classes.root, className)}>
            <form
              onSubmit={handleSubmit((e) => onSubmit(e))}
              autoComplete="off"
              noValidate
            >
              <CardHeader
                subheader="The information can be edited"
                title="Profile Details"
              />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      helperText="Please specify the first name"
                      label="First name"
                      margin="dense"
                      name="first_name"
                      onChange={handleChange}
                      required
                      value={val.first_name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Last name"
                      margin="dense"
                      name="last_name"
                      onChange={handleChange}
                      required
                      value={val.last_name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Email Address"
                      margin="dense"
                      name="email"
                      onChange={handleChange}
                      required
                      value={val.email}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Full Name"
                      margin="dense"
                      name="name"
                      onChange={handleChange}
                      value={val.name}
                      variant="outlined"
                    />
                  </Grid>

                  {/* <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Date Of Birth"
                      margin="dense"
                      name="date_of_birth"
                      onChange={handleChange}
                      required
                      value={val.date_of_birth}
                      type="date"
                      InputProps={{
                        inputProps: {
                          max: new Date().toISOString().split("T")[0],
                        },
                      }}
                      variant="outlined"
                    />
                  </Grid> */}
                </Grid>
              </CardContent>
              <Divider />
              <CardActions>
                <Button type="submit" color="primary" variant="contained">
                  Save details
                </Button>
              </CardActions>
            </form>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};
export default withSnackbar(MyAccount);
