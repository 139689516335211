import React, { useEffect, useRef } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { green, pink } from "@material-ui/core/colors";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  makeStyles,
  Box,
  Button,
  LinearProgress,
} from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import MoneyIcon from "@material-ui/icons/Money";
import AddIcon from "@material-ui/icons/Add";
import DescriptionIcon from "@material-ui/icons/Description";
import AssignmentIcon from "@material-ui/icons/Assignment";
import CategoryModal from "../template/CategoryModal";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
  },
  content: {
    alignItems: "center",
    display: "flex",
  },

  //cards
  actionArea: {
    borderRadius: 16,
    transition: "0.2s",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  card: {
    MaxWidth: 200,
    borderRadius: 16,
    boxShadow: "none",
    "&:hover": {
      boxShadow: `0 6px 12px 0 #fff
        .rotate(-12)
        .darken(0.2)
        .fade(0.5)}`,
      cursor: "pointer",
    },
  },
  content: {
    backgroundColor: "#fff",
    padding: "1rem 1.5rem 1.5rem",
    textAlign: "center",
  },

  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const RecentDocuments = (props) => {
  const addDialog = useRef(null);
  const { blank, className, ...rest } = props;
  const [progress, setProgress] = React.useState(40);

  const classes = useStyles();

  return (
    <>
      {blank ? (
        <>
          <Box className={classes.actionArea} style={{ borderRadius: 16 }}>
            <Card className={classes.card} style={{ borderRadius: 16 }}>
              <CardContent className={classes.content}>
                <Box p={5}>
                  <Typography className={classes.title} variant="h6">
                    Create New Template
                  </Typography>
                  <Button
                    style={{
                      backgroundColor: "#CC626F",
                      color: "#fff",
                    }}
                    onClick={() => addDialog.current.handleClickOpen()}
                  >
                    <AddIcon></AddIcon>
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </>
      ) : (
        <>
          <Box className={classes.actionArea} style={{ borderRadius: 16 }}>
            <Card className={classes.card} style={{ borderRadius: 16 }}>
              <CardContent className={classes.content}>
                <Box p={5}>
                  <Avatar style={{ height: "70px", width: "70px" }}>
                    <AssignmentIcon style={{ height: "50px", width: "50px" }} />
                  </Avatar>
                  <Typography className={classes.title} variant="h6">
                    Professional CV
                  </Typography>{" "}
                </Box>
                <LinearProgressWithLabel value={progress} />
              </CardContent>
            </Card>
          </Box>
        </>
      )}
      <CategoryModal ref={addDialog}></CategoryModal>
    </>
  );
};

RecentDocuments.propTypes = {
  className: PropTypes.string,
};

export default RecentDocuments;
