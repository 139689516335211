import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import ReactGA from "react-ga";
import { Link, useHistory } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import SearchIcon from "@material-ui/icons/Search";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {
  Container,
  makeStyles,
  Button,
  Breadcrumbs,
  Typography,
  Box,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  CardMedia,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@material-ui/core";

import TemplateModal from "./TemplateModal";
import TemplateFilter from "../templateFilter/TemplateFilter";
import axiosInstance from "../../../../axios";
import { urls } from "../../../../utils/urls";
import HeroSection from "../../../../components/HeroSection";
import resume from "./resume.png";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "40vh",
    position: "relative",
    "& video": {
      objectFit: "cover",
    },
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#D8D1F2",
  },
  title: {
    paddingBottom: theme.spacing(4),
    display: "block",
    fontFamily: "Source Sans Pro, Arial, sans-serif",
    fontSize: "30px",
    fontStyle: "normal",
    fontVariant: "normal",
    fontWeight: "600",
    letterSpacing: "2px",
    lineHeight: "92px",
    textDecoration: "none solid rgb(31, 31, 31)",
    textAlign: "start",
    textIndent: "0px",
    textTransform: "none",
    verticalAlign: "baseline",
    whiteSpace: "normal",
    wordSpacing: "0px",

    color: "#58097D",
  },
  link: {
    display: "flex",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  card: {
    MaxWidth: 200,
    borderRadius: 16,
    transition: "0.2s",
    boxShadow: "none",
    "&:hover": {
      boxShadow: `0 6px 12px 0 #888888`,
      transform: "scale(1.1)",
    },
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
    justifyContent: "space-between",
    // backgroundColor: "#f7f9fa",
    backgroundColor: "#fff",
  },

  media: {
    height: 140,
  },
}));

function TemplateList() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [category, setCategory] = useState([]);
  const [search, setSearch] = useState("");
  const [templates, setTemplates] = useState([]);
  console.log(templates);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(null);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    //fetchCategory();
    //getTemplate();
  }, [dispatch]);

  useEffect(() => {
    fetchCategory();
  }, []);

  const fetchCategory = async (search = null) => {
    await axiosInstance.get(urls.templateCategory).then((res) => {
      setCategory(res.data);
    });
  };

  const handleSubmitCategory = async (queryParams) => {
    let response = null;
    setLoading(true);

    await axiosInstance.get(urls.getTemplateFilter(queryParams)).then((res) => {
      setLoading(false);
      setTemplates(res.data);
    });
  };

  const getTemplate = async (queryParams) => {
    let response = null;
    setLoading(true);

    await axiosInstance.get(urls.getTemplateList).then((res) => {
      setLoading(false);
      setTemplates(res.data);
    });
  };

  const addDialog = useRef(null);

  const handleSearch = (e) => {
    e.preventDefault();
    setBtnLoading(true);
  };
  return (
    <>
      {/* <section className={classes.root}>
        <div className={classes.overlay}>
          <Breadcrumbs
            style={{ marginLeft: "50px", marginTop: "10px" }}
            aria-label="breadcrumb"
          >
            <Link color="inherit" to="/" className={classes.link}>
              <HomeIcon className={classes.icon} />
              Dashboard
            </Link>
            <Link color="inherit" to="/#templates" className={classes.link}>
              Templates
            </Link>
          </Breadcrumbs>
          <Box
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            color="#fff"
          >
            <Typography variant="h5" component="h1" className={classes.title}>
              Select Your Professional Template
            </Typography>
          </Box>
        </div>
      </section> */}
      <HeroSection
        variant="h6"
        color="white"
        bgColor="#white"
        size="medium"
        textColor="#0a0a55"
        bgImage={resume}
        bgImageOpacity={1}
        title="Select Your Professional Template"
      />

      {/* <Box sx={{ mb: 3 }}>
        <Card>
          <CardContent>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <form isLoading={false} onSubmit={handleSearch}>
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <FormControl variant="outlined" size="small">
                    <InputLabel htmlFor="search">Search</InputLabel>
                    <OutlinedInput
                      id="search"
                      name="search"
                      type="type"
                      color="primary"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton type="submit">
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={70}
                    />
                  </FormControl>
                  <Button
                    style={{ marginLeft: "5px" }}
                    color="primary"
                    variant="contained"
                    type="submit"
                  >
                    search
                    {btnLoading && (
                      <CircularProgress
                        style={{ marginLeft: "10px", color: "white" }}
                        size={15}
                      />
                    )}
                  </Button>
                </Box>
              </form>
            </Box>
          </CardContent>
        </Card>
      </Box> */}
      <Container>
        <Grid
          style={{ marginTop: "30px" }}
          container
          spacing={4}
          justifyContent="center"
        >
          <Grid item md={3}>
            <TemplateFilter
              handleSubmitCategory={handleSubmitCategory}
              category={category}
            ></TemplateFilter>
          </Grid>
          <Grid item md={9}>
            <Grid container spacing={4} justifyContent="center">
              {templates &&
                templates.length > 0 &&
                templates.map((temp) => (
                  <Grid item md={3}>
                    <Card className={classes.card}>
                      <CardActionArea>
                        <CardMedia
                          className={classes.media}
                          image={temp.image}
                          title={temp.name ? temp.name : "No Name"}
                        />

                        <CardContent>
                          <Typography gutterBottom variant="h6" component="h4">
                            {temp.name}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                      <CardActions>
                        <VisibilityIcon
                          color="primary"
                          style={{ cursor: "pointer" }}
                          onClick={() => addDialog.current.handleClickOpen()}
                        ></VisibilityIcon>
                        <Button
                          onClick={() =>
                            history.push(
                              `/dashboard/templates/single-template/${temp.id}/`
                            )
                          }
                          variant="contained"
                          color="primary"
                          fullWidth
                          size="small"
                        >
                          Select
                        </Button>
                      </CardActions>
                    </Card>
                    <TemplateModal tempImage={temp.image} ref={addDialog} />
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default TemplateList;
