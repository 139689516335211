import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function ShowHeader(props) {
  const location = useLocation();
  const [showHeader, setShowHeader] = useState(false);

  useEffect(() => {
    
    if (location.pathname.includes("dashboard")) {
      setShowHeader(false);
    } else if (location.pathname === "/") {
      setShowHeader(true);
    }
  }, [location]);

  return <>{showHeader && props.children}</>;
}

export default ShowHeader;
