import React, { useState, useEffect, useRef, useCallback } from "react";
import SideMenu from "../../components/dashboard/SideMenu";
import Stepper from "../../components/dashboard/Stepper";
import UserBasicInfo from "../../components/dashboard/UserBasicInfo";
import QuillEditor from "../../components/dashboard/QuillEditor";
import html2canvas from "html2canvas";
import Quill from "quill"; // ES6
import { jsPDF } from "jspdf";
import "quill/dist/quill.snow.css"; // ES6
import "../../static/docs.css";
import { saveAs } from "file-saver";
import * as quillToWord from "quill-to-word";
import ReactQuill from "react-quill";
const TOOLBAR_OPTIONS = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ font: [] }],
  [{ list: "ordered" }, { list: "bullet" }],
  ["bold", "italic", "underline"],
  [{ color: [] }, { background: [] }],
  [{ script: "sub" }, { script: "super" }],
  [{ align: [] }],
  ["image", "blockquote", "code-block"],
  ["clean"],
];

function DocumentGenerator() {
  const [template, setTemplate] = useState("");
  const [quill, setQuill] = useState();

  const wrapperRef = useCallback((wrapper) => {
    if (wrapper == null) return;
    wrapper.innerHTML = "";
    const editor = document.createElement("div");
    wrapper.append(editor);
    const q = new Quill(editor, {
      theme: "snow",
      modules: { toolbar: TOOLBAR_OPTIONS },
    });
    // q.disable();
    // q.setText("Loading...");
    setQuill(q);
  }, []);
  const handleChange = (value) => {
    setTemplate(value);
  };
  const a = () => {
    html2canvas(document.querySelector("#capture")).then((canvas) => {
      document.body.appendChild(canvas); // if you want see your screenshot in body.
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 10, 10);
      pdf.save("download.pdf");
    });
  };

  const export2Word = async (element, filename = "") => {
    const delta = quill.getContents();
    const quillToWordConfig = {
      exportAs: "blob",
    };
    const docAsBlob = await quillToWord.generateWord(delta, quillToWordConfig);
    saveAs(docAsBlob, "word-export.docx");
  };
  return (
    // <SideMenu>
    <>
      <div className="ccontainer" ref={wrapperRef}></div>
      <button onClick={() => export2Word("ql-editor", "jadu")}>export</button>
      {/* <Stepper>
        <UserBasicInfo />
        <div id="capture">
          {" "}
          <h1>hello world</h1>{" "}
        </div>
        <button onClick={a}>hello</button>
        <QuillEditor />
        <ReactQuill theme="snow" value={template} onChange={handleChange} />
      </Stepper> */}
    </>
    // </SideMenu>
  );
}

export default DocumentGenerator;
