import React, { useContext } from "react";

import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardActionArea,
  Button,
  CardActions,
  Container,
  Badge,
  CardMedia,
  makeStyles,
  Chip,
  Box,
  AppBar,
  CssBaseline,
  Slide,
  Toolbar,
  useScrollTrigger,
} from "@material-ui/core";

import { useHistory } from "react-router-dom";

import cv1 from "../../cv1.jpg";
import cv2 from "../../cv2.png";
import cv3 from "../../cv3.jpg";
import cv4 from "../../cv4.jpg";

const useStyles = makeStyles({
  card: {
    MaxWidth: 200,
    borderRadius: 16,
    transition: "0.2s",
    boxShadow: "none",
    "&:hover": {
      boxShadow: `0 6px 12px 0 #888888`,
      transform: "scale(1.1)",
    },
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
    justifyContent: "space-between",
    backgroundColor: "#f7f9fa",
  },

  media: {
    height: 140,
    width: 100,
  },
});

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

// HideOnScroll.propTypes = {
//   children: PropTypes.element.isRequired,
//   /**
//    * Injected by the documentation to work in an iframe.
//    * You won't need it on your project.
//    */
//   window: PropTypes.func,
// };

function Templates(props) {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div>
      {/*  className="left" */}

      <Grid container spacing={2} justifyContent="center">
        <Grid item md={6}>
          <Card className={classes.card}>
            <CardActionArea>
              {/* <CardMedia
                className={classes.media}
                image={cv1}
                title={`hello`}
              /> */}
              <img src={cv1}></img>
              <CardContent>
                {/* <Typography gutterBottom variant="h6" component="h4">
                  Professional CV
                </Typography> */}
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button
                onClick={() =>
                  history.push(`/dashboard/documents/single-templates/cv1`)
                }
                variant="contained"
                color="primary"
                fullWidth
                size="small"
              >
                Select
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item md={6}>
          <Card className={classes.card}>
            <CardActionArea>
              {/* <CardMedia
                className={classes.media}
                image={cv1}
                title={`hello`}
              /> */}
              <img src={cv2}></img>
              <CardContent>
                {/* <Typography gutterBottom variant="h6" component="h4">
                  Professional CV
                </Typography> */}
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button
                onClick={() => history.push(`/resources/`)}
                variant="contained"
                fullWidth
                color="primary"
                size="small"
              >
                Select
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item md={6}>
          <Card className={classes.card}>
            <CardActionArea>
              {/* <CardMedia
                className={classes.media}
                image={cv1}
                title={`hello`}
              /> */}
              <img src={cv3}></img>
              <CardContent>
                {/* <Typography gutterBottom variant="h6" component="h4">
                  Professional CV
                </Typography> */}
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button
                onClick={() => history.push(`/resources/`)}
                variant="contained"
                fullWidth
                color="primary"
                size="small"
              >
                Select
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item md={6}>
          <Card className={classes.card}>
            <CardActionArea>
              {/* <CardMedia
                className={classes.media}
                image={cv1}
                title={`hello`}
              /> */}
              <img src={cv4}></img>
              <CardContent>
                {/* <Typography gutterBottom variant="h6" component="h4">
                  Professional CV
                </Typography> */}
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button
                onClick={() => history.push(`/resources/`)}
                variant="contained"
                fullWidth
                color="primary"
                size="small"
              >
                Select
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>

      {/* <hr className={myClasses.hr2} /> */}
      {/* <h2 className={myClasses.templatesH2}>Templates</h2>
      <div className={myClasses.cards}>
        <div className={myClasses.templateCard}>
          <img src={thumbn} alt="thumbnail" className={myClasses.imgThumb} />
          <Button
            className={classes.headerLink}
            component={Link}
            to="/basic/header"
          >
            The Basic
          </Button>
        </div> */}
      {/* Placeholder for a second template */}
      {/* <div className={myClasses.templateCard}>
          <img src={thumbn} alt="thumbnail" className={myClasses.imgThumb} />
          <Button
            className={classes.headerLink}
            component={Link}
            to="/basic/header"
          >
            The Stylish
          </Button>
        </div> */}
    </div>
  );
}

export default Templates;
