import React, { useState } from "react";
import Section from "./Section";
import { Link } from "react-router-dom";

import {
  AppBar,
  Container,
  Toolbar,
  Hidden,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 28,
    marginRight: theme.spacing(2),
  },
  drawerList: {
    width: 250,
  },
  spacer: {
    flexGrow: 1,
  },
}));

function Navbar(props) {
  const classes = useStyles();

  // const darkMode = useDarkMode();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuState, setMenuState] = useState(null);

  // Use inverted logo if specified
  // and we are in dark mode

  // const logo =
  // props.logoInverted && darkMode.value ? props.logoInverted : props.logo;

  const logo = props.logo;

  const handleOpenMenu = (event, id) => {
    // Store clicked element (to anchor the menu to)
    // and the menu id so we can tell which menu is open.
    setMenuState({ anchor: event.currentTarget, id });
  };

  const handleCloseMenu = () => {
    setMenuState(null);
  };

  return (
    <Section
      style={{ backgroundColor: props.bgColor }}
      bgColor={props.color}
      size="auto"
    >
      <AppBar position="static" color="transparent" elevation={0}>
        <Container disableGutters={true}>
          <Toolbar>
            {props.logoLink && (
              <Link to={props.logoLink}>
                <a>
                  <img
                    style={{ height: props.logoHeight, width: props.logoWidth }}
                    src={logo}
                    alt="Logo"
                    className={classes.logo}
                  />
                </a>
              </Link>
            )}

            <div className={classes.spacer} />

            <Hidden xsDown={true} implementation="css">
              <Link to={props.firstButtonLink} passHref={true}>
                {props.firstButtonText && (
                  <Button
                    style={{
                      backgroundColor: "#002067",
                      color: "#FFFFFF",
                      padding: "18px 36px",
                      marginRight: "10px",
                    }}
                    component="a"
                  >
                    {props.firstButtonText}
                  </Button>
                )}
              </Link>
              <Link to={props.secondButtonLink} passHref={true}>
                <Button
                  style={{
                    backgroundColor: "#002067",
                    color: "#FFFFFF",
                    padding: "18px 36px",
                  }}
                  component="a"
                >
                  {props.secondButtonText}
                </Button>
              </Link>
            </Hidden>
          </Toolbar>
        </Container>
      </AppBar>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <List
          className={classes.drawerList}
          onClick={() => setDrawerOpen(false)}
        >
          {/* {!auth.user && ( */}
          <Link href="/v2/pricing" passHref={true}>
            <ListItem button={true} component="a">
              <ListItemText>Pricing</ListItemText>
            </ListItem>
          </Link>
          {/* )} */}
        </List>
      </Drawer>
    </Section>
  );
}

export default Navbar;
