import React, { useContext } from "react";
import AppContext from "../../../../../../../src/context/AppContext";

function PoorStandardOfService({ template }) {
  const { signImgUrl, signText } = useContext(AppContext);
  return (
    <div id="poor-standard-of-service" style={{ padding: "5px" }}>
      <div style={{ textAlign: "right" }}>
        {template.sections[0].fields[0].field_content ? (
          <p>{template.sections[0].fields[0].field_content}</p>
        ) : (
          <p>[Your Full Name]</p>
        )}
        {template.sections[0].fields[1].field_content ? (
          <p>{template.sections[0].fields[1].field_content}</p>
        ) : (
          <p>[Your Address]</p>
        )}
        {template.sections[0].fields[2].field_content ? (
          <p>{template.sections[0].fields[2].field_content}</p>
        ) : (
          <p>[Your Email]</p>
        )}
        {template.sections[0].fields[3].field_content ? (
          <p>{template.sections[0].fields[3].field_content}</p>
        ) : (
          <p>[Your Phone Number]</p>
        )}
      </div>
      <div>
        {template.sections[1].fields[0].field_content ? (
          <p>{template.sections[1].fields[0].field_content}</p>
        ) : (
          <p>[Trader's Name]</p>
        )}
        {template.sections[1].fields[1].field_content ? (
          <p>{template.sections[1].fields[1].field_content}</p>
        ) : (
          <p>[Trader's Address]</p>
        )}
      </div>
      <div style={{ textAlign: "right" }}>
        <p>9 April 2024</p>
      </div>
      <div>
        {template.sections[1].fields[3].field_content ? (
          <p>{template.sections[1].fields[3].field_content}</p>
        ) : (
          <p>To whom it may concern,</p>
        )}
      </div>
      <div>
        <strong>Consumer Rights Act 2015</strong>
        <p>
          On{" "}
          {template.sections[2].fields[0].field_content ? (
            <span>{template.sections[2].fields[0].field_content}</span>
          ) : (
            <span>[When Did You Sign The Contract]</span>
          )}
          , you agreed to the following work:{" "}
          {template.sections[2].fields[1].field_content ? (
            <span>{template.sections[2].fields[1].field_content}</span>
          ) : (
            <span>[What Service Did You Agree]</span>
          )}
        </p>
        <p>
          We agreed this work would be completed by{" "}
          {template.sections[2].fields[2].field_content ? (
            <span>{template.sections[2].fields[2].field_content}</span>
          ) : (
            <span>[When Did You Agree The Work Would Be Completed]</span>
          )}{" "}
          at a cost of{" "}
          {template.sections[2].fields[3].field_content ? (
            <span>{template.sections[2].fields[3].field_content}</span>
          ) : (
            <span>[How Much Did You Pay]</span>
          )}
        </p>
        <p>However, I have had the following problems:</p>
        {template.sections[3].fields[0].field_content ? (
          <span>{template.sections[3].fields[0].field_content}</span>
        ) : (
          <span>[Describe The Problem]</span>
        )}
        <p>
          According to the law named above, I am entitled to have this work done
          by the date we agreed.
        </p>
        <p>
          I would like you to{" "}
          {template.sections[3].fields[1].field_content ? (
            <span>{template.sections[3].fields[1].field_content}</span>
          ) : (
            <span>[How Would You Like This Problem To Be Solved]</span>
          )}
          .
        </p>
        <p>
          I would like you to{" "}
          {template.sections[3].fields[2].field_content ? (
            <span>{template.sections[3].fields[2].field_content}</span>
          ) : (
            <span>
              [Are You Waiting For The Work To Be Started Or Finished]
            </span>
          )}{" "}
          this work in the next 14 days.
        </p>
        <p>
          If you fail to{" "}
          {template.sections[3].fields[2].field_content ? (
            <span>{template.sections[3].fields[2].field_content}</span>
          ) : (
            <span>[start/finish]</span>
          )}{" "}
          the work in this time, I will have no option but to consider taking
          the matter further.
        </p>
        <p>Yours faithfully,</p>
        <p>
          {signImgUrl ? <img src={signImgUrl} alt="signature" /> : signText}
        </p>
        {template.sections[0].fields[0].field_content ? (
          <p>{template.sections[0].fields[0].field_content}</p>
        ) : (
          <p>[Your Full Name]</p>
        )}
      </div>
    </div>
  );
}

export default PoorStandardOfService;
