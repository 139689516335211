const baseURL = process.env.REACT_APP_BACKEND_URL;

export const urls = {
  //ai
  aiChatUrl: `${baseURL}/api/ai/conversation/`,
  // visa
  visaUrl: `${baseURL}/api/guides/visa-type/`,
  visaTypeUrl: `${baseURL}/api/guides/pdf/search/visa-type/`,
  //guides
  getGuides: `${baseURL}/api/guides/pdf/`,
  addToCartUrl: `${baseURL}/api/guides/add-to-cart/`,
  getCartUrl: `${baseURL}/api/guides/order-summary/`,
  deleteGuideUrl: (id) => `${baseURL}/api/guides/order-items/${id}/delete/`,
  checkoutUrl: `${baseURL}/api/guides/checkout/`,
  getPurchaseUrl: `${baseURL}/api/guides/purchase-history/`,

  // letter
  getLetterUrl: `${baseURL}/api/guides/letter-template/`,

  // template category
  templateCategory: `${baseURL}/api/templates/template-categories/`,
  // get template list
  getTemplateList: `${baseURL}/api/templates/template-list/`,
  // update template order
  updateTemplateOrder: `${baseURL}/api/templates/template-order-update/`,
  // get template list by user
  getUserTemplateList: (user_id) =>
    `${baseURL}/api/templates/template-list/${user_id}/`,
  // get template filter
  getTemplateFilter: (queryParms) =>
    `${baseURL}/api/templates/template-filter/?${queryParms}`,
  // get my template filter
  getMyTemplateFilter: (user_id, queryParms) =>
    `${baseURL}/api/templates/my-template-filter/${user_id}/?${queryParms}`,

  // search template list
  getTemplateSearch: (search) =>
    `${baseURL}/api/templates/template-list/search/${search}/`,
  // search user template list
  getUserTemplateSearch: (user_id, search) =>
    `${baseURL}/api/templates/user-template-list/${user_id}/search/${search}/`,
  // create template
  createTemplate: `${baseURL}/api/templates/create-template/`,

  // update template
  updateTemplate: (template_id) =>
    `${baseURL}/api/templates/update-template/${template_id}/`,

  // create template section
  createTemplateSection: (template_id) =>
    `${baseURL}/api/templates/create-template-section/${template_id}/`,
  // update template section
  updateTemplateSection: (section_id) =>
    `${baseURL}/api/templates/update-template-section/${section_id}/`,

  // delete template section
  deleteTemplateSection: (section_id) =>
    `${baseURL}/api/templates/delete-template-section/${section_id}/`,

  // create template field
  createTemplateField: (section_id) =>
    `${baseURL}/api/templates/create-template-field/${section_id}/`,
  // update template field
  updateTemplateField: (field_id) =>
    `${baseURL}/api/templates/update-template-field/${field_id}/`,
  // delete template field
  deleteTemplateField: (field_id) =>
    `${baseURL}/api/templates/delete-template-field/${field_id}/`,

  // get single templates
  singleTemplate: (id) => `${baseURL}/api/templates/single-template/${id}/`,
  // get user and put user
  userGetOrPut: (id) => `${baseURL}/api/users/${id}/`,
  userImagePut: (id) => `${baseURL}/api/users/image/${id}/`,

  // post
  postGrievanceLetter: `${baseURL}/api/templates/grievance/`,

  // update GRIEVANCE LETTER
  updateGrievanceLetter: (template_id) =>
    `${baseURL}/api/templates/grievance/update/${template_id}/`,
};
