import React from "react";

import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormLabel from "@mui/material/FormLabel";

export function RadioButtonsGroup(props) {
  return (
    <FormControl>
      <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="text"
        name="radio-buttons-group"
        onChange={props.onChange}
      >
        <FormControlLabel value="text" control={<Radio />} label="text" />
        <FormControlLabel value="number" control={<Radio />} label="number" />
        <FormControlLabel value="date" control={<Radio />} label="date" />
        <FormControlLabel value="mcq" control={<Radio />} label="mcq" />
      </RadioGroup>
    </FormControl>
  );
}

export default RadioButtonsGroup;
