import {
  CART_START,
  CART_SUCCESS,
  CART_FAIL,
} from "../constants/cartConstants";

import AuthAxios from "../../AuthAxios";
import axios from "axios";
// import { orderSummaryURL } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
const orderSummaryURL = `http://127.0.0.1:8000/api/guides/order-summary/`;

export const cartStart = () => {
  return {
    type: CART_START,
  };
};

export const cartSuccess = (data) => {
  return {
    type: CART_SUCCESS,
    data,
  };
};

export const cartFail = (error) => {
  return {
    type: CART_FAIL,
    error: error,
  };
};

export const fetchCart = (userInfo) => {
  // const userLogin = useSelector((state) => state.userLogin);
  // const { userInfo } = userLogin;

  const authAxios = axios.create({
    baseURL: "http://127.0.0.1:8000/",
    headers: {
      Authorization: `Bearer ${userInfo}`,
    },
  });
  return (dispatch) => {
    dispatch(cartStart());
    authAxios
      .get(orderSummaryURL)
      .then((res) => {
        dispatch(cartSuccess(res.data));
      })
      .catch((err) => {
        dispatch(cartFail(err));
      });
  };
};
