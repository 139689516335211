import React from "react";
import { Box, Button, Typography, Stack } from "@mui/material";
import { useHistory } from "react-router-dom";
import feature1 from "../assets1/feature1.png";
import feature2 from "../assets1/feature2.1.png";
import manBg from "../assets1/man.svg";
import manWithSuit from "../assets1/second-man.png";

function Features() {
  const history = useHistory();
  return (
    <Box px={{ xs: "2%", sm: "7%" }}>
      {/* section first */}
      <Stack direction={{ xs: "column", sm: "row" }}>
        <Box flex={{ sm: "0.5", md: "0.4" }} pt={{ sm: "0%", md: "15%" }}>
          <Typography
            variant="h2"
            fontWeight="800"
            fontSize={{ xs: "34px", sm: "44px", md: "54px" }}
            mb={{ xs: "15px", sm: "25px" }}
          >
            Get thousands of templates
          </Typography>
          <Typography
            variant="body1"
            fontWeight="500"
            mb={{ xs: "15px", sm: "25px" }}
          >
            Need to appeal a parking ticket? There is a template for that! Need
            to apply for a UK VIsa? There is a template for that. Accelerate
            your writing with Docelerate!
          </Typography>
          <Button
            variant="contained"
            sx={{ backgroundColor: " #EE8F7B", color: "#fff" }}
            onClick={() => history.push("/signup")}
          >
            Start free
          </Button>
        </Box>
        <Box flex="0.6">
          <img className="feature1-image" src={manBg} alt="person" />
        </Box>
      </Stack>
      {/* section two */}
      <Stack direction={{ xs: "column-reverse", sm: "row" }}>
        <Box
          flex={{ sm: "0.5", md: "0.6" }}
          pt={{ xs: "10%", sm: "5%", lg: "0%" }}
        >
          <img className="feature2-image" src={manWithSuit} alt="person" />
        </Box>
        <Box flex="0.4" pt={{ sm: "0%", md: "5%" }}>
          <Typography
            variant="h2"
            fontWeight="800"
            fontSize={{ xs: "34px", sm: "44px", md: "54px" }}
            mb={{ xs: "15px", sm: "25px" }}
          >
            Save on legals fees!
          </Typography>
          <Typography
            variant="body1"
            fontWeight="500"
            mb={{ xs: "15px", sm: "10px", md: "25px" }}
          >
            Lawyers are not cheap! Why not save money by using our lawyer-made
            letter templates instead?
          </Typography>
          <Button
            variant="contained"
            sx={{ backgroundColor: " #EE8F7B", color: "#fff" }}
            onClick={()=>history.push("/signup")}
          >
            Start free
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}

export default Features;
