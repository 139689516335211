import React, { useState, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import queryString from "query-string";

import Icon from "@material-ui/core/Icon";
import classNames from "classnames";

import {
  Paper,
  Grid,
  Button,
  Accordion,
  AccordionSummary,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  DialogActions,
  MenuItem,
  DialogContent,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import InputRange from "react-input-range";

import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";

import styles from "./styles.css";
import { urls } from "../../../../utils/urls";
import axiosInstance from "../../../../axios";

const useStyles = makeStyles((theme) => ({
  Root: {
    marginLeft: 0,
    marginRight: 0,
  },
  Paper: {
    padding: "20px",
    marginBottom: "15px",
    background: "#f7f9fa",
  },
  Accordion: {
    boxShadow: "none",
    backgroundColor: "transparent",
  },
  Margin0: {
    margin: 0,
  },
  AccordionSummary: {
    padding: 0,
    minHeight: "48px !important",
  },
  ExpandIcon: {
    paddingRight: 0,
    paddingLeft: 0,
    right: 0,
  },
  SummaryContent: {
    margin: "12px 0 0 0 !important",
  },
  Title: {
    marginTop: 0,
    position: "relative",
    marginBottom: 0,
  },
  AccordionContent: {
    maxHeight: "300px",
    overflow: "auto",
    /* overflow-y: scroll; */
    padding: "5px 15px 5px 0",
  },
  List: {
    height: "max-content",
    width: "100%",
  },
}));

const TemplateFilter = (props) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [val, setVal] = useState({
    category: "",
    subcategory: "",
  });
  const { register, errors, handleSubmit, setValue, reset } = useForm({
    defaultValues: useMemo(() => {
      return val;
    }, [setVal]),
  });

  const handleFieldChange = (event) => {
    const { name, value } = event.target;

    setVal({ ...val, [name]: value });
  };

  //   const handleSubmitCategory = async (e, edit, courseName) => {
  //     let response = null;
  //     setLoading(true);

  //     const queryParams = queryString.stringify({
  //       ...val,
  //     });

  //     response = await axiosInstance
  //       .get(urls.getTemplate(queryParams))
  //       .then((res) => {
  //         setLoading(false);
  //         setLetter(res.data);
  //       });
  //     if (response.status === 200) {

  //       console.log("will recieve course");
  //     }
  //   };
  const onSubmit = (e) => {
    const queryParams = queryString.stringify({
      ...val,
    });
    props.handleSubmitCategory(queryParams);
  };

  return (
    <React.Fragment>
      <Paper
        tour={"Filters"}
        elevation={0}
        className={`${classes.Paper} ${styles.Filters}`}
      >
        <Grid container spacing={2}>
          <Grid item md={12} xs={6}>
            <Accordion
              defaultExpanded
              classes={{
                root: classes.Accordion,
                expanded: classes.Margin0,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                classes={{
                  root: classes.AccordionSummary,
                  expandIcon: classes.ExpandIcon,
                  content: classes.SummaryContent,
                }}
              >
                <h4 textAlign="center" className={classes.Title}>
                  Filter
                </h4>
              </AccordionSummary>
              <AccordionSummary classes={{ root: classes.AccordionContent }}>
                <form onSubmit={handleSubmit((e) => onSubmit(e))}>
                  <>
                    <FormControl fullWidth>
                      <InputLabel>Category</InputLabel>
                      <Select
                        fullWidth
                        value={val.category}
                        onChange={handleFieldChange}
                        name="category"
                      >
                        {props.category &&
                          props.category.length > 0 &&
                          props.category.map((cat) => (
                            <MenuItem key={cat.id} value={cat.id}>
                              {cat.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <br></br>
                    <br></br>
                    <FormControl fullWidth>
                      <InputLabel>Sub Category</InputLabel>
                      <Select
                        fullWidth
                        value={val.subcategory}
                        onChange={handleFieldChange}
                        name="subcategory"
                      >
                        {props.category &&
                          props.category.length > 0 &&
                          props.category.map(
                            (cat) =>
                              cat.sub_category &&
                              cat.sub_category.length > 0 &&
                              cat.sub_category.map((sub_cat) => (
                                <MenuItem key={sub_cat.id} value={sub_cat.id}>
                                  {sub_cat.name}
                                </MenuItem>
                              ))
                          )}
                      </Select>
                    </FormControl>
                  </>
                  <div style={{ padding: "30px" }}>
                    <Button
                      fullWidth
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      Apply Filter
                      {/* {updateLoading && (
                <CircularProgress style={{ marginLeft: '10px' }} size={15} /> */}
                    </Button>
                  </div>
                </form>
              </AccordionSummary>
            </Accordion>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
};

export default TemplateFilter;
