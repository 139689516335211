import React from "react";
import { Box, Typography } from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import AddIcon from "@mui/icons-material/Add";

function Group(props) {
  return (
    <Box
      id={props.groupId}
      ref={props.groupRef}
      className={props.classes.fields}
    >
      {props.modal}
      {props.modalEdit}
      <>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Box style={{ flex: 1 }}>{props.groupName}</Box>
            <Box display={"flex"} alignItems={"center"}>
              <Tooltip title={"Edit"}>
                <EditIcon
                  sx={{ color: "#4494ea" }}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    props.handleOpenModal3();
                    props.editGroupName(props.parentId);
                  }}
                />
              </Tooltip>
              <Box onClick={() => props.deleteGroup(props.parentId)}>
                <Tooltip title={"Delete"}>
                  <DeleteIcon
                    sx={{ color: "#d83864" }}
                    style={{ cursor: "pointer" }}
                  />
                </Tooltip>
              </Box>
            </Box>
          </AccordionSummary>
          <Divider />
          <AccordionDetails>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              onClick={() => {
                //props.createFormField(props.parentId);
                props.handleOpenModal2();
                //props.addChildToParent(props.parentId);
              }}
            >
              <Tooltip title={"Add field"}>
                <Box
                  width={30}
                  height={30}
                  borderRadius={"50%"}
                  padding={"5px"}
                  bgcolor={"#00c853"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  marginTop={"10px"}
                  marginBottom={"10px"}
                  style={{ cursor: "pointer" }}
                >
                  <AddIcon sx={{ color: "white" }} />
                </Box>
              </Tooltip>
            </Box>
            {/* {props.formFieldList} */}
            {props.formFieldList.map((el) => el)}
          </AccordionDetails>
        </Accordion>
        <Box display={"flex"} alignItems={"center"}></Box>
      </>
    </Box>
  );
}

export default Group;
