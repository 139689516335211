import React, { useContext } from "react";
import TextField from "@material-ui/core/TextField";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import SendIcon from "@material-ui/icons/Send";
import Button from "@material-ui/core/Button";
import AppContext from "../../../../../../context/AppContext";

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapForm: {
      display: "flex",
      justifyContent: "center",
      width: "95%",
      margin: `${theme.spacing(0)} auto`,
    },
    wrapText: {
      width: "100%",
    },
    button: {
      //margin: theme.spacing(1),
    },
  })
);

const TextInput = (props) => {
  const classes = useStyles();
  const { setChatAIResult } = useContext(AppContext);
  return (
    <>
      <form className={classes.wrapForm} noValidate autoComplete="off">
        <TextField
          id="standard-text"
          label="Type here"
          className={classes.wrapText}
          value={props.chatMsg}
          onChange={(e) => props.setChatMsg(e.target.value)}
          //margin="normal"
        />
        <Button
          onClick={() => {
            props.setIsLoading(true);
            props.sendMsg();
            props.chatWithAI(props.chatMsg);
            setTimeout(() => {
              document.getElementById(
                "message"
              ).scrollTop = document.getElementById("message").scrollHeight;
            }, 1000);
          }}
          variant="contained"
          color="primary"
          className={classes.button}
        >
          <SendIcon />
        </Button>
      </form>
    </>
  );
};

export default TextInput;
