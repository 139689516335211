import React, { useEffect, useState, useRef, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { fabric } from "fabric";
import { v4 as uuidv4 } from "uuid";
import { Update } from "@material-ui/icons";
import WidgetsIcon from "@mui/icons-material/Widgets";
import ImageIcon from "@mui/icons-material/Image";
import ApprovalIcon from "@mui/icons-material/Approval";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CircularProgress from "@mui/material/CircularProgress";
import SearchIcon from "@mui/icons-material/Search";
import Carousel from "react-material-ui-carousel";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import EditIcon from "@mui/icons-material/Edit";
import Tabs from "@mui/material/Tabs";
import SignatureCanvas from "react-signature-canvas";
import Divider from "@mui/material/Divider";
import Tab from "@mui/material/Tab";
import TuneIcon from "@mui/icons-material/Tune";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import TextEditor from "../../../../../screens/dashboard/document/existingDocument/templateView/components/TextEditor.js";
import RadioButtonsGroup from "./components/RadioButtonGroup.js";
import GroupField from "./components/GroupField.js";
import Group from "./components/Group.js";
import Modal2 from "./components/Modal2.js";
import Modal1 from "./components/Modal1.js";
import Modal3 from "./components/Modal3.js";
import SignModal from "./components/SignModal.js";
import { MessageLeft, MessageRight } from "./components/Message.js";
import TextInput from "./components/TextInput.js";
import BasicSelect from "./components/Select.js";
import AppContext from "../../../../../context/AppContext.js";
import templateImg from "../../../../Home/assets1/template-img.png";
import {
  Button,
  Container,
  Grid,
  makeStyles,
  Box,
  Typography,
  Input,
  TextField,
} from "@material-ui/core";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import CloseIcon from "@mui/icons-material/Close";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import TemplateLayout from "../templateLayOut/TemplateLayout.js";
import TextureIcon from "@mui/icons-material/Texture";
import LineStyleIcon from "@mui/icons-material/LineStyle";

import { urls } from "../../../../../utils/urls.js";
import axiosInstance from "../../../../../axios.js";

const useStyles = makeStyles((theme) => ({
  app: {
    display: "flex",
    minHeight: "100vh",
  },
  leftBarCircle: {
    width: "3.2rem",
    height: "3.2rem",
    borderRadius: "50%",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },

  leftBarBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "20px",
    marginBottom: "20px",
    zIndex: 1000,
  },
  leftBar: {
    backgroundColor: "#fff",
    padding: "0 7px",
    height: "100vh",
    // width: "5vw",
    flex: "20%",
    position: "fixed",
    padding: "14px",
    backgroundColor: "#F0F0F0",

    // width: "6vw",
  },
  rightBar: {
    backgroundColor: "#f2f3f5",
    // width: "100vw",
    flex: "80%",
    marginLeft: "110px",
  },
  rightBarContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    position: "relative",
  },

  rightBarContainerLeft: {
    padding: "10px",
    position: "fixed",
    top: 70,
    left: 140,
    width: "20%",
    border: "1px solid #696969",
    borderRadius: "10px",
    height: "88vh",
    overflowY: "auto",
    zIndex: 50,
  },

  rightBarContainerRight: {
    position: "absolute",
    top: 75,
    right: 60,
    width: "850px",
    // border: "1px solid red",
    borderRadius: "10px",
  },

  leftBarSmallText: {
    fontSize: "0.9rem",
  },

  whiteCardOne: {
    backgroundColor: "#fff",
    padding: "10px",
    marginBottom: "8px",
  },

  whiteCardTwo: {
    backgroundColor: "#fff",
    padding: "10px",
    marginBottom: "8px",
  },

  whiteCardThree: {
    backgroundColor: "#fff",
    padding: "10px",
    marginBottom: "8px",
  },

  fields: {
    backgroundColor: "#fff",
    padding: "10px",
    marginBottom: "8px",
  },

  whiteCardOneSmallText: {
    fontSize: "12px",
  },

  tabBox: {},

  tabCircle: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    backgroundColor: "#bdbdbd",
    backgroundColor: "lightgray",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },

  lensBoxContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  lensBox: {
    display: "flex",
    alignItems: "center",
  },

  configBox: {},

  searchTempTxt: {
    fontSize: "12px",
    color: "#929595",
  },

  templateImg: {
    width: "100%",
    height: "100%",
    marginTop: "0px",
    marginRight: "0px",
    marginLeft: "0px",
    // maxWidth: "95px",
    maxHeight: "160px",
    height: "160px",
    display: "block",
    cursor: "pointer",
    objectFit: "cover",
  },

  templateBtn: {
    fontSize: "12px",
    color: "#fff",
    width: "70%",
    backgroundColor: "#5400b8",
    paddingRight: "40px",
    paddingLeft: "40px",
    marginTop: "15px",
    marginBottom: "15px",
  },

  minorNav: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px",
    boxShadow: "0px 2px 2px #bdbdbd",
    width: "90%",
    position: "fixed",
    top: 0,

    zIndex: 100,
    opacity: 1,
    backgroundColor: "#f1f1f1",
  },

  previewBtn: {
    backgroundColor: "#3f94ed",
    color: "#fff",
  },

  mainContainer: {
    display: "flex",
    width: "100%",
    // marginTop: "127px",
  },
  poppinsSemiBold: {
    fontFamily: "Poppins, sans-serif",
    fontWeight: 600,
  },

  // styling for ai chat
  wrapForm: {
    display: "flex",
    justifyContent: "center",
    width: "95%",
    margin: `${theme.spacing(0)} auto`,
  },
  wrapText: {
    width: "100%",
  },
  button: {
    //margin: theme.spacing(1),
  },
}));

function EditorView(props) {
  //const location = useLocation();
  const [template, setTemplate] = useState({});
  const [templateName, setTemplateName] = useState("Template Name Here");
  const [shouldSetTemplateName, setShouldSetTemplateName] = useState(false);
  const [showTemplateInput, setShowTemplateInput] = useState(false);
  const [toolbar, SetToolbar] = useState(false);
  const [fieldContents, setFieldContents] = useState({});
  const [selectedValue, setSelectedValue] = useState("collins@gmail.com");
  const [aiChatResult, setAIChatResult] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showBackBtn, setShowBackBtn] = useState(false);

  const classes = useStyles();
  const history = useHistory();

  const [tabValue, setTabValue] = useState(0);

  const [fields, setFields] = useState(false);

  // const [isOpen, toggleIsOpen] = useState(false);

  //Context api
  const {
    templateStatus,
    changeShowPaperContent,
    showPaperContent,
    templates,
    setTemplates,
    targetTemplate,
    changeTargetTemplateQuestionValue,
    openSignModal,
    setOpenSignModal,
    changeTargetTemplate,
    setSignText,
    setSignImgUrl,
    changeTemplateQuestionValue,
    userId,
    useUserTemplates,
    isOpen,
    toggleIsOpen,
    isOpenAi,
    setIsOpenAi,
    aiPrompt,
    setAIPrompt,
    isChatAI,
    setIsChatAI,
    chatAIResult,
    setChatAIResult,
    isWidget,
    setIsWidget,
    isAI,
    setIsAI,
    widgetTextInput,
    widgetTextSize,
    widgetTextStyle,
    widgetTextColor,
    widgetTextDecoration,
    setWidgetTextInput,
    setWidgetTextSize,
    setWidgetTextStyle,
    setWidgetTextColor,
    setWidgetTextDecoration,
    showCanvas,
    setShowCanvas,
    openPreviewModalCanvas,
    setOpenPreviewModalCanvas,
    inputs,
    texts,
    setInputs,
    setTexts,
    handleInputChangeNew,
    handleAddInput,
  } = useContext(AppContext);

  //for modals
  const [open, setOpen] = React.useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [openModal3, setOpenModal3] = useState(false);

  //for group name
  const [groupName, setGroupName] = useState(null);
  const [groupNameEdit, setGroupNameEdit] = useState(null);

  //for field name
  const [fieldName, setFieldName] = useState(null);

  //for field name edit modal
  const [fieldNameEdit, setFieldNameEdit] = useState(null);

  const [showFieldNameError, setFieldNameError] = useState(false);
  const [showGroupNameError, setGroupNameError] = useState(false);

  //for radio
  const [fieldType, setFieldType] = useState("text");

  //for switch
  const [isFieldRequired, setIsFieldRequired] = useState(false);

  //Fabric js canvas
  const [canvas, setCanvas] = useState(null);

  const [isWidgetText, setIsWidgetText] = useState(true);

  const [isWidgetImage, setIsWidgetImage] = useState(false);

  const [isWidgetSignature, setIsWidgetSignature] = useState(false);

  const [clearUpperCase, setClearUpperCase] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");

  const [modalContent, setModalContent] = useState("");

  const [modalStyles, setModalStyles] = useState(null);

  const [rotationAngle, setRotationAngle] = useState(0);

  // Function to handle input change for search templates
  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleOpenModal2 = () => setOpenModal2(true);
  const handleCloseModal2 = () => setOpenModal2(false);

  const handleOpenModal3 = () => setOpenModal3(true);
  const handleCloseModal3 = () => setOpenModal3(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //For Radio Buttons Options
  const handleChangeRadio = (event) => {
    setFieldType(event.target.value);
  };

  //For switch
  const handleSwitch = (event) => setIsFieldRequired(event.target.checked);

  //for field name
  const handleFieldName = (event) => {
    setFieldName(event.target.value);
    setFieldNameError(false);
  };

  //for group name
  const handleGroupName = (event) => setGroupName(event.target.value);

  //for group name edit (on typing in textarea)
  const handleGroupNameEdit = (event) => setGroupNameEdit(event.target.value);

  //To create group
  const createGroup = (event) => {
    if (groupName === null || groupName.trim() === "") {
      setGroupNameError(true);
    } else {
      setGroupName(groupName);
      handleClose();
      setGroupNameError(false);
      createGroupList();
    }
  };

  //   To delete group
  const deleteGroup = (id) => {
    setGroupList((current) => current.filter((el) => el.id !== id));
  };

  const initCanvas = () => {
    const minHeight = 700;
    const canvasContainer = document.getElementById("canvasContainer");
    canvasContainer.style.height = minHeight + "px";
    return new fabric.Canvas("canvas", {
      preserveObjectStacking: true,
      height: minHeight,
      width: 750,
      backgroundColor: "#fff",
      selection: false,
      margin: "0 auto",
      renderOnAddRemove: true,
    });
  };

  const myCanvas = useRef();
  const groupRef = useRef();
  const signRef = useRef();
  const fileInputRef = useRef();

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  //add sign image to canvas fabric js
  function addImage(canvas, imageUrl) {
    // Check if the URL is not empty
    if (imageUrl) {
      // Create a Fabric.js image from the URL
      fabric.Image.fromURL(
        imageUrl,
        function (img) {
          // Optionally, scale the image to fit the canvas
          var imgWidth = img.width;
          var imgHeight = img.height;
          var maxWidth = canvas.width;
          var maxHeight = canvas.height;

          if (imgWidth > maxWidth || imgHeight > maxHeight) {
            var scaleFactor = Math.min(
              maxWidth / imgWidth,
              maxHeight / imgHeight
            );
            img.scale(scaleFactor);
          }

          // Add the image to the canvas
          canvas.add(img);
          canvas.renderAll();
        },
        { crossOrigin: "anonymous" }
      ); // Cross-Origin attribute if needed
    }
  }

  // Function to update canvas height based on content
  function updateCanvasHeight(canvas, canvasContainer, maxHeight) {
    const objects = canvas.getObjects();
    if (objects.length === 0) return;

    //let maxHeight = 700;

    objects.forEach((obj) => {
      const objBottom = obj.top + obj.height * obj.scaleY;
      if (objBottom > maxHeight) {
        maxHeight = objBottom;
      }
    });

    // Adding some padding to the bottom
    const padding = 550;
    const newHeight = maxHeight + padding;

    canvas.setHeight(newHeight);
    canvasContainer.style.height = newHeight + "px";
    canvas.renderAll();
  }

  //Fields for a group
  const [formFieldList, setFormFieldList] = useState([]);
  const [name, setName] = useState(null);
  const [index, setIndex] = useState(null);
  const [value, setValue] = useState(null);

  //for groups
  const [groupList, setGroupList] = useState([]);

  const [chatMsg, setChatMsg] = useState("");

  //Display for widget and chat ai
  const [chatAIDisplay, setChatAIDisplay] = useState("none");
  const [widgetDisplay, setIsWidgetDisplay] = useState("none");
  const [aiDisplay, setAIDisplay] = useState("none");

  //For switch
  const label = { inputProps: { "aria-label": "Switch demo" } };

  // useEffect(() => {
  //   fetchSingleTemplate();
  // }, []);

  useEffect(() => {
    const url = urls.getTemplateList;
    const urlUser = urls.getUserTemplateList(userId);
    const targetUrl = useUserTemplates === false ? url : urlUser;

    axiosInstance.get(targetUrl).then((res) => {
      const arr = [...res.data];
      setTemplates(arr);
    });
  }, []);

  useEffect(() => {
    setTemplateName(targetTemplate.name);
  }, [targetTemplate]);

  useEffect(() => {
    if (isChatAI === true) {
      setChatAIDisplay("block");
      setIsWidgetDisplay("none");
      setAIDisplay("none");
    } else if (isWidget === true) {
      setChatAIDisplay("none");
      setIsWidgetDisplay("block");
      setAIDisplay("none");
    } else if (isAI === true) {
      setChatAIDisplay("none");
      setIsWidgetDisplay("none");
      setAIDisplay("block");
    }
  }, [isChatAI, isWidget, isAI]);

  //Init Fabric js canvas
  useEffect(() => {
    setCanvas(initCanvas());
  }, []);

  //IText and prevent object from leaving canvas boundaries fabric js
  useEffect(() => {
    if (!canvas) {
      return;
    }

    //For fabric js
    const addText = (e) => {
      var text = new fabric.IText("", {
        left: e.offsetX,
        top: e.offsetY,
      });
      canvas.add(text).setActiveObject(text);
      text.enterEditing();
    };

    canvas.on("mouse:down", (options) => {
      if (options.target?.canvas.getActiveObject()?.type === "i-text") {
        const fontSize = options?.target?.fontSize;

        setWidgetTextSize((prev) => (fontSize ? fontSize : prev));
      }
      setClearUpperCase(true);
      if (options.target === null) {
        addText(options.e);
      }
      canvas.renderAll();
    });

    // Listen for the object:scaling event on the canvas
    canvas.on("object:scaling", function (e) {
      var obj = e.target;
      if (obj.type === "i-text" && obj.scaleX !== obj.scaleY) {
        obj.scaleY = obj.scaleX;
        obj.set("fontSize", obj.fontSize * obj.scaleX);
        setWidgetTextSize(Math.round(obj.fontSize * obj.scaleX));
        canvas.renderAll();
      }
    });

    canvas.on("text:changed", function (e) {
      if (e.target && e.target.type === "i-text") {
        const textId = e.target.textId;

        // const targetText = texts.map((text) =>
        //   text.id === textId ? { ...text, value: e.target.text } : text
        // );

        // setTexts(targetText);
        // document.getElementById(textId).value = e.target.text;

        canvas.renderAll();
      }
    });

    canvas.on("object:rotating", function (e) {
      var obj = e.target;
      if (obj.type === "i-text") {
        const rotateAngle = obj.angle; // Get the rotation angle in degrees

        setRotationAngle(rotateAngle);
        canvas.renderAll();
      }
    });

    // Event listener for object moving
    canvas.on("object:moving", function (e) {
      var obj = e.target;
      // if object is too big ignore
      if (
        obj.currentHeight > obj.canvas.height ||
        obj.currentWidth > obj.canvas.width
      ) {
        return;
      }
      obj.setCoords();
      // top-left  corner
      if (obj.getBoundingRect().top < 0 || obj.getBoundingRect().left < 0) {
        obj.top = Math.max(obj.top, obj.top - obj.getBoundingRect().top);
        obj.left = Math.max(obj.left, obj.left - obj.getBoundingRect().left);
      }
      // bot-right corner
      if (
        obj.getBoundingRect().top + obj.getBoundingRect().height >
          obj.canvas.height ||
        obj.getBoundingRect().left + obj.getBoundingRect().width >
          obj.canvas.width
      ) {
        obj.top = Math.min(
          obj.top,
          obj.canvas.height -
            obj.getBoundingRect().height +
            obj.top -
            obj.getBoundingRect().top
        );
        obj.left = Math.min(
          obj.left,
          obj.canvas.width -
            obj.getBoundingRect().width +
            obj.left -
            obj.getBoundingRect().left
        );
      }
    });

    // Listen for object changes to update canvas height dynamically
    const canvasContainer = document.getElementById("canvasContainer");

    canvas.on("object:added", () =>
      updateCanvasHeight(canvas, canvasContainer, 700)
    );
    canvas.on("object:removed", () =>
      updateCanvasHeight(canvas, canvasContainer, 700)
    );
    canvas.on("object:modified", (e) => {
      updateCanvasHeight(canvas, canvasContainer, 700);
    });

    canvas.renderAll();
  }, [canvas]);

  const {
    match: {
      params: { slug },
    },
  } = props;

  // Fetch single template and set initial field contents
  const fetchSingleTemplate = async (search = null) => {
    await axiosInstance.get(urls.singleTemplate(slug)).then((res) => {
      setTemplate(res.data);

      // Set initial field contents based on fetched data
      const initialFieldContents = {};
      res.data.sections.forEach((section) => {
        section.fields.forEach((field) => {
          initialFieldContents[`field_content${field.id}`] =
            field.field_content || "";
        });
      });
      setFieldContents(initialFieldContents);
    });
  };

  // const fetchSingleTemplate = async (search = null) => {
  //   await axiosInstance.get(urls.singleTemplate(slug)).then((res) => {
  //     setTemplate(res.data);
  //   });
  // };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return template;
    }
    const items = Array.from(template.sections);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    // Update the state
    setTemplate({
      ...template,
      sections: items,
    });
    // Update the server with the new order
    await axiosInstance.post(urls.updateTemplateOrder, {
      templateId: slug,
      newOrder: items.map((item) => item.id),
    });
  };

  //To create group fields
  //   const createFormField = () => {
  //     setFormFieldList(
  //       formFieldList.concat(
  //         <GroupField
  //           //   name={fieldName}
  //           value={value}
  //           groupName={groupName}
  //         />
  //       )
  //     );
  //   };

  //To Create group field
  const createFormField = () => {};

  //To create groups
  const createGroupList = () => {
    const uniqueId = uuidv4(); // Generate unique id
    const newParentElement = {
      id: uniqueId,
      groupName: groupName,
      children: [
        // Add more children as needed
      ],
    };
    setGroupList((prev) => [...prev, newParentElement]);
  };

  const addChildToParent = (parentId, fieldName, fieldType) => {
    // Generate unique key and id using react-id-generator
    const uniqueKey = uuidv4(); // Generate unique key
    const uniqueId = uuidv4(); // Generate unique id

    setGroupList((prevParentElements) => {
      const updatedParents = prevParentElements.map((parent) => {
        if (parent.id === parentId) {
          // Add more children to the existing parent
          const newChild = (
            <GroupField
              mode={"Create"}
              key={uniqueKey}
              id={uniqueId}
              fieldName={fieldName}
              fieldNameEdit={fieldNameEdit}
              setFieldNameEdit={setFieldNameEdit}
              setFieldName={setFieldName}
              value={value}
              fieldType={fieldType}
              parentId={parentId}
              setValue={setValue}
              onChange={updateChildValue}
              removeChildFromParent={removeChildFromParent}
              canvas={canvas}
            />
          );

          return { ...parent, children: [...parent.children, newChild] };
        }
        return parent;
      });

      return updatedParents;
    });
  };

  //Update value property in child
  const updateChildValue = (parentId, childId, newValue) => {
    // Create the IText object

    setGroupList((prevParentElements) => {
      const updatedParents = prevParentElements.map((parent) => {
        if (parent.id === parentId) {
          const updatedChildren = parent.children.map((child) => {
            if (child.props.id === childId) {
              // Update only the value of the specific child
              return React.cloneElement(child, { value: newValue });
            }
            return child;
          });

          return { ...parent, children: updatedChildren };
        }
        return parent;
      });

      return updatedParents;
    });
  };

  //Remove child from parent
  const removeChildFromParent = (parentId, childIdToRemove) => {
    setGroupList((prevParentElements) => {
      const updatedParents = prevParentElements.map((parent) => {
        if (parent.id === parentId) {
          // Remove the child with the specified childIdToRemove
          const updatedChildren = parent.children.filter(
            (child) => child.props.id !== childIdToRemove
          );

          // Return the parent with the updated children array
          return { ...parent, children: updatedChildren };
        }
        return parent;
      });

      return updatedParents;
    });
  };

  //To edit group name (show group name in textarea)
  // const editGroupName = (id) => {
  //   const groupNameEditTarget = groupList.filter((el) => el.id === id)[0]
  //     .groupName;
  //   setGroupNameEdit(groupNameEditTarget);
  // };

  const editGroupName = (id) => {
    groupList.map(
      (group) => group.id === id && setGroupNameEdit(group.groupName)
    );
  };

  const editGroup = (id, updatedGroupName) => {
    setGroupList((current) => {
      const index = current.findIndex((group) => group.id === id);

      if (index !== -1) {
        // Clone the array to avoid mutating the state directly
        const updatedList = [...current];

        // Update only the groupName property of the matching group
        updatedList[index] = {
          ...updatedList[index],
          groupName: updatedGroupName,
        };

        return updatedList;
      }

      return current;
    });

    //handleCloseModal3();
  };

  //To create a field
  const handleCreateField = (id) => {
    if (fieldName === null || fieldName.trim() === "") {
      setFieldNameError(true);
    } else {
      setFieldNameError(false);

      addChildToParent(id, fieldName, fieldType);
      handleCloseModal2();
      setFieldType("text");
    }
  };

  //For AI Chat
  const chatWithAI = async (prompt) => {
    await axiosInstance
      .post(urls.aiChatUrl, {
        prompt: prompt,
      })
      .then((res) => {
        setChatAIResult((prev) => [...prev, { chatAI: res.data.result }]);
        setIsLoading(false);
        setTimeout(() => {
          document.getElementById(
            "message"
          ).scrollTop = document.getElementById("message").scrollHeight;
        }, 1000);
      })
      .catch((err) => {
        setIsLoading(false);
        setChatAIResult((prev) => [...prev, { chatAI: `${err}` }]);
        setTimeout(() => {
          document.getElementById(
            "message"
          ).scrollTop = document.getElementById("message").scrollHeight;
        }, 1000);
      });
  };

  const makeReadyForPreview = (canvas) => {
    const text = new fabric.IText("");
    canvas?.add(text);
    canvas.renderAll();
  };

  const renderSectionFields = (section) => {
    const res = [];
    for (const field of section.fields) {
      res.push(field);
    }
    return res;
  };

  const defaultStyle = {
    transition: `transform 500ms ease-in-out`,
    transform: "translateX(-30%)",
  };

  const transitionStyles = {
    // entering: { transform: "translateX(101%)" },
    // entered: { transform: "translateX(0)" }
  };

  // Function to filter items based on the search query
  const filteredTemplates = templates.filter((template) =>
    template.name
      .toLowerCase()
      .trim()
      .includes(searchQuery.toLowerCase().trim())
  );

  return (
    <>
      <Modal1
        open={open}
        handleClose={handleClose}
        groupName={groupName}
        handleGroupName={handleGroupName}
        showGroupNameError={showGroupNameError}
        createGroup={createGroup}
        classes={classes}
      />

      <SignModal
        openSignModal={openSignModal}
        handleCloseSignModal={() => setOpenSignModal(false)}
      />

      {/* End of modal */}
      {/* comment */}
      <Box className={classes.mainContainer}>
        <Box className={classes.leftBar}>
          <Box className={classes.leftBarBox}>
            <Box
              className={classes.leftBarCircle}
              onClick={(e) => {
                changeShowPaperContent(false);
                setIsOpenAi(false);
                !showPaperContent && isOpenAi
                  ? toggleIsOpen(true)
                  : toggleIsOpen((prev) => !prev);
                e.currentTarget.childNodes[0].style.color = "blue";
                document.getElementById("widgets").style.color = "black";
                document.getElementById("ai").style.color = "black";
                document.getElementById("chat-ai").style.color = "black";
              }}
            >
              <InsertDriveFileIcon id="templates" sx={{ color: "black" }} />
            </Box>
            <Typography className={classes.leftBarSmallText}>
              Templates
            </Typography>
          </Box>
          <Box className={classes.leftBarBox}>
            <Box
              onClick={(e) => {
                changeShowPaperContent(false);
                toggleIsOpen(true);
                setIsOpenAi(true);
                // setIsChatAI(false);
                setSignText("");
                setSignImgUrl(null);
                setIsWidget(true);
                setIsChatAI(false);
                setIsAI(false);
                //setOpenSignModal(true);

                e.currentTarget.childNodes[0].style.color = "blue";
                document.getElementById("templates").style.color = "black";
                document.getElementById("ai").style.color = "black";
                document.getElementById("chat-ai").style.color = "black";
              }}
              className={classes.leftBarCircle}
            >
              <WidgetsIcon
                fontSize="large"
                id="widgets"
                sx={{ color: "black" }}
              />
            </Box>
            <Typography className={classes.leftBarSmallText}>
              Widgets
            </Typography>
          </Box>
          <Box className={classes.leftBarBox}>
            <Box
              onClick={(e) => {
                changeShowPaperContent(false);
                toggleIsOpen(true);
                setIsOpenAi(true);
                setIsWidget(false);
                setIsChatAI(false);
                setIsAI(true);
                e.currentTarget.childNodes[0].style.color = "blue";
                document.getElementById("widgets").style.color = "black";
                document.getElementById("templates").style.color = "black";
                document.getElementById("chat-ai").style.color = "black";
              }}
              className={classes.leftBarCircle}
            >
              {/* <CloudUploadIcon fontSize="large" /> */}
              <AutoAwesomeIcon
                fontSize="large"
                id="ai"
                sx={{ color: "black" }}
              />
            </Box>
            <Typography className={classes.leftBarSmallText}>AI</Typography>
          </Box>
          <Box className={classes.leftBarBox}>
            <Box
              onClick={(e) => {
                changeShowPaperContent(false);
                toggleIsOpen(true);
                setIsOpenAi(true);
                setIsChatAI(true);
                setIsWidget(false);
                setIsAI(false);
                e.currentTarget.childNodes[0].style.color = "blue";
                document.getElementById("widgets").style.color = "black";
                document.getElementById("templates").style.color = "black";
                document.getElementById("ai").style.color = "black";
              }}
              className={classes.leftBarCircle}
            >
              {/* <CloudUploadIcon fontSize="large" /> */}

              <SmartToyIcon
                fontSize="large"
                id="chat-ai"
                sx={{ color: "black" }}
              />
            </Box>
            <Typography className={classes.leftBarSmallText}>
              Chat AI
            </Typography>
          </Box>
        </Box>
        {/* Right bar starts here */}
        <Box className={classes.rightBar}>
          {!showPaperContent && (
            <Box className={classes.minorNav}>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                gridGap={"100px"}
              >
                <ArrowBackIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => history.goBack()}
                />
                {showTemplateInput ? (
                  <Box display={"flex"} alignItems={"center"} gridGap={5}>
                    <Input
                      value={templateName}
                      onChange={(e) => setTemplateName(e.target.value)}
                      placeholder={"Enter name of template"}
                      type="text"
                    />
                    <Button
                      onClick={() => setShowTemplateInput(false)}
                      className={classes.previewBtn}
                    >
                      Save
                    </Button>
                    <Button
                      onClick={() => setShowTemplateInput(false)}
                      style={{ backgroundColor: " #FF0000", color: "#fff" }}
                    >
                      Cancel
                    </Button>
                  </Box>
                ) : (
                  <Typography variant="h5">{templateName}</Typography>
                )}

                <Tooltip title={"Edit template name"}>
                  <ModeEditIcon
                    onClick={() => setShowTemplateInput(true)}
                    style={{ cursor: "pointer" }}
                  />
                </Tooltip>
              </Box>

              <Box
                style={{
                  padding: "10px",
                  backgroundColor: "#39b055",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  marginTop: "0px",
                  color: "#fff",
                  cursor: "pointer",
                  display: "block",
                }}
                onClick={() => {
                  makeReadyForPreview(canvas);
                  setShowBackBtn(false);
                  setTimeout(() => {
                    window.scrollBy(0, -100000);
                  }, 500);

                  setModalContent(
                    document.getElementById("htmlOutput").innerHTML.trim()
                  );

                  const computedStyles = getComputedStyle(
                    document.getElementById("htmlOutput")
                  );

                  setModalStyles({
                    color: computedStyles.color,
                    fontSize: computedStyles.fontSize,
                    width: computedStyles.width,
                    height: computedStyles.height,
                  });

                  setTimeout(() => setOpenPreviewModalCanvas(true), 400);
                }}
              >
                Preview
              </Box>
            </Box>
          )}

          <div className={classes.rightBarContainer}>
            {/* left bar info */}
            {showPaperContent ? (
              <Box
                style={{
                  border: "1px solid #696969",
                  width: "85%",
                  backgroundColor: "#fff",
                  zIndex: 1000,
                  position: "fixed",
                  borderRadius: "5px",
                  padding: "5px",
                  top: 0,
                  left: 130,
                  right: 0,
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "5px",
                  }}
                >
                  <ArrowBackIcon
                    onClick={() => changeShowPaperContent(false)}
                    style={{ cursor: "pointer" }}
                  />

                  <Box
                    style={{
                      border: shouldSetTemplateName && "1px solid black",
                    }}
                  >
                    <Typography
                      contentEditable={shouldSetTemplateName ? true : false}
                      style={{
                        color: "#ca6c59",
                        fontWeight: "bold",
                        padding: "5px",
                      }}
                      variant={"h6"}
                    >
                      {templateName?.length >= 18
                        ? templateName.slice(0, 17) + ".."
                        : templateName}
                    </Typography>
                  </Box>
                  {shouldSetTemplateName ? (
                    <Tooltip title="Save">
                      <Box
                        style={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          backgroundColor: "#00FF7F",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => setShouldSetTemplateName(false)}
                      >
                        <AddIcon style={{ fontSize: "18px", color: "#fff" }} />
                      </Box>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Edit template name">
                      <Box
                        style={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          backgroundColor: "#3a51b1",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => setShouldSetTemplateName(true)}
                      >
                        <EditIcon style={{ fontSize: "18px", color: "#fff" }} />
                      </Box>
                    </Tooltip>
                  )}
                </Box>
                <Divider />
                <Box display={"none"}>
                  {/* Left it at display none bcos its not needed again :) */}
                  <Box
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      backgroundColor: "#3a51b1",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      margin: "10px auto",
                    }}
                    onClick={() => setShouldSetTemplateName(true)}
                  >
                    <AddIcon style={{ fontSize: "19px", color: "#fff" }} />
                  </Box>
                </Box>
                <Box style={{ maxWidth: "310px" }}>
                  <center style={{ marginBottom: "5px", marginTop: "5px" }}>
                    <Button
                      onClick={() => fileInputRef.current.click()}
                      variant="outlined"
                      color="primary"
                    >
                      Change Template Thumbnail
                    </Button>
                    <input
                      onChange={(e) => console.log("file Target", e.target)}
                      multiple={false}
                      ref={fileInputRef}
                      type="file"
                      hidden
                    />
                  </center>
                  <div
                    style={{
                      height: "80vh",
                      border: "1px solid #696969",
                      borderRadius: "5px",
                      overflowY: "auto",
                      position: "fixed",
                      top: 95,
                      width: "20%",
                      left: 128,
                    }}
                  >
                    {targetTemplate.sections &&
                      targetTemplate.sections.map((section) => {
                        return (
                          <Accordion defaultExpanded>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                            >
                              <Box
                                style={{
                                  flex: 1,
                                  fontWeight: "bold",
                                  fontSize: "25px",
                                }}
                              >
                                {section.section_name}
                              </Box>
                              <Box display={"flex"} alignItems={"center"}>
                                <Tooltip title={"Edit"}>
                                  <EditIcon
                                    sx={{ color: "#4494ea" }}
                                    style={{ cursor: "pointer" }}
                                  />
                                </Tooltip>
                                <Box>
                                  <Tooltip title={"Delete"}>
                                    <DeleteIcon
                                      sx={{ color: "#d83864" }}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </Tooltip>
                                </Box>
                              </Box>
                            </AccordionSummary>
                            <Divider />
                            <AccordionDetails>
                              <Box>
                                {renderSectionFields(section).map(
                                  (question) => (
                                    <GroupField
                                      mode={"Edit"}
                                      key={question.id}
                                      id={question.id}
                                      qId={question.id}
                                      fieldName={question.name}
                                      value={question.field_content}
                                      fieldType={question.type}
                                      section={section}
                                      onChange2={
                                        changeTargetTemplateQuestionValue
                                      }
                                      //onChange2={changeTemplateQuestionValue}
                                    />
                                  )
                                )}
                              </Box>
                            </AccordionDetails>
                          </Accordion>
                        );
                      })}
                  </div>
                </Box>
              </Box>
            ) : (
              <div
                // sm={isOpen ? 3 : 1}
                // md={isOpen ? 3 : 1}
                // lg={isOpen ? 3 : 1}
                // item
                className={classes.rightBarContainerLeft}
                id="tray"
                style={
                  isOpen
                    ? {
                        transform: "translate(2%, 0%)",
                        transition: "transform 800ms ease-in-out",
                      }
                    : {
                        transform: "translate(-250%, 0%)",
                        transition: "transform 800ms ease-out",
                      }
                }
              >
                {isOpenAi ? (
                  <>
                    <section
                      style={{
                        display: isAI === true ? aiDisplay : "none",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#fff",
                          borderRadius: "8px",
                        }}
                      >
                        <Typography
                          style={{
                            textAlign: "center",
                            fontWeight: 600,
                            marginBottom: "7px",
                          }}
                        >
                          Assitant AI
                        </Typography>
                        <center>
                          <TextField
                            onChange={(e) => {
                              setAIPrompt(e.target.value);
                            }}
                            value={aiPrompt}
                            id="outlined-multiline-static"
                            label="Enter prompt here"
                            multiline
                            rows={8}
                            defaultValue=""
                            style={{
                              border: "1px solid gray",
                              width: "90%",
                              borderRadius: "5px",
                              padding: "3px",
                              marginBottom: "5px",
                            }}
                          />
                        </center>
                        <center>
                          <Button
                            variant="contained"
                            style={{
                              color: "white",
                              backgroundColor: "#03b2cb",
                              marginBottom: "10px",
                            }}
                            // onClick={(e) => {
                            //   setIsLoading(true);
                            //   chatWithAI(aiPrompt);
                            // }}
                          >
                            Go
                          </Button>
                        </center>
                        <center>
                          <p
                            style={{
                              maxHeight: "270px",
                              overflowY: "auto",
                              padding: "4px",
                            }}
                          ></p>
                        </center>
                      </div>
                    </section>
                    <section
                      style={{
                        display: isChatAI === true ? chatAIDisplay : "none",
                      }}
                    >
                      {/* Here */}
                      <div
                        style={{
                          backgroundColor: "#fff",
                          borderRadius: "8px",
                          padding: "6px",
                        }}
                      >
                        <Typography
                          style={{
                            textAlign: "center",
                            fontWeight: 600,
                            marginBottom: "7px",
                          }}
                        >
                          Chat AI
                        </Typography>
                        <div
                          id={"message"}
                          style={{
                            maxHeight: "74vh",
                            overflowY: "auto",
                            padding: "4px",
                          }}
                        >
                          {chatAIResult?.map((item) =>
                            Object.keys(item)[0] === "chatAI" ? (
                              <MessageLeft
                                message={item.chatAI}
                                timestamp={null}
                                photURL={null}
                                displayName={null}
                              />
                            ) : (
                              <MessageRight
                                message={item.user}
                                timestamp={null}
                              />
                            )
                          )}
                          {isLoading ? (
                            <MessageLeft
                              message={<CircularProgress size={"16px"} />}
                              timestamp={null}
                              photURL={null}
                              displayName={null}
                            />
                          ) : null}
                        </div>
                        <TextInput
                          chatMsg={chatMsg}
                          setChatMsg={setChatMsg}
                          sendMsg={() => {
                            setChatAIResult((prev) => [
                              ...prev,
                              { user: chatMsg },
                            ]);
                            setChatMsg("");
                          }}
                          chatWithAI={chatWithAI}
                          setIsLoading={setIsLoading}
                        />
                      </div>
                    </section>
                    {/* widgets section */}
                    <section
                      style={{ display: isWidget ? widgetDisplay : "none" }}
                    >
                      <div
                        style={{
                          backgroundColor: "#fff",
                          borderRadius: "8px",
                          padding: "6px",
                        }}
                      >
                        <Typography
                          style={{
                            textAlign: "center",
                            fontWeight: 600,
                            marginBottom: "7px",
                          }}
                        >
                          Widgets
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                          }}
                        >
                          <div
                            style={{
                              minWidth: "30%",
                              height: "70px",
                              border: "1px solid blue",
                              borderRadius: "5px",
                              cursor: "pointer",
                              padding: "4px",
                            }}
                            onClick={() => {
                              setIsWidgetText(true);
                              setIsWidgetImage(false);
                              setIsWidgetSignature(false);
                            }}
                          >
                            <center>
                              <TextFieldsIcon sx={{ color: "#000" }} />
                            </center>
                            <center>
                              <p
                                style={{
                                  color: isWidgetText ? "blue" : "black",
                                }}
                              >
                                Text
                              </p>
                            </center>
                          </div>
                          <div
                            style={{
                              minWidth: "30%",
                              height: "70px",
                              border: "1px solid blue",
                              borderRadius: "5px",
                              cursor: "pointer",
                              padding: "4px",
                            }}
                            onClick={() => {
                              setIsWidgetImage(true);
                              setIsWidgetText(false);
                              setIsWidgetSignature(false);
                            }}
                          >
                            <center>
                              <ImageIcon sx={{ color: "#000" }} />
                            </center>
                            <center>
                              <p
                                style={{
                                  color: isWidgetImage ? "blue" : "black",
                                }}
                              >
                                Image
                              </p>
                            </center>
                          </div>
                          <div
                            style={{
                              minWidth: "30%",
                              height: "70px",
                              border: "1px solid blue",
                              borderRadius: "5px",
                              cursor: "pointer",
                              padding: "4px",
                            }}
                            onClick={() => {
                              setIsWidgetSignature(true);
                              setIsWidgetText(false);
                              setIsWidgetImage(false);
                            }}
                          >
                            <center>
                              <ApprovalIcon sx={{ color: "#000" }} />
                            </center>
                            <center>
                              <p
                                style={{
                                  color: isWidgetSignature ? "blue" : "black",
                                }}
                              >
                                Signature
                              </p>
                            </center>
                          </div>
                        </div>
                        {/* widgets content */}
                        {isWidgetText && (
                          <div id="for-text" style={{ marginTop: "20px" }}>
                            <TextField
                              id="outlined-multiline-static"
                              label="Enter text"
                              multiline
                              rows={8}
                              value={widgetTextInput}
                              onChange={(e) =>
                                setWidgetTextInput(e.target.value)
                              }
                              defaultValue=""
                              style={{
                                border: "1px solid gray",
                                width: "80%",
                                margin: "0 auto",
                                borderRadius: "5px",
                                padding: "3px",
                                marginBottom: "5px",
                              }}
                            />
                            <div
                              style={{
                                display: "flex",
                                gap: "4px",
                                flexWrap: "wrap",
                              }}
                            >
                              <BasicSelect
                                flex={"40%"}
                                width={"70px"}
                                label={"Font Size"}
                                value={widgetTextSize}
                                values={[...Array(400).keys()].map(
                                  (i) => i + 1
                                )}
                                setValue={setWidgetTextSize}
                                canvas={canvas}
                              />
                              <BasicSelect
                                flex={"40%"}
                                width={"70px"}
                                label={"Font Style"}
                                value={widgetTextStyle}
                                values={["normal", "italic", "bold", "no bold"]}
                                setValue={setWidgetTextStyle}
                                canvas={canvas}
                              />
                              <BasicSelect
                                flex={"40%"}
                                width={"70px"}
                                label={"Font Color"}
                                value={widgetTextColor}
                                values={["black", "red", "blue", "green"]}
                                setValue={setWidgetTextColor}
                                canvas={canvas}
                              />
                              <BasicSelect
                                flex={"40%"}
                                width={"70px"}
                                label={"Text Decoration"}
                                value={widgetTextDecoration}
                                values={[
                                  "underline",
                                  "no underline",
                                  "strike",
                                  "no strike",
                                ]}
                                setValue={setWidgetTextDecoration}
                                canvas={canvas}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "2px",
                                }}
                              >
                                <Button
                                  onClick={() => {
                                    const text = new fabric.IText(
                                      widgetTextInput,
                                      {
                                        fill: widgetTextColor,
                                        fontSize: widgetTextSize,
                                        fontStyle: widgetTextStyle,
                                      }
                                    );
                                    //canvasText.text = widgetTextInput
                                    canvas?.add(text);

                                    setWidgetTextInput("");
                                  }}
                                  variant="outlined"
                                  color={"primary"}
                                >
                                  Add Text
                                </Button>
                                <Button
                                  onClick={() => {
                                    if (
                                      canvas?.getActiveObject()?.type ===
                                      "i-text"
                                    ) {
                                      canvas?.remove(canvas?.getActiveObject());
                                      canvas?.renderAll();
                                    }
                                  }}
                                  variant="outlined"
                                  color={"primary"}
                                >
                                  Remove Selected Text
                                </Button>
                              </div>
                            </div>
                          </div>
                        )}
                        {isWidgetImage && (
                          <div style={{ padding: "10px" }}>
                            <p>Upload Image Here</p>
                            <form
                              onSubmit={(e) => e.preventDefault()}
                              action=""
                              method="post"
                              enctype="multipart/form-data"
                            >
                              <label for="fileInput">
                                Select an image to upload:
                              </label>
                              <input
                                type="file"
                                id="fileInput"
                                name="image"
                                accept="image/*"
                                onChange={(e) => {
                                  var file = e.target.files[0];
                                  if (file) {
                                    var reader = new FileReader();
                                    reader.onload = function (f) {
                                      var data = f.target.result;
                                      // Create a Fabric.js Image object from the data URL
                                      fabric.Image.fromURL(data, function (
                                        img
                                      ) {
                                        // Scale down the image if it's too large for the canvas
                                        var imgWidth = img.width;
                                        var imgHeight = img.height;
                                        var maxWidth = canvas.width;
                                        var maxHeight = canvas.height;

                                        if (
                                          imgWidth > maxWidth ||
                                          imgHeight > maxHeight
                                        ) {
                                          var scaleFactor = Math.min(
                                            maxWidth / imgWidth,
                                            maxHeight / imgHeight
                                          );
                                          img.scale(scaleFactor);
                                        }

                                        // Add the image to the canvas
                                        canvas?.add(img);
                                        canvas?.renderAll();
                                      });
                                    };
                                    reader.readAsDataURL(file);
                                  }
                                }}
                              />
                              <br />
                              <br />
                            </form>
                            <Button
                              onClick={() => {
                                if (
                                  canvas?.getActiveObject()?.type === "image"
                                ) {
                                  canvas?.remove(canvas?.getActiveObject());
                                  canvas?.renderAll();
                                }
                              }}
                              variant="outlined"
                              color={"primary"}
                            >
                              Remove Selected Image
                            </Button>
                          </div>
                        )}
                        {isWidgetSignature && (
                          <div style={{ padding: "5px" }}>
                            <p>Sign Here</p>
                            <center>
                              <Box
                                style={{
                                  minHeight: "345px",
                                  maxHeight: "345px",
                                  width: "100%",
                                  backgroundColor: "gray",
                                  borderRadius: "4px",
                                }}
                              >
                                <SignatureCanvas
                                  ref={signRef}
                                  penColor="black"
                                  canvasProps={{
                                    style: {
                                      width: "100%",
                                      height: "345px",
                                      borderRadius: "4px",
                                    },
                                  }}
                                />
                              </Box>
                            </center>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "3px",
                              }}
                            >
                              <Button
                                variant="contained"
                                style={{
                                  backgroundColor: "blue",
                                  marginTop: "4px",
                                  color: "#fff",
                                  "&:hover": {
                                    backgroundColor: "darkblue",
                                  },
                                }}
                                onClick={() => {
                                  const URL = signRef.current
                                    .getTrimmedCanvas()
                                    .toDataURL("image/png");
                                  // setSignImgUrl(URL);
                                  URL && addImage(canvas, URL);
                                }}
                              >
                                Add
                              </Button>
                              <Button
                                variant="contained"
                                style={{
                                  backgroundColor: "#7B0323",
                                  marginTop: "4px",
                                  color: "#fff",
                                  "&:hover": {
                                    backgroundColor: "darkblue",
                                  },
                                }}
                                onClick={() => signRef.current.clear()}
                              >
                                Clear
                              </Button>
                              <Button
                                variant="contained"
                                style={{
                                  backgroundColor: "#7B0323",
                                  marginTop: "4px",
                                  color: "#fff",
                                  "&:hover": {
                                    backgroundColor: "darkblue",
                                  },
                                }}
                                onClick={() => {
                                  if (
                                    canvas?.getActiveObject()?.type === "image"
                                  ) {
                                    canvas?.remove(canvas?.getActiveObject());
                                    canvas?.renderAll();
                                  }
                                }}
                              >
                                Remove
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                    </section>
                  </>
                ) : (
                  <div>
                    <Box className={classes.whiteCardOne}>
                      <Typography className={classes.whiteCardOneSmallText}>
                        Select Template
                      </Typography>
                      <Box className={classes.tabBox}>
                        <Tabs
                          centered
                          className={classes.tabs}
                          value={tabValue}
                          onChange={handleChange}
                          aria-label="icon tabs example"
                        >
                          <Tab
                            icon={
                              <Box className={classes.tabCircle}>
                                <TextureIcon />
                              </Box>
                            }
                            aria-label="Designs"
                            label={"Designs"}
                          />

                          <Tab
                            icon={
                              <Box className={classes.tabCircle}>
                                <LineStyleIcon />
                              </Box>
                            }
                            aria-label="Styles"
                            label={"Styles"}
                          />
                        </Tabs>
                      </Box>
                    </Box>
                    <Box className={classes.whiteCardTwo}>
                      <Box className={classes.lensBoxContainer}>
                        <Box className={classes.lensBox}>
                          <SearchIcon fontSize={"medium"} />
                          <Input
                            placeholder={"Search A4 templates"}
                            className={classes.searchTempTxt}
                            onChange={(e) => handleInputChange(e)}
                          />
                        </Box>
                        <Box className={classes.configBox}>
                          <TuneIcon fontSize={"medium"} />
                        </Box>
                      </Box>
                    </Box>
                    {templateStatus === "Create" && (
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Tooltip title={"Add Group"}>
                          <Box
                            onClick={() => {
                              handleOpen();
                            }}
                            width={30}
                            height={30}
                            borderRadius={"50%"}
                            padding={"5px"}
                            bgcolor={"#4494ea"}
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            marginTop={"10px"}
                            marginBottom={"10px"}
                            style={{ cursor: "pointer" }}
                          >
                            <AddIcon sx={{ color: "white" }} />
                          </Box>
                        </Tooltip>
                      </Box>
                    )}

                    {groupList.map((item) => (
                      <Group
                        classes={classes}
                        handleOpenModal2={handleOpenModal2}
                        handleOpenModal3={handleOpenModal3}
                        formFieldList={item.children}
                        groupName={item.groupName}
                        groupId={item.id}
                        editGroupName={editGroupName}
                        groupRef={groupRef}
                        setGroupNameEdit={setGroupNameEdit}
                        parentId={item.id}
                        //   addChildToParent={addChildToParent}
                        deleteGroup={deleteGroup}
                        setOpen={setOpen}
                        setOpenModal3={setOpenModal3}
                        modal={
                          <Modal2
                            handleCloseModal2={handleCloseModal2}
                            openModal2={openModal2}
                            handleFieldName={handleFieldName}
                            classes={classes}
                            fieldName={fieldName}
                            showFieldNameError={showFieldNameError}
                            label={label}
                            handleCreateField={handleCreateField}
                            handleChangeRadio={handleChangeRadio}
                            handleSwitch={handleSwitch}
                            isFieldRequired={isFieldRequired}
                            parentId={item.id}
                            canvas={canvas}
                          />
                        }
                        modalEdit={
                          <Modal3
                            open={openModal3}
                            handleClose={handleCloseModal3}
                            groupNameEdit={groupNameEdit}
                            handleGroupNameEdit={handleGroupNameEdit}
                            showGroupNameError={showGroupNameError}
                            classes={classes}
                            editGroupName={editGroupName}
                            parentId={item.id}
                            groupList={groupList}
                            editGroup={editGroup}
                            setGroupNameEdit={setGroupNameEdit}
                          />
                        }
                      />
                    ))}

                    {tabValue === 0 ? (
                      <Box
                        className={classes.whiteCardThree}
                        display={templateStatus === "Edit" ? "block" : "none"}
                      >
                        <Grid container spacing={2}>
                          {filteredTemplates &&
                            filteredTemplates.map((template) => (
                              <Grid key={template.id} xs={12} sm={6} item>
                                <Box
                                  bgcolor={"#f8f9fb"}
                                  borderRadius={"5px"}
                                  padding={"4px"}
                                  width={"100%"}
                                  display={"flex"}
                                  alignItems={"center"}
                                  justifyContent={"center"}
                                  flexDirection={"column"}
                                  onClick={() => {
                                    const url = urls.singleTemplate(
                                      template.id
                                    );
                                    changeTargetTemplate(url, axiosInstance);
                                    changeShowPaperContent(true);
                                  }}
                                >
                                  <Tooltip title={template.name}>
                                    <img
                                      className={classes.templateImg}
                                      src={
                                        template.image
                                          ? template.image
                                          : templateImg
                                      }
                                    />
                                  </Tooltip>
                                  <Button
                                    onClick={() => {
                                      const url = urls.singleTemplate(
                                        template.id
                                      );
                                      changeTargetTemplate(url, axiosInstance);
                                      changeShowPaperContent(true);
                                    }}
                                    className={classes.templateBtn}
                                  >
                                    SELECT
                                  </Button>
                                </Box>
                              </Grid>
                            ))}

                          {/* <Grid xs={12} sm={6} md={6} lg={6} item>
                      <Box
                        bgcolor={"#f8f9fb"}
                        borderRadius={"5px"}
                        padding={"4px"}
                        width={"100%"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        flexDirection={"column"}
                      >
                        <img
                          className={classes.templateImg}
                          src={
                            "https://www.goodcv.com/images/cv/screenshots/thumbs/en/outstanding_10.png?v=1.0.1"
                          }
                        />
                        <Button className={classes.templateBtn}>SELECT</Button>
                      </Box>
                    </Grid>
                    <Grid xs={12} sm={6} md={6} lg={6} item>
                      <Box
                        bgcolor={"#f8f9fb"}
                        borderRadius={"5px"}
                        padding={"4px"}
                        width={"100%"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        flexDirection={"column"}
                      >
                        <img
                          className={classes.templateImg}
                          src={
                            "https://www.goodcv.com/images/cv/screenshots/thumbs/en/outstanding_10.png?v=1.0.1"
                          }
                        />
                        <Button className={classes.templateBtn}>SELECT</Button>
                      </Box>
                    </Grid>
                    <Grid xs={12} sm={6} item>
                      <Box
                        bgcolor={"#f8f9fb"}
                        borderRadius={"5px"}
                        padding={"4px"}
                        width={"100%"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        flexDirection={"column"}
                      >
                        <img
                          className={classes.templateImg}
                          src={
                            "https://www.goodcv.com/images/cv/screenshots/thumbs/en/outstanding_10.png?v=1.0.1"
                          }
                        />
                        <Button className={classes.templateBtn}>SELECT</Button>
                      </Box>
                    </Grid> */}
                        </Grid>
                      </Box>
                    ) : (
                      <section>
                        <div className={classes.whiteCardThree}>
                          <p style={{ fontSize: "14px", fontWeight: 600 }}>
                            Combinations
                          </p>
                          <div
                            id="carousel-container"
                            style={{ width: "100%" }}
                          >
                            <Carousel
                              navButtonsAlwaysVisible
                              className="text-center"
                            >
                              <div>
                                <div
                                  style={{
                                    width: "80%",
                                    height: "180px",
                                    borderRadius: "4px",
                                    backgroundColor: "#343537",
                                    margin: "0 auto",
                                    paddingTop: "5px",
                                    paddingBottom: "5px",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "80%",
                                      height: "160px",
                                      margin: "0 auto",
                                      borderRadius: "4px",
                                      background:
                                        "linear-gradient(to right, rgb(84, 64, 19) 0%, rgb(84, 64, 19) 16.6667%, rgb(200, 90, 20) 16.6667%, rgb(200, 90, 20) 33.3333%, rgb(175, 128, 22) 33.3333%, rgb(175, 128, 22) 50%, rgb(216, 132, 158) 50%, rgb(216, 132, 158) 66.6667%, rgb(232, 181, 148) 66.6667%, rgb(232, 181, 148) 83.3333%, rgb(237, 233, 221) 83.3333%, rgb(237, 233, 221) 100%)",
                                    }}
                                  ></div>
                                </div>
                                <p style={{ fontWeight: 600 }}>Anton</p>
                                <p style={{ fontSize: "12px" }}>
                                  Prompt medium
                                </p>
                              </div>

                              <div>
                                <div
                                  style={{
                                    width: "80%",
                                    height: "180px",
                                    borderRadius: "4px",
                                    backgroundColor: "#343537",
                                    margin: "0 auto",
                                    paddingTop: "5px",
                                    paddingBottom: "5px",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "80%",
                                      height: "160px",
                                      margin: "0 auto",
                                      borderRadius: "4px",
                                      background:
                                        "linear-gradient(to right, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 16.6667%, rgb(6, 50, 125) 16.6667%, rgb(6, 50, 125) 33.3333%, rgb(43, 112, 228) 33.3333%, rgb(43, 112, 228) 50%, rgb(32, 157, 92) 50%, rgb(32, 157, 92) 66.6667%, rgb(255, 208, 52) 66.6667%, rgb(255, 208, 52) 83.3333%, rgb(255, 255, 255) 83.3333%, rgb(255, 255, 255) 100%)",
                                    }}
                                  ></div>
                                </div>
                                <p style={{ fontWeight: 700 }}>Arbutus Slab</p>
                                <p style={{ fontSize: "12px" }}>Barlow Bold</p>
                              </div>

                              <div>
                                <div
                                  style={{
                                    width: "80%",
                                    height: "180px",
                                    borderRadius: "4px",
                                    backgroundColor: "#343537",
                                    margin: "0 auto",
                                    paddingTop: "5px",
                                    paddingBottom: "5px",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "80%",
                                      height: "160px",
                                      margin: "0 auto",
                                      borderRadius: "4px",
                                      background:
                                        "linear-gradient(to right, rgb(60, 108, 168) 0%, rgb(60, 108, 168) 16.6667%, rgb(183, 143, 214) 16.6667%, rgb(183, 143, 214) 33.3333%, rgb(94, 176, 144) 33.3333%, rgb(94, 176, 144) 50%, rgb(234, 178, 187) 50%, rgb(234, 178, 187) 66.6667%, rgb(234, 225, 100) 66.6667%, rgb(234, 225, 100) 83.3333%, rgb(255, 252, 246) 83.3333%, rgb(255, 252, 246) 100%)",
                                    }}
                                  ></div>
                                </div>
                                <p style={{ fontWeight: 600 }}>Horizon</p>
                                <p style={{ fontSize: "12px" }}>Poppins Bold</p>
                              </div>
                            </Carousel>
                          </div>
                        </div>

                        <div className={classes.whiteCardThree}>
                          <p style={{ fontSize: "14px", fontWeight: 600 }}>
                            Colour Palettes
                          </p>
                          <div
                            id="carousel-container"
                            style={{ width: "100%" }}
                          >
                            <Carousel
                              navButtonsAlwaysVisible
                              className="text-center"
                            >
                              <div>
                                <div
                                  style={{
                                    width: "80%",
                                    height: "180px",
                                    borderRadius: "4px",
                                    backgroundColor: "#343537",
                                    margin: "0 auto",
                                    paddingTop: "5px",
                                    paddingBottom: "5px",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "80%",
                                      height: "160px",
                                      margin: "0 auto",
                                      borderRadius: "4px",
                                      background:
                                        "linear-gradient(to right, rgb(54, 91, 109) 0%, rgb(54, 91, 109) 16.6667%, rgb(108, 146, 134) 16.6667%, rgb(108, 146, 134) 33.3333%, rgb(40, 157, 210) 33.3333%, rgb(40, 157, 210) 50%, rgb(65, 193, 186) 50%, rgb(65, 193, 186) 66.6667%, rgb(242, 241, 236) 66.6667%, rgb(242, 241, 236) 83.3333%, rgb(247, 247, 247) 83.3333%, rgb(247, 247, 247) 100%)",
                                    }}
                                  ></div>
                                </div>
                              </div>

                              <div>
                                <div
                                  style={{
                                    width: "80%",
                                    height: "180px",
                                    borderRadius: "4px",
                                    backgroundColor: "#343537",
                                    margin: "0 auto",
                                    paddingTop: "5px",
                                    paddingBottom: "5px",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "80%",
                                      height: "160px",
                                      margin: "0 auto",
                                      borderRadius: "4px",
                                      background:
                                        "linear-gradient(to right, rgb(43, 71, 67) 0%, rgb(43, 71, 67) 16.6667%, rgb(140, 56, 57) 16.6667%, rgb(140, 56, 57) 33.3333%, rgb(218, 112, 115) 33.3333%, rgb(218, 112, 115) 50%, rgb(221, 168, 63) 50%, rgb(221, 168, 63) 66.6667%, rgb(206, 222, 235) 66.6667%, rgb(206, 222, 235) 83.3333%, rgb(255, 211, 219) 83.3333%, rgb(255, 211, 219) 100%)",
                                    }}
                                  ></div>
                                </div>
                              </div>

                              <div>
                                <div
                                  style={{
                                    width: "80%",
                                    height: "180px",
                                    borderRadius: "4px",
                                    backgroundColor: "#343537",
                                    margin: "0 auto",
                                    paddingTop: "5px",
                                    paddingBottom: "5px",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "80%",
                                      height: "160px",
                                      margin: "0 auto",
                                      borderRadius: "4px",
                                      background:
                                        "linear-gradient(to right, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 16.6667%, rgb(34, 47, 230) 16.6667%, rgb(34, 47, 230) 33.3333%, rgb(240, 54, 18) 33.3333%, rgb(240, 54, 18) 50%, rgb(247, 209, 1) 50%, rgb(247, 209, 1) 66.6667%, rgb(241, 241, 241) 66.6667%, rgb(241, 241, 241) 83.3333%, rgb(255, 253, 253) 83.3333%, rgb(255, 253, 253) 100%)",
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </Carousel>
                          </div>
                        </div>

                        <div className={classes.whiteCardThree}>
                          <p style={{ fontSize: "14px", fontWeight: 600 }}>
                            Font Sets
                          </p>
                          <div
                            id="carousel-container"
                            style={{ width: "100%" }}
                          >
                            <Carousel
                              navButtonsAlwaysVisible
                              className="text-center"
                            >
                              <div>
                                <div
                                  style={{
                                    width: "80%",
                                    height: "180px",
                                    borderRadius: "4px",
                                    backgroundColor: "#343537",
                                    margin: "0 auto",
                                    paddingTop: "5px",
                                    paddingBottom: "5px",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "80%",
                                      height: "160px",
                                      margin: "0 auto",
                                      borderRadius: "4px",
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: "19px",
                                        fontWeight: 600,
                                        color: "#fff",
                                        fontFamily: "Poppins, sans-serif",
                                      }}
                                    >
                                      Poppins Bold
                                    </p>
                                    <p
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: 600,
                                        color: "#fff",
                                        ontFamily: "Poppins, sans-serif",
                                      }}
                                    >
                                      Poppins Bold
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div>
                                <div
                                  style={{
                                    width: "80%",
                                    height: "180px",
                                    borderRadius: "4px",
                                    backgroundColor: "#343537",
                                    margin: "0 auto",
                                    paddingTop: "5px",
                                    paddingBottom: "5px",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "80%",
                                      height: "160px",
                                      margin: "0 auto",
                                      borderRadius: "4px",
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: "24px",
                                        fontWeight: 600,
                                        color: "#fff",
                                        fontFamily: "Squada One, sans-serif",
                                        fontWeight: 400,
                                      }}
                                    >
                                      Squada One
                                    </p>
                                    <p
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: 400,
                                        color: "#fff",
                                        fontFamily: "Squada One, sans-serif",
                                      }}
                                    >
                                      Klima
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div>
                                <div
                                  style={{
                                    width: "80%",
                                    height: "180px",
                                    borderRadius: "4px",
                                    backgroundColor: "#343537",
                                    margin: "0 auto",
                                    paddingTop: "5px",
                                    paddingBottom: "5px",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "80%",
                                      height: "160px",
                                      margin: "0 auto",
                                      borderRadius: "4px",
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: "24px",
                                        fontWeight: 600,
                                        color: "#fff",
                                        fontFamily: "Squada One, sans-serif",
                                      }}
                                    >
                                      Archivo
                                    </p>
                                    <p
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: 600,
                                        color: "#fff",
                                        fontFamily: "Squada One, sans-serif",
                                      }}
                                    >
                                      Open Sans
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Carousel>
                          </div>
                        </div>
                      </section>
                    )}
                  </div>
                )}
              </div>
            )}

            {/* end left bar info */}
            <div
              // sm={isOpen ? 9 : 11}
              // md={isOpen ? 9 : 11}
              // lg={isOpen ? 9 : 11}
              // item
              className={classes.rightBarContainerRight}
              id="rightBarContainerRight"
            >
              <TextEditor
                canvas={canvas}
                widgetTextSize={widgetTextSize}
                setWidgetTextSize={setWidgetTextSize}
                groupList={groupList}
                canvasRef={myCanvas}
                clearUpperCase={clearUpperCase}
                setClearUpperCase={setClearUpperCase}
                modalContent={modalContent}
                modalStyles={modalStyles}
                rotationAngle={rotationAngle}
                showBackBtn={showBackBtn}
                setShowBackBtn={setShowBackBtn}
              />
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
}

export default EditorView;
