import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Paper,
  Box,
  Divider,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  divide: {
    background: "#e0dede",
    height: "0.8px",
  },
  heading: {
    // fontSize: theme.typography.pxToRem(15),
    // fontWeight: theme.typography.fontWeightRegular,
  },
}));
export default function CustomRegularAccordion({
  title,
  content,
  color,
  bgColor,
  expand = true,
  disabled,
}) {
  const [expanded, setExpanded] = React.useState(expand);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(!expanded);
  };

  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Accordion
        expanded={expanded}
        onChange={handleChange()}
        square
        style={{ color, backgroundColor: bgColor }}
        disabled={disabled}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "#b83b5e" }} />}
          id="panel1a-header"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Typography style={{ flex: 1 }} variant="h6">
            {title}
          </Typography>
        </AccordionSummary>
        <Divider className={classes.divide} />

        <AccordionDetails style={{ flexDirection: "column" }}>
          {content}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
