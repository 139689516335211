/**
 *
 * StripeElementWrapper
 *
 */

import React from "react";
// import PropTypes from "prop-types";
// import styled from 'styled-components';

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import StripeInput from "./StripeInput";
import FormHelperText from "@material-ui/core/FormHelperText";

/* eslint-disable react/prefer-stateless-function */
class StripeElementWrapper extends React.PureComponent {
  static displayName = "StripeElementWrapper";

  //   static propTypes = {
  //     component: PropTypes.func.isRequired,
  //     label: PropTypes.string.isRequired,
  //   };

  state = {
    focused: false,
    empty: true,
    error: false,
  };

  handleBlur = () => {
    this.setState({ focused: false });
  };

  handleFocus = () => {
    this.setState({ focused: true });
  };

  handleChange = (changeObj) => {
    this.setState({
      error: changeObj.error,
      empty: changeObj.empty,
    });
  };

  render() {
    const { component, label, onFocus, onBlur, onChange } = this.props;
    const { focused, empty, error } = this.state;

    return (
      <div>
        <FormControl fullWidth margin="normal">
          <InputLabel
            focused={focused}
            shrink={focused || !empty}
            error={!!error}
          >
            {label}
          </InputLabel>
          <Input
            fullWidth
            inputComponent={StripeInput}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            inputProps={{ component }}
          />
          {error && <FormHelperText error>{error.message}</FormHelperText>}
        </FormControl>
        {/* {error && <Errors>{error.message}</Errors>} */}
      </div>
    );
  }
}

export default StripeElementWrapper;
