import React, { useState, useEffect, createRef } from "react";
import Modal from "./Modal";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Link, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import MenuItem from "@material-ui/core/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { addGuidesForm } from "../store/actions/guidesActions";
import { sendEmail, contactUs } from "../store/actions/contactusActions";
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" to="/">
        Nottinghilllaw
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
const useStyles = makeStyles((theme) => ({
  paper1: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 0),
  },
}));

const currencies = [
  {
    value: "GETTING MARRIED",
    label: "1. GETTING MARRIED",
  },
  {
    value: "STARTING A BUISNESS",
    label: "2. STARTING A BUISNESS",
  },
  {
    value: "INVESTING",
    label: "3. INVESTING",
  },
  {
    value: "WORKING IN THE UK",
    label: "4. WORKING IN THE UK",
  },
  {
    value: "STUDYING IN THE UK",
    label: "5. STUDYING IN THE UK",
  },
  {
    value: "BRITISH PASSPORT",
    label: "6. BRITISH PASSPORT",
  },
  {
    value: "VISITING IN THE UK",
    label: "7. VISITING IN THE UK",
  },
  {
    value: "SWITCHING TO ANOTHER VISA",
    label: "8. SWITCHING TO ANOTHER VISA",
  },
  {
    value: "OTHER",
    label: "9. OTHER",
  },
];
function GuidesForm(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [auth, setAuth] = useState(false);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const guidesForm = useSelector((state) => state.guidesForm);
  const { loading, success, error } = guidesForm;
  const [body, setBody] = useState({
    first_name: "",
    last_name: "",
    email: "",
    city: "",
    country: "",
    telephone: "",
    visa: "",
  });
  useEffect(() => {
    if (userInfo) {
      setAuth(true);
    } else {
      setAuth(false);
    }
  }, [userInfo, userLogin]);
  const [val, setVal] = React.useState("GETTING MARRIED");
  const handleChange = (event) => {
    setVal(event.target.value);
    setBody({ ...body, visa: event.target.value });
  };
  const handleChangeFields = (e) => {
    setBody({ ...body, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const link = document.createElement("a");
    link.href = props.pdf;
    link.target = "_blank";
    link.download = props.title;
    // Append to the document
    document.body.appendChild(link);

    // Trigger the click event
    link.click();
    // Remove the element
    // document.body.removeChild(link);
    sendEmail(
      body.first_name + " " + body.last_name,
      "Notification About Guide Form",
      body.visa,
      body.email,
      body.telephone
    );
    contactUs(body).then((data) => {
      setBody({
        first_name: "",
        last_name: "",
        email: "",
        telephone: "",
        visa: "",
        country: "",
        city: "",
      });
      setVal("");
    });
  };
  return (
    <Modal buttonTxt="Download" buttonTag={"guides"}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        {auth ? (
          <div className={classes.paper1}>
            <Typography component="h1" variant="h6">
              Download Your Copy for free
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    size="small"
                    fullWidth
                    name="first_name"
                    value={body.first_name}
                    label="First Name"
                    type="text"
                    onChange={handleChangeFields}
                    id="firstName"
                    autoComplete="fname"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    value={body.last_name}
                    onChange={handleChangeFields}
                    fullWidth
                    name="last_name"
                    size="small"
                    label="Last Name"
                    type="text"
                    id="lastName"
                    autoComplete="lname"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    value={body.email}
                    type="email"
                    onChange={handleChangeFields}
                    id="email"
                    label="Email"
                    size="small"
                    name="email"
                    autoComplete="email"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    variant="outlined"
                    required
                    onChange={handleChangeFields}
                    fullWidth
                    name="city"
                    value={body.city}
                    label="City"
                    type="text"
                    id="city"
                    size="small"
                    autoComplete="city"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={body.country}
                    required
                    name="country"
                    label="Country"
                    type="text"
                    id="country"
                    onChange={handleChangeFields}
                    autoComplete="country"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="telephone"
                    value={body.telephone}
                    label="Telephone"
                    onChange={handleChangeFields}
                    size="small"
                    type="text"
                    id="telephone"
                    autoComplete="telephone"
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="What you are interested in?"
                    fullWidth
                    required
                    size="small"
                    value={body.visa}
                    name="visa"
                    value={val}
                    onChange={handleChange}
                    helperText="Please select"
                    variant="outlined"
                  >
                    {currencies.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <Button
                fullWidth
                variant="contained"
                type="submit"
                color="primary"
                className={classes.submit}
              >
                <i className="fa fa-download mr-2"></i>
                Download
              </Button>

              <Grid container>
                <Grid item xs>
                  <Link to="/" variant="body2">
                    {/* Forgot password? */}
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
        ) : (
          <>
            <h3 className="p-3">Please Login to download this guide</h3>
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <img
                style={{
                  height: "200px",
                }}
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSni4W_ssx3U1KqS7a7wY_Q4NVU2hW3CP-1jA&usqp=CAU"
              ></img>
            </div>

            <Link to="/login">
              <Button
                fullWidth
                variant="contained"
                type="submit"
                color="primary"
                className={classes.submit}
              >
                Go For Login
              </Button>
            </Link>
          </>
        )}
        <Box mt={2}>
          <Copyright />
        </Box>
      </Container>
    </Modal>
  );
}

export default GuidesForm;
