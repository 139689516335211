import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";

import {
  List,
  Paper,
  Divider,
  Grid,
  Button,
  TextField,
  withStyles,
} from "@material-ui/core";

import SideMenu from "../../../components/dashboard/SideMenu";
import SubscriptionTable from "./SubscriptionTable";
import SubscriptionInfo from "./SubscriptionInfo";

const styles = {
  divider: {
    backgroundColor: "rgba(0, 0, 0, 0.26)",
  },
};

function Subscription(props) {
  const [transactions, setTransactions] = useState([]);
  const [isAddBalanceDialogOpen, setIsAddBalanceDialogOpen] = useState(false);
  const { classes, selectSubscription } = props;

  const openAddBalanceDialog = useCallback(() => {
    setIsAddBalanceDialogOpen(true);
  }, [setIsAddBalanceDialogOpen]);

  const closeAddBalanceDialog = useCallback(() => {
    setIsAddBalanceDialogOpen(false);
  }, [setIsAddBalanceDialogOpen]);

  const fetchRandomTransactions = useCallback(() => {
    const transactions = [];
    const iterations = 32;
    const oneMonthSeconds = Math.round(60 * 60 * 24 * 30.5);
    const transactionTemplates = [
      {
        description: "Starter subscription",
        isSubscription: true,
        balanceChange: -1499,
      },
      {
        description: "Premium subscription",
        isSubscription: true,
        balanceChange: -2999,
      },
      {
        description: "Business subscription",
        isSubscription: true,
        balanceChange: -4999,
      },
      {
        description: "Tycoon subscription",
        isSubscription: true,
        balanceChange: -9999,
      },
      {
        description: "Added funds",
        isSubscription: false,
        balanceChange: 2000,
      },
      {
        description: "Added funds",
        isSubscription: false,
        balanceChange: 5000,
      },
    ];
    let curUnix = Math.round(
      new Date().getTime() / 1000 - iterations * oneMonthSeconds
    );
    for (let i = 0; i < iterations; i += 1) {
      const randomTransactionTemplate =
        transactionTemplates[
          Math.floor(Math.random() * transactionTemplates.length)
        ];
      const transaction = {
        id: i,
        description: randomTransactionTemplate.description,
        balanceChange: randomTransactionTemplate.balanceChange,
        paidUntil: curUnix + oneMonthSeconds,
        timestamp: curUnix,
      };
      curUnix += oneMonthSeconds;
      transactions.push(transaction);
    }
    transactions.reverse();
    setTransactions(transactions);
  }, [setTransactions]);

  useEffect(() => {
    fetchRandomTransactions();
  }, [fetchRandomTransactions, selectSubscription]);

  //   useEffect(selectSubscription, [selectSubscription]);

  return (
    // <SideMenu>
    <Grid style={{ marginTop: "10px" }} container spacing={5}>
      {/* style={{ minWidth: "1100px" }} */}
      <Grid item md={12}>
        <Paper>
          <List disablePadding>
            <SubscriptionInfo openAddBalanceDialog={openAddBalanceDialog} />
            <Divider className={classes.divider} />
            <SubscriptionTable transactions={transactions} />
          </List>
        </Paper>
      </Grid>
    </Grid>
    // </SideMenu>
  );
}

Subscription.propTypes = {
  classes: PropTypes.object.isRequired,
  transactions: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectSubscription: PropTypes.func.isRequired,
  openAddBalanceDialog: PropTypes.func.isRequired,
};

export default withStyles(styles)(Subscription);
