import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import NotificationsIcon from "@material-ui/icons/Notifications";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PersonIcon from "@material-ui/icons/Person";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

//import "../../static/sideMenu.scss";
//import "../static/SideMenu.scss"
import { useHistory, useLocation } from "react-router-dom";
import { Image } from "react-bootstrap";
import IconBreadcrumbs from "./BreadCrums";
import { useDispatch, useSelector } from "react-redux";
//import { urls } from "../../utils/urls";
import { urls } from "../utils/urls";
import axios from "axios";
import {
  Avatar,
  Button,
  ButtonBase,
  Fab,
  Menu,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
//import { logout } from "../../store/actions/userActions";
import { logout } from "../store/actions/userActions";
import { Skeleton } from "@material-ui/lab";
import emtyCart from "../screens/book/emty.png";
import logo from "./21.png";
import nameLogo from "../../src/screens/Home/assets1/name-logo.svg"

function Copyrigt() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Docelerate
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // marginTop: "10px",
    // overflow: "hidden",

    // position: "fixed",
    // [theme.breakpoints.up("xs")]: {
    //   minWidth: 300,
    // },
    // [theme.breakpoints.up("sm")]: {
    //   minWidth: 400,
    // },
    // [theme.breakpoints.down("md")]: {
    //   minWidth: 1870,
    // },
    // [theme.breakpoints.up("lg")]: {
    //   width: "1000px",
    // },

    // position: "relative",
  },

  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    
  },
  mobileDisplay: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 0px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    // zIndex: theme.zIndex.drawer + 1,
    // transition: theme.transitions.create(["width", "margin"], {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
    // marginBottom: "300px",
  },
  appBarShift: {
    // marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    // transition: theme.transitions.create(["width", "margin"], {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  noFlexContent: {
    flexGrow: 0,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    // paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginTop: "120px",
    // minWidth: "1200",
    // width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },

  // for cart
  paper1: {
    padding: theme.spacing(1),
    margin: "auto",
    background: "#f7f9fa",
    maxWidth: 500,
  },
  paper2: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 700,
  },

  newcardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  newcardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },

  link: {
    display: "flex",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
  image: {
    width: 50,
    height: 50,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },

  roundedImage: {
    borderRadius: "50%", // Make the image rounded
    width: 60, // Set the width as needed
    height: 60, // Set the height as needed
    objectFit: "cover", // Ensure the image covers the entire container
    border: "2px solid #ccc", // Add a border to the image
    cursor: "pointer",
    margin: "5px",
    // Add a pointer cursor to indicate clickability
  },
  sideRoundedImage: {
    borderRadius: "50%", // Make the image rounded
    width: 120, // Set the width as needed
    height: 120, // Set the height as needed
    objectFit: "cover", // Ensure the image covers the entire container
    border: "2px solid #ccc", // Add a border to the image
    margin: "5px",
  },
}));

export default function NavigationBar({ children, toolbar, template }) {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(true);

  useEffect(() => {
    if (toolbar === false) {
      setOpen(false);
    }
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    dispatch(logout());
    history.push("/");
  };

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [userData, setUserData] = useState();
  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };
  const handleDrawer = () => {
    setOpen(!open);
  };
  const handleDrawerClose = () => {
    setOpen(false);
    // setOpen(true);
  };
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const activeItem = (pathname) => {
    return history.location.pathname === pathname;
  };

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      Authorization: `Bearer ${userInfo && userInfo.access}`,
    },
  });

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async (search = null) => {
    await authAxios.get(urls.userGetOrPut(userInfo.id)).then((res) => {
      setUserData(res.data);
    });
  };

  const [shoppingCart, setShoppingCart] = useState([]);
  const [cartItem, setCartItem] = useState(false);

  const [cartEl, setCartEl] = useState(null);

  const handleCartClick = (event) => {
    setCartEl(event.currentTarget);
  };

  const handleCartClose = () => {
    setCartEl(null);
  };

  useEffect(() => {
    getCart();
  }, [dispatch]);

  const getCartUrl = "http://127.0.0.1:8000/api/guides/order-summary/";
  const getCart = () => {
    authAxios.get(getCartUrl).then((res) => {
      setShoppingCart(res.data);
      setCartItem(true);
    });
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        // position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          {/* never remove */}
          {/* {toolbar && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawer}
              className={clsx(
                classes.menuButton,
                open && classes.menuButtonHidden
              )}
            >
              <MenuIcon />
            </IconButton>
          )} */}
          {/* never remove */}
          {/* <Box display="flex"> */}
          <img
            style={{ height: "40px", width: "60px", marginBottom: "15px" }}
            src={logo}
          ></img>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {/* NHL */}

            <span className="p-1" style={{ border: "0.13rem solid #fff" }}>
              NHL
            </span>
          </Typography>
          {/* </Box> */}

          <IconButton color="inherit">
            {userInfo && (
              <div>
                <Badge
                  badgeContent={`${
                    cartItem
                      ? shoppingCart !== null
                        ? shoppingCart.guides && shoppingCart.guides.length
                        : 0
                      : 0
                  }`}
                  color="secondary"
                  style={{ cursor: "pointer" }}
                >
                  <ShoppingCartIcon
                    variant="contained"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleCartClick}
                    style={{ height: "35px", width: "35px" }}
                  ></ShoppingCartIcon>
                </Badge>

                <Menu
                  id="simple-menu"
                  anchorEl={cartEl}
                  keepMounted
                  open={Boolean(cartEl)}
                  onClose={handleCartClose}
                  style={{ marginTop: "70px" }}
                >
                  <Paper className={classes.paper2}>
                    {shoppingCart
                      ? shoppingCart.guides &&
                        shoppingCart.guides.map((v, i) => (
                          <>
                            <Paper className={classes.paper1}>
                              <Grid container spacing={2}>
                                <Grid item>
                                  <ButtonBase className={classes.image}>
                                    <img
                                      className={classes.img}
                                      alt="complex"
                                      src={`http://127.0.0.1:8000${v.get_image}`}
                                    />
                                  </ButtonBase>
                                </Grid>

                                <Grid item xs={12} sm container>
                                  <Grid
                                    item
                                    xs
                                    container
                                    direction="column"
                                    spacing={2}
                                  >
                                    <Grid item xs>
                                      <Typography
                                        gutterBottom
                                        variant="subtitle2"
                                      >
                                        {v.guide}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Paper>
                            <br></br>
                          </>
                        ))
                      : Array.from(Array(2).keys()).map((i) => (
                          <Box padding={3} key={i}>
                            <Skeleton variant="rect" width={150} height={100} />
                            <Skeleton animation="wave" />
                          </Box>
                        ))}
                    {shoppingCart.guides && shoppingCart.guides.length === 0 && (
                      <Box
                        display="flex"
                        height="100%"
                        marginTop="1vh"
                        alignItems="center"
                        flexDirection="column"
                        justifyContent="center"
                      >
                        <img
                          style={{
                            maxWidth: "150px",
                          }}
                          src={emtyCart}
                        ></img>
                        <Typography variant="h4">Your Cart is Emty</Typography>
                      </Box>
                    )}
                    {shoppingCart.guides && shoppingCart.guides.length === 0 ? (
                      <Link to="/visa-guides">
                        {" "}
                        <Button
                          style={{ marginTop: "10px" }}
                          variant="contained"
                          color="primary"
                          fullWidth
                        >
                          Buy Guide
                        </Button>
                      </Link>
                    ) : (
                      <Link to="/cart">
                        {" "}
                        <Button
                          style={{ marginTop: "10px" }}
                          variant="contained"
                          color="primary"
                          fullWidth
                        >
                          Go to cart
                        </Button>
                      </Link>
                    )}
                  </Paper>
                </Menu>
              </div>
            )}
          </IconButton>

          {userData && userData.image ? (
            <Tooltip title="Profile menu" placement="middle">
              <img
                onClick={handleClick}
                src={userData && userData.image}
                className={clsx(classes.roundedImage)}
              ></img>
            </Tooltip>
          ) : (
            <Tooltip title="Profile menu" placement="middle">
              <img
                onClick={handleClick}
                src="https://www.shareicon.net/data/128x128/2016/05/24/770117_people_512x512.png"
                className={clsx(classes.roundedImage)}
              ></img>
            </Tooltip>
          )}

          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            style={{ marginTop: "60px" }}
          >
            <MenuItem style={{ display: "flex" }}>
              {userData && userData.image ? (
                <Tooltip title="Profile menu" placement="middle">
                  <img
                    onClick={handleClick}
                    src={userData && userData.image}
                    className={clsx(classes.roundedImage)}
                  ></img>
                </Tooltip>
              ) : (
                <Tooltip title="Profile menu" placement="middle">
                  <img
                    onClick={handleClick}
                    src="https://www.shareicon.net/data/128x128/2016/05/24/770117_people_512x512.png"
                    className={clsx(classes.roundedImage)}
                  ></img>
                </Tooltip>
              )}
              <Typography style={{ marginLeft: "10px" }} variant="h6">
                {userInfo.email}
              </Typography>
            </MenuItem>
            <Divider></Divider>
            <MenuItem onClick={() => history.push(`/dashboard/profile`)}>
              <Typography variant="h6">Account Settings</Typography>
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              {" "}
              <Typography variant="h6">Logout</Typography>
            </MenuItem>
          </Menu>
          {/* </Fab> */}
        </Toolbar>

        <div className={classes.mobileDisplay}>
          {history.location.pathname != "/dashboard/documents/create" && (
            <IconBreadcrumbs template={template && template} />
          )}
        </div>
      </AppBar>
    </div>
  );
}
