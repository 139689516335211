import React, { useEffect, useState, useRef, useContext } from "react";

import {
  Button,
  Container,
  Grid,
  makeStyles,
  Box,
  Typography,
  Input,
} from "@material-ui/core";
import AppContext from "../../../../../../context/AppContext";

function PaperContent(props) {
  const { targetTemplate, signImgUrl, signText, getFieldContents } = useContext(
    AppContext
  );

  const [fieldContents, setFieldContents] = useState([]);
  const [paperContent, setPaperContent] = useState({});

  useEffect(() => {
    const arr = [];
    getFieldContents().forEach((item) => {
      arr.push(item);
    });

    setFieldContents(arr);
  }, [targetTemplate]);

  useEffect(() => {
    const obj = {};
    fieldContents.forEach((item) => {
      //const tId = item.name.toLowerCase().replace(/ /g, "-");
      if (item.name === "Your Full Name") {
        obj["Your Full Name"] = item.field_content;
      } else if (item.name === "Your Address") {
        obj["Your Address"] = item.field_content;
      } else if (item.name === "Your Email Address") {
        obj["Your Email Address"] = item.field_content;
      } else if (item.name === "Your Phone Number") {
        obj["Your Phone Number"] = item.field_content;
      } else if (item.name === "Date") {
        obj["Date"] = item.field_content;
      } else if (item.name === "Amount Paid") {
        obj["Amount Paid"] = item.field_content;
      } else if (item.name === "Name Of What You Bought") {
        obj["Name Of What You Bought"] = item.field_content;
      } else if (item.name === "Trader Name") {
        obj["Trader Name"] = item.field_content;
      } else if (item.name === "Trader Address") {
        obj["Trader Address"] = item.field_content;
      } else if (
        item.name === "Is there a specific person you want to write to"
      ) {
        obj["Is there a specific person you want to write to"] =
          item.field_content;
      } else if (item.name === "If yes, what's their name") {
        obj["If yes, what's their name"] = item.field_content;
      } else if (item.name === "What did you buy") {
        obj["What did you buy"] = item.field_content;
      } else if (item.name === "When did you buy it") {
        obj["When did you buy it"] = item.field_content;
      } else if (item.name === "How much did you pay") {
        obj["How much did you pay"] = item.field_content;
      } else if (item.name === "What's wrong with the goods") {
        obj["What's wrong with the goods"] = item.field_content;
      } else if (item.name === "Describe the problem") {
        obj["Describe the problem"] = item.field_content;
      } else if (item.name === "What you are asking for") {
        obj["What you are asking for"] = item.field_content;
      } else if (item.name === "How much do you want refunded") {
        obj["How much do you want refunded"] = item.field_content;
      } else if (
        item.name === "List the documents you’re sending with this letter"
      ) {
        obj["List the documents you’re sending with this letter"] =
          item.field_content;
      }
    });

    setPaperContent((prev) => ({ ...prev, ...obj }));
    console.log("PaPeRCoNtEnT", paperContent);
  }, [fieldContents]);

  const styleObj = {
    transform: "scaleX(0.6) scaleY(0.98)",
    height: "500px",
    maxHeight: "500px",
    overflowY: "auto",
  };

  return (
    <>
      <Box style={props.scaleDown ? styleObj : { padding: "10px" }}>
        <div id={props.id}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box style={{ flex: "1" }}></Box>
            <Box>
              <p style={{ display: "block", marginBottom: "5px" }}>
                {paperContent["Your Full Name"]}
              </p>
              <p style={{ display: "block", marginBottom: "5px" }}>
                {paperContent["Your Address"]}
              </p>
              <p style={{ display: "block", marginBottom: "5px" }}>
                {paperContent["Your Email Address"]}
              </p>
              <p style={{ display: "block", marginBottom: "5px" }}>
                {paperContent["Your Phone Number"]}
              </p>
            </Box>
          </Box>
          <Box style={{ marginTop: "5px" }}>
            <p style={{ marginBottom: "5px" }}>{paperContent["Trader Name"]}</p>
            <br></br>
            <p style={{ marginBottom: "5px" }}>
              {paperContent["Trader Address"]}
            </p>
          </Box>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box style={{ flex: "1" }}></Box>
            <Box>
              <p>{paperContent["Date"]}</p>
            </Box>
          </Box>
          <Box>
            <p>To whom it may concern,</p>
          </Box>
          <Box>
            <p style={{ fontWeight: "bold" }}>Complaint about faulty goods</p>
          </Box>
          <Box>
            <p>
              I bought a sewing machine from you on {paperContent["Date"]}. I
              paid {paperContent["Amount Paid"]}.<br></br>I now find the goods
              have the following fault:
              <br></br>
              <br></br>
              The machine stops working after half an hour and won't work again
              for<br></br>
              atleast an hour.<br></br>
              <br></br>
              Under the Consumer Rights Act 2015, goods you supply must be fit
              for
              <br></br>
              purpose. As there was a problem with the goods when I bought them,
              <br></br>I request that you give me a full refund.
              <br></br>
              <br></br>
              In support of my claim I have enclosed: The receipt for the{" "}
              {paperContent["Name Of What You Bought"]}
              <br></br>
              <br></br>
              Please respond within 14 days of receiving this letter.
              <br></br>
              <br></br>
              Yours faithfully<br></br>
              <br></br>
              {paperContent["Your Full Name"]}
              <span style={{ display: "block", marginBottom: "10px" }}></span>
              <br></br>
              {signImgUrl ? (
                <img
                  style={{ width: "150px", height: "50px" }}
                  src={signImgUrl}
                  alt="Signature"
                />
              ) : (
                <input
                  style={{
                    background: "#f5f5f5",
                    border: "1px solid #f5f5f5",
                  }}
                  value={signText}
                  placeholder={"[Recipient Signature]"}
                ></input>
              )}
            </p>
          </Box>
        </div>
      </Box>
    </>
  );
}

export default PaperContent;
