import React, { useContext } from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { fabric } from "fabric";
import { Box, Typography, Input } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import Backdrop from "@mui/material/Backdrop";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import RadioButtonsGroup from "./RadioButtonGroup";
import Switch from "@mui/material/Switch";
import { Button } from "@material-ui/core";
import AppContext from "../../../../../../../src/context/AppContext";

function Modal2(props) {
  //Context api
  const {
    inputs,
    texts,
    setInputs,
    setTexts,
    handleInputChangeNew,
    handleAddInput,
  } = useContext(AppContext);
  const createItext = (canvas) => {
    if (canvas) {
      //canvas.clear(); // Clear the canvas before adding new text
      const iText = new fabric.IText("", {
        left: 100,
        top: 100,
        fontSize: 30,
        fill: "black",
        fontFamily: "Arial",
        selectable: true,
      });
      canvas.add(iText);
      canvas.renderAll();
    }
  };
  return (
    <Modal
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 5500,
      }}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.openModal2}
      onClose={props.handleCloseModal2}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={props.openModal2}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
          style={{
            backgroundColor: "#fff",
            zIndex: 300,

            padding: "10px",
            width: "500px",
          }}
        >
          <Box display={"flex"} alignItems={"center"} gridGap={8}>
            <Box>
              <AddIcon sx={{ color: "#000" }} />
            </Box>
            <Box flex={1}>
              <Typography variant="h5" component="h2">
                Add New Field
              </Typography>
            </Box>
            <Box onClick={props.handleCloseModal2}>
              <CloseIcon style={{ color: "#000" }} />
            </Box>
          </Box>
          <Box mt={4}>
            <Input
              onChange={props.handleFieldName}
              value={props.fieldName}
              padding={5}
              fullWidth
              placeholder={"Type Field Name (e.g Business Name)"}
            />
            {props.showFieldNameError && (
              <small style={{ color: "red" }}>
                This field should not be empty
              </small>
            )}
          </Box>
          <Divider></Divider>

          <Box mt={4}>
            <Typography component={"p"}>
              Select Field Type (options:)
            </Typography>
            <RadioButtonsGroup onChange={props.handleChangeRadio} />
          </Box>
          <Box mt={2} display={"flex"} alignItems={"center"} gridGap={4}>
            <Switch
              {...props.label}
              onChange={props.handleSwitch}
              checked={props.isFieldRequired}
            />
            <Typography style={{ color: "#000" }}>Required field?</Typography>
          </Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box flex={1}></Box>
            <Box>
              <Button
                onClick={() => {
                  props.handleCreateField(props.parentId);
                  //createItext(props.canvas);
                  handleAddInput(props.canvas);
                }}
                className={props.classes.previewBtn}
              >
                Create
              </Button>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}

export default Modal2;
