import React, { useContext } from "react";
import {
  Typography,
  Button,
  makeStyles,
  AppBar,
  Dialog,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import Header from "./Header";
import Professional from "./Professional";
import Education from "./Education";
import AdditionalSkills from "./AdditionalSkills";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
} from "react-router-dom";

import myClasses from "./Left.module.css";

import Templates from "./templates/Templates";

import thumbn from "../templateA.png";
import logo from "../default.png";
import nhl from "../nhl_logo.png";

import { ResumeContext } from "../../../contexts/ResumeContext";

const useStyles = makeStyles({
  rootAdd: {
    background: "linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px #F2F3F4",
    color: "white",
    height: 30,
    textAlign: "center",
    fontSize: 10,
    marginTop: 15,
    marginRight: 20,
    fontWeight: 700,
  },
  rootRemove: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    height: 30,
    textAlign: "center",
    minWidth: 130,
    fontSize: 10,
    marginTop: 15,
    marginRight: 20,
    fontWeight: 700,
  },

  headerLink: {
    color: "#0298B8 ",
  },
});

function Sidebar(props) {
  const { control, addFakeData, removeFakeData } = useContext(ResumeContext);
  const classes = useStyles();
  const [scroll, setScroll] = React.useState("paper");

  function useFakeData(e) {
    e.preventDefault();
    addFakeData();
  }

  function clearFakeData(e) {
    e.preventDefault();
    removeFakeData();
  }

  let expData;
  if (control) {
    expData = (
      <Button
        color="secondary"
        onClick={clearFakeData}
        className={classes.rootRemove}
        // style={{ marginTop: 15, marginRight: 20, height: "auto" }}
      >
        remove example
      </Button>
    );
  } else {
    expData = (
      <Button
        color="primary"
        onClick={useFakeData}
        className={classes.rootAdd}

        // style={{ marginTop: 15, marginRight: 20, height: "auto" }}
      >
        example
      </Button>
    );
  }

  return (
    <div className="left">
      <div className={myClasses.headerLeft}>
        <div styles={{ flexGrow: 2 }}>
          {/* <Link to="/" style={{ display: "inline" }}>
            <img
              style={{ height: "50px", width: "80px" }}
              src={nhl}
              alt="logo"
            />
          </Link> */}
          <Typography style={{ paddingLeft: "40px" }} variant="h4">
            {props.template.name}
          </Typography>
          {/* <Link to="/" style={{ display: "inline", marginTop: "20px" }}>
            <span
              className="p-1 color border-primary mobile__header"
              style={{ border: "0.13rem solid" }}
            >
              NHL
            </span>{" "}
          </Link> */}
        </div>
        {/* <div styles={{ flexGrow: 1 }}>{expData}</div> */}
      </div>

      <div className="">
        {/* <Router> */}

        <div>
          <hr className={myClasses.hr} />
          <div className={myClasses.formsSection}>
            {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
            {/* <Switch> */}
            {/* <Route path="/" component={Templates} exact /> */}

            {/* <Route path="/dashboard/documents/single-templates/cv1"> */}

            {/* Your content here */}
            <Professional
              templateUpdate={props.templateUpdate}
              template={props.template}
            />
            {/* Your content here */}

            {/* </Route> */}
            {/* <Route path="/basic/education">
                  <Education />
                </Route>
                <Route path="/basic/additional">
                  <AdditionalSkills />
                </Route> */}
            {/* </Switch> */}
          </div>
        </div>
        {/* </Router> */}
      </div>
    </div>
  );
}

export default Sidebar;
