import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useMemo,
  useEffect,
} from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { withSnackbar } from "notistack";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextareaAutosize,
  makeStyles,
  Container,
  Input,
} from "@material-ui/core";
import { urls } from "../../../../utils/urls";
import axiosInstance from "../../../../axios";

const useStyles = makeStyles((theme) => ({
  textarea: {
    width: "100%",
    padding: theme.spacing(2),
    fontSize: "16px",
    lineHeight: "1.6",
    borderRadius: "8px",
    border: "1px solid #ced4da",
    fontFamily: "Arial, sans-serif",
    transition: "border-color 0.2s",
    "&:hover": {
      borderColor: "#999",
    },
    "&:focus": {
      borderColor: theme.palette.primary.main,
      boxShadow: `0 0 0 0.2rem ${theme.palette.primary.light}`,
      outline: "none",
    },
    marginTop: 12,
    marginLeft: 8,
    marginRight: 8,
  },
}));

const FieldModal = forwardRef((props, ref) => {
  const { handleSubmitField } = props;
  const [fieldType, setFieldType] = useState("TextField");
  const [fieldId, setFieldId] = useState(null);
  const [sectionId, setSectionId] = useState(null);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [val, setVal] = useState({
    field_name: "",
    field_content: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setVal({ ...val, [name]: value });
  };

  const handleClickOpen = (e) => {
    setOpen(true);
    setSectionId(e.id);
    setVal({
      field_name: "",
      field_content: "",
    });
    reset({
      field_name: "",
      field_content: "",
    });
  };
  const handleClose = (e) => {
    setOpen(false);
    setEdit(false);
  };

  const { register, errors, handleSubmit, setValue, reset } = useForm({
    defaultValues: useMemo(() => {
      return val;
    }, [setVal]),
  });

  const handleEdit = (e) => {
    setEdit(true);
    setFieldId(e.id);
    setOpen(true);
    setVal({
      field_name: "",
      field_content: "",
    });
    reset({
      field_name: "",
      field_content: "",
    });
  };
  useImperativeHandle(ref, () => ({
    handleClickOpen,
    handleEdit,
  }));

  const onSubmit = (e, edit, fieldId, sectionId, handleClose) => {
    const payload = {
      ...e,
      ...val,
    };
    handleSubmitField(payload, edit, fieldId, sectionId, handleClose);
  };

  const handleFieldTypeChange = (event) => {
    setFieldType(event.target.value);
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleUpload = () => {
    // Handle file upload logic here
    if (selectedFile) {
      console.log("Selected File:", selectedFile);
      // Perform your file upload operation
    }
  };

  const renderFieldContent = () => {
    switch (fieldType) {
      case "DateField":
        return (
          <TextField
            style={{ marginTop: 12, marginLeft: 8, marginRight: 8 }}
            fullWidth
            variant="outlined"
            label="Field Content"
            type="date"
            required
          />
        );
      case "ImageField":
        return (
          <div>
            <Input
              type="file"
              onChange={handleFileChange}
              style={{ display: "none" }}
              id="fileInput"
            />
            <label htmlFor="fileInput">
              <Button component="span" variant="contained" color="primary">
                Choose File
              </Button>
            </label>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpload}
              disabled={!selectedFile}
            >
              Upload
            </Button>
          </div>
        );
      case "IntegerField":
        return (
          <TextField
            style={{ marginTop: 12, marginLeft: 8, marginRight: 8 }}
            fullWidth
            variant="outlined"
            label="Field Content"
            type="number"
            required
          />
        );
      case "EmailField":
        return (
          <TextField
            style={{ marginTop: 12, marginLeft: 8, marginRight: 8 }}
            fullWidth
            variant="outlined"
            label="Field Content"
            type="email"
            required
          />
        );
      default:
        return (
          <>
            <TextareaAutosize
              id="outlined-basic"
              fullWidth
              placeholder="Field Content"
              name="field_content"
              variant="outlined"
              //   value={val.field_content}
              maxRows={2}
              className={classes.textarea}
              onChange={handleChange}
              // style={{ marginTop: 12, paddingLeft: 8, paddingRight: 8 }}
            />
            {/* <TextField
              style={{ marginTop: 12, marginLeft: 8, paddingRight: 8 }}
              fullWidth
              variant="outlined"
              label="Field Content"
              required
            /> */}
          </>
        );
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle style={{ textAlign: "center" }} id="form-dialog-title">
          {edit ? `Edit Section Field` : `Add Section Field`}
        </DialogTitle>

        <form
          onSubmit={
            handleSubmit((e) =>
              onSubmit(e, edit, fieldId, sectionId, handleClose)
            )
            // handleClose(e);
          }
        >
          <Container>
            <DialogContent>
              <>
                {/* <FormControl fullWidth>
                    
                    <Select
                      fullWidth
                     
  
                      name="category"
                      variant="outlined"
                      
                    > */}
                {/* <FormControl
                  style={{ marginTop: 12, marginLeft: 8, marginRight: 0 }}
                  fullWidth
                >
                  <InputLabel>Field Type</InputLabel>
                  <Select
                    value={fieldType}
                    fullWidth
                    variant="outlined"
                    onChange={handleFieldTypeChange}
                  >
                    <MenuItem value="CharField">CharField</MenuItem>
                    <MenuItem value="DateField">DateField</MenuItem>
                   
                    <MenuItem value="IntegerField">IntegerField</MenuItem>
                    <MenuItem value="TextField">TextField</MenuItem>
                    <MenuItem value="EmailField">EmailField</MenuItem>
                  </Select>
                </FormControl>
                <div> {renderFieldContent()}</div> */}
                {/* </Select>
                  </FormControl> */}
                <Container>
                  <TextField
                    // id="outlined-basic"
                    fullWidth
                    // margin="dense"
                    label="Field Name"
                    name="field_name"
                    onChange={handleChange}
                    value={val.field_name}
                    variant="outlined"
                    // defaultValue={content.professional.company1}
                    // inputRef={register}

                    style={{ marginTop: 12, marginLeft: 8, marginRight: 0 }}
                  />
                  <TextareaAutosize
                    id="outlined-basic"
                    fullWidth
                    placeholder="Field Content"
                    name="field_content"
                    variant="outlined"
                    value={val.field_content}
                    maxRows={2}
                    className={classes.textarea}
                    onChange={handleChange}
                    style={{ marginTop: 20 }}
                  />
                </Container>
              </>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button variant="contained" type="submit" color="primary">
                {/* {edit ? 'Update' : 'Add'} */}

                {edit ? "Update" : "Create"}
                {/* {updateLoading && (
                    <CircularProgress style={{ marginLeft: '10px' }} size={15} />
                  )} */}
              </Button>
            </DialogActions>
          </Container>
        </form>
      </Dialog>
    </>
  );
});
export default withSnackbar(FieldModal);
