import React, { useEffect, useState } from "react";
// import Basic from "../components/Left/Basic";
import Sidebar from "./sidebar/Sidebar";
// import Right from "../components/Right/Right";
import Main from "./main/main";
import "./styles.css";

import { BrowserRouter, Route, Switch, Link } from "react-router-dom";
import myClasses from "../singleTemplate/sidebar/Left.module.css";
import logo from "../singleTemplate/default.png";
import thumbn from "../singleTemplate/templateA.png";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import CvFormateOne from "../../../../components/cvDesignLIst/CvFormateOne";
import { urls } from "../../../../utils/urls";
import axiosInstance from "../../../../axios";

function Templates() {
  const useStyles = makeStyles({
    headerLink: {
      color: "#FF8E53 ",
      minWidth: 100,
      marginLeft: 30,
    },
  });

  const classes = useStyles();

  return (
    <div className="left">
      <div className={myClasses.headerLeft}>
        <Link to="/" style={{ textAlign: "left" }}>
          <img src={logo} alt="logo" className={myClasses.img2} />
        </Link>
      </div>
      <hr className={myClasses.hr2} />
      <h2 className={myClasses.templatesH2}>Templates</h2>
      <div className={myClasses.cards}>
        <div className={myClasses.templateCard}>
          <img src={thumbn} alt="thumbnail" className={myClasses.imgThumb} />
          <Button
            className={classes.headerLink}
            component={Link}
            to="/basic/header"
          >
            The Basic
          </Button>
        </div>
        {/* Placeholder for a second template */}
        {/* <div className={myClasses.templateCard}>
          <img src={thumbn} alt="thumbnail" className={myClasses.imgThumb} />
          <Button
            className={classes.headerLink}
            component={Link}
            to="/basic/header"
          >
            The Stylish
          </Button>
        </div> */}
      </div>
    </div>
  );
}

function SingleTemplate(props) {
  const [template, setTemplate] = useState({});

  useEffect(() => {
    fetchSingleTemplate();
  }, []);

  const {
    match: {
      params: { slug },
    },
  } = props;

  const fetchSingleTemplate = async (search = null) => {
    await axiosInstance.get(urls.singleTemplate(slug)).then((res) => {
      setTemplate(res.data);
    });
  };
  const renderTemplateComponent = () => {
    let templateComponent = <CvFormateOne />;
    if (template.id == 1) {
      return (templateComponent = <CvFormateOne template={template} />);
    } else if (template.id == 3) {
      return (templateComponent = <Main />);
    } else {
      return (templateComponent = <h1>template does not exist</h1>);
    }
  };

  return (
    <div className="app">
      <Sidebar
        templateUpdate={fetchSingleTemplate}
        template={template}
      ></Sidebar>
      {/* <ResumeContextProvider> */}
      {/* <BrowserRouter>
        <Switch>
          <Route path="/" component={Templates} exact />
          <Route path="/basic" component={Basic} />
        </Switch>
      </BrowserRouter> */}
      {/* <Main /> */}
      {renderTemplateComponent()}

      {/* <CvFormateOne></CvFormateOne> */}
      {/* </ResumeContextProvider> */}
    </div>
  );
}

export default SingleTemplate;
