import React, { useEffect, useState } from "react";
import { Update } from "@material-ui/icons";
import WidgetsIcon from "@mui/icons-material/Widgets";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import SearchIcon from "@mui/icons-material/Search";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TuneIcon from "@mui/icons-material/Tune";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import TextEditor from "../existingDocument/templateView/components/TextEditor";
import TemplateView from "../existingDocument/templateView/TemplateView";
//import EditorView from "../../../../components/EditorView";
import {
  Button,
  Container,
  Grid,
  makeStyles,
  Box,
  Typography,
  Input,
} from "@material-ui/core";

import { urls } from "../../../../utils/urls";
import axiosInstance from "../../../../axios";

const useStyles = makeStyles((theme) => ({
  app: {
    display: "flex",
    minHeight: "100vh",
  },
  leftBarCircle: {
    width: "70px",
    height: "70px",
    borderRadius: "50%",
    backgroundColor: "#bdbdbd",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  leftBarBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "20px",
    marginBottom: "20px",
  },
  leftBar: {
    backgroundColor: "#fff",
  },
  rightBar: {
    backgroundColor: "#f2f3f5",
  },
  rightBarContainer: {},

  rightBarContainerLeft: {
    padding: "10px",
  },

  rightBarContainerRight: {},

  whiteCardOne: {
    backgroundColor: "#fff",
    padding: "10px",
    marginBottom: "8px",
  },

  whiteCardTwo: {
    backgroundColor: "#fff",
    padding: "10px",
    marginBottom: "8px",
  },

  whiteCardThree: {
    backgroundColor: "#fff",
    padding: "10px",
    marginBottom: "8px",
  },

  whiteCardOneSmallText: {
    fontSize: "12px",
  },

  tabBox: {},

  tabCircle: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    backgroundColor: "#bdbdbd",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },

  lensBoxContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  lensBox: {
    display: "flex",
    alignItems: "center",
  },

  configBox: {},

  searchTempTxt: {
    fontSize: "12px",
    color: "#929595",
  },

  templateImg: {
    width: "100%",
    height: "100%",
    marginTop: "0px",
    marginRight: "0px",
    marginLeft: "0px",
    // maxWidth: "95px",
    // maxHeight: "100px",
    display: "block",
  },

  templateBtn: {
    fontSize: "12px",
    color: "#fff",
    width: "70%",
    backgroundColor: "#5400b8",
    paddingRight: "40px",
    paddingLeft: "40px",
    marginTop: "15px",
    marginBottom: "15px",
  },

  minorNav: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px",
    boxShadow: "0px 2px 2px #bdbdbd",
  },

  previewBtn: {
    backgroundColor: "#3f94ed",
    color: "#fff",
  },
}));

function CreateScratchDocument(props) {
  return (
    <TemplateView {...props}/>
  )
}

export default CreateScratchDocument;
