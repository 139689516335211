import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useMemo,
} from "react";
import { useForm } from "react-hook-form";
import queryString from "query-string";
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import { urls } from "../../../utils/urls";
import axiosInstance from "../../../axios";

const useStyles = makeStyles((theme) => ({
  dialogCustomizedWidth: {
    width: "50%",
    height: "40%",
  },
}));

const CategoryModal = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const history = useHistory();

  const [val, setVal] = useState({
    category: "",
    subcategory: "",
  });
  const { register, errors, handleSubmit, setValue, reset } = useForm({
    defaultValues: useMemo(() => {
      return val;
    }, [setVal]),
  });
  const handleClickOpen = () => {
    setOpen(true);
    setVal({
      category: "",
      subcategory: "",
    });
  };
  const handleClose = (e) => {
    setOpen(false);
  };
  useImperativeHandle(ref, () => ({
    handleClickOpen,
  }));

  const handleSubmitCategory = async (e, edit, courseName) => {
    let response = null;
    // setLoading(true);
    // setUpdateLoading(true);
    const queryParams = queryString.stringify({
      ...val,
    });

    await axiosInstance.get(urls.getTemplate(queryParams)).then((res) => {
      // setBtnLoading(false);
      // setLoading(false);
      // setLetter(res.data);
      console.log(res);
    });
    // if (response.status === 201) {
    //   handleClose();
    //   fetchCourseList();
    //   // setUpdateLoading(false);
    // } else {
    //   setUpdateLoading(false);

    // }
  };
  const onSubmit = (e) => {
    handleSubmitCategory();
    history.push(`/dashboard/templates-list`);
  };
  const handleFieldChange = (event) => {
    const { name, value } = event.target;

    setVal({ ...val, [name]: value });
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.dialogCustomizedWidth }}
      >
        <DialogTitle id="form-dialog-title">Choose Category</DialogTitle>

        <form onSubmit={handleSubmit((e) => onSubmit(e))}>
          <DialogContent>
            <>
              <FormControl fullWidth>
                <InputLabel>Category</InputLabel>
                <Select
                  fullWidth
                  value={val.category}
                  onChange={handleFieldChange}
                  name="category"
                >
                  {props.category &&
                    props.category.length > 0 &&
                    props.category.map((cat) => (
                      <MenuItem key={cat.id} value={cat.id}>
                        {cat.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <br></br>
              <br></br>
              <FormControl fullWidth>
                <InputLabel>Sub Category</InputLabel>
                <Select
                  fullWidth
                  value={val.subcategory}
                  onChange={handleFieldChange}
                  name="subcategory"
                >
                  {props.category &&
                    props.category.length > 0 &&
                    props.category.map(
                      (cat) =>
                        cat.sub_category &&
                        cat.sub_category.length > 0 &&
                        cat.sub_category.map((sub_cat) => (
                          <MenuItem key={sub_cat.id} value={sub_cat.id}>
                            {sub_cat.name}
                          </MenuItem>
                        ))
                    )}
                </Select>
              </FormControl>
            </>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleClose} color="primary">
              Cancel
            </Button>

            <Button
              // onClick={() =>
              //   history.push(`/dashboard/templates-list/category/subcategory`)
              // }
              type="submit"
              variant="contained"
              color="primary"
            >
              Select
              {/* {updateLoading && (
                <CircularProgress style={{ marginLeft: '10px' }} size={15} />
              )} */}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
});

export default CategoryModal;
