import React, { useState, useContext, useRef } from "react";
import { withSnackbar } from "notistack";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import EditIcon from "@material-ui/icons/Edit";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import classes from "./Left.module.css";
import { useForm } from "react-hook-form";
import { ResumeContext } from "../../../contexts/ResumeContext";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Box,
  Fab,
  Tooltip,
  Typography,
  makeStyles,
  TextareaAutosize,
  IconButton,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CustomAccordion from "../../../../../components/CustomAccordion";
import GroupModal from "./GroupModal";
import FieldModal from "./FieldModal";
import axiosInstance from "../../../../../axios";
import { urls } from "../../../../../utils/urls";

const useStyles = makeStyles((theme) => ({
  fab: {
    bottom: theme.spacing(2),
    right: theme.spacing(3),
    left: theme.spacing(15),
  },
  fab2: {
    top: theme.spacing(1),
    bottom: theme.spacing(2),
    right: theme.spacing(3),
    left: theme.spacing(14),
  },

  textarea: {
    width: "100%",
    padding: theme.spacing(2),
    fontSize: "16px",
    lineHeight: "1.6",
    borderRadius: "8px",
    border: "1px solid #ced4da",
    fontFamily: "Arial, sans-serif",
    transition: "border-color 0.2s",
    "&:hover": {
      borderColor: "#999",
    },
    "&:focus": {
      borderColor: theme.palette.primary.main,
      boxShadow: `0 0 0 0.2rem ${theme.palette.primary.light}`,
      outline: "none",
    },
  },
}));

const CustomTextAreaComponent = () => {
  const classes = useStyles();

  return (
    <div>
      <TextField
        id="custom-textarea"
        label="Custom Label"
        multiline
        InputProps={{
          className: classes.textarea,
        }}
      />
    </div>
  );
};

function Professional(props) {
  const { content, updateProfessionalData, removeFakeData } =
    useContext(ResumeContext);

  const [btnText, setBtnText] = useState("Add");

  const classes = useStyles();
  const addDialog = useRef(null);
  const fieldAddDialog = useRef(null);

  const [values, setValues] = useState({});

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    removeFakeData();
    updateProfessionalData(data);
    setBtnText("Update");
  };

  const handleFieldUpdate = (e) => {
    fieldAddDialog.current.handleEdit(e);
  };

  const handleUpdate = (e) => {
    addDialog.current.handleEdit(e);
    console.log(e);
  };

  const handleSubmitField = async (
    e,
    edit,
    fieldId,
    sectionId,
    handleClose
  ) => {
    if (edit) {
      await axiosInstance
        .put(urls.updateTemplateField(fieldId), e)
        .then((res) => {
          props.enqueueSnackbar(`Field Updated Successfully`, {
            variant: "success",
            autoHideDuration: 5000,
          });
          handleClose();
          props.templateUpdate();
        });
    } else {
      await axiosInstance
        .post(urls.createTemplateField(sectionId), e)
        .then((res) => {
          props.enqueueSnackbar(`Field Created Successfully`, {
            variant: "success",
            autoHideDuration: 5000,
          });
          handleClose();
          props.templateUpdate();
        });
    }
  };

  const handleSubmitSection = async (
    e,
    edit,
    sectionId,
    templateId,
    handleClose
  ) => {
    if (edit) {
      await axiosInstance
        .put(urls.updateTemplateSection(sectionId), e)
        .then((res) => {
          props.enqueueSnackbar(`Section Updated Successfully`, {
            variant: "success",
            autoHideDuration: 5000,
          });
          handleClose();
          props.templateUpdate();
        });
    } else {
      await axiosInstance
        .post(urls.createTemplateSection(templateId), e)
        .then((res) => {
          props.enqueueSnackbar(`Section Created Successfully`, {
            variant: "success",
            autoHideDuration: 5000,
          });
          handleClose();
          props.templateUpdate();
        });
    }
  };

  const handleDeleteSection = async (sectionId) => {
    await axiosInstance
      .delete(urls.deleteTemplateSection(sectionId))
      .then((res) => {
        props.enqueueSnackbar(`Section Deleted Successfully`, {
          variant: "error",
          autoHideDuration: 5000,
        });

        props.templateUpdate();
      });
  };
  const handleDeleteField = async (fieldId) => {
    await axiosInstance
      .delete(urls.deleteTemplateField(fieldId))
      .then((res) => {
        props.enqueueSnackbar(`Field Deleted Successfully`, {
          variant: "error",
          autoHideDuration: 5000,
        });

        props.templateUpdate();
      });
  };

  return (
    <div className="">
      <Tooltip title="Create Group" aria-label="Create Group">
        <Fab
          color="primary"
          className={classes.fab}
          onClick={() => addDialog.current.handleClickOpen()}
        >
          <AddIcon />
        </Fab>
      </Tooltip>
      {props.template ? (
        <>
          {props.template.sections &&
            props.template.sections.map((section) => (
              <>
                <CustomAccordion
                  title={section.section_name}
                  key={section.id}
                  handleUpdate={() => {
                    handleUpdate(section);
                  }}
                  handleDeleteSection={() => {
                    handleDeleteSection(section.id);
                  }}
                  content={
                    <>
                      <Tooltip
                        title="Create New Field"
                        aria-label="Create New Field"
                      >
                        <Fab
                          size="small"
                          color="secondary"
                          className={classes.fab2}
                          onClick={() =>
                            fieldAddDialog.current.handleClickOpen(section)
                          }
                        >
                          <AddIcon />
                        </Fab>
                      </Tooltip>

                      <form
                        className={classes.formStyle}
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        {section.fields.map((field) => (
                          <>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <label>{field.name}</label>
                              <IconButton
                                onClick={() => {
                                  handleDeleteField(field.id);
                                }}
                              >
                                <DeleteIcon color="secondary"></DeleteIcon>
                              </IconButton>
                            </Box>

                            <TextareaAutosize
                              key={field.id}
                              id="outlined-basic"
                              fullWidth
                              label={field.name}
                              name={field.content}
                              variant="outlined"
                              value={field.content}
                              inputRef={register}
                              maxRows={2}
                              className={classes.textarea}
                              onChange={handleChange}
                            />
                            {/* <label>{field.name}</label> */}
                            {/* <ReactQuill
                          value={editorContent}
                          onChange={handleEditorChange}
                          modules={{
                            toolbar: [
                              ["bold", "italic", "underline", "strike"], // Customizable options
                              [{ list: "ordered" }, { list: "bullet" }],
                              ["link"],
                            ],
                          }}
                        /> */}
                          </>
                        ))}
                      </form>
                    </>
                  }
                ></CustomAccordion>

                <br></br>
                <FieldModal
                  handleSubmitField={handleSubmitField}
                  templateUpdate={props.templateUpdate}
                  sectionId={section.id}
                  //  fieldId={field.id}
                  ref={fieldAddDialog}
                />
                <GroupModal
                  handleSubmitSection={handleSubmitSection}
                  templateUpdate={props.templateUpdate}
                  templateId={props.template.id}
                  ref={addDialog}
                />
              </>
            ))}
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}
export default withSnackbar(Professional);
