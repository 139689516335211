import React, { useEffect } from "react";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    marginBottom: "10px",
    // position: "relative",
  },
  appBar: {
    top: "unset",
    left: "unset",

    position: "relative",
    [theme.breakpoints.down("sm")]: {
      right: "unset",
    },
  },
}));

export default function CustomNewTabs({
  collapse,
  data,
  fixed,
  bgwhite,
  center,
}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(data[0].label);
  const history = useHistory();
  const location = useLocation();
  function handleChange(event, newValue) {
    history.push(`#tab=${newValue}`);
    setValue(newValue);
  }

  useEffect(() => {
    const tmpData = [];
    if (data) {
      data.forEach((element) => {
        tmpData.push(element.label);
      });
    }
    const locationValue = location.hash.slice(5);

    if (location.hash && tmpData.includes(locationValue)) {
      handleChange(null, locationValue);
    }
  }, [(data, location.hash)]);

  return (
    <div
      className={classes.root}
      style={{
        marginTop: fixed ? "-20px" : "initial",
        backgroundColor: bgwhite ? "#fff" : "transparent",
      }}
    >
      <AppBar
        position={fixed ? "fixed" : "static"}
        className={classes.appBar}
        color="default"
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          fullwidth="true"
          textColor="primary"
          variant={center ? "standard" : "scrollable"}
          centered={!!center}
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {data.map((v, i) => (
            <Tab key={i} value={v.label} icon={v.icon} label={v.label} />
          ))}
        </Tabs>
      </AppBar>
    </div>
  );
}
CustomNewTabs.propTypes = {
  data: PropTypes.array.isRequired,
  fixed: PropTypes.bool,
  bgwhite: PropTypes.bool,
  center: PropTypes.bool,
};
