import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import ReactGA from "react-ga";
import {
  Card,
  CardContent,
  Button,
  Box,
  Typography,
  Grid,
} from "@material-ui/core";
import "../../../static/sideMenu.scss";

import { urls } from "../../../utils/urls";
import CategoryModal from "./CategoryModal";
import axiosInstance from "../../../axios";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  mobileDisplay: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },

  //cards
  actionArea: {
    borderRadius: 16,
    transition: "0.2s",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  card: {
    MaxWidth: 200,

    borderRadius: 16,
    boxShadow: "none",
    "&:hover": {
      boxShadow: `0 6px 12px 0 #fff
        .rotate(-12)
        .darken(0.2)
        .fade(0.5)}`,
    },
  },
  content: {
    backgroundColor: "#fff",
    padding: "1rem 1.5rem 1.5rem",
    textAlign: "center",
  },
}));

const Template = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const [category, setCategory] = useState([]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    fetchCategory();
  }, [dispatch]);

  const fetchCategory = async (search = null) => {
    await axiosInstance.get(urls.templateCategory).then((res) => {
      setCategory(res.data);
    });
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const addDialog = useRef(null);
  return (
    <div>
      {/* <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <List>
          <ListItem button onClick={() => history.push("/dashboard")}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem button onClick={() => history.push("/dashboard/profile")}>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </ListItem>
          <ListItem button onClick={() => history.push("/dashboard/documents")}>
            <ListItemIcon>
              <InsertDriveFileIcon />
            </ListItemIcon>
            <ListItemText primary="Documents" />
          </ListItem>
        </List>
        
      </Drawer>  */}
      <Grid style={{ marginTop: "10px" }} container spacing={5}>
        <Grid item md={2} xs={12} />
        <Grid item md={3} xs={12}>
          <Box className={classes.actionArea} style={{ borderRadius: 16 }}>
            <Card className={classes.card} style={{ borderRadius: 16 }}>
              <CardContent className={classes.content}>
                <Typography
                  className={classes.title}
                  style={{ fontSize: 24, marginTop: "20px" }}
                  variant={"h6"}
                >
                  CREATE FROM TEMPLATE
                </Typography>

                <Typography
                  style={{ fontSize: "20px", marginTop: "15px" }}
                  variant="body1"
                >
                  Select existing template
                </Typography>
                <Box style={{ justifyContent: "center", marginTop: "15px" }}>
                  <Link to="/dashboard/documents/exist-template">
                    {" "}
                    <Button
                      style={{
                        backgroundColor: "#CC626F",
                        color: "#fff",
                      }}
                    >
                      Select
                    </Button>
                  </Link>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Grid>
        <Grid item md={3} xs={12}>
          <Box className={classes.actionArea} style={{ borderRadius: 16 }}>
            <Card className={classes.card} style={{ borderRadius: 16 }}>
              <CardContent className={classes.content}>
                <Typography
                  className={classes.title}
                  style={{ fontSize: 24, marginTop: "20px" }}
                  variant={"h6"}
                >
                  MY TEMPLATES
                </Typography>

                <Typography
                  style={{ fontSize: "20px", marginTop: "15px" }}
                  variant="body1"
                >
                  Select template from your existing template!
                </Typography>
                <Box style={{ justifyContent: "center", marginTop: "15px" }}>
                  <Link to="/dashboard/documents/my-template">
                    <Button
                      style={{
                        backgroundColor: "#CC626F",
                        color: "#fff",
                      }}
                    >
                      Select
                    </Button>
                  </Link>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Grid>
        <Grid item md={3} xs={12}>
          <Box className={classes.actionArea} style={{ borderRadius: 16 }}>
            <Card className={classes.card} style={{ borderRadius: 16 }}>
              <CardContent className={classes.content}>
                <Typography
                  className={classes.title}
                  style={{ fontSize: 24, marginTop: "20px" }}
                  variant={"h6"}
                >
                  CREATE FROM SCRATCH
                </Typography>

                <Typography
                  style={{ fontSize: "20px", marginTop: "15px" }}
                  variant="body1"
                >
                  You can create your own template!
                </Typography>
                <Box style={{ justifyContent: "center", marginTop: "15px" }}>
                  {/* <Link to="/dashboard/documents/single-templates"> */}
                  <Button
                    style={{
                      backgroundColor: "#CC626F",
                      color: "#fff",
                    }}
                    onClick={() => addDialog.current.handleClickOpen()}
                  >
                    Select
                  </Button>
                  {/* </Link> */}
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Grid>
        <Grid item md={2} xs={12} />
      </Grid>
      <CategoryModal category={category} ref={addDialog}></CategoryModal>
    </div>
  );
};

export default Template;
