import React, { useEffect } from "react";
import ScrollToTop from "../components/ScrollToTop";
import data from "../data/data.json";
import ReactGA from 'react-ga'
function Features(props) {
  const JsonData = data.Features;
  useEffect(() => {
    document.title = "Notting Hill Law";
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <>
      <div
        id="features"
        className="text-center"
        style={{
          background: "url('/static/intro-bg0.png') 50% 0%",
          backgroundSize: "cover",
          height:
            props.history && props.history.location.pathname == "/process"
              ? "100vh"
              : "100%",
          padding:
            props.history && props.history.location.pathname == "/process"
              ? "50px"
              : "150px",
        }}
      >
        <ScrollToTop />
        <div className="container ff">
          <div className="col-md-12 col-md-offset-1 section-title">
            <h2>Our Process</h2>
          </div>
          <div className="row">
            {JsonData
              ? JsonData.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-xs-12 col-sm-6 col-md-4 feature_icon"
                  >
                    {" "}
                    <i className={d.icon}></i>
                    <h3>{d.title}</h3>
                    <p>{d.text}</p>
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
    </>
  );
}

export default Features;
