import React, { useState, useEffect } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Link, useLocation, useHistory } from "react-router-dom";
import { logout } from "../store/actions/userActions";
import axios from "axios";
import { fetchCart } from "../store/actions/cart";
import emtyCart from "../screens/book/emty.png";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import NavigationBar from "./NavigationBar";

import {
  Grid,
  Typography,
  Paper,
  withStyles,
  Divider,
  Menu,
  MenuItem,
  Button,
  Badge,
  ButtonBase,
  Box,
} from "@material-ui/core";

import "../static/navbar.css";

const styles = (theme) => ({
  paper1: {
    padding: theme.spacing(1),
    margin: "auto",
    background: "#f7f9fa",
    maxWidth: 500,
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 700,
  },

  newcardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  newcardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },

  link: {
    display: "flex",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
  image: {
    width: 50,
    height: 50,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
});

export function Header(props) {
  const { classes } = props;

  const location = useLocation();

  if (location.pathname === "/dashboard/documents") {
    return <NavigationBar />;
  }

  if (
    location.pathname === "/login" ||
    location.pathname == "/signup" ||
    // location.pathname.startsWith("/dashboard") ||
    location.pathname.startsWith("/users") ||
    location.pathname === "/forgot-password"
  ) {
    return null;
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light sticky-top bg-white">
        <Link className="navbar-brand text-center ml-3" to="/">
          <span
            className="p-1 color border-primary mobile__header"
            style={{ border: "0.13rem solid" }}
          >
            NHL
          </span>{" "}
          <h5 className="color mt-2 text-uppercase title">Notting Hill Law</h5>{" "}
          <h6 className="mobile__header color mb-0">
            <small className="text-uppercase">Immigration Law simplified</small>{" "}
          </h6>
        </Link>
        <button
          className="navbar-toggler bg-light"
          type="button"
          data-toggle="collapse"
          data-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
          <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
            {/* <li className="nav-item active">
            <NavLink className="nav-link" exact to="/">
              Home
            </NavLink>
          </li> */}
            {/* <li className="nav-item">
            <NavLink exact className="nav-link" to="/process">
              Process
            </NavLink>
          </li> */}
            <li className="nav-item">
              <NavLink exact className="nav-link" to="/pricing">
                Pricing
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink exact className="nav-link" to="/contactus">
                Ask a Lawyer!
              </NavLink>
            </li>
            {/* <li className="nav-item">
            <NavLink exact className="nav-link" to="/guide-lading-page">
              Guide book
            </NavLink>
          </li> */}
            <li className="nav-item">
              <NavLink exact className="nav-link" to="/services">
                Services
              </NavLink>
            </li>
            {/* <li className="nav-item">
            <NavLink exact className="nav-link" to="/resources">
              Visa guides
            </NavLink>
          </li> */}
            {/* <li className="nav-item">
            <NavLink exact className="nav-link" to="/templates">
              Templates
            </NavLink>
          </li> */}
            {/* <li className="nav-item">
            <NavLink exact className="nav-link" to="/contactus">
              contact
            </NavLink>
          </li> */}

            <li className="nav-item">
              <NavLink exact className="nav-link" to="/login">
                Login
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
      {/* <NavigationBar /> */}
    </>
  );
}
export default withStyles(styles)(Header);
