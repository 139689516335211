import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import html2pdf from "html2pdf.js";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { green, pink } from "@material-ui/core/colors";
import {
  Typography,
  Paper,
  Grid,
  makeStyles,
  Divider,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Link,
  Tooltip,
  CardMedia,
  Card,
  TextField,
  Container,
} from "@material-ui/core";
import { DragHandle } from "@material-ui/icons";
import PhoneIcon from "@material-ui/icons/Phone";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import UpdateIcon from "@material-ui/icons/Update";
import GetAppIcon from "@material-ui/icons/GetApp";
import EmailIcon from "@material-ui/icons/Email";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { Button } from "react-bootstrap";
// import profileImg from "./profile.jpg";

const useStyles = makeStyles((theme) => ({
  page: {
    background: "white",
    display: "block",
    margin: "0 auto",
    marginTop: "10px",
    marginBottom: "0.5cm",
    boxShadow: " 0 0 0.5cm #7b7d7d",
    minHeight: "100%",
    maxHeight: "100%",
    overflowY: "auto",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%", // Set full width on small screens
      padding: "10px", // Adjust padding for small screens
    },
  },
  root: {
    "& > *": {
      margin: 0,
    },
  },

  green: {
    color: "#fff",
    backgroundColor: green[500],
    margin: 20,
    display: "flex",
  },
}));

function GrievanceLetter(props) {
  const classes = useStyles();
  const { template, onDragEnd } = props;

  const handleSaveToPDF = () => {
    const element = document.getElementById("pdf-content"); // Replace with the ID of the element you want to export

    html2pdf(element);
    return false;
  };

  const styleObj = {
    transform: "scaleX(0.6) scaleY(0.98)",
    height: "500px",
    maxHeight: "500px",
    overflowY: "auto",
  };

  return (
    <Container>
      <div>
        <div
          size="A4"
          className={classes.page}
          style={props.scaleDown ? styleObj : { padding: "10px" }}
        >
          <div id="pdf-content">
            <div style={{ padding: "20px" }}>
              <Grid container spacing={1}>
                <Grid md={12}>
                  <>
                    <h6 style={{ textAlign: "center" }}>GRIEVANCE LETTER</h6>
                    {template.sections &&
                      template.sections.map((section, index) => (
                        <Typography style={{ padding: "10px" }} variant="body1">
                          <p>
                            <>
                              {section.fields[0].field_content ? (
                                <Typography
                                  style={{
                                    background: "#f5f5f5",
                                    display: "inline-block",
                                    margin: "5px 0",
                                    padding: "6px",
                                  }}
                                >
                                  {section.fields[0].field_content}
                                </Typography>
                              ) : (
                                <Typography
                                  style={{
                                    background: "#f5f5f5",
                                    display: "inline-block",
                                    margin: "5px 0",
                                    padding: "6px",
                                  }}
                                >
                                  [ EMPLOYER’S NAME ]
                                </Typography>
                              )}
                              <br></br>
                              {section.fields[1].field_content ? (
                                <Typography
                                  style={{
                                    background: "#f5f5f5",
                                    display: "inline-block",
                                    margin: "5px 0",
                                    padding: "6px",
                                  }}
                                >
                                  {section.fields[1].field_content}
                                </Typography>
                              ) : (
                                <Typography
                                  style={{
                                    background: "#f5f5f5",
                                    display: "inline-block",
                                    margin: "5px 0",
                                    padding: "6px",
                                  }}
                                >
                                  [ COMPANY ADDRESS & POSTCODE ]
                                </Typography>
                              )}
                              <br></br>
                              <br></br>
                              {section.fields[2].field_content ? (
                                <Typography
                                  style={{
                                    background: "#f5f5f5",
                                    display: "inline-block",
                                    margin: "5px 0",
                                    padding: "6px",
                                  }}
                                >
                                  {section.fields[2].field_content}
                                </Typography>
                              ) : (
                                <Typography
                                  style={{
                                    background: "#f5f5f5",
                                    display: "inline-block",
                                    margin: "5px 0",
                                    padding: "6px",
                                  }}
                                >
                                  [ DATE ]
                                </Typography>
                              )}
                              <br></br>
                              <br></br> Dear{" "}
                              {section.fields[3].field_content ? (
                                <Typography
                                  style={{
                                    background: "#f5f5f5",
                                    display: "inline-block",
                                    margin: "5px 0",
                                    padding: "6px",
                                  }}
                                >
                                  {section.fields[3].field_content}
                                </Typography>
                              ) : (
                                <Typography
                                  style={{
                                    background: "#f5f5f5",
                                    display: "inline-block",
                                    margin: "5px 0",
                                    padding: "6px",
                                  }}
                                >
                                  [ Name of Employer, Line Manager or HR manager
                                  ]
                                </Typography>
                              )}
                              ,<br></br>
                              <br></br>I write to inform you that I wish to
                              raise a formal grievance relating to
                              discrimination I have experienced in the
                              workplace.
                              <br></br>
                              <br></br>
                              {section.fields[4].field_content ? (
                                <Typography
                                  style={{
                                    background: "#f5f5f5",
                                    display: "inline-block",
                                    margin: "5px 0",
                                    padding: "6px",
                                  }}
                                >
                                  {section.fields[4].field_content}
                                </Typography>
                              ) : (
                                <Typography
                                  style={{
                                    background: "#f5f5f5",
                                    display: "inline-block",
                                    margin: "5px 0",
                                    padding: "6px",
                                  }}
                                >
                                  [ PROVIDE A BRIEF SUMMARY OF THE FACTS ]
                                </Typography>
                              )}
                              <br></br>
                              <br></br>I have been working for{" "}
                              {section.fields[0].field_content ? (
                                <Typography
                                  style={{
                                    background: "#f5f5f5",
                                    display: "inline-block",
                                    margin: "5px 0",
                                    padding: "6px",
                                  }}
                                >
                                  {section.fields[0].field_content}
                                </Typography>
                              ) : (
                                <Typography
                                  style={{
                                    background: "#f5f5f5",
                                    display: "inline-block",
                                    margin: "5px 0",
                                    padding: "6px",
                                  }}
                                >
                                  [ NAME OF EMPLOYER ]
                                </Typography>
                              )}
                              for 4 years as a packer on the production line.
                              From the point at which Jane Doe became my shift
                              manager
                              {section.fields[2].field_content ? (
                                <Typography
                                  style={{
                                    background: "#f5f5f5",
                                    display: "inline-block",
                                    margin: "5px 0",
                                    padding: "6px",
                                  }}
                                >
                                  {section.fields[2].field_content}
                                </Typography>
                              ) : (
                                <Typography
                                  style={{
                                    background: "#f5f5f5",
                                    display: "inline-block",
                                    margin: "5px 0",
                                    padding: "6px",
                                  }}
                                >
                                  [ DATE ]
                                </Typography>
                              )}
                              , I have been experiencing poor treatment compared
                              to my colleagues. I believe this is because of
                              racial discrimination.
                              <br></br>
                              <br></br> I am constantly taken off the production
                              line to mop up the food prep area, empty the bins
                              around the warehouse, and carry out general clean
                              up duties. Jane Doe singles me out to do these
                              tasks whenever I am on shift. I have not seen her
                              ask any of my white colleagues to do the same.
                              <br></br>
                              <br></br>
                              Also, whenever there is over-time available, I
                              never get asked if I can do it, but my white
                              colleagues do. I am the only Black employee
                              working this shift pattern so believe this to be
                              detrimental treatment based on my ethnicity.
                              <br></br>
                              <br></br>
                              {section.fields[5].field_content ? (
                                <Typography
                                  style={{
                                    background: "#f5f5f5",
                                    display: "inline-block",
                                    margin: "5px 0",
                                    padding: "6px",
                                  }}
                                >
                                  {section.fields[5].field_content}
                                </Typography>
                              ) : (
                                <Typography
                                  style={{
                                    background: "#f5f5f5",
                                    display: "inline-block",
                                    margin: "5px 0",
                                    padding: "4px",
                                  }}
                                >
                                  [ GIVE DETAILS OF HOW THIS HAS IMPACTED YOU ]
                                </Typography>
                              )}
                              <br></br>
                              <br></br>
                              This less favourable treatment is not only
                              affecting my mental health, but also my
                              opportunities to earn extra over-time which is
                              paid at time and a half. I believe I am being
                              victimised by my shift manager and this is
                              beginning to rub off on my colleagues who have
                              started to distance themselves from me. They have
                              stopped including me in conversations on the line
                              and, also during breaks.
                              <br></br>
                              <br></br>
                              {section.fields[6].field_content ? (
                                <Typography
                                  style={{
                                    background: "#f5f5f5",
                                    display: "inline-block",
                                    margin: "5px 0",
                                    padding: "6px",
                                  }}
                                >
                                  {section.fields[6].field_content}
                                </Typography>
                              ) : (
                                <Typography
                                  style={{
                                    background: "#f5f5f5",
                                    display: "inline-block",
                                    margin: "5px 0",
                                    padding: "4px",
                                  }}
                                >
                                  [ EXPLAIN WHAT ACTION YOU WOULD LIKE YOUR
                                  EMPLOYER TO TAKE TO REMEDY THE SITUATION. IF
                                  YOU ARE UNSURE YOU SHOULD SAY THAT YOU WOULD
                                  LIKE TO DISCUSS FINDING A SOLUTION WITH THEM ]
                                </Typography>
                              )}
                              <br></br>
                              <br></br>I think that if I could be moved to work
                              under a different shift manager this issue could
                              be resolved. If that is not possible, I would like
                              to discuss alternative solutions with you.
                              <br></br>
                              <br></br>
                              Please could you let me know when I can meet you
                              to discuss my grievance. I am aware from my staff
                              handbook that I am entitled to be accompanied at a
                              grievance hearing and would like to bring
                              {section.fields[2].field_content ? (
                                <Typography
                                  style={{
                                    background: "#f5f5f5",
                                    display: "inline-block",
                                    margin: "5px 0",
                                    padding: "6px",
                                  }}
                                >
                                  {section.fields[2].field_content}
                                </Typography>
                              ) : (
                                <Typography
                                  style={{
                                    background: "#f5f5f5",
                                    display: "inline-block",
                                    margin: "5px 0",
                                    padding: "4px",
                                  }}
                                >
                                  [ DATE ]
                                </Typography>
                              )}
                              .<br></br>
                              <br></br>
                              Yours sincerely,
                              <br></br>
                              <br></br>
                              {section.fields[7].field_content ? (
                                <Typography
                                  style={{
                                    background: "#f5f5f5",
                                    display: "inline-block",
                                    margin: "5px 0",
                                    padding: "6px",
                                  }}
                                >
                                  {section.fields[7].field_content}
                                </Typography>
                              ) : (
                                <Typography
                                  style={{
                                    background: "#f5f5f5",
                                    display: "inline-block",
                                    margin: "5px 0",
                                    padding: "4px",
                                  }}
                                >
                                  [ NAME ]
                                </Typography>
                              )}
                            </>
                          </p>
                          <br></br>
                        </Typography>
                      ))}
                  </>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default GrievanceLetter;
