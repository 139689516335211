import React, { useState } from "react";
import AppContext from "./AppContext";
import { fabric } from "fabric";

const AppProvider = ({ children }) => {
  const [templateStatus, setTemplateStatus] = useState("Edit"); //Edit or Create
  const [showPaperContent, setShowPaperContent] = useState(false);
  const [openSignModal, setOpenSignModal] = useState(false);
  const [signImgUrl, setSignImgUrl] = useState(null);
  const [ownerImgUrl, setOwnerImgUrl] = useState(null);
  const [signText, setSignText] = useState(null);
  const [targetTemplate, setTargetTemplate] = useState({});
  const [targetEle, setTargetEle] = useState(null);
  const [userId, setUserId] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [scaleDown, setScaleDown] = useState(false);
  const [useUserTemplates, setUserTemplates] = useState(false);
  const [isOpen, toggleIsOpen] = useState(true);
  const [isOpenAi, setIsOpenAi] = useState(false);
  const [aiPrompt, setAIPrompt] = useState(null);
  const [isChatAI, setIsChatAI] = useState(false);
  const [isWidget, setIsWidget] = useState(false);
  const [isAI, setIsAI] = useState(false);
  const [chatAIResult, setChatAIResult] = useState([
    { chatAI: "Hello, I am an interactive chat AI.\nHow can i help you" },
    // { user: "I am the user" },
  ]);
  const [widgetTextInput, setWidgetTextInput] = useState("");
  const [widgetTextSize, setWidgetTextSize] = useState(90);
  const [widgetTextStyle, setWidgetTextStyle] = useState("normal");
  const [widgetTextColor, setWidgetTextColor] = useState("black");
  const [widgetTextDecoration, setWidgetTextDecoration] = useState(
    "no underline"
  );
  const [showCanvas, setShowCanvas] = useState(false);

  const [openPreviewModal, setOpenPreviewModal] = useState(false);

  const [openPreviewModalCanvas, setOpenPreviewModalCanvas] = useState(false);

  const [inputs, setInputs] = useState([]);

  const [texts, setTexts] = useState([]);

  const [textsMap, setTextsMap] = useState({});

  const changeTemplateStatus = (status) => {
    setTemplateStatus(status);
  };

  const changeShowPaperContent = (val) => {
    setShowPaperContent(val);
  };

  const changeTargetTemplateQuestionValue = (sectionId, id, val) => {
    let newSections = targetTemplate.sections;
    newSections.forEach((section) => {
      if (section.id === sectionId) {
        section.fields.forEach((field) => {
          if (field.id === id) {
            field.field_content = val;
          } else {
            return field;
          }
        });
      } else {
        return section;
      }
    });

    setTargetTemplate({ ...targetTemplate, sections: newSections });
  };

  //Here
  const getFieldContents = () => {
    const fieldContents = [];
    targetTemplate?.sections?.forEach((section) => {
      section?.fields?.forEach((field) => {
        fieldContents.push(field);
      });
    });
    return fieldContents;
  };

  const getTargetQuestionValue = (sectionId, name) => {
    targetTemplate.sections.forEach((section) => {
      if (section.id === sectionId) {
        section.fields.forEach((field) => {
          if (field.name.toLowerCase() === name.toLowerCase()) {
            return field.name;
          } else {
            return field;
          }
        });
      } else {
        return section;
      }
    });
  };

  const changeTemplateQuestionValue = (val, qId) => {
    //console.log(templates[0].sectionNames[0].questions[0].questionId)
    setTemplates(
      templates.map((template) => {
        return template.sectionNames.map((template) => {
          return template.questions.map((question) => {
            if (question.questionId === qId) {
              console.log("Col", { ...question, questionValue: val });
              return { ...question, questionValue: val };
            } else {
              return question;
            }
          });
        });
      })
    );
  };

  const changeTargetTemplate = (url, axiosInstance) => {
    axiosInstance.get(url).then((res) => setTargetTemplate(res.data));
  };

  const handleAddInput = (id, canvas) => {
    const newInput = { id, value: "" };
    setInputs([...inputs, newInput]);

    // Create a new IText object and store it in the texts state
    if (canvas) {
      const iText = new fabric.IText("", {
        left: 100,
        top: 30 + inputs.length * 40, // Adjust top position for each text
        fontSize: 30,
        fill: "black",
        fontFamily: "Arial",
        selectable: true,
        textId: newInput.id
      });

      canvas.add(iText);
      
      setTextsMap(prevMap => ({ ...prevMap, [newInput.id]: iText }));
    }
  };


  const handleInputChangeNew = (id, value, canvas) => {
    const updatedInputs = inputs.map(input => 
      input.id === id ? { ...input, value } : input
    );
    setInputs(updatedInputs);

    // Update the corresponding IText object
    const textObj = textsMap[id];
    if (textObj) {
      textObj.set('text', value);
      canvas.renderAll();
    }
  };

  return (
    <AppContext.Provider
      value={{
        templateStatus,
        changeTemplateStatus,
        changeShowPaperContent,
        showPaperContent,
        templates,
        targetTemplate,
        changeTargetTemplateQuestionValue,
        scaleDown,
        setScaleDown,
        signImgUrl,
        setSignImgUrl,
        ownerImgUrl,
        setOwnerImgUrl,
        openSignModal,
        setOpenSignModal,
        signText,
        setSignText,
        changeTargetTemplate,
        changeTemplateQuestionValue,
        getTargetQuestionValue,
        setTemplates,
        getFieldContents,
        userId,
        setUserId,
        useUserTemplates,
        setUserTemplates,
        targetEle,
        setTargetEle,
        isOpen,
        toggleIsOpen,
        isOpenAi,
        setIsOpenAi,
        aiPrompt,
        setAIPrompt,
        isChatAI,
        setIsChatAI,
        chatAIResult,
        setChatAIResult,
        isWidget,
        setIsWidget,
        isAI,
        setIsAI,
        widgetTextInput,
        widgetTextSize,
        widgetTextStyle,
        widgetTextColor,
        widgetTextDecoration,
        setWidgetTextInput,
        setWidgetTextSize,
        setWidgetTextStyle,
        setWidgetTextColor,
        setWidgetTextDecoration,
        showCanvas,
        setShowCanvas,
        openPreviewModalCanvas,
        setOpenPreviewModalCanvas,
        openPreviewModal,
        setOpenPreviewModal,
        inputs,
        texts,
        setInputs,
        setTexts,
        handleInputChangeNew,
        handleAddInput,
        textsMap,
        setTextsMap,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
