import React, { useEffect, useRef, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactGA from "react-ga";
import { Link, useHistory } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import SearchIcon from "@material-ui/icons/Search";
import AssignmentIcon from "@material-ui/icons/Assignment";
import cv1 from "./cv1.jpg";
import doc from "./doc.avif";
import templateImg from "../../../../screens/Home/assets1/template-img.png";
import {
  Container,
  makeStyles,
  Button,
  Breadcrumbs,
  Typography,
  Box,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  CardMedia,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  CircularProgress,
  LinearProgress,
  Avatar,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DocumentModal from "./DocumentModal";
import DocumentFilter from "../documentFilter/DocumentFilter";
import axiosInstance from "../../../../axios";
import { urls } from "../../../../utils/urls";
import HeroSection from "../../../../components/HeroSection";
import resume from "./resume.png";
import SideMenu from "../../../../components/dashboard/SideMenu";
import AppContext from "../../../../context/AppContext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "30vh",
    position: "relative",
    "& video": {
      objectFit: "cover",
    },
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#D8D1F2",
  },
  bannerTitle: {
    paddingBottom: theme.spacing(4),
    display: "block",
    fontFamily: "Source Sans Pro, Arial, sans-serif",
    fontSize: "30px",
    fontStyle: "normal",
    fontVariant: "normal",
    fontWeight: "600",
    letterSpacing: "2px",
    lineHeight: "92px",
    textDecoration: "none solid rgb(31, 31, 31)",
    textAlign: "start",
    textIndent: "0px",
    textTransform: "none",
    verticalAlign: "baseline",
    whiteSpace: "normal",
    wordSpacing: "0px",

    color: "#58097D",
  },
  link: {
    display: "flex",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  // card: {
  //   MaxWidth: 200,
  //   // textAlign: "center",
  //   borderRadius: 16,
  //   transition: "0.2s",
  //   boxShadow: "none",
  //   "&:hover": {
  //     boxShadow: `0 6px 12px 0 #888888`,
  //     transform: "scale(1.1)",
  //   },
  //   display: "flex",
  //   flexDirection: "column",
  //   minHeight: "100%",
  //   justifyContent: "space-between",
  //   // backgroundColor: "#f7f9fa",
  //   backgroundColor: "#fff",
  //   position: "relative",
  //   overflow: "hidden",
  //   "&:hover $visibilityIcon": {
  //     opacity: 1,
  //   },
  // },
  //cards
  actionArea: {
    borderRadius: 16,
    transition: "0.2s",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  card: {
    MaxWidth: 200,
    borderRadius: 16,
    boxShadow: "none",
    "&:hover": {
      boxShadow: `0 6px 12px 0 #fff
        .rotate(-12)
        .darken(0.2)
        .fade(0.5)}`,
      cursor: "pointer",
    },
  },
  content: {
    backgroundColor: "#fff",
    padding: "1rem 1.5rem 1.5rem",
    textAlign: "center",
  },
  visibilityIcon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    opacity: 0,
    transition: "opacity 0.3s ease-in-out",
  },

  media: {
    height: 140,
  },
  boxRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // minHeight: "35vh",
  },
  fab2: {
    margin: theme.spacing(1),
  },
  title: {
    textAlign: "center",

    marginTop: theme.spacing(1),
  },
  boxContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
}));

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

function MyDocument() {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(40);

  const history = useHistory();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const {
    templateStatus,
    changeShowPaperContent,
    showPaperContent,
    // templates,
    // setTemplates,
    targetTemplate,

    changeTargetTemplate,
    isOpen,
    toggleIsOpen,
  } = useContext(AppContext);

  const [category, setCategory] = useState([]);
  const [search, setSearch] = useState("");
  const [templates, setTemplates] = useState([]);

  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(null);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    fetchTemplateList();
    fetchCategory();
  }, [dispatch]);

  const fetchCategory = async (search = null) => {
    await axiosInstance.get(urls.templateCategory).then((res) => {
      setCategory(res.data);
    });
  };

  const fetchTemplateList = async (queryParams) => {
    setLoading(true);
    let url = "";
    if (queryParams != null && queryParams != "") {
      setLoading(true);
      url = `${urls.getMyTemplateFilter(userInfo.id, queryParams)}`;
    } else {
      setLoading(true);
      url = `${urls.getUserTemplateList(userInfo.id)}`;
    }

    await axiosInstance.get(url).then((res) => {
      setTemplates(res.data);
      setLoading(false);
    });
  };

  const searchUserTemplateList = async (search) => {
    setLoading(true);
    let url = "";
    if (search != null && search != "") {
      setLoading(true);
      url = `${urls.getUserTemplateSearch(userInfo.id, search)}`;
    } else {
      setLoading(true);
      url = `${urls.getUserTemplateList(userInfo.id)}`;
    }

    await axiosInstance.get(url).then((res) => {
      setTemplates(res.data);
      setLoading(false);
    });
  };

  const handleSubmitCategory = (e) => {
    // e.preventDefault();
    setLoading(true);
    fetchTemplateList(e);
    // setBtnLoading(false);
  };
  const clearFilter = () => {
    fetchTemplateList();
    // fetchCategory();
  };

  const addDialog = useRef(null);

  const handleSearch = (e) => {
    e.preventDefault();

    searchUserTemplateList(search);
    setBtnLoading(false);
  };
  return (
    <>
      <section className={classes.root}>
        <div className={classes.overlay}>
          {/* <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to="/" className={classes.link}>
              <HomeIcon className={classes.icon} />
              Dashboard
            </Link>
            <Link color="inherit" to="/#templates" className={classes.link}>
              Documents
            </Link>
            <Link color="inherit" to="/#templates" className={classes.link}>
              My Template
            </Link>
          </Breadcrumbs> */}
          <Box
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            color="#fff"
          >
            <Typography
              variant="h5"
              component="h1"
              className={classes.bannerTitle}
            >
              Select From Your Template
            </Typography>
          </Box>
        </div>
      </section>
      <Grid container>
        <Grid item md={12}>
          <Box sx={{ mb: 3 }}>
            <Card>
              <CardContent>
                <Box
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <form isLoading={false} onSubmit={handleSearch}>
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <FormControl variant="outlined" size="small">
                        <InputLabel htmlFor="search">Search</InputLabel>
                        <OutlinedInput
                          id="search"
                          name="search"
                          type="type"
                          color="primary"
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton type="submit">
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          }
                          labelWidth={70}
                        />
                      </FormControl>
                      <Button
                        style={{ marginLeft: "5px" }}
                        color="primary"
                        variant="contained"
                        type="submit"
                      >
                        search
                        {btnLoading && (
                          <CircularProgress
                            style={{ marginLeft: "10px", color: "white" }}
                            size={15}
                          />
                        )}
                      </Button>
                    </Box>
                  </form>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>
      <Container>
        <Grid
          style={{ marginTop: "10px", marginBottom: "30px" }}
          container
          spacing={3}
          justifyContent="center"
        >
          <Grid item md={3}>
            <DocumentFilter
              handleSubmitCategory={handleSubmitCategory}
              category={category}
              clearFilter={clearFilter}
            ></DocumentFilter>
          </Grid>
          <Grid item md={9}>
            <Grid container spacing={3} justifyContent="center">
              {templates && templates.length > 0 ? (
                templates.map((temp) => (
                  <Grid item md={4}>
                    <Box
                      className={classes.actionArea}
                      style={{ borderRadius: 16 }}
                    >
                      <Card
                        className={classes.card}
                        style={{ borderRadius: 16 }}
                      >
                        <CardContent className={classes.content}>
                          <Box className={classes.boxRoot}>
                            <Box className={classes.boxContainer}>
                              <Avatar style={{ height: "70px", width: "70px" }}>
                                <AssignmentIcon
                                  style={{ height: "50px", width: "50px" }}
                                />
                              </Avatar>
                              <Typography
                                className={classes.title}
                                variant="h6"
                              >
                                {temp.name}
                              </Typography>{" "}
                            </Box>
                          </Box>
                          <LinearProgressWithLabel
                            value={temp.completeness_percentage}
                          />
                          <Button
                            size="small"
                            fullWidth
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              // history.push(
                              //   `/dashboard/documents/my-template/${temp.id}/`
                              // );
                              history.push(
                                "/dashboard/documents/editorview#editorview"
                              );
                              const url = urls.singleTemplate(temp.id);
                              changeTargetTemplate(url, axiosInstance);
                              changeShowPaperContent(true);
                              toggleIsOpen(true);
                            }}
                          >
                            Customize
                          </Button>
                        </CardContent>
                      </Card>
                    </Box>
                    <DocumentModal tempImage={temp.image} ref={addDialog} />
                  </Grid>
                ))
              ) : (
                <Grid item md={8}>
                  <Box
                    height="100%"
                    // marginTop="1vh"
                    alignItems="center"
                    flexDirection="column"
                    justifyContent="center"
                  >
                    <img
                      style={{
                        height: "250px",
                        width: "300px",
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                      src={templateImg}
                      alt="document icon"
                    ></img>
                    <Typography
                      style={{ textAlign: "center", marginTop: "5px" }}
                      variant="h4"
                    >
                      {" "}
                      templates not found!
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default MyDocument;
