import React from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Box, Typography, Input } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import Backdrop from "@mui/material/Backdrop";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import RadioButtonsGroup from "./RadioButtonGroup";
import Switch from "@mui/material/Switch";
import { Button } from "@material-ui/core";
import SignTabs from "./SignTabs";
import Tooltip from "@mui/material/Tooltip";


function SignModal(props) {
  return (
    <Modal
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.openSignModal && props.openSignModal}
      onClose={props.handleCloseSignModal && props.handleCloseSignModal}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={props.openSignModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
          style={{
            backgroundColor: "#fff",
            zIndex: 300,
            padding: "10px",
            width: "500px",
          }}
        >
          <Box sx={{ bgcolor: "background.paper", border: "1px solid black" }}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography style={{fontWeight: "bold"}}>Signature</Typography>
              <Tooltip title={"Close"}>
                <CloseIcon
                  sx={{ color: "#000" }}
                  style={{ cursor: "pointer" }}
                  onClick={props.handleCloseSignModal && props.handleCloseSignModal}
                />
              </Tooltip>
            </Box>
            {/* tabs box */}
            <Box>
              <SignTabs  handleCloseSignModal={ props.handleCloseSignModal}/>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}

export default SignModal;
