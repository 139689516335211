import React from "react";
import { Box, Stack, Typography, Link } from "@mui/material";

const company = ["About us", "Careers", "Blog", "Pricing"];
const product = [
  "facebook followers",
  "Instagram followers",
  "Twitter followers",
  "Tiktok followers",
];
const resources = ["Proposal template", "Tutorial", "Invoices", "Freebies"];

function Footer() {
  return (
    <Box px={{ xs: "2%", sm: "7%" }} className="footer" color="#ffffff">
      <Stack
        textAlign={{ xs: "center", sm: "left" }}
        direction={{ xs: "column", sm: "row" }}
        py={{ xs: "7%", sm: "4%" }}
        spacing={{ xs: 3 }}
      >
        {/* left section */}
        <Box flex="0.5" boxSizing="border-box">
          <Typography variant="h6" fontWeight="500" mb="20px">
            Docelerate
          </Typography>
          <Typography variant="body2" width={{ xs: "100%", sm: "90%" }}>
            Need to appeal a parking ticket? There is a template for that! Need
            to apply for a UK VIsa? There is a template for that. Accelerate
            your writing with Docelerate!
          </Typography>
        </Box>
        {/* right section */}
        {/* first column */}
        <Stack flex="0.16" gap={{ sx: "9px", sm: "12px" }}>
          <Typography varaint="body1" fontWeight="500">
            Company
          </Typography>
          {company.map((item) => (
            <Link
              className="footer-link"
              href="#"
              color="inherit"
              variant="body2"
            >
              {item}
            </Link>
          ))}
        </Stack>
        {/* second column */}
        <Stack flex="0.16" gap={{ sx: "9px", sm: "12px" }}>
          <Typography varaint="body1" fontWeight="500">
            Product
          </Typography>
          {product.map((item) => (
            <Link
              className="footer-link"
              href="#"
              color="inherit"
              variant="body2"
            >
              {item}
            </Link>
          ))}
        </Stack>
        {/* third column */}
        <Stack flex="0.16" gap={{ sx: "9px", sm: "12px" }}>
          <Typography varaint="body1" fontWeight="500">
            Resources
          </Typography>
          {resources.map((item) => (
            <Link
              className="footer-link"
              href="#"
              color="inherit"
              variant="body2"
            >
              {item}
            </Link>
          ))}
        </Stack>
      </Stack>
    </Box>
  );
}

export default Footer;
