import * as React from "react";
import { useRef, useContext } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SignatureCanvas from "react-signature-canvas";
import AppContext from "../../../../../../context/AppContext";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function SignTabs(props) {
  const [value, setValue] = React.useState(0);

  //App context
  const { setSignImgUrl, signText, setSignText } = useContext(AppContext);

  //sign ref
  const signRef = useRef();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab onClick={() => {}} label="Draw" {...a11yProps(0)} />
          <Tab label="Type" onClick={() => {}} {...a11yProps(1)} />
          <Tab label="Upload" onClick={() => {}} {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Box
          style={{
            border: "1px solid #bdbdbd",
            padding: "15px",
            borderRadius: "5px",
          }}
        >
          <Box
            style={{
              backgroundColor: "#bdbdbd",
              padding: "5px",
              width: "60px",
              borderRadius: "5px",
              cursor: "pointer",
              textAlign: "center",
            }}
          >
            <Typography
              onClick={() => signRef && signRef.current.clear()}
              style={{ fontWeight: "500" }}
            >
              Clear
            </Typography>
          </Box>
          {/* main sign box area */}
          <Box style={{ minHeight: "150px", maxHeight: "150px" }}>
            <SignatureCanvas
              ref={signRef}
              penColor="black"
              canvasProps={{ width: "400px", height: "150px" }}
            />
          </Box>
        </Box>
        {/* text write up acknowledgement */}
        <Box style={{ marginTop: "20px" }}>
          <Typography style={{ color: "gray", fontSize: "12px" }}>
            I understand that NHL uses my name, email address and limited
            information will be used to<br></br>
            complete the signature process and to enhance the user experience.
            To learn more about how<br></br>
            NHL uses information, please see our <span>Privacy Policy.</span> By
            signing this document with an<br></br>
            electronic signature, I agree that such signature will be as valid
            as handwritten signatures and<br></br>
            considered originals to the extent allowed by applicable law.
          </Typography>
        </Box>
        <Box
          style={{
            marginTop: "25px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            style={{
              backgroundColor: "#bdbdbd",
              padding: "5px",
              width: "65px",
              borderRadius: "5px",
              cursor: "pointer",
              textAlign: "center",
            }}
            onClick={() => props.handleCloseSignModal()}
          >
            <Typography style={{ fontWeight: "500" }}>Cancel</Typography>
          </Box>
          <Box
            style={{
              backgroundColor: "#66CC99",
              padding: "5px",
              width: "170px",
              borderRadius: "5px",
              cursor: "pointer",
              textAlign: "center",
              color: "#fff",
            }}
            onClick={() => {
              const URL = signRef.current
                .getTrimmedCanvas()
                .toDataURL("image/png");
              setSignImgUrl(URL);
              props.handleCloseSignModal();
            }}
          >
            <Typography style={{ fontWeight: "500" }}>
              Accept and sign
            </Typography>
          </Box>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Box
          style={{
            border: "1px solid #bdbdbd",
            padding: "15px",
            borderRadius: "5px",
          }}
        >
          <Box
            style={{
              backgroundColor: "#bdbdbd",
              padding: "5px",
              width: "60px",
              borderRadius: "5px",
              cursor: "pointer",
              textAlign: "center",
            }}
          >
            <Typography
              onClick={() => setSignText("")}
              style={{ fontWeight: "500" }}
            >
              Clear
            </Typography>
          </Box>
          {/* main sign box area */}
          <Box style={{ minHeight: "150px", maxHeight: "150px" }}>
            <textarea
              type="text"
              onChange={(e) => setSignText(e.target.value)}
              value={signText}
              style={{
                width: "400px",
                height: "150px",
                marginTop: "8px",
                padding: "10px",
                fontSize: "50px",
              }}
            ></textarea>
          </Box>
        </Box>
        {/* text write up acknowledgement */}
        <Box style={{ marginTop: "20px" }}>
          <Typography style={{ color: "gray", fontSize: "12px" }}>
            I understand that NHL uses my name, email address and limited
            information will be used to<br></br>
            complete the signature process and to enhance the user experience.
            To learn more about how<br></br>
            NHL uses information, please see our <span>Privacy Policy.</span> By
            signing this document with an<br></br>
            electronic signature, I agree that such signature will be as valid
            as handwritten signatures and<br></br>
            considered originals to the extent allowed by applicable law.
          </Typography>
        </Box>
        <Box
          style={{
            marginTop: "25px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            style={{
              backgroundColor: "#bdbdbd",
              padding: "5px",
              width: "65px",
              borderRadius: "5px",
              cursor: "pointer",
              textAlign: "center",
            }}
            onClick={() => props.handleCloseSignModal()}
          >
            <Typography style={{ fontWeight: "500" }}>Cancel</Typography>
          </Box>
          <Box
            style={{
              backgroundColor: "#66CC99",
              padding: "5px",
              width: "170px",
              borderRadius: "5px",
              cursor: "pointer",
              textAlign: "center",
              color: "#fff",
            }}
            onClick={() => {
              props.handleCloseSignModal();
              signText && setSignImgUrl("");
            }}
          >
            <Typography style={{ fontWeight: "500" }}>
              Accept and sign
            </Typography>
          </Box>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Box
          style={{
            border: "1px solid #bdbdbd",
            padding: "15px",
            borderRadius: "5px",
          }}
        >
          <Box
            style={{
              backgroundColor: "#bdbdbd",
              padding: "5px",
              width: "60px",
              borderRadius: "5px",
              cursor: "pointer",
              textAlign: "center",
            }}
          >
            <Typography
              onClick={() => setSignText("")}
              style={{ fontWeight: "500" }}
            >
              Clear
            </Typography>
          </Box>
          {/* main sign box area */}
          <Box style={{ minHeight: "150px", maxHeight: "150px" }}>
            <input
              type="file"
              accept="image/*"
              onChange={(e) =>
                setSignImgUrl(URL.createObjectURL(e.target.files[0]))
              }
              style={{
                marginTop: "8px",
                padding: "5px",
                fontSize: "18px",
              }}
            />
          </Box>
        </Box>
        {/* text write up acknowledgement */}
        <Box style={{ marginTop: "20px" }}>
          <Typography style={{ color: "gray", fontSize: "12px" }}>
            I understand that NHL uses my name, email address and limited
            information will be used to<br></br>
            complete the signature process and to enhance the user experience.
            To learn more about how<br></br>
            NHL uses information, please see our <span>Privacy Policy.</span> By
            signing this document with an<br></br>
            electronic signature, I agree that such signature will be as valid
            as handwritten signatures and<br></br>
            considered originals to the extent allowed by applicable law.
          </Typography>
        </Box>
        <Box
          style={{
            marginTop: "25px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            style={{
              backgroundColor: "#bdbdbd",
              padding: "5px",
              width: "65px",
              borderRadius: "5px",
              cursor: "pointer",
              textAlign: "center",
            }}
            onClick={() => props.handleCloseSignModal()}
          >
            <Typography style={{ fontWeight: "500" }}>Cancel</Typography>
          </Box>
          <Box
            style={{
              backgroundColor: "#66CC99",
              padding: "5px",
              width: "170px",
              borderRadius: "5px",
              cursor: "pointer",
              textAlign: "center",
              color: "#fff",
            }}
            onClick={() => {
              props.handleCloseSignModal();
            }}
          >
            <Typography style={{ fontWeight: "500" }}>
              Accept and sign
            </Typography>
          </Box>
        </Box>
      </CustomTabPanel>
    </Box>
  );
}
