import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Typography,
  Paper,
  Grid,
  makeStyles,
  Divider,
  Box,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  page: {
    background: "white",
    display: "block",
    margin: "0 auto",
    marginTop: "20px",
    marginBottom: "0.5cm",
    boxShadow: " 0 0 0.5cm #7b7d7d",
    width: "21cm",
    height: "29.7cm",
  },
}));

function CvFormateOne(props) {
  const classes = useStyles();

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = Array.from(props.template.sections);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    // Perform the necessary state update with the reordered items
    // For example, props.updateSections(items);
  };

  return (
    <div size="A4" className={classes.page}>
      <div style={{ padding: 20 }}>
        <Grid container spacing={1}>
          <Grid item md={4}>
            <Box>
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAegVu9zIAFOhUw0NOfQiwEMxVe_90o0Fshw&usqp=CAU"></img>
              <Typography variant="h4" gutterBottom>
                John Doe
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Software Engineer
              </Typography>
            </Box>
            <Box>
              <Typography variant="h4" gutterBottom>
                Contacts
              </Typography>
              <Divider></Divider>
              <Typography variant="subtitle1" gutterBottom>
                Email: johndoe@example.com
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Phone: + 8801463576756
              </Typography>
            </Box>
          </Grid>
          <Grid item md={8}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="sections">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {props.template.sections.map((section, index) => (
                      <Draggable
                        key={section.section_name}
                        draggableId={section.section_name}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Box>
                              <Typography variant="h4" gutterBottom>
                                {section.section_name}
                              </Typography>
                              <Divider></Divider>
                              {section.fields.map((field, fieldIndex) => (
                                <Typography
                                  key={fieldIndex}
                                  variant="subtitle1"
                                >
                                  {field.content}
                                </Typography>
                              ))}
                            </Box>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Grid>
          {/* <Grid item md={8}>
                {props.template.sections &&
                  props.template.sections.map((data) => (
                    <Box>
                      <Typography variant="h4" gutterBottom>
                        {data.name}
                      </Typography>
                      <Divider></Divider>
                      {data.fields.map((field) => (
                        <Typography variant="subtitle1">
                          {field.content}
                        </Typography>
                      ))}
                    </Box>
                  ))}

                 <Box>
              <Typography variant="h4" gutterBottom>
                Work Experience
              </Typography>
              <Divider></Divider>
              <Box>
                <Typography variant="subtitle1" gutterBottom>
                  2011 - 2014 <br></br>
                  <strong>Software Engineer</strong>
                  <br></br>
                  Tiger It
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  Shahadat Hossen is a Full Stack Developer, With experience
                  Over 2 years programming/design/development skills.
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" gutterBottom>
                  2015 - 2018 <br></br>
                  <strong>Sr Software Engineer</strong>
                  <br></br>
                  Rising It
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  Shahadat Hossen is a Full Stack Developer, With experience
                  Over 2 years programming/design/development skills.
                </Typography>
              </Box>
            </Box>
            <Box>
              <Typography variant="h4" gutterBottom>
                Education
              </Typography>
              <Divider></Divider>
              <Box>
                <Typography variant="subtitle1" gutterBottom>
                  2011 - 2014 <br></br>
                  <strong>BCSE</strong>
                  <br></br>
                  SAGH
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  Shahadat Hossen is a Full Stack Developer, With experience
                  Over 2 years programming/design/development skills.
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" gutterBottom>
                  2015 - 2018 <br></br>
                  <strong>MSE</strong>
                  <br></br>
                  MIT
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  Shahadat Hossen is a Full Stack Developer, With experience
                  Over 2 years programming/design/development skills.
                </Typography>
              </Box>
            </Box> 
              </Grid>  */}
        </Grid>
      </div>
    </div>
  );
}

export default CvFormateOne;
