import React, { useContext, useState } from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Box, Typography, Input } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import Backdrop from "@mui/material/Backdrop";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import RadioButtonsGroup from "./RadioButtonGroup";
import Switch from "@mui/material/Switch";
import { Button } from "@material-ui/core";
import html2pdf from "html2pdf.js";
import AppContext from "../../../../../../context/AppContext";

function PreviewModal(props) {
  const { openPreviewModalCanvas } = useContext(AppContext);

  const [isDownloaded, setIsDownloaded] = useState(false);

  const onDownload = () => {
    let targetId;
    let ele;

    targetId = props.modalContent
      ? props.modalContent?.props?.id
      : props?.targetId;

    if (props.fromCanvas) {
      targetId = "innerBox";
      //targetId = "previewModal"
      ele = document.getElementById(targetId);
      var dloadBtn = document.getElementById("download");
      ele.style.width = "100%";
      ele.style.height = ele.scrollHeight + "px";
    } else {
      ele = document.getElementById(targetId);
    }

    console.log("eLe", ele);
    ele.style.padding = "10px";

    // Adjusting the page size and margins to fit content on one page
    const opt = {
      margin: [5, 5],
      filename: `${targetId}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, letterRendering: true },
      jsPDF: { unit: "pt", format: "letter", orientation: "portrait" },
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },
    };

    try {
      dloadBtn.style.display = "none";
    } catch (er) {
      console.log("errrrr", er);
    }

    ele && html2pdf().from(ele).set(opt).save();
  };

  return (
    <Modal
      id={props.id}
      ref={props.modalRef}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflowY: "auto",
        zIndex: 5000,
      }}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.openPreviewModal}
      onClose={props.handleClosePreviewModal}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={props.openPreviewModal}>
        <Box
          id={props.innerBox}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
          style={{
            backgroundColor: "#fff",
            zIndex: 5000,
            padding: "10px",
            //width: "500px",
            width: "70%",
            // overflow: "auto",
            height: "100vh",
            overflow: "auto",
            position: "relative",
          }}
        >
          {/* {props.modalContent && props.modalContent} */}

          {props.fromCanvas ? (
            <div
              id={"canvasContent"}
              dangerouslySetInnerHTML={{ __html: props.modalContent }}
              style={{
                ...props.modalStyles,
                position: "absolute",
                top: "80px",
              }}
            />
          ) : (
            props.modalContent
          )}

          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
            }}
          >
            <Box
              id="download"
              style={{
                padding: "10px",
                backgroundColor: "#39b055",
                paddingLeft: "20px",
                paddingRight: "20px",
                marginTop: "10px",
                color: "#fff",
                cursor: "pointer",
                width: "120px",
                borderRadius: "5px",
              }}
              onClick={() => {
                onDownload();

                setTimeout(() => {
                  props.setShowBackBtn(true);
                  const innerBox = document.getElementById("innerBox");
                  innerBox.style.height = "100%";
                }, 1500);
              }}
            >
              Download
            </Box>
            {props.showBackBtn && (
              <Box
                id="back"
                style={{
                  padding: "10px",
                  backgroundColor: "#39b055",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  marginTop: "10px",
                  color: "#fff",
                  cursor: "pointer",
                  width: "120px",
                  borderRadius: "5px",
                  textAlign: "center",
                }}
                onClick={() => props.handleClosePreviewModal(false)}
              >
                Back
              </Box>
            )}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}

export default PreviewModal;
