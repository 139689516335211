import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import moment from "moment";
import { urls } from "../../../../utils/urls";
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Typography,
  Divider,
  Button,
  LinearProgress,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {},
  details: {
    display: "flex",
  },
  avatar: {
    // marginRight: "auto",
    marginLeft: "auto",
    height: 100,
    width: 100,

    flexShrink: 0,
    flexGrow: 0,
    // borderRadius: "100%",
  },
  progress: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    marginRight: theme.spacing(2),
  },
  fileInput: {
    display: "none",
  },
  tooltip: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    zIndex: theme.zIndex.tooltip,
    opacity: 0,
    transition: "opacity 0.3s ease",
  },
  detailsHovered: {
    "&:hover $tooltip": {
      opacity: 1,
    },
  },
  roundedImage: {
    borderRadius: "50%", // Make the image rounded
    width: 120, // Set the width as needed
    height: 120, // Set the height as needed
    objectFit: "cover", // Ensure the image covers the entire container
    border: "2px solid #ccc", // Add a border to the image
    cursor: "pointer",
    margin: "5px",
    // Add a pointer cursor to indicate clickability
  },
}));

const AccountProfile = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [userData, setUserData] = useState();
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };
  useEffect(() => {
    fetchUserData();
  }, []);

  const handleAvatarClick = () => {
    document.getElementById("upload-avatar").click();
  };
  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      Authorization: `Bearer ${userInfo && userInfo.access}`,
    },
  });

  const fetchUserData = async (search = null) => {
    await authAxios.get(urls.userGetOrPut(userInfo.id)).then((res) => {
      setUserData(res.data);
    });
  };

  const handleUpload = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("image", selectedFile);

      const apiUrl = urls.userImagePut(userInfo.id);

      fetch(apiUrl, {
        method: "PUT",
        body: formData,
        headers: {
          // Include any additional headers needed for your API
          // "Authorization": "Bearer YOUR_ACCESS_TOKEN",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          // Handle the response from the server
          console.log("Avatar uploaded successfully:", data);
        })
        .catch((error) => {
          console.error("Error uploading avatar:", error);
        });
    }
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <div className={classes.details}>
          <div>
            <Typography gutterBottom variant="h4">
              {userData && userData.name}
            </Typography>

            <Typography
              className={classes.dateText}
              color="textSecondary"
              variant="body1"
            >
              {moment().format("hh:mm A")}
            </Typography>
          </div>

          <input
            accept="image/*"
            className={classes.fileInput}
            id="upload-avatar"
            type="file"
            onChange={handleFileChange}
            // style={{ cursor: "pointer" }}
          />
          {/* hello */}

          {userData && userData.image ? (
            <Tooltip title="Upload Image" placement="middle">
              <img
                onClick={handleAvatarClick}
                // src={userData && userData.image}
                src={
                  selectedFile
                    ? URL.createObjectURL(selectedFile)
                    : userData && userData.image
                }
                className={clsx(classes.roundedImage, classes.noImageBorder)}
              ></img>
            </Tooltip>
          ) : (
            <Tooltip title="Upload Image" placement="middle">
              <img
                onClick={handleAvatarClick}
                // src={userData && userData.image}
                alt="Upload Image"
                src={
                  selectedFile
                    ? URL.createObjectURL(selectedFile)
                    : userData && userData.image
                }
                className={clsx(classes.roundedImage, classes.noImageBorder)}
              ></img>
            </Tooltip>
          )}
        </div>
        <div className={classes.progress}>
          <Typography variant="body1">
            Profile Completeness: {userData && userData.completeness_percentage}
            %
          </Typography>
          <LinearProgress
            value={userData && userData.completeness_percentage}
            variant="determinate"
          />
        </div>
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          className={classes.uploadButton}
          color="primary"
          size="small"
          variant="contained"
          onClick={handleUpload} // Attach the handleUpload function to onClick
        >
          Upload picture
        </Button>
        {/* <Button size="small" color="primary" variant="contained">
          Remove picture
        </Button> */}
      </CardActions>
    </Card>
  );
};

AccountProfile.propTypes = {
  className: PropTypes.string,
};

export default AccountProfile;
