// import React, { useState } from "react";
// import SideMenu from "../../components/dashboard/SideMenu";
// import HomeIcon from "@material-ui/icons/Home";
// import { Link, useLocation } from "react-router-dom";
// import {
//   Container,
//   Grid,
//   Paper,
//   Button,
//   IconButton,
//   Tabs,
//   Tab,
//   Breadcrumbs,
//   Box,
//   Typography,
//   makeStyles,
// } from "@material-ui/core";
// import AddIcon from "@material-ui/icons/Add";
// import MyAccount from "../purchaseHistory/PurchaseHistory";

// import Template from "./template/Template";
// import VisaGuide from "./visaGuide/VisaGuide";
// // import TemplateList from "./template/templateList/templateList";
// import ExistingDocument from "./document/existingDocument/ExistingDocument";
// import CustomNewTabs from "../../components/CustomNewTabs";
// import MyDocument from "./document/myDocument/MyDocument";

// // import EditorView from "../../components/EditorView";
// import TemplateView from "../../screens/dashboard/document/existingDocument/templateView/TemplateView";

// // const tabs = [
// //   {
// //     icon: null,
// //     label: "Templates",
// //   },
// //   {
// //     icon: null,
// //     label: "MyDocuments",
// //   },
// // ];

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: "100%",
//     height: "30vh",
//     position: "relative",
//     "& video": {
//       objectFit: "cover",
//     },
//   },
//   overlay: {
//     position: "absolute",
//     top: 0,
//     left: 0,
//     width: "100%",
//     height: "100%",
//     backgroundColor: "#D8D1F2",
//   },
//   title: {
//     paddingBottom: theme.spacing(4),
//     display: "block",
//     fontFamily: "Source Sans Pro, Arial, sans-serif",
//     fontSize: "30px",
//     fontStyle: "normal",
//     fontVariant: "normal",
//     fontWeight: "600",
//     letterSpacing: "2px",
//     lineHeight: "92px",
//     textDecoration: "none solid rgb(31, 31, 31)",
//     textAlign: "start",
//     textIndent: "0px",
//     textTransform: "none",
//     verticalAlign: "baseline",
//     whiteSpace: "normal",
//     wordSpacing: "0px",

//     color: "#58097D",
//   },
//   link: {
//     display: "flex",
//   },
//   icon: {
//     marginRight: theme.spacing(0.5),
//     width: 20,
//     height: 20,
//   },
//   card: {
//     MaxWidth: 200,
//     borderRadius: 16,
//     transition: "0.2s",
//     boxShadow: "none",
//     "&:hover": {
//       boxShadow: `0 6px 12px 0 #888888`,
//       transform: "scale(1.1)",
//     },
//     display: "flex",
//     flexDirection: "column",
//     minHeight: "100%",
//     justifyContent: "space-between",
//     // backgroundColor: "#f7f9fa",
//     backgroundColor: "#fff",
//   },

//   media: {
//     height: 140,
//   },
// }));

// function DocumentContainer(props) {
//   const [value, setValue] = useState(0);
//   const [toolbar, SetToolbar] = useState(true);
//   const classes = useStyles();
//   const location = useLocation().hash.slice(5);

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };
//   const [tabData, setTabData] = useState([
//     {
//       // icon: <AddIcon />,
//       label: "Document",
//       component: "",
//     },
//     {
//       // icon: <AddIcon />,
//       label: "My Document",
//       component: "",
//     },
//   ]);
//   return <TemplateView {...props}/>;
// }

// export default DocumentContainer;


import React, { useState } from "react";
import SideMenu from "../../components/dashboard/SideMenu";
import HomeIcon from "@material-ui/icons/Home";
import { Link, useLocation } from "react-router-dom";
import {
  Container,
  Grid,
  Paper,
  Button,
  IconButton,
  Tabs,
  Tab,
  Breadcrumbs,
  Box,
  Typography,
  makeStyles,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import MyAccount from "../purchaseHistory/PurchaseHistory";

import Template from "./template/Template";
import VisaGuide from "./visaGuide/VisaGuide";
// import TemplateList from "./template/templateList/templateList";
import ExistingDocument from "./document/existingDocument/ExistingDocument";
import CustomNewTabs from "../../components/CustomNewTabs";
import MyDocument from "./document/myDocument/MyDocument";

// const tabs = [
//   {
//     icon: null,
//     label: "Templates",
//   },
//   {
//     icon: null,
//     label: "MyDocuments",
//   },
// ];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "30vh",
    position: "relative",
    "& video": {
      objectFit: "cover",
    },
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#D8D1F2",
  },
  title: {
    paddingBottom: theme.spacing(4),
    display: "block",
    fontFamily: "Source Sans Pro, Arial, sans-serif",
    fontSize: "30px",
    fontStyle: "normal",
    fontVariant: "normal",
    fontWeight: "600",
    letterSpacing: "2px",
    lineHeight: "92px",
    textDecoration: "none solid rgb(31, 31, 31)",
    textAlign: "start",
    textIndent: "0px",
    textTransform: "none",
    verticalAlign: "baseline",
    whiteSpace: "normal",
    wordSpacing: "0px",

    color: "#58097D",
  },
  link: {
    display: "flex",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  card: {
    MaxWidth: 200,
    borderRadius: 16,
    transition: "0.2s",
    boxShadow: "none",
    "&:hover": {
      boxShadow: `0 6px 12px 0 #888888`,
      transform: "scale(1.1)",
    },
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
    justifyContent: "space-between",
    // backgroundColor: "#f7f9fa",
    backgroundColor: "#fff",
  },

  media: {
    height: 140,
  },
}));

function DocumentContainer() {
  const [value, setValue] = useState(0);
  const [toolbar, SetToolbar] = useState(true);
  const classes = useStyles();
  const location = useLocation().hash.slice(5);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [tabData, setTabData] = useState([
    {
      // icon: <AddIcon />,
      label: "Document",
      component: "",
    },
    {
      // icon: <AddIcon />,
      label: "My Document",
      component: "",
    },
  ]);
  return (
    <SideMenu toolbar={toolbar}>
      <Grid
        alignItems="center"
        justify="center"
        container
        style={{
          marginTop: "10px",
        }}
      >
        <Grid item md={12}>
          <CustomNewTabs center data={tabData} />
        </Grid>
      </Grid>
      {(location === "Document" || location === "") && <ExistingDocument />}

      {location.startsWith("My") && <MyDocument />}
    </SideMenu>
  );
}

export default DocumentContainer;

