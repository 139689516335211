import React, { useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { withSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Rating from "@material-ui/lab/Rating";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AppContext from "../../context/AppContext";
import axiosInstance from "../../axios";
import {
  Typography,
  Card,
  CardContent,
  CardActionArea,
  Button,
  CardActions,
  Badge,
  CardMedia,
  makeStyles,
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { urls } from "../../utils/urls";

const useStyles = makeStyles((theme) => ({
  card: {
    MaxWidth: 200,
    borderRadius: 16,
    transition: "0.2s",
    boxShadow: "none",
    "&:hover": {
      boxShadow: `0 6px 12px 0 #888888`,
      transform: "scale(1.1)",
    },
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
    justifyContent: "space-between",
    backgroundColor: "#f7f9fa",
  },

  media: {
    height: 140,
  },
}));

const Template = ({ enqueueSnackbar, letter }) => {
  const {
    changeShowPaperContent,
    changeTargetTemplate,
    toggleIsOpen,
  } = useContext(AppContext);

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const handleAddToCart = (guides_id) => {
    axios.post(urls.addToCartUrl, { guides_id }).then((res) => {
      console.log(res.data);
      enqueueSnackbar(`Course added to cart Successfully`, {
        variant: "success",
        autoHideDuration: 5000,
      });
    });
  };

  return (
    <>
      <Card className={classes.card}>
        <CardActionArea>
          <CardContent>
            <Typography align="center" gutterBottom variant="h5" component="h4">
              {letter.letter_title}
            </Typography>
            <Typography
              style={{ marginLeft: "15px" }}
              gutterBottom
              variant="h6"
              component="h4"
            >
              {/* Use Case: */}
              Template Name:
            </Typography>
            {/* <List>
              {letter &&
                letter.letter_template &&
                letter.letter_template.map((v, i) => (
                  <ListItemUser text={v.use_case} key={i} />
                ))}
            </List> */}
            <Typography gutterBottom variant="h6" component="h4">
              {letter?.name}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          {/* <Link to="/login"> */}
          <Button
            // onClick={() =>
            //   history.push(`/dashboard/documents/single-templates#single-templates`)
            // }
            onClick={() => {
              history.push("/dashboard/documents/editorview#editorview");
              const url = urls.singleTemplate(letter?.id);
              changeTargetTemplate(url, axiosInstance);
              changeShowPaperContent(true);
              toggleIsOpen(true);
            }}
            fullWidth
            size="large"
            variant="contained"
            color="primary"
          >
            Get Started
          </Button>
          {/* </Link> */}
        </CardActions>
      </Card>
    </>
  );
};

function ListItemUser({ text }) {
  return (
    <ListItem>
      <ListItemIcon>
        <CheckCircleIcon className="text-dark" />
      </ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  );
}
export default Template;
// export default withSnackbar(Template);
