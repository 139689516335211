import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactGA from "react-ga";
import {
  Box,
  Button,
  Grid,
  makeStyles,
  Paper,
  Typography,
  Container,
} from "@material-ui/core";
import confirmed from "./order-confirmed.gif";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "80px",
  },
  img: {
    width: "50%",
  },
}));

const OrderSuccessPage = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [cart, setCart] = useState([]);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    document.title = "Order-Success / Notting Hill Law";
  }, [dispatch, history]);

  return (
    <Container>
      <Grid container display="flex" justifyContent="center">
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Box display="flex" justifyContent="center">
              <img
                src={confirmed}
                alt="confirmation-image"
                className={classes.img}
              />
            </Box>
            <Typography variant="h4" align="center">
              Your order is complete
            </Typography>

            <Box display="flex" justifyContent="center" pt={2}>
              <Button
                onClick={() => history.push(`/dashboard/documents`)}
                variant="contained"
                color="primary"
              >
                Go Dashboard For Download Book!
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default OrderSuccessPage;
