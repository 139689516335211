import React, { useEffect, useState, useRef } from "react";
import SideMenu from "../../components/dashboard/SideMenu";
import { useHistory } from "react-router-dom";
import {
  Typography,
  Card,
  IconButton,
  CardContent,
  CardActionArea,
  Button,
  CardActions,
  CardHeader,
  Badge,
  CardMedia,
  makeStyles,
  Chip,
  Paper,
  Tabs,
  Tab,
  Container,
  Grid,
  ButtonBase,
  Box,
  Tooltip,
  Fab,
  Avatar,
  LinearProgress,
} from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import AssignmentIcon from "@material-ui/icons/Assignment";
import CustomAccordion from "../../components/CustomAccordion";
import WelcomeBackCard from "../../components/WelcomeBackCard";
import { urls } from "../../utils/urls";
import axiosInstance from "../../axios";
import { Skeleton } from "@material-ui/lab";
import RecentDocuments from "./components/RecentDocuments";
import Notification from "./components/Notification";
import NewDocumentDialog from "./components/NewDocumentDialog";
import { useSelector } from "react-redux";
import templateImg from "../../screens/Home/assets1/template-img.png"

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(4),
    paddingBottom: theme.spacing(8),
  },
  paper1: {
    padding: theme.spacing(1),
    margin: "auto",
    background: "#f7f9fa",
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 700,
  },
  newcardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },

  newcardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },

  link: {
    display: "flex",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  //
  actionArea: {
    borderRadius: 16,
    transition: "0.2s",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  card: {
    MaxWidth: 200,
    borderRadius: 16,
    boxShadow: "none",
    "&:hover": {
      boxShadow: `0 6px 12px 0 #fff
        .rotate(-12)
        .darken(0.2)
        .fade(0.5)}`,
      cursor: "pointer",
    },
  },
  content: {
    backgroundColor: "#fff",
  },
  // title: {
  //   fontSize: 24,
  //   color: "#002067",
  // },

  // new
  boxRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // minHeight: "35vh",
  },
  fab2: {
    margin: theme.spacing(1),
  },
  title: {
    textAlign: "center",

    marginTop: theme.spacing(1),
  },
  boxContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
}));

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

function Dashboard() {
  const classes = useStyles();
  const [toolbar, SetToolbar] = useState(true);
  const addDialog = useRef(null);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const history = useHistory();
  const [guideList, setGuideList] = useState([]);
  const [blank, setBlank] = useState(true);
  const [btnLoading, setBtnLoading] = useState(null);
  const [loading, setLoading] = useState(null);
  const [progress, setProgress] = React.useState(40);

  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    fetchGuideList();
    fetchTemplateList();
  }, []);

  const fetchGuideList = async (search = null) => {
    let url = "";
    if (search != null && search != "") {
      setLoading(true);
      url = `${urls.getGuides}search/${search}`;
    } else {
      setLoading(true);
      url = `${urls.getGuides}`;
    }
    await axiosInstance.get(url).then((res) => {
      setBtnLoading(false);
      setLoading(false);
      setGuideList(res.data);
    });
  };

  const fetchTemplateList = async (queryParams) => {
    setLoading(true);
    let url = "";
    if (queryParams != null && queryParams != "") {
      setLoading(true);
      url = `${urls.getMyTemplateFilter(userInfo.id, queryParams)}`;
    } else {
      setLoading(true);
      url = `${urls.getUserTemplateList(userInfo.id)}`;
    }

    await axiosInstance.get(url).then((res) => {
      setTemplates(res.data);
      setLoading(false);
    });
  };
  return (
    <>
      <SideMenu toolbar={toolbar}>
        {/* <Container maxWidth="lg">
          <WelcomeBackCard
            image={`https://img.freepik.com/free-vector/shiny-white-gray-background-with-wavy-lines_1017-25101.jpg`}
            title="Dashboard | Progress your way!"
            color="#332B30"
          />
          <Grid style={{ minWidth: "1000px" }} container>
            <Grid item sm={6} xs={12} className="px-2">
              <Paper className="mt-5 row mr-0 ml-0" elevation={0}>
                <CustomAccordion
                  title="Recent Documents"
                  content={
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                          {guideList
                            ? guideList.map((v, i) => (
                                <>
                                  <Paper
                                    style={{ background: "#f7f9fa" }}
                                    className={classes.paper}
                                  >
                                    <Grid container spacing={2}>
                                      <Grid item>
                                        <ButtonBase className={classes.image}>
                                          <img
                                            className={classes.img}
                                            alt="no picture"
                                            src={v.thumbnail_image}
                                          />
                                        </ButtonBase>
                                      </Grid>

                                      <Grid item xs={12} sm container>
                                        <Grid
                                          item
                                          xs
                                          container
                                          direction="column"
                                          spacing={2}
                                        >
                                          <Grid item xs>
                                            <Typography
                                              gutterBottom
                                              variant="h5"
                                            >
                                              {v.thumbnail_title}
                                            </Typography>
                                            <Typography
                                              variant="subtitle1"
                                              gutterBottom
                                            >
                                              {v.heading_outline_paragraph.substr(
                                                0,
                                                220
                                              ) + "..."}
                                            </Typography>
                                            <Typography
                                              variant="h6"
                                              color="textSecondary"
                                            >
                                              £ {v.price}
                                            </Typography>
                                            <Button
                                              variant="contained"
                                              color="primary"
                                              onClick={() =>
                                                history.push(
                                                  `/resources/${v.id}`
                                                )
                                              }
                                            >
                                              Go For Download
                                            </Button>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Paper>
                                  <br></br>
                                </>
                              ))
                            : Array.from(Array(3).keys()).map((i) => (
                                <Box padding={3} key={i}>
                                  <Skeleton
                                    variant="rect"
                                    width={250}
                                    height={200}
                                  />
                                  <Skeleton animation="wave" />
                                </Box>
                              ))}
                          {guideList.length === 0 && (
                            <Grid item md={12}>
                              <Box
                                height="100%"
                                marginTop="1vh"
                                alignItems="center"
                                flexDirection="column"
                                justifyContent="center"
                              >
                                <img
                                  style={{
                                    maxWidth: "150px",
                                    display: "block",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                  }}
                                  src={`https://trojournal.com/wp-content/uploads/2022/03/istockphoto-1175118057-612x612-1.jpg`}
                                ></img>
                                <Typography
                                  style={{ textAlign: "center" }}
                                  variant="h4"
                                >
                                  {" "}
                                  There is no document!
                                </Typography>
                              </Box>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </>
                  }
                />
              </Paper>
            </Grid>
            <Grid item sm={6} xs={12} className="px-2">
              <Paper className="mt-5 row mr-0 ml-0" elevation={0}>
                <CustomAccordion
                  title="Recent Templates"
                  content={
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <Card
                            style={{
                              marginBottom: "50px",
                              background: "#f7f9fa",
                            }}
                          >
                            <CardHeader
                              title={`Professional Cv`}
                              // subheader={`Most popular`}
                              titleTypographyProps={{ align: "center" }}
                              subheaderTypographyProps={{ align: "center" }}
                            />
                            <CardMedia
                              style={{ height: "0", paddingTop: "56.25%" }}
                              image="https://static.vecteezy.com/system/resources/previews/022/228/745/original/professional-cv-or-resume-template-design-or-cover-letter-with-a-modern-minimal-and-unique-concept-free-vector.jpg"
                              title="Paella dish"
                            />
                            <CardContent>
                              <div>
                                <Typography
                                  component="h2"
                                  variant="h5"
                                  color="textPrimary"
                                >
                                  £21
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  Are you applying for a company that values
                                  innovation and creativity? Pick a creative
                                  curriculum vitae template.
                                </Typography>
                              </div>
                            </CardContent>
                            <CardActions>
                              <Button
                                variant="contained"
                                fullWidth
                                color="primary"
                                onClick={() =>
                                  history.push(
                                    `/dashboard/documents/single-templates`
                                  )
                                }
                              >
                                Select
                              </Button>
                            </CardActions>
                          </Card>
                        </Grid>
                      </Grid>
                    </>
                  }
                />
              </Paper>
            </Grid>
          </Grid>

          <Grid container></Grid>
        </Container> */}

        <Container>
          <WelcomeBackCard
            image={`https://img.freepik.com/free-vector/shiny-white-gray-background-with-wavy-lines_1017-25101.jpg`}
            title="Dashboard | Automate Templates Creation!"
            color="#332B30"
          />
          <div className={classes.root}>
            <Grid container spacing={4}>
              <Grid item md={12} sm={6} xs={12}>
                <Typography style={{ marginBottom: "20px" }} variant="h4">
                  Recent Documents
                </Typography>
                <Grid container spacing={4}>
                  <Grid item lg={4} sm={6} xl={3} xs={12}>
                    <Box
                      className={classes.actionArea}
                      style={{ borderRadius: 16 }}
                    >
                      <Card
                        className={classes.card}
                        style={{ borderRadius: 16 }}
                      >
                        <CardContent className={classes.content}>
                          <Box className={classes.boxRoot}>
                            <Box className={classes.boxContainer} p={4}>
                              <Tooltip
                                title="Create New Docs"
                                aria-label="Create Docs"
                              >
                                <Fab
                                  color="primary"
                                  size="large"
                                  className={classes.fab2}
                                  onClick={() =>
                                    addDialog.current.handleClickOpen()
                                  }
                                >
                                  <AddIcon />
                                </Fab>
                              </Tooltip>
                              <Typography
                                className={classes.title}
                                variant="h6"
                              >
                                Create New Documents
                              </Typography>
                            </Box>
                          </Box>
                        </CardContent>
                      </Card>
                    </Box>
                  </Grid>
                  {templates && templates.length > 0 ? (
                    templates.map((temp) => (
                      <Grid item md={4}>
                        <Box
                          className={classes.actionArea}
                          style={{ borderRadius: 16 }}
                        >
                          <Card
                            className={classes.card}
                            style={{ borderRadius: 16 }}
                          >
                            <CardContent className={classes.content}>
                              <Box className={classes.boxRoot}>
                                <Box className={classes.boxContainer}>
                                  <Avatar
                                    style={{ height: "70px", width: "70px" }}
                                  >
                                    <AssignmentIcon
                                      style={{
                                        height: "50px",
                                        width: "50px",
                                      }}
                                    />
                                  </Avatar>
                                  <Typography
                                    className={classes.title}
                                    variant="h6"
                                  >
                                    {temp.name}
                                  </Typography>{" "}
                                </Box>
                              </Box>
                              <LinearProgressWithLabel
                                value={temp.completeness_percentage}
                              />
                              <Button
                                size="small"
                                fullWidth
                                color="primary"
                                variant="contained"
                                onClick={() =>
                                  history.push(
                                    `/dashboard/documents/my-template/${temp.id}/`
                                  )
                                }
                              >
                                Customize
                              </Button>
                            </CardContent>
                          </Card>
                        </Box>
                      </Grid>
                    ))
                  ) : (
                    <Grid item lg={8}>
                      <Box
                        height="100%"
                        // marginTop="1vh"
                        alignItems="center"
                        flexDirection="column"
                        justifyContent="center"
                      >
                        <img
                          style={{
                            height: "200px",
                            width: "250px",
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                          src={templateImg}
                        ></img>
                        <Typography
                          style={{ textAlign: "center" }}
                          variant="h4"
                        >
                          {" "}
                          You have not recent templates Yet!
                        </Typography>
                      </Box>
                    </Grid>
                  )}

                  {/* {templates &&
                    templates.length > 0 &&
                    templates.map((temp) => (
                      <Grid item md={4}>
                        <Box
                          className={classes.actionArea}
                          style={{ borderRadius: 16 }}
                        >
                          <Card
                            className={classes.card}
                            style={{ borderRadius: 16 }}
                          >
                            <CardContent className={classes.content}>
                              <Box className={classes.boxRoot}>
                                <Box className={classes.boxContainer}>
                                  <Avatar
                                    style={{ height: "70px", width: "70px" }}
                                  >
                                    <AssignmentIcon
                                      style={{
                                        height: "50px",
                                        width: "50px",
                                      }}
                                    />
                                  </Avatar>
                                  <Typography
                                    className={classes.title}
                                    variant="h6"
                                  >
                                    {temp.name}
                                  </Typography>{" "}
                                </Box>
                              </Box>
                              <LinearProgressWithLabel
                                value={temp.completeness_percentage}
                              />
                              <Button
                                size="small"
                                fullWidth
                                color="primary"
                                variant="contained"
                                onClick={() =>
                                  history.push(
                                    `/dashboard/documents/create-scratch/${temp.id}/`
                                  )
                                }
                              >
                                Customize
                              </Button>
                            </CardContent>
                          </Card>
                        </Box>
                      </Grid>
                    ))} */}
                </Grid>
              </Grid>
              {/* <Grid item lg={4} sm={6} xs={12}>
                <Notification></Notification>
              </Grid> */}

              {/* <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TotalUsers />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TasksProgress />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TotalProfit />
        </Grid> */}
              {/* <Grid
          item
          lg={8}
          md={12}
          xl={9}
          xs={12}
        >
          <LatestSales />
        </Grid>
        <Grid
          item
          lg={4}
          md={6}
          xl={3}
          xs={12}
        >
          <UsersByDevice />
        </Grid>
        <Grid
          item
          lg={4}
          md={6}
          xl={3}
          xs={12}
        >
          <LatestProducts />
        </Grid>
        <Grid
          item
          lg={8}
          md={12}
          xl={9}
          xs={12}
        >
          <LatestOrders />
        </Grid> */}
            </Grid>
          </div>
        </Container>
        <NewDocumentDialog ref={addDialog}></NewDocumentDialog>
      </SideMenu>
    </>
  );
}

export default Dashboard;
