export const GUIDES_LIST_REQUEST = "GUIDES_LIST_REQUEST"
export const GUIDES_LIST_SUCCESS = "GUIDES_LIST_SUCCESS"
export const GUIDES_LIST_FAIL = "GUIDES_LIST_FAIL"


export const GUIDES_DETAILS_REQUEST = "GUIDES_DETAILS_REQUEST"
export const GUIDES_DETAILS_SUCCESS = "GUIDES_DETAILS_SUCCESS"
export const GUIDES_DETAILS_FAIL = "GUIDES_DETAILS_FAIL"


export const GUIDES_FORM_REQUEST = "GUIDES_FORM_REQUEST"
export const GUIDES_FORM_SUCCESS = "GUIDES_FORM_SUCCESS"
export const GUIDES_FORM_FAIL = "GUIDES_FORM_FAIL"

export const GUIDES_TEMP_REQUEST = "GUIDES_TEMP_REQUEST"
export const GUIDES_TEMP_SUCCESS = "GUIDES_TEMP_SUCCESS"
export const GUIDES_TEMP_FAIL = "GUIDES_TEMP_FAIL"