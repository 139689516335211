import React, { useState, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import queryString from "query-string";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ClearIcon from "@material-ui/icons/Clear";
import {
  Paper,
  Grid,
  Button,
  Accordion,
  AccordionSummary,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  DialogActions,
  MenuItem,
  DialogContent,
  CircularProgress,
  Box,
  Typography,
  Divider,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  Root: {
    marginLeft: 0,
    marginRight: 0,
  },
  Paper: {
    padding: "12px",
    marginBottom: "15px",
    background: "#fff",
    boxShadow: `0 6px 12px 0 #888888`,
  },
  Accordion: {
    boxShadow: "none",
    backgroundColor: "transparent",
  },
  Margin0: {
    margin: 0,
  },
  AccordionSummary: {
    padding: 0,
    minHeight: "48px !important",
  },
  ExpandIcon: {
    paddingRight: 0,
    paddingLeft: 0,
    right: 0,
  },
  SummaryContent: {
    margin: "12px 0 0 0 !important",
  },
  Title: {
    marginTop: 0,
    position: "relative",
    marginBottom: 0,
    textAlign: "center",
  },
  AccordionContent: {
    maxHeight: "300px",
    overflow: "auto",
    // padding: "5px 15px 5px 0",
    paddingTop: "5px",
  },
  List: {
    height: "max-content",
    width: "100%",
  },
}));

const DocumentFilter = (props) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [val, setVal] = useState({
    category: "",
    subcategory: "",
  });
  const { register, errors, handleSubmit, setValue, reset } = useForm({
    defaultValues: useMemo(() => {
      return val;
    }, [setVal]),
  });

  const handleFieldChange = (event) => {
    const { name, value } = event.target;

    setVal({ ...val, [name]: value });
  };

  const onSubmit = (e) => {
    const queryParams = queryString.stringify({
      ...val,
    });
    props.handleSubmitCategory(queryParams);
  };

  return (
    <React.Fragment>
      <Paper tour={"Filters"} elevation={0} className={`${classes.Paper} `}>
        <Grid container spacing={1}>
          <Grid item md={12} xs={6}>
            <Accordion
              defaultExpanded
              classes={{
                root: classes.Accordion,
                expanded: classes.Margin0,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                classes={{
                  root: classes.AccordionSummary,
                  expandIcon: classes.ExpandIcon,
                  content: classes.SummaryContent,
                }}
              >
                <div style={{ width: "100%" }}>
                  <Typography
                    component="h3"
                    variant="h5"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Apply Filter
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionSummary classes={{ root: classes.AccordionContent }}>
                <form onSubmit={handleSubmit((e) => onSubmit(e))}>
                  <>
                    <FormControl fullWidth>
                      <InputLabel>Category</InputLabel>
                      <Select
                        fullWidth
                        value={val.category}
                        onChange={handleFieldChange}
                        name="category"
                      >
                        {props.category &&
                          props.category.length > 0 &&
                          props.category.map((cat) => (
                            <MenuItem key={cat.id} value={cat.id}>
                              {cat.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <br></br>
                    <br></br>
                    <FormControl fullWidth>
                      <InputLabel>Sub Category</InputLabel>
                      <Select
                        fullWidth
                        value={val.subcategory}
                        onChange={handleFieldChange}
                        name="subcategory"
                      >
                        {props.category &&
                          props.category.length > 0 &&
                          props.category.map(
                            (cat) =>
                              cat.sub_category &&
                              cat.sub_category.length > 0 &&
                              cat.sub_category.map((sub_cat) => (
                                <MenuItem key={sub_cat.id} value={sub_cat.id}>
                                  {sub_cat.name}
                                </MenuItem>
                              ))
                          )}
                      </Select>
                    </FormControl>
                  </>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    style={{ marginTop: "20px" }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      // fullWidth
                      // size="large"
                    >
                      Apply
                      {/* {updateLoading && (
                <CircularProgress style={{ marginLeft: '10px' }} size={15} /> */}
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      // fullWidth
                      // size="large"
                      // size="small"
                      onClick={() => props.clearFilter()}
                      style={{ marginLeft: "25px" }}
                    >
                      Clear
                    </Button>
                  </Box>
                </form>
              </AccordionSummary>
            </Accordion>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
};

export default DocumentFilter;
