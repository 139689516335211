import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Box, Typography, Input } from "@material-ui/core";
import Backdrop from "@mui/material/Backdrop";
import { Button } from "@material-ui/core";

function ModalEditField(props) {
  return (
    <Modal
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={props.open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
          style={{
            backgroundColor: "#fff",
            zIndex: 100,
            padding: "10px",
            width: "500px",
          }}
        >
          <Typography id="transition-modal-title" variant="h6" component="h2">
            Edit Field
          </Typography>
          <Box mt={2}>
            <textarea
              placeholder={"Enter field name here"}
              style={{ width: "100%", padding: "5px" }}
              value={props.fieldNameEdit}
              onChange={(e) => props.setFieldNameEdit(e.target.value)}
            />
            {props.showGroupNameError && (
              <small style={{ color: "red" }}>
                This field should not be empty
              </small>
            )}
          </Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            gridGap={70}
            mt={2}
            justifyContent={"center"}
          >
            <Button
              onClick={props.createGroup}
              className={props.classes.previewBtn}
            >
              Edit
            </Button>
            <Button
              onClick={props.handleClose}
              className={props.classes.previewBtn}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}

export default ModalEditField;
