import React, { useContext, useState, useEffect } from "react";
import AppContext from "../../../../../../context/AppContext";

import {
  Typography,
  Paper,
  Grid,
  makeStyles,
  Divider,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Link,
  Tooltip,
  CardMedia,
  Card,
  TextField,
  Container,
} from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";

function LegalDocumentTemplate(props) {
  const {
    targetTemplate,
    changeTargetTemplateQuestionValue,
    signImgUrl,
    signText,
    getTargetQuestionValue,
    getFieldContents,
  } = useContext(AppContext);

  const [fieldContents, setFieldContents] = useState([]);
  const [paperContent, setPaperContent] = useState({});

  useEffect(() => {
    const arr = [];
    getFieldContents().forEach((item) => {
      arr.push(item);
    });

    setFieldContents(arr);
  }, [targetTemplate]);

  useEffect(() => {
    const obj = {};
    fieldContents.forEach((item) => {
      //const tId = item.name.toLowerCase().replace(/ /g, "-");
      if (item.name === "Effective Date") {
        obj["Effective Date"] = item.field_content;
       
      } else if (item.name === "Owner") {
        obj["Owner"] = item.field_content;
        
      } else if (item.name === "Business Name") {
        obj["Business Name"] = item.field_content;
        
      } else if (item.name === "Recipient") {
        obj["Recipient"] = item.field_content;
        

      } else if (item.name === "Owner Signatory Name") {
        obj["Owner Signatory Name"] = item.field_content;
       
      } else if (item.name === "Recipient Signatory Name") {
        obj["Recipient Signatory Name"] = item.field_content;
        
      }
    });

    setPaperContent((prev) => ({ ...prev, ...obj }));
  }, [fieldContents]);

  const getTargetTemplateValue = (qName) => {
    targetTemplate.sectionNames?.forEach((section) => {
      section.questions?.forEach((question) => {
        if (question?.questionName === qName) {
          return question?.questionValue;
        } else {
          return question;
        }
      });
    });
  };

  const styleObj = {
    transform: "scaleX(0.6) scaleY(0.98)",
    height: "500px",
    maxHeight: "500px",
    overflowY: "auto",
  };

  return (
    <>
      <Grid
        id={props.id}
        style={props.scaleDown ? styleObj : {}}
        container
        spacing={1}
      >
        <Grid md={12}>
          <>
            <Typography
              style={{ textAlign: "center" }}
              variant="h5"
              component="h2"
            >
              Non-Disclosure Agreement(NDA)
            </Typography>
            <Typography style={{ padding: "10px" }} variant="body1">
              <p>
                This Non-disclosure Agreement ("Agreement") is made effective as
                of{" "}
                <input
                  className="input"
                  type="text"
                  style={{
                    background: "#f5f5f5",
                    border: "1px solid #f5f5f5",
                    margin: "5px 0", // 5px top and bottom margin, 0 left and right
                  }}
                  value={paperContent["Effective Date"]}
                  placeholder="[Effective Date]"
                />
                ("Effective Date"), by and between{" "}
                <input
                  style={{
                    background: "#f5f5f5",
                    border: "1px solid #f5f5f5",
                    margin: "5px 0", // 5px top and bottom margin, 0 left and right
                  }}
                  className="input"
                  placeholder={"[Owner]"}
                  value={paperContent["Owner"]}
                ></input>{" "}
                ("Owner"), of{" "}
                <input
                  style={{
                    background: "#f5f5f5",
                    border: "1px solid #f5f5f5",
                    margin: "5px 0", // 5px top and bottom margin, 0 left and right
                  }}
                  placeholder="[Business Name]"
                  className="input"
                  value={paperContent["Business Name"]}
                ></input>
                , and ("Recipient"), of{" "}
                <input
                  style={{
                    background: "#f5f5f5",
                    border: "1px solid #f5f5f5",
                    margin: "5px 0", // 5px top and bottom margin, 0 left and right
                  }}
                  className="input"
                  placeholder="[Recipient]"
                  id="recipient"
                  value={paperContent["Recipient"]}
                ></input>
                .
              </p>
              <br></br>
              <br></br>The Owner has requested and the Recipient agrees that the
              Recipient will protect the confidential material and information
              which may be disclosed between the Owner and the Recipient.
              Therefore, the parties agree as follows: <br></br>
              <br></br>
              <b>I. Confidential Information.</b>
              The term "Confidential Information" means any information or
              material which is proprietary to the Owner, whether or not owned
              or developed by the Owner, which is not generally known other than
              by the Owner, and which the Recipient may obtain through any
              direct or indirect contact with the Owner. Regardless of whether
              specifically identified as confidential or proprietary,
              Confidential Information shall include any information provided by
              the Owner concerning the business, technology and information of
              the Owner and any third party with which the Owner deals,
              including, without limitation, business records and plans, trade
              secrets, technical data, product ideas, contracts, financial
              information, pricing structure, discounts, computer programs and
              listings, source code and/or object code, copyrights and
              intellectual property, inventions, sales leads, strategic
              alliances, partners, and customer and client lists. The nature of
              the information and the manner of disclosure are such that a
              reasonable person would understand it to be confidential.{" "}
              <br></br>
              <br></br>(a) Confidential Information does not include:
              <br></br>
              <br></br>
              <ul>
                <li>
                  1. matters of public knowledge that result from disclosure by
                  the Owner;{" "}
                </li>
                <li>
                  2. information rightfully received by the Recipient from a
                  third party without a duty of confidentiality;
                </li>
                <li>
                  3. information independently developed by the Recipient;
                  information disclosed by operation of law; information
                  disclosed by operation of law;{" "}
                </li>
                <li>
                  4. and any other information that both parties agree in
                  writing is not confidential.
                </li>
              </ul>
              <b>II. Protection of Confidential Information. </b>
              The Recipient understands and acknowledges that the Confidential
              Information has been developed or obtained by the Owner by the
              investment of significant time, effort, and expense, and that the
              Confidential Information is a valuable, special, and unique asset
              of the Owner which provides the Owner with a significant
              competitive advantage and needs to be protected from improper
              disclosure. In consideration for the receipt by the Recipient of
              the Confidential Information, the Recipient agrees as follows:{" "}
              <br></br>
              <br></br>
              <ul>
                <li>
                  {" "}
                  (a) <b>No Disclosure.</b> The Recipient will hold the
                  Confidential Information in confidence and will not disclose
                  the Confidential Information to any person or entity without
                  the prior written consent of the Owner.{" "}
                </li>
                <li>
                  (b) <b>No Copying/Modifying. </b>The Recipient will not copy
                  or modify any Confidential Information without the prior
                  written consent of the Owner.{" "}
                </li>
                <li>
                  (c) <b>Unauthorized Use. </b> The Recipient shall promptly
                  advise the Owner if the Recipient becomes aware of any
                  possible unauthorized disclosure or use of the Confidential
                  Information.{" "}
                </li>
                <li>
                  (d) <b>Application to Employees. </b>The Recipient shall not
                  disclose any Confidential Information to any employees of the
                  Recipient, except those employees who are required to have the
                  Confidential Information in order to perform their job duties
                  in connection with the limited purposes of this Agreement.
                  Each permitted employee to whom Confidential Information is
                  disclosed shall sign a nondisclosure agreement substantially
                  the same as this Agreement at the request of the Owner.{" "}
                </li>
              </ul>
              <br></br>
              <b>
                III. Unauthorized Disclosure of Information - Injunction.
              </b>{" "}
              If it appears that the Recipient has disclosed (or has threatened
              to disclose) Confidential Information in violation of this
              Agreement, the Owner shall be entitled to an injunction to
              restrain the Recipient from disclosing the Confidential
              Information in whole or in part. The Owner shall not be prohibited
              by this provision from pursuing other remedies, including a claim
              for losses and damages.
              <br></br>
              <br></br>
              <b> IV. Non-Circumvention. </b>For a period of five (5) years
              after the end of the term of this Agreement, the Recipient will
              not attempt to do business with, or otherwise solicit any business
              contacts found or otherwise referred by the Owner to the Recipient
              for the purpose of circumventing, the result of which shall be to
              prevent the Owner from realizing or recognizing a profit, fees, or
              otherwise, without the specific written approval of the Owner. If
              such circumvention shall occur the Owner shall be entitled to any
              commissions due pursuant to this Agreement or relating to such
              transaction.
              <br></br>
              <br></br>
              <b>V. Return of Confidential Information. </b>
              Upon the written request of the Owner, the Recipient shall return
              to the Owner all written materials containing the Confidential
              Information. The Recipient shall also deliver to the Owner written
              statements signed by the Recipient certifying that all materials
              have been returned within five (5) days of receipt of the request.
              <br></br>
              <br></br>
              <b>VI. Relationship of Parties. </b>
              Neither party has an obligation under this Agreement to purchase
              any service or item from the other party, or commercially offer
              any products using or incorporating the Confidential Information.
              This Agreement does not create any agency, partnership, or joint
              venture.
              <br></br>
              <br></br>
              <b>VII. No Warranty. </b> The Recipient acknowledges and agrees
              that the Confidential Information is provided on an "AS IS" basis.
              THE OWNER MAKES NO WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT TO
              THE CONFIDENTIAL INFORMATION AND HEREBY EXPRESSLY DISCLAIMS ANY
              AND ALL IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A
              PARTICULAR PURPOSE. IN NO EVENT SHALL THE OWNER BE LIABLE FOR ANY
              DIRECT, INDIRECT, SPECIAL, OR CONSEQUENTIAL DAMAGES IN CONNECTION
              WITH OR ARISING OUT OF THE PERFORMANCE OR USE OF ANY PORTION OF
              THE CONFIDENTIAL INFORMATION. The Owner does not represent or
              warrant that any product or business plans disclosed to the
              Recipient will be marketed or carried out as disclosed, or at all.
              Any actions taken by the Recipient in response to the disclosure
              of the Confidential Information shall be solely at the risk of the
              Recipient.
              <br></br>
              <br></br>
              <b>VIII. Limited License To Use. </b>The Recipient shall not
              acquire any intellectual property rights under this Agreement
              except the limited right to use as set forth above. The Recipient
              acknowledges that, as between the Owner and the Recipient, the
              Confidential Information and all related copyrights and other
              intellectual property rights, are (and at all times will be) the
              property of the Owner, even if suggestions, comments, and/or ideas
              made by the Recipient are incorporated into the Confidential
              Information or related materials during the period of this
              Agreement.
              <br></br>
              <br></br>
              <b>IX. Indemnity. </b>
              Each party agrees to defend, indemnify, and hold harmless the
              other party and its officers, directors, agents, affiliates,
              distributors, representatives, and employees from any and all
              third party claims, demands, liabilities, costs and expenses,
              including reasonable attorney's fees, costs and expenses resulting
              from the indemnifying party's material breach of any duty,
              representation, or warranty under this Agreement.
              <br></br>
              <br></br>
              <b>X. Attorney's Fees. </b>
              In any legal action between the parties concerning this Agreement,
              the prevailing party shall be entitled to recover reasonable
              attorney's fees and costs.
              <br></br>
              <br></br>
              <b>XI. Term. </b>The obligations of this Agreement shall survive{" "}
              <input
                style={{
                  background: "#f5f5f5",
                  border: "1px solid #f5f5f5",
                }}
                placeholder={"[Effective Date]"}
                value={paperContent["Effective Date"]}
                className="input"
              ></input>{" "}
              from the Effective Date or until the Owner sends the Recipient
              written notice releasing the Recipient from this Agreement. After
              that, the Recipient must continue to protect the Confidential
              Information that was received during the term of this Agreement
              from unauthorized use or disclosure indefinitely.
              <br></br>
              <br></br>
              <b>XII. General Provisions. </b>
              This Agreement sets forth the entire understanding of the parties
              regarding confidentiality. Any amendments must be in writing and
              signed by both parties. This Agreement shall be construed under
              the laws of the State of{" "}
              <input
                style={{
                  background: "#f5f5f5",
                  border: "1px solid #f5f5f5",
                }}
                value=""
                placeholder="[Name]"
                className="input"
              ></input>
              .This Agreement shall not be assignable by either party. Neither
              party may delegate its duties under this Agreement without the
              prior written consent of the other party. The confidentiality
              provisions of this Agreement shall remain in full force and effect
              at all times in accordance with the term of this Agreement. If any
              provision of this Agreement is held to be invalid, illegal, or
              unenforceable, the remaining portions of this Agreement shall
              remain in full force and effect and construed so as to best
              effectuate the original intent and purpose of this Agreement.
              <br></br>
              <br></br>
              <b>XIII. Whistleblower Protection. </b>
              This Agreement is in compliance with the Defend Trade Secrets Act
              and provides civil or criminal immunity to any individual for the
              disclosure of trade secrets: (i) made in confidence to a federal,
              state, or local government official, or to an attorney when the
              disclosure is to report suspected violations of the law; or (ii)
              in a complaint or other document filed in a lawsuit if made under
              seal.
              <br></br>
              <br></br>
              <b>XIV. Signatories.</b>
              This Agreement shall be executed by{" "}
              <input
                style={{
                  background: "#f5f5f5",
                  border: "1px solid #f5f5f5",
                }}
                className="input"
                id={"owner-signatory-name"}
                placeholder={"[Owner Signatory Name]"}
                value={paperContent["Owner Signatory Name"]}
              ></input>
              ,on behalf of{" "}
              <input
                style={{
                  background: "#f5f5f5",
                  border: "1px solid #f5f5f5",
                }}
                placeholder={"[Recipient Signatory Name]"}
                className="input"
                value={paperContent["Recipient Signatory Name"]}
              ></input>{" "}
              and delivered in the manner prescribed by law as of the date first
              written above.
            </Typography>
            <br></br>
            <br></br>
            <br></br>
            <Box>
              <Typography>The Owner :</Typography>
              <br></br>
              <br></br>
              <Box display="flex" justifyContent="space-between">
                <Box>
                  <input
                    style={{
                      background: "#f5f5f5",
                      marginBottom: "15px",
                      border: "1px solid #f5f5f5",
                    }}
                    value={targetTemplate && getTargetTemplateValue("Owner")}
                    placeholder={"[Owner Signature]"}
                  ></input>
                  <br></br>
                  <input
                    style={{
                      background: "#f5f5f5",
                      border: "1px solid #f5f5f5",
                    }}
                    value={targetTemplate && getTargetTemplateValue("Owner")}
                  ></input>
                </Box>

                <Box>
                  Date:{" "}
                  <input
                    style={{
                      background: "#f5f5f5",
                      border: "1px solid #f5f5f5",
                    }}
                    className="input"
                    value={paperContent["Effective Date"]}
                    placeholder={"[Effective Date]"}
                  ></input>
                </Box>
              </Box>
            </Box>
            <Box style={{ marginTop: "40px" }}>
              <Typography>The Recipient :</Typography>
              <br></br>
              <br></br>
              <Box display="flex" justifyContent="space-between">
                <Box>
                  <input
                    style={{
                      background: "#f5f5f5",
                      marginBottom: "15px",
                      border: "1px solid #f5f5f5",
                    }}
                    className="input"
                    value={paperContent["Recipient Signatory Name"]}
                    placeholder={"[Recipient]"}
                  ></input>
                  <br></br>
                  {/* Recipient Sign here */}
                  {/* <input
                    style={{
                      background: "#f5f5f5",
                      border: "1px solid #f5f5f5",
                    }}
                    value=""
                    placeholder={"[Recipient Signature]"}
                  ></input> */}
                  {signImgUrl ? (
                    <img
                      style={{ width: "150px", height: "50px" }}
                      src={signImgUrl}
                      alt="Signature"
                    />
                  ) : (
                    <input
                      style={{
                        background: "#f5f5f5",
                        border: "1px solid #f5f5f5",
                      }}
                      value={signText}
                      placeholder={"[Recipient Signature]"}
                    ></input>
                  )}
                </Box>

                <Box>
                  Date:{" "}
                  <input
                    style={{
                      background: "#f5f5f5",
                      border: "1px solid #f5f5f5",
                    }}
                    className="input"
                    value={paperContent["Effective Date"]}
                    placeholder={"[Effective Date]"}
                  ></input>
                </Box>
              </Box>
            </Box>

            <br></br>
            <br></br>
            <br></br>
          </>
        </Grid>
      </Grid>
    </>
  );
}
export default LegalDocumentTemplate;
