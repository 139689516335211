import React, { useEffect, useRef, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { jsPDF } from "jspdf";
import domtoimage from "dom-to-image";
import html2canvas from "html2canvas";

import html2pdf from "html2pdf.js";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { green, pink } from "@material-ui/core/colors";
import {
  Typography,
  Paper,
  Grid,
  makeStyles,
  Divider,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Link,
  Tooltip,
  CardMedia,
  Card,
  Container,
  Button,
} from "@material-ui/core";
import { DragHandle } from "@material-ui/icons";
import PhoneIcon from "@material-ui/icons/Phone";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import SaveIcon from "@material-ui/icons/Save";
import EmailIcon from "@material-ui/icons/Email";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import profileImg from "../profile.jpg";

const useStyles = makeStyles((theme) => ({
  page: {
    // background: "white",
    background: `linear-gradient(to right, #f5f5f5 42%, white 40%)`,
    display: "block",
    margin: "0 auto",
    marginTop: "0px",
    marginBottom: "0.5cm",
    boxShadow: " 0 0 0.5cm #7b7d7d",
    width: "22cm",
    // height: "29.7cm",
    [theme.breakpoints.down("sm")]: {
      width: "100%", // Set full width on small screens
      padding: "10px", // Adjust padding for small screens
    },
  },
  root: {
    // position: "absolute",
    "& > *": {
      margin: 0,
    },
  },
  pink: {
    // color: theme.palette.getContrastText(pink[500]),
    color: "#fff",
    backgroundColor: pink[500],
    margin: 20,
  },
  green: {
    color: "#fff",
    backgroundColor: green[500],
    margin: 20,
  },
}));

function DefaultFormate(props) {
  const classes = useStyles();
  const componentRef = useRef();
  const { template, onDragEnd } = props;

  const col = [5, 7];

  const handleSaveToPDF = async () => {
    const input = document.getElementById("pdf-content");

    try {
      const blob = await domtoimage.toBlob(input);

      const pdf = new jsPDF("p", "mm", "a4");
      const imgData = URL.createObjectURL(blob);

      // Assuming your content is in portrait orientation (change accordingly)
      pdf.addImage(imgData, "JPEG", 0, 0, 210, 297);

      // Save the PDF
      pdf.save("download.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  return (
    <Container>
      <div>
        {/* <Box display="flex" justifyContent="space-between">
          <Button
            onClick={handleSaveToPDF}
            className={classes.green}
            variant="contained"
          >
            Download <PictureAsPdfIcon />
          </Button>
          <Button
            onClick={handleSaveToPDF}
            className={classes.pink}
            variant="contained"
          >
            Save as your template <SaveIcon />
          </Button>
        </Box> */}
        <Box display="flex" justifyContent="center">
          <Button
            onClick={handleSaveToPDF}
            className={classes.green}
            variant="contained"
          >
            Download <PictureAsPdfIcon />
          </Button>
        </Box>

        <div size="A4" className={classes.page}>
          <div id="pdf-content" ref={componentRef}>
            <Grid container>
              <Grid md={5}></Grid>
              <Grid md={7}>
                <div style={{ padding: "20px", background: "#ffb700" }}></div>
              </Grid>
            </Grid>

            <div style={{ padding: "20px" }}>
              <Grid container spacing={1}>
                <DragDropContext onDragEnd={onDragEnd}>
                  {template.sections &&
                    template.sections.map((section, index) => (
                      <Grid item md={col[index % col.length]}>
                        {/* <Grid container spacing={1}> */}
                        {/* <Grid item md={12}> */}
                        <Droppable
                          key={section.id}
                          droppableId={section.id.toString()}
                        >
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              <Draggable
                                key={section.id}
                                draggableId={section.id.toString()}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    key={section.id}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <Grid container spacing={1}>
                                      <Grid md={12}>
                                        <Box
                                          style={{
                                            flexDirection: "column",
                                            marginBottom: "25px",
                                          }}
                                        >
                                          {section.has_image ? (
                                            <Typography
                                              style={{ display: "none" }}
                                              variant="h4"
                                              gutterBottom
                                            >
                                              {section.section_name}
                                            </Typography>
                                          ) : (
                                            <>
                                              <Typography
                                                variant="h4"
                                                gutterBottom
                                              >
                                                {section.section_name}
                                              </Typography>
                                              <Divider
                                                style={{
                                                  background: "#ffb700",
                                                  marginTop: "5px",
                                                  marginBottom: "5px",
                                                  height: "2px",
                                                }}
                                              ></Divider>
                                            </>
                                          )}

                                          {section.fields.map(
                                            (field, fieldIndex) => (
                                              <>
                                                {/* {console.log(field.image)} */}
                                                {field.image && (
                                                  <img
                                                    style={{
                                                      height: 250,
                                                      width: 200,
                                                      marginBottom: "15px",
                                                    }}
                                                    src={field.image}
                                                  ></img>
                                                )}

                                                <Typography
                                                  key={fieldIndex}
                                                  variant={field.text_type}
                                                >
                                                  {field.field_content}
                                                </Typography>
                                              </>
                                            )
                                          )}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </div>
                                )}
                              </Draggable>

                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </Grid>
                      // </Grid>
                      // </Grid>
                    ))}
                </DragDropContext>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default DefaultFormate;
