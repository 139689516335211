import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { NavLink, useHistory } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import GrainIcon from "@material-ui/icons/Grain";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import { Add, Edit } from "@material-ui/icons";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { Grid, Button, Box, Fab } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
//import TemplateLayout from "../../screens/dashboard/document/existingDocument/templateLayOut/TemplateLayout";
import TemplateLayout from "../screens/dashboard/document/existingDocument/templateLayOut/TemplateLayout";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  link: {
    display: "flex",
    color: "grey",
    "&:hover": {
      backgroundColor: "#f8f9fa",
      color: "black !important",
    },
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));

export default function IconBreadcrumbs({ template }) {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() => {
          handleMenuClose();
          history.push("/dashboard/documents/create");
        }}
      >
        <Add /> Create New Document
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>Browse Templates</MenuItem>
    </Menu>
  );
  return (
    <>
      <Grid container>
        <Grid item sm={10}>
          <Breadcrumbs
            aria-label="breadcrumb"
            className="text-center pl-5 py-2 bg-white"
          >
            {history.location.pathname != "/dashboard/documents/create" && (
              <NavLink
                to="/dashboard"
                exact
                className={`${classes.link} ml-5 p-2`}
                activeClassName="text-dark bg-light rounded"
              >
                <DashboardIcon className={classes.icon} />
                Dashboard
              </NavLink>
            )}

            {history.location.pathname === "/dashboard/profile" && (
              <NavLink
                color="inherit"
                to="/dashboard/profile"
                className={`${classes.link} p-2`}
                activeClassName="text-dark bg-light rounded"
              >
                <PersonIcon className={classes.icon} />
                Profile
              </NavLink>
            )}
            {history.location.pathname === "/dashboard/documents" && (
              <NavLink
                color="inherit"
                to="/dashboard/documents"
                className={`${classes.link} p-2`}
                activeClassName="text-dark bg-light rounded"
              >
                <InsertDriveFileIcon className={classes.icon} />
                Documents
              </NavLink>
            )}
            {history.location.pathname === "/dashboard/documents/upload" && (
              <NavLink
                color="inherit"
                to="/dashboard/documents/upload"
                className={`${classes.link} p-2`}
                activeClassName="text-dark bg-light rounded"
              >
                <InsertDriveFileIcon className={classes.icon} />
                Upload
              </NavLink>
            )}

            {history.location.pathname ===
              "/dashboard/documents/create-scratch" && (
              <>
                <NavLink
                  color="inherit"
                  to="/dashboard/documents"
                  className={`${classes.link} p-2`}
                  activeClassName="text-dark bg-light rounded"
                >
                  <InsertDriveFileIcon className={classes.icon} />
                  Document
                </NavLink>
              </>
            )}
            {history.location.pathname ===
              "/dashboard/documents/create-scratch" && (
              <>
                <NavLink
                  color="inherit"
                  to="/dashboard/documents/create-scratch"
                  className={`${classes.link} p-2`}
                  activeClassName="text-dark bg-light rounded"
                >
                  Create From Scratch
                </NavLink>
              </>
            )}
            {/* my document */}
            {history.location.pathname ===
              `/dashboard/documents/my-template/${
                template && template.id
              }/` && (
              <>
                <NavLink
                  color="inherit"
                  to="/dashboard/documents"
                  className={`${classes.link} p-2`}
                  activeClassName="text-dark bg-light rounded"
                >
                  <InsertDriveFileIcon className={classes.icon} />
                  Document
                </NavLink>
              </>
            )}
            {history.location.pathname ===
              `/dashboard/documents/my-template/${
                template && template.id
              }/` && (
              <>
                <NavLink
                  color="inherit"
                  to="/dashboard/documents#tab=My%20Document"
                  className={`${classes.link} p-2`}
                  activeClassName="text-dark bg-light rounded"
                >
                  <CheckBoxOutlineBlankIcon className={classes.icon} />
                  My Template
                </NavLink>
              </>
            )}
            {history.location.pathname ===
              `/dashboard/documents/my-template/${
                template && template.id
              }/` && (
              <>
                <NavLink
                  color="inherit"
                  to={`/dashboard/documents/my-template/${
                    template && template.id
                  }/`}
                  className={`${classes.link} p-2`}
                  activeClassName="text-dark bg-light rounded"
                >
                  {template && template.name}
                </NavLink>
              </>
            )}
            {/* end my single document */}

            {/* existing single template breadcrumbs */}
            {history.location.pathname ===
              `/dashboard/templates/existing-template/template-layout/${
                template && template.id
              }/` && (
              <>
                <NavLink
                  color="inherit"
                  to="/dashboard/documents"
                  className={`${classes.link} p-2`}
                  activeClassName="text-dark bg-light rounded"
                >
                  <InsertDriveFileIcon className={classes.icon} />
                  Document
                </NavLink>
              </>
            )}
            {history.location.pathname ===
              `/dashboard/templates/existing-template/template-layout/${
                template && template.id
              }/` && (
              <>
                <NavLink
                  color="inherit"
                  to="/dashboard/documents#tab=Document"
                  className={`${classes.link} p-2`}
                  activeClassName="text-dark bg-light rounded"
                >
                  <InsertDriveFileIcon className={classes.icon} />
                  Existing Template
                </NavLink>
              </>
            )}
            {history.location.pathname ===
              `/dashboard/templates/existing-template/template-layout/${
                template && template.id
              }/` && (
              <>
                <NavLink
                  color="inherit"
                  to={`/dashboard/templates/existing-template/template-layout/${
                    template && template.id
                  }/`}
                  className={`${classes.link} p-2`}
                  activeClassName="text-dark bg-light rounded"
                >
                  {template && template.name}
                </NavLink>
              </>
            )}
          </Breadcrumbs>
        </Grid>
        <Grid item sm={2} className="p-2" style={{ backgroundColor: "#fff" }}>
          {/* {history.location.pathname ===
            `/dashboard/documents/create-scratch/${
              template && template.id
            }/` && (
            <Box display="flex">
              <Fab
                style={{
                  color: "#fff",
                  backgroundColor: green[500],
                  marginLeft: "10px",
                }}
                size="small"
                variant="extended"
              >
                {template && template.name}
                <Edit></Edit>
              </Fab>
            </Box>
          )} */}
        </Grid>
      </Grid>

      {renderMenu}
    </>
  );
}
