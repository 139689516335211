import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ReactGA from "react-ga";
import { withSnackbar } from "notistack";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Rating from "@material-ui/lab/Rating";
import {
  Typography,
  Card,
  CardContent,
  CardActionArea,
  Button,
  CardActions,
  Badge,
  CardMedia,
  makeStyles,
  Chip,
} from "@material-ui/core";
import { fetchCart } from "../store/actions/cart";
import { urls } from "../utils/urls";

const useStyles = makeStyles((theme) => ({
  card: {
    MaxWidth: 200,
    borderRadius: 16,
    transition: "0.2s",
    boxShadow: "none",
    "&:hover": {
      boxShadow: `0 6px 12px 0 #888888`,
      transform: "scale(1.1)",
    },
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
    justifyContent: "space-between",
    backgroundColor: "#f7f9fa",
  },

  media: {
    height: 140,
  },
}));

const Guide = ({ guideList, enqueueSnackbar }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [cart, setCart] = useState([]);
  const [enable, setEnable] = useState(false);

  const cartList = useSelector((state) => state.cartList);
  const { loading, shoppingCart, error } = cartList;

  useEffect(() => {
    dispatch(fetchCart(userInfo));
  }, [dispatch]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    getCart();
  }, [dispatch]);

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      Authorization: `Bearer ${userInfo && userInfo.access}`,
    },
  });

  const getCart = () => {
    authAxios.get(urls.getCartUrl).then((res) => {
      setCart(res.data);
    });
  };

  const handleAddToCart = (guides_id) => {
    authAxios.post(urls.addToCartUrl, { guides_id }).then((res) => {
      enqueueSnackbar(`Course added to cart Successfully`, {
        variant: "success",
        autoHideDuration: 5000,
      });
      fetchCart(userInfo);
      window.location.reload(false);
    });
  };

  return (
    <>
      <Card className={classes.card}>
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={guideList.thumbnail_image}
            title={guideList.thumbnail_title}
          />
          <CardContent>
            <Typography gutterBottom variant="h6" component="h4">
              {guideList.thumbnail_title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {/* {guideList.heading_outline_paragraph} */}
              {guideList.heading_outline_paragraph.substr(0, 220) + "..."}
            </Typography>

            {/* <Badge
              badgeContent={3.0}
              color="error"
              style={{ marginTop: "7px" }}
            >
              <Rating name="read-only" value={3} readOnly />
            </Badge> */}
            {guideList.is_free ? (
              <>
                <Typography
                  style={{
                    marginTop: "5px",

                    textAlign: "center",
                  }}
                  variant="h6"
                >
                  <Chip
                    justifyContent="center"
                    color="secondary"
                    label="Free"
                    style={{
                      padding: "15px",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  />
                </Typography>
              </>
            ) : (
              <Typography
                variant="h6"
                style={{ color: "#e59819", marginTop: "5px" }}
              >
                £ {guideList.price}
              </Typography>
            )}
          </CardContent>
        </CardActionArea>
        <CardActions>
          {guideList.is_free ? (
            <Button
              onClick={() => history.push(`/resources/${guideList.id}`)}
              variant="contained"
              color="primary"
              fullWidth
            >
              Go For Download<GetAppIcon></GetAppIcon>
            </Button>
          ) : (
            <>
              <Button
                onClick={() => history.push(`/resources/${guideList.id}`)}
                variant="contained"
                color="secondary"
              >
                Quick View
              </Button>
              {userInfo ? (
                <Button
                  onClick={() => handleAddToCart(guideList.id)}
                  variant="contained"
                  color="primary"
                  // disabled={cart.guides.map((v) => v.cart_order)}
                >
                  Add to Cart
                </Button>
              ) : (
                <Link to="/login">
                  <Button variant="contained" color="primary">
                    Add to Cart
                  </Button>
                </Link>
              )}
            </>
          )}
        </CardActions>
      </Card>
    </>
  );
};

export default withSnackbar(Guide);
