import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactGA from "react-ga";
import Skeleton from "@material-ui/lab/Skeleton";
import SearchIcon from "@material-ui/icons/Search";

import AccessTimeIcon from "@material-ui/icons/AccessTime";
import Rating from "@material-ui/lab/Rating";

import Template from "../../letterTemplates/Template";
import {
  Typography,
  Box,
  Breadcrumbs,
  Grid,
  Card,
  CardContent,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
  CircularProgress,
  CardActionArea,
  Button,
  CardActions,
  Badge,
  CardMedia,
  Container,
  makeStyles,
} from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import SideMenu from "../../../components/dashboard/SideMenu";

import { urls } from "../../../utils/urls";
import axiosInstance from "../../../axios";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "40vh",
    position: "relative",
    "& video": {
      objectFit: "cover",
    },
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#D8D1F2",
  },
  title: {
    paddingBottom: theme.spacing(4),
    display: "block",
    fontFamily: "Source Sans Pro, Arial, sans-serif",
    fontSize: "30px",
    fontStyle: "normal",
    fontVariant: "normal",
    fontWeight: "600",
    letterSpacing: "2px",
    lineHeight: "92px",
    textDecoration: "none solid rgb(31, 31, 31)",
    textAlign: "start",
    textIndent: "0px",
    textTransform: "none",
    verticalAlign: "baseline",
    whiteSpace: "normal",
    wordSpacing: "0px",

    color: "#58097D",
  },
  link: {
    display: "flex",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));

const MyTemplate = () => {
  const classes = useStyles();
  const [letter, setLetter] = useState([]);
  const [search, setSearch] = useState("");
  const [btnLoading, setBtnLoading] = useState(null);
  const [loading, setLoading] = useState(null);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    document.title = "Letter Templates / Notting Hill Law";
    fetchLetterTemplate();
  }, [dispatch, history]);

  const fetchLetterTemplate = async (search = null) => {
    let url = "";
    if (search != null && search != "") {
      setLoading(true);
      url = `${urls.getLetterUrl}search/${search}`;
    } else {
      setLoading(true);
      url = `${urls.getLetterUrl}`;
    }
    await axiosInstance.get(url).then((res) => {
      setBtnLoading(false);
      setLoading(false);
      setLetter(res.data);
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setBtnLoading(true);
    fetchLetterTemplate(search);
    // setBtnLoading(false);
  };
  return (
    <SideMenu>
      <Container>
        <Box style={{ minWidth: "1000px" }}>
          <Typography
            variant="h4"
            align="center"
            style={{ marginTop: "50px", marginBottom: "30px" }}
          >
            Select From Your Template
          </Typography>{" "}
          <Grid
            style={{ marginTop: "30px", marginBottom: "30px" }}
            container
            spacing={2}
            justifyContent="center"
          >
            <Grid item md={12} xs={8} sm={8}>
              <Grid container spacing={3}>
                {letter
                  ? letter.map((v, i) => (
                      <Grid key={i} item lg={4} md={6} xs={12}>
                        <Template letter={v}></Template>
                      </Grid>
                    ))
                  : Array.from(Array(3).keys()).map((i) => (
                      <Box padding={3} key={i}>
                        <Skeleton variant="rect" width={250} height={200} />
                        <Skeleton animation="wave" />
                      </Box>
                    ))}

                {letter.length === 0 && (
                  <Grid item md={12}>
                    <Box
                      height="100%"
                      marginTop="1vh"
                      alignItems="center"
                      flexDirection="column"
                      justifyContent="center"
                    >
                      <img
                        style={{
                          maxWidth: "150px",
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                        src={`https://trojournal.com/wp-content/uploads/2022/03/istockphoto-1175118057-612x612-1.jpg`}
                      ></img>
                      <Typography style={{ textAlign: "center" }} variant="h5">
                        {" "}
                        There is no Template!
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </SideMenu>
  );
};

export default MyTemplate;
