import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactGA from "react-ga";
import Skeleton from "@material-ui/lab/Skeleton";
import SearchIcon from "@material-ui/icons/Search";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import Rating from "@material-ui/lab/Rating";

import queryString from "query-string";

import {
  Typography,
  Box,
  Breadcrumbs,
  Grid,
  Card,
  CardContent,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
  CircularProgress,
  CardActionArea,
  Button,
  CardActions,
  Badge,
  CardMedia,
  Container,
  makeStyles,
} from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import Guide from "../../../components/Guide";
import { urls } from "../../../utils/urls";
import axiosInstance from "../../../axios";
import Filter from "../../../components/filters/Filter";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "40vh",
    position: "relative",
    "& video": {
      objectFit: "cover",
    },
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#D8D1F2",
  },
  title: {
    paddingBottom: theme.spacing(4),
    display: "block",
    fontFamily: "Source Sans Pro, Arial, sans-serif",
    fontSize: "30px",
    fontStyle: "normal",
    fontVariant: "normal",
    fontWeight: "600",
    letterSpacing: "2px",
    lineHeight: "92px",
    textDecoration: "none solid rgb(31, 31, 31)",
    textAlign: "start",
    textIndent: "0px",
    textTransform: "none",
    verticalAlign: "baseline",
    whiteSpace: "normal",
    wordSpacing: "0px",

    color: "#58097D",
  },
  link: {
    display: "flex",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));

const VisaGuide = () => {
  const classes = useStyles();

  const history = useHistory();
  const dispatch = useDispatch();
  const [guideList, setGuideList] = useState([]);
  const [visaList, setVisaList] = useState([]);
  const [search, setSearch] = useState("");
  const [btnLoading, setBtnLoading] = useState(null);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    document.title = "Guide Book / Notting Hill Law";
    fetchGuideList();
    fetchVisaList();
  }, [dispatch, history]);

  const fetchGuideList = async (search = null) => {
    let url = "";
    if (search != null && search != "") {
      setLoading(true);
      url = `${urls.getGuides}search/${search}`;
    } else {
      setLoading(true);
      url = `${urls.getGuides}`;
    }
    await axiosInstance.get(url).then((res) => {
      setBtnLoading(false);
      setLoading(false);
      setGuideList(res.data);
    });
  };

  const fetchVisaList = async () => {
    await axiosInstance.get(urls.visaUrl).then((res) => {
      setVisaList(res.data);
    });
  };

  const handleApplyFilters = async (data) => {
    const payload = {
      ...data,
    };

    if (data.visa_type === "") {
      delete payload.visa_type;
    } else {
      payload.visa_type = data.visa_type;
    }
    const queryParams = queryString.stringify(payload);
    await axiosInstance.get(urls.visaTypeUrl).then((res) => {
      setBtnLoading(false);
      setLoading(false);
      setGuideList(res.data);
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setBtnLoading(true);
    fetchGuideList(search);
    // setBtnLoading(false);
  };
  return (
    <>
      <Grid spacing={2}>
        <Grid style={{ minWidth: "1000px" }} item md={12}>
          <Box sx={{ mb: 3 }}>
            <Card>
              <CardContent>
                <Box
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <form isLoading={false} onSubmit={handleSearch}>
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <FormControl variant="outlined" size="small">
                        <InputLabel htmlFor="search">Search</InputLabel>
                        <OutlinedInput
                          id="search"
                          name="search"
                          type="type"
                          color="primary"
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton type="submit">
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          }
                          labelWidth={70}
                        />
                      </FormControl>
                      <Button
                        style={{ marginLeft: "5px" }}
                        color="primary"
                        variant="contained"
                        type="submit"
                      >
                        search
                        {btnLoading && (
                          <CircularProgress
                            style={{ marginLeft: "10px", color: "white" }}
                            size={15}
                          />
                        )}
                      </Button>
                    </Box>
                  </form>
                </Box>
              </CardContent>
            </Card>
          </Box>
          <Container>
            <Grid
              style={{
                marginTop: "30px",
                marginBottom: "30px",
                // marginRight: "120px",
              }}
              container
              spacing={2}
              justifyContent="center"
            >
              {/* <Grid item md={6}>
                <Filter visaList={visaList}></Filter>
              </Grid> */}

              <Grid item md={12} xs={8} sm={8}>
                <Grid container spacing={3}>
                  {guideList
                    ? guideList.map((v, i) => (
                        <Grid key={i} item lg={4} md={6} xs={12}>
                          <Guide guideList={v}></Guide>
                        </Grid>
                      ))
                    : Array.from(Array(3).keys()).map((i) => (
                        <Box padding={3} key={i}>
                          <Skeleton variant="rect" width={250} height={200} />
                          <Skeleton animation="wave" />
                        </Box>
                      ))}
                  {guideList.length === 0 && (
                    <Grid item md={12}>
                      <Box
                        height="100%"
                        marginTop="1vh"
                        alignItems="center"
                        flexDirection="column"
                        justifyContent="center"
                      >
                        <img
                          style={{
                            maxWidth: "150px",
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                          src={`https://trojournal.com/wp-content/uploads/2022/03/istockphoto-1175118057-612x612-1.jpg`}
                        ></img>
                        <Typography
                          style={{ textAlign: "center" }}
                          variant="h4"
                        >
                          {" "}
                          There is no visa guides!
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </>
  );
};

export default VisaGuide;
