import React, { useContext } from "react";
import AppContext from "../../../../../../../src/context/AppContext";

function DelayToAService(props) {
  const { template } = props;
  const { signImgUrl, signText } = useContext(AppContext);
  return (
    <div id="delay-to-service">
      <div style={{ padding: "5px" }}>
        <div style={{ textAlign: "right" }}>
          {template.sections[0].fields[0].field_content ? (
            <p>{template.sections[0].fields[0].field_content}</p>
          ) : (
            <p>[Your Full Name]</p>
          )}
          {template.sections[0].fields[1].field_content ? (
            <p>{template.sections[0].fields[1].field_content}</p>
          ) : (
            <p>[Your Address]</p>
          )}
          {template.sections[0].fields[2].field_content ? (
            <p>{template.sections[0].fields[2].field_content}</p>
          ) : (
            <p>[Your Email Address]</p>
          )}
          {template.sections[0].fields[3]?.field_content ? (
            <p>{template.sections[0].fields[3]?.field_content}</p>
          ) : (
            <p>[Your Phone Number]</p>
          )}
        </div>
        <div>
          {template.sections[1].fields[0]?.field_content ? (
            <p>{template.sections[1].fields[0]?.field_content}</p>
          ) : (
            <p>[Trader's]</p>
          )}
          {template.sections[1].fields[1]?.field_content ? (
            <p>{template.sections[1].fields[1]?.field_content}</p>
          ) : (
            <p>[Trader's Address]</p>
          )}
        </div>
        <div>
          <p style={{ textAlign: "right" }}>
            <span>{new Date().toDateString()}</span>
          </p>
          {template.sections[1].fields[3]?.field_content ? (
            <p>{template.sections[1].fields[3]?.field_content}</p>
          ) : (
            <p>To Whom It May Concern</p>
          )}
          <p>Consumer Rights Act 2015</p>
          <p>
            On:{" "}
            {template.sections[2].fields[1]?.field_content ? (
              <span>{template.sections[2].fields[1]?.field_content}</span>
            ) : (
              <span>[When did you agree the work with the trader]</span>
            )}
            , you agreed to do the following work:{" "}
            {template.sections[2].fields[0]?.field_content ? (
              <span>{template.sections[2].fields[0]?.field_content}</span>
            ) : (
              <span>[What Work Did You Agree]</span>
            )}{" "}
            at a cost of{" "}
            {template.sections[2].fields[2]?.field_content ? (
              <span>{template.sections[2].fields[2]?.field_content}.</span>
            ) : (
              <span>[How much did you agree to pay]</span>
            )}
          </p>
          <p>However, I have had the following problems:</p>
          {template.sections[3].fields[1]?.field_content ? (
            <p>{template.sections[3].fields[1]?.field_content}.</p>
          ) : (
            <span>[Describe The Problem]</span>
          )}
          <p>
            According to the law named above, I am entitled to have this work
            done by the date we agreed.
          </p>

          <p>
            I would like you to{" "}
            {template.sections[3]?.fields[0]?.field_content ? (
              <span>{template.sections[3]?.fields[0]?.field_content}</span>
            ) : (
              <span>
                [Are You Waiting For The Work To Be Started Or Finished]
              </span>
            )}{" "}
            this work in the next 14 days.
          </p>
          <p>
            If you fail to <span>finish</span> the work in this time, I will
            have no option but to consider taking the matter further.
          </p>
          <p>Yours faithfully,</p>
          {template.sections[0].fields[0].field_content ? (
            <p>{template.sections[0].fields[0].field_content}</p>
          ) : (
            <p>[Your Full Name]</p>
          )}
          <p>
            {signImgUrl ? <img src={signImgUrl} alt="signature" /> : signText}
          </p>
        </div>
      </div>
    </div>
  );
}

export default DelayToAService;
