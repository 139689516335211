import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, Link } from "react-router-dom";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { Box, Button, Container, makeStyles } from "@material-ui/core";

import { urls } from "../../../../../utils/urls";
import axiosInstance from "../../../../../axios";

import Sidebar from "../sidebar/Sidebar";
import GrievanceSidebar from "../sidebar/GrievanceSidebar";
import DefaultFormate from "../mainContent/component/DefaultFormate";
import LegalDocumentTemplate from "../mainContent/component/LegalDocumentTemplate";
import { Update } from "@material-ui/icons";
import SideMenu from "../../../../../components/dashboard/SideMenu";
import GrievanceLetter from "../mainContent/component/GrievanceLetter";
import LetterOfAuthority from "../mainContent/component/LetterOfAuthority";

const useStyles = makeStyles((theme) => ({
  app: {
    display: "flex",
    minHeight: "100vh",
  },
}));

function TemplateLayout(props) {
  const [template, setTemplate] = useState({});
  const [toolbar, SetToolbar] = useState(false);
  const [fieldContents, setFieldContents] = useState({});

  const classes = useStyles();

  useEffect(() => {
    fetchSingleTemplate();
  }, []);

  const {
    match: {
      params: { slug },
    },
  } = props;

  // Fetch single template and set initial field contents
  const fetchSingleTemplate = async (search = null) => {
    await axiosInstance.get(urls.singleTemplate(slug)).then((res) => {
      setTemplate(res.data);

      // Set initial field contents based on fetched data
      const initialFieldContents = {};
      res.data.sections.forEach((section) => {
        section.fields.forEach((field) => {
          initialFieldContents[`field_content${field.id}`] =
            field.field_content || "";
        });
      });
      setFieldContents(initialFieldContents);
    });
  };

  // const fetchSingleTemplate = async (search = null) => {
  //   await axiosInstance.get(urls.singleTemplate(slug)).then((res) => {
  //     setTemplate(res.data);
  //   });
  // };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return template;
    }
    const items = Array.from(template.sections);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    // Update the state
    setTemplate({
      ...template,
      sections: items,
    });
    // Update the server with the new order
    await axiosInstance.post(urls.updateTemplateOrder, {
      templateId: slug,
      newOrder: items.map((item) => item.id),
    });
    // setTemplate({
    //   ...template,
    //   sections: items,
    // });
  };

  const renderTemplateComponent = () => {
    let templateComponent = (
      <DefaultFormate
        onDragEnd={onDragEnd}
        template={template}
        templateId={slug}
        fieldContents={fieldContents}
      />
    );
    if (template.category === "cv") {
      return (templateComponent = (
        <DefaultFormate
          onDragEnd={onDragEnd}
          template={template}
          templateId={slug}
          fieldContents={fieldContents}
        />
      ));
    } else if (template.category === "Business and contracts") {
      return (templateComponent = (
        <LegalDocumentTemplate
          onDragEnd={onDragEnd}
          template={template}
          templateId={slug}
          fieldContents={fieldContents}
        />
      ));
    } else if (template.category === "Discrimination") {
      return (templateComponent = (
        <GrievanceLetter
          onDragEnd={onDragEnd}
          template={template}
          templateId={slug}
          fieldContents={fieldContents}
        />
      ));
    } else if (template.category === "Authority") {
      return (templateComponent = (
        <LetterOfAuthority
          onDragEnd={onDragEnd}
          template={template}
          templateId={slug}
          fieldContents={fieldContents}
        />
      ));
    } else {
      return (templateComponent = (
        <DefaultFormate
          onDragEnd={onDragEnd}
          template={template}
          templateId={slug}
          fieldContents={fieldContents}
        />
      ));
    }
  };

  const renderSidebarComponent = () => {
    let sidebarComponent = (
      <Sidebar
        templateUpdate={fetchSingleTemplate}
        template={template}
        fieldContents={fieldContents}
      ></Sidebar>
    );
    if (template.name === "Grievance Letter") {
      return (sidebarComponent = (
        <GrievanceSidebar
          templateUpdate={fetchSingleTemplate}
          template={template}
          fieldContents={fieldContents}
        />
      ));
    } else {
      return (sidebarComponent = (
        <Sidebar
          templateUpdate={fetchSingleTemplate}
          template={template}
          fieldContents={fieldContents}
        ></Sidebar>
      ));
    }
  };

  return (
    <SideMenu toolbar={toolbar} template={template}>
      <Container>
        <div className={classes.app}>
          {/* {renderSidebarComponent()} */}
          <Sidebar
            templateUpdate={fetchSingleTemplate}
            template={template}
            fieldContents={fieldContents}
          ></Sidebar>

          {/* <DefaultFormate
        onDragEnd={onDragEnd}
        template={template}
        templateId={slug}
      /> */}
          <Container style={{ marginLeft: "320px" }}>
            {renderTemplateComponent()}
          </Container>

          {/* <LegalDocumentTemplate
        onDragEnd={onDragEnd}
        template={template}
        templateId={slug}
      /> */}
        </div>
      </Container>
    </SideMenu>
  );
}

export default TemplateLayout;
