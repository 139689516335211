import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Container,
  Grid,
  makeStyles,
  Paper,
  Button,
  IconButton,
  Tabs,
  Tab,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import PurchaseHistory from "../../purchaseHistory/PurchaseHistory";
import SideMenu from "../../../components/dashboard/SideMenu";
import Template from "../template/Template";
// import MyAccount from "../myAccount/MyAccount";

import VisaGuide from "../visaGuide/VisaGuide";

import AccountProfile from "./components/AccountProfile";
import AccountDetails from "./components/AccountDetails";
import CustomNewTabs from "../../../components/CustomNewTabs";
import MyAccount from "./components/MyAccount";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

function Profile() {
  const classes = useStyles();
  const [toolbar, SetToolbar] = useState(true);
  const location = useLocation().hash.slice(5);

  const [tabData, setTabData] = useState([
    {
      // icon: <AddIcon />,
      label: "My Account",
      component: "",
    },
    {
      // icon: <AddIcon />,
      label: "Purchase History",
      component: "",
    },
  ]);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <SideMenu toolbar={toolbar}>
        <Grid
          alignItems="center"
          justify="center"
          container
          style={{
            marginTop: "10px",
          }}
        >
          <Grid item md={12}>
            <CustomNewTabs center data={tabData} />
          </Grid>
        </Grid>

        {(location.startsWith("My") || location === "") && (
          // <div className={classes.root}>
          //   <Grid container spacing={4}>
          //     <Grid item lg={4} md={6} xl={4} xs={12}>
          //       <AccountProfile />
          //     </Grid>
          //     <Grid item lg={8} md={6} xl={8} xs={12}>
          //       <AccountDetails />
          //     </Grid>
          //   </Grid>
          // </div>
          <MyAccount></MyAccount>
        )}

        {location.startsWith("Purchase") && (
          <>
            <PurchaseHistory />
          </>
        )}
      </SideMenu>
    </>
  );
}

export default Profile;
