import React, { useEffect, } from "react";
import { useHistory } from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";
import data from "../data/data.json";
import ReactGA from "react-ga";
import BusinessIcon from "@material-ui/icons/Business";
import HomeIcon from "@material-ui/icons/Home";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import PeopleIcon from "@material-ui/icons/People";
import { Box, Button, Typography } from "@material-ui/core";

function DocumentFeature(props) {
  const history = useHistory()
  const JsonData = data.Features;
  useEffect(() => {
    document.title = "Notting Hill Law";
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <>
      <div
        id="features"
        className="text-center"
        style={{
          // background: "#f7f9fa",
          background: "#fff",
          backgroundSize: "cover",
          height:
            props.history && props.history.location.pathname == "/process"
              ? "100vh"
              : "100%",
          padding:
            props.history && props.history.location.pathname == "/process"
              ? "50px"
              : "50px",
        }}
      >
        <ScrollToTop />
        <div className="container ff">
          <div className="col-md-12 col-md-offset-1 section-title">
            <h2>Our most popular documents</h2>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-3 feature_icon">
              {" "}
              <Box>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <BusinessIcon fontSize="large"></BusinessIcon>
                  <Typography
                    style={{
                      marginLeft: "10px",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                    variant="h6"
                  >
                    Business
                  </Typography>
                </Box>
                <br></br>
                <Box flexDirection="column" alignItems="flex-start">
                  <Typography variant="h6">Privacy Policy</Typography>
                  <Typography variant="h6">Terms and Conditions</Typography>

                  <Typography variant="h6">Non Disclosure Agreement</Typography>
                  <Typography variant="h6">Shareholders Agreement</Typography>
                </Box>
              </Box>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-3 feature_icon">
              {" "}
              <Box>
                <Box display="flex" alignItems="center">
                  <HomeIcon fontSize="large"></HomeIcon>
                  <Typography
                    style={{
                      marginLeft: "10px",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                    variant="h6"
                  >
                    Property
                  </Typography>
                </Box>
                <br></br>
                <Box flexDirection="column" alignItems="flex-start">
                  <Typography variant="h6">Tenancy Agreement</Typography>
                  <Typography variant="h6">Lodger Agreement</Typography>
                  <Typography variant="h6">Eviction Notice</Typography>
                  <Typography variant="h6">Declaration of Trust</Typography>
                </Box>
              </Box>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-3 feature_icon">
              {" "}
              <Box>
                <Box display="flex" alignItems="center">
                  <PeopleIcon fontSize="large"></PeopleIcon>
                  <Typography
                    style={{
                      marginLeft: "10px",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                    variant="h6"
                  >
                    Employment
                  </Typography>
                </Box>
                <br></br>
                <Box flexDirection="column" alignItems="flex-start">
                  <Typography variant="h6">Employment Contract</Typography>
                  <Typography variant="h6">Health and Safety Policy</Typography>
                  <Typography variant="h6">Settlement Agreement</Typography>
                  <Typography variant="h6">Consultancy Agreement</Typography>
                </Box>
              </Box>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-3 feature_icon">
              {" "}
              <Box>
                <Box display="flex" alignItems="center">
                  <HomeWorkIcon fontSize="large"> </HomeWorkIcon>
                  <Typography
                    style={{
                      marginLeft: "10px",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                    variant="h6"
                  >
                    Family and Personal
                  </Typography>
                </Box>
                <br></br>
                <Box flexDirection="column" alignItems="flex-start">
                  <Typography variant="h6">Affidavit</Typography>
                  <Typography variant="h6">Prenuptial Agreement</Typography>
                  <Typography variant="h6">Last Will and Testment</Typography>
                  <Typography variant="h6">Power of Attorney</Typography>
                </Box>
              </Box>
            </div>
          </div>
          <Button
            style={{ marginTop: "30px" }}
            size="large"
            variant="contained"
            color="primary"
            onClick={() =>
              history.push(
                '/letter-templates'
              )
            }
          >
            See all documents
          </Button>
        </div>
      </div>
    </>
  );
}

export default DocumentFeature;
