import React, { useEffect, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { withSnackbar } from "notistack";
import axios from "axios";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  InputAdornment,
} from "@material-ui/core";

import { urls } from "../../../../utils/urls";
import axiosInstance from "../../../../axios";

const useStyles = makeStyles(() => ({
  root: {},
}));

const AccountDetails = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [val, setVal] = useState({
    date_of_birth: "",
    email: "",
    first_name: "",
    last_name: "",
    name: "",
  });
  // const handleChange = (event) => {
  //   setVal({
  //     [event.target.name]: event.target.value,
  //   });
  // };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setVal({ ...val, [name]: value });
  };
  useEffect(() => {
    fetchUserData();
  }, []);

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      Authorization: `Bearer ${userInfo && userInfo.access}`,
    },
  });

  const fetchUserData = async (search = null) => {
    await authAxios.get(urls.userGetOrPut(userInfo.id)).then((res) => {
      setVal({
        first_name: res.data.first_name,
        last_name: res.data.last_name,
        name: res.data.name,
        email: res.data.email,
        date_of_birth: res.data.date_of_birth,
      });
    });
  };
  const { register, errors, handleSubmit, setValue, reset } = useForm({
    defaultValues: useMemo(() => {
      return val;
    }, [setVal]),
  });
  const handleSubmitData = async (e) => {
    let response = null;
    // setLoading(true);
    // setUpdateLoading(true);

    await authAxios.put(urls.userGetOrPut(userInfo.id), e).then((res) => {
      // setBtnLoading(false);
      // setLoading(false);
      // setLetter(res.data);
      props.enqueueSnackbar(`Profile Updated Successfully`, {
        variant: "success",
        autoHideDuration: 5000,
      });
      console.log(res);
    });
    // if (response.status === 201) {
    //   handleClose();
    //   fetchCourseList();
    //   // setUpdateLoading(false);
    // } else {
    //   setUpdateLoading(false);

    // }
  };

  const onSubmit = (e) => {
    const payload = {
      ...e,
      ...val,
    };
    handleSubmitData(payload);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form
        onSubmit={handleSubmit((e) => onSubmit(e))}
        autoComplete="off"
        noValidate
      >
        <CardHeader
          subheader="The information can be edited"
          title="Profile Details"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText="Please specify the first name"
                label="First name"
                margin="dense"
                name="first_name"
                onChange={handleChange}
                required
                value={val.first_name}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Last name"
                margin="dense"
                name="last_name"
                onChange={handleChange}
                required
                value={val.last_name}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Email Address"
                margin="dense"
                name="email"
                onChange={handleChange}
                required
                value={val.email}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Full Name"
                margin="dense"
                name="name"
                onChange={handleChange}
                value={val.name}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Date Of Birth"
                margin="dense"
                name="date_of_birth"
                onChange={handleChange}
                required
                value={val.date_of_birth}
                type="date"
                InputProps={{
                  inputProps: { max: new Date().toISOString().split("T")[0] },
                }}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button type="submit" color="primary" variant="contained">
            Save details
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string,
};

export default withSnackbar(AccountDetails);
