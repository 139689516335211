import React, { useEffect, useRef, useState, useContext } from "react";
import { useDispatch } from "react-redux";
import ReactGA from "react-ga";
import { Link, useHistory } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import SearchIcon from "@material-ui/icons/Search";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import cv1 from "./cv1.jpg";
import doc from "./doc.avif";
import templateImg from "../../../../screens/Home/assets1/template-img.png";
import {
  Container,
  makeStyles,
  Button,
  Breadcrumbs,
  Typography,
  Box,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  CardMedia,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  CircularProgress,
  Tooltip,
  Fab,
  Modal,
  Fade,
  Backdrop,
} from "@material-ui/core";

import DocumentModal from "./DocumentModal";
import DocumentFilter from "../documentFilter/DocumentFilter";
import axiosInstance from "../../../../axios";
import { urls } from "../../../../utils/urls";
import HeroSection from "../../../../components/HeroSection";
import resume from "./resume.png";
import SideMenu from "../../../../components/dashboard/SideMenu";
import AppContext from "../../../../context/AppContext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "30vh",
    position: "relative",
    "& video": {
      objectFit: "cover",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#D8D1F2",
  },
  title: {
    paddingBottom: theme.spacing(4),
    display: "block",
    fontFamily: "Source Sans Pro, Arial, sans-serif",
    fontSize: "30px",
    fontStyle: "normal",
    fontVariant: "normal",
    fontWeight: "600",
    letterSpacing: "2px",
    lineHeight: "92px",
    textDecoration: "none solid rgb(31, 31, 31)",
    textAlign: "start",
    textIndent: "0px",
    textTransform: "none",
    verticalAlign: "baseline",
    whiteSpace: "normal",
    wordSpacing: "0px",

    color: "#58097D",
  },
  link: {
    display: "flex",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  card: {
    MaxWidth: 200,
    // textAlign: "center",
    borderRadius: 16,
    transition: "0.2s",
    boxShadow: "none",
    "&:hover": {
      boxShadow: `0 6px 12px 0 #888888`,
      transform: "scale(1.1)",
    },
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
    justifyContent: "space-between",
    // backgroundColor: "#f7f9fa",
    backgroundColor: "#fff",
    position: "relative",
    overflow: "hidden",
    "&:hover $visibilityIcon": {
      opacity: 1,
    },
  },

  media: {
    height: 140,
  },

  // card: {
  //   position: "relative",
  //   overflow: "hidden",
  //   "&:hover .visibility-icon": {
  //     opacity: 1,
  //   },
  // },
  visibilityIcon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    opacity: 0,
    transition: "opacity 0.3s ease-in-out",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function ExistingDocument() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    templateStatus,
    changeShowPaperContent,
    showPaperContent,
    // templates,
    // setTemplates,
    targetTemplate,

    changeTargetTemplate,
    isOpen,
    toggleIsOpen,
  } = useContext(AppContext);

  const [category, setCategory] = useState([]);
  const [search, setSearch] = useState("");
  const [templates, setTemplates] = useState([]);

  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(null);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    fetchTemplateList();
    fetchCategory();
  }, [dispatch]);

  const fetchCategory = async (search = null) => {
    await axiosInstance.get(urls.templateCategory).then((res) => {
      setCategory(res.data);
    });
  };

  const fetchTemplateList = async (queryParams) => {
    let response = null;
    setLoading(true);

    let url = "";
    if (queryParams != null && queryParams != "") {
      setLoading(true);
      url = `${urls.getTemplateFilter(queryParams)}`;
    } else {
      setLoading(true);
      url = `${urls.getTemplateList}`;
    }

    await axiosInstance.get(url).then((res) => {
      setTemplates(res.data);
      setLoading(false);
    });
  };

  const searchTemplateList = async (search) => {
    let response = null;
    setLoading(true);

    let url = "";
    if (search != null && search != "") {
      setLoading(true);
      url = `${urls.getTemplateSearch(search)}`;
    } else {
      setLoading(true);
      url = `${urls.getTemplateList}`;
    }

    await axiosInstance.get(url).then((res) => {
      setTemplates(res.data);
      setLoading(false);
    });
  };

  const handleSubmitCategory = (e) => {
    // e.preventDefault();
    setLoading(true);
    fetchTemplateList(e);
    // setBtnLoading(false);
  };
  const clearFilter = () => {
    fetchTemplateList();
    // fetchCategory();
  };

  const addDialog = useRef(null);

  const handleSearch = (e) => {
    e.preventDefault();
    searchTemplateList(search);
    setBtnLoading(false);
  };
  return (
    <>
      <section className={classes.root}>
        <div className={classes.overlay}>
          {/* <Breadcrumbs
            style={{ marginTop: "20px", display: "inline", width: "auto" }}
            aria-label="breadcrumb"
          >
            <Link color="inherit" to="/" className={classes.link}>
              <HomeIcon className={classes.icon} />
              Dashboard
            </Link>
            <Link color="inherit" to="/#templates" className={classes.link}>
              Documents
            </Link>
            <Link className={classes.link}>Existing Templates</Link>
          </Breadcrumbs> */}

          <Box
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            color="#fff"
          >
            <Typography variant="h5" component="h1" className={classes.title}>
              Select From Our Template
            </Typography>
          </Box>
        </div>
      </section>

      <Grid container>
        <Grid item md={12}>
          <Box sx={{ mb: 3 }}>
            <Card>
              <CardContent>
                <Box
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <form isLoading={false} onSubmit={handleSearch}>
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <FormControl variant="outlined" size="small">
                        <InputLabel htmlFor="search">Search</InputLabel>
                        <OutlinedInput
                          id="search"
                          name="search"
                          type="type"
                          color="primary"
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton type="submit">
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          }
                          labelWidth={70}
                        />
                      </FormControl>
                      <Button
                        style={{ marginLeft: "5px" }}
                        color="primary"
                        variant="contained"
                        type="submit"
                      >
                        search
                        {btnLoading && (
                          <CircularProgress
                            style={{ marginLeft: "10px", color: "white" }}
                            size={15}
                          />
                        )}
                      </Button>
                    </Box>
                  </form>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>

      <Container>
        <Grid
          style={{ marginTop: "10px" }}
          container
          spacing={3}
          justifyContent="center"
        >
          <Grid item md={3}>
            <DocumentFilter
              handleSubmitCategory={handleSubmitCategory}
              category={category}
              clearFilter={clearFilter}
            ></DocumentFilter>
          </Grid>
          <Grid item md={9}>
            <Grid container spacing={3} justifyContent="center">
              {templates && templates.length > 0 ? (
                templates.map((temp) => (
                  <Grid item md={4}>
                    <Card className={classes.card}>
                      <CardActionArea>
                        {/* <CardMedia
                        className={classes.media}
                        image={temp.image && temp.image ? temp.image : doc}
                        title={`hello`}
                      /> */}

                        <CardContent>
                          <Typography
                            style={{ textAlign: "center" }}
                            gutterBottom
                            variant="h6"
                            component="h4"
                          >
                            {temp.name}
                          </Typography>

                          {/* <Typography
                            style={{ marginBottom: "10px" }}
                            variant="h6"
                            // component="h4"
                          >
                            Use Case :
                          </Typography> */}

                          {temp &&
                            temp.template_use_case.map((useCase) => (
                              <Box
                                style={{ marginBottom: "8px" }}
                                display="flex"
                              >
                                <CheckCircleIcon color="primary"></CheckCircleIcon>
                                <Typography variant="body1">
                                  {useCase.title}
                                </Typography>
                              </Box>
                            ))}
                        </CardContent>
                      </CardActionArea>
                      <CardActions>
                        {/* <Tooltip
                        className={classes.visibilityIcon}
                        title="Create New Docs"
                        aria-label="Create Docs"
                      >
                        <Fab
                          color="primary"
                          size="large"
                          className={classes.fab2}
                          onClick={() => addDialog.current.handleClickOpen()}
                        >
                          <VisibilityIcon
                            onClick={() => addDialog.current.handleClickOpen()}
                            style={{ cursor: "pointer" }}
                            className={classes.visibilityIcon}
                          />
                        </Fab>
                      </Tooltip> */}
                        <VisibilityIcon
                          className={classes.visibilityIcon}
                          color="primary"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            addDialog.current.handleClickOpen(temp.image)
                          }
                        ></VisibilityIcon>
                        <Button
                          onClick={() => {
                            // history.push(
                            //   `/dashboard/templates/existing-template/template-layout/${temp.id}/`
                            // )
                            history.push(
                              "/dashboard/documents/editorview#editorview"
                            );
                            const url = urls.singleTemplate(temp.id);
                            changeTargetTemplate(url, axiosInstance);
                            changeShowPaperContent(true);
                            toggleIsOpen(true);
                          }}
                          variant="contained"
                          color="primary"
                          fullWidth
                          size="small"
                        >
                          Select
                        </Button>
                      </CardActions>
                    </Card>

                    <DocumentModal tempImage={temp.image} ref={addDialog} />
                  </Grid>
                ))
              ) : (
                <Grid item md={8}>
                  <Box
                    height="100%"
                    // marginTop="1vh"
                    alignItems="center"
                    flexDirection="column"
                    justifyContent="center"
                  >
                    <img
                      style={{
                        height: "250px",
                        width: "300px",
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                      src={templateImg}
                      alt="document icon"
                    ></img>
                    <Typography
                      style={{ textAlign: "center", marginTop: "5px" }}
                      variant="h4"
                    >
                      {" "}
                      Templates not found!
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default ExistingDocument;
