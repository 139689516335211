import React, { useRef } from "react";
import { createTheme, ThemeProvider, Divider } from "@mui/material";
import "./Home.css";
import Hero from "./components/Hero";
import Businesses from "./components/Businesses";
import Squares from "./components/Squares";
import About from "./components/About";
import Features from "./components/Features";
import Testimonial from "./components/Testimonial";
import Faqs from "./components/Faqs";
import Subscribe from "./components/Subscribe";
import Footer from "./components/Footer";
import JoinWaitlist from "./components/JoinWaitlist";
import Copyright from "./components/Copyright";
import DocumentFeature from "../DocumentFeature";

const theme = createTheme({
  typography: {
    fontFamily: [
      "Manrope",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

function Home() {
  const testimonialRef = useRef(null);
  return (
    <>
      <ThemeProvider theme={theme}>
        <Hero />
        <DocumentFeature/>
        <Divider orientation="horizontal" />
        <Businesses />
        <Divider orientation="horizontal" />
        <Squares />
        <About />
        <Features />
        <Testimonial testimonialRef={testimonialRef} />
        <Faqs />
        <Subscribe />
        <JoinWaitlist />
        <Divider className="footer-divider" orientation="horizontal" />
        <Footer />
        <Divider className="footer-divider" orientation="horizontal" />
        <Copyright />
      </ThemeProvider>
    </>
  );
}

export default Home;
