/**
 *
 * StripeInput
 *
 */

import React from "react";
import PropTypes from "prop-types";
// import styled from 'styled-components';

import withStyles from "@material-ui/core/styles/withStyles";

const styles = () => ({
  root: {
    width: "100%",
    padding: "6px 0 7px",
    cursor: "text",
  },
});

/* eslint-disable react/prefer-stateless-function */
class StripeInput extends React.PureComponent {
  static displayName = "StripeInput";

  static propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    component: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    onFocus: () => {},
    onBlur: () => {},
    onChange: () => {},
  };

  render() {
    const {
      classes: c,
      theme,
      component: Component,
      onFocus,
      onBlur,
      onChange,
    } = this.props;

    return (
      <Component
        className={c.root}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        placeholder=""
        style={{
          base: {
            color: "#000000de",
          },
        }}
      />
    );
  }
}

StripeInput.propTypes = {};

export default withStyles(styles)(StripeInput);
