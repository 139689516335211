import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import html2pdf from "html2pdf.js";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { green, pink } from "@material-ui/core/colors";
import {
  Typography,
  Paper,
  Grid,
  makeStyles,
  Divider,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Link,
  Tooltip,
  CardMedia,
  Card,
  TextField,
  Container,
} from "@material-ui/core";
import { DragHandle } from "@material-ui/icons";
import PhoneIcon from "@material-ui/icons/Phone";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import UpdateIcon from "@material-ui/icons/Update";
import GetAppIcon from "@material-ui/icons/GetApp";
import EmailIcon from "@material-ui/icons/Email";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { Button } from "react-bootstrap";
// import profileImg from "./profile.jpg";

const useStyles = makeStyles((theme) => ({
  page: {
    background: "white",
    display: "block",
    margin: "0 auto",
    marginTop: "10px",
    marginBottom: "0.5cm",
    boxShadow: " 0 0 0.5cm #7b7d7d",
    width: "22cm",
    [theme.breakpoints.down("sm")]: {
      width: "100%", // Set full width on small screens
      padding: "10px", // Adjust padding for small screens
    },
  },
  root: {
    "& > *": {
      margin: 0,
    },
  },

  green: {
    color: "#fff",
    backgroundColor: green[500],
    margin: 20,
    display: "flex",
  },
}));

function LetterOfAuthority(props) {
  const classes = useStyles();
  const { template, onDragEnd } = props;

  const handleSaveToPDF = () => {
    const element = document.getElementById("pdf-content"); // Replace with the ID of the element you want to export

    html2pdf(element);
    return false;
  };

  return (
    <Container>
      <div>
        <Box display="flex" justifyContent="center">
          <Button
            onClick={handleSaveToPDF}
            className={classes.green}
            variant="contained"
          >
            Download <PictureAsPdfIcon />
          </Button>
        </Box>

        <div size="A4" className={classes.page}>
          <div id="pdf-content">
            <div style={{ padding: "20px" }}>
              <Grid container spacing={1}>
                <Grid md={12}>
                  <>
                    <Typography
                      style={{ textAlign: "center" }}
                      variant="h5"
                      component="h2"
                    >
                      LETTER OF AUTHORITY
                    </Typography>
                    {template.sections &&
                      template.sections.map((section, index) => (
                        <Typography style={{ padding: "10px" }} variant="body1">
                          <p>
                            <>
                              <Typography
                                style={{
                                  textDecoration: "underline",
                                }}
                                variant="h5"
                              >
                                PRIVATE AND CONFIDENTIAL
                              </Typography>
                              <br></br>
                              <Grid container spacing={1}>
                                <Grid md={6}>
                                  {section.fields[0].field_content ? (
                                    <Typography
                                      style={{
                                        background: "#f5f5f5",
                                        display: "inline-block",
                                        margin: "5px 0",
                                        padding: "6px",
                                      }}
                                    >
                                      Name : {section.fields[0].field_content}
                                    </Typography>
                                  ) : (
                                    <Typography
                                      style={{
                                        background: "#f5f5f5",
                                        display: "inline-block",
                                        margin: "5px 0",
                                        padding: "6px",
                                      }}
                                    >
                                      Name : /
                                    </Typography>
                                  )}
                                  <br></br>
                                  {section.fields[1].field_content ? (
                                    <Typography
                                      style={{
                                        background: "#f5f5f5",
                                        display: "inline-block",
                                        margin: "5px 0",
                                        padding: "6px",
                                      }}
                                    >
                                      Address: {section.fields[1].field_content}
                                    </Typography>
                                  ) : (
                                    <Typography
                                      style={{
                                        background: "#f5f5f5",
                                        display: "inline-block",
                                        margin: "5px 0",
                                        padding: "6px",
                                      }}
                                    >
                                      Address: /
                                    </Typography>
                                  )}
                                  <br></br>
                                  <br></br>

                                  <Typography
                                    style={{
                                      textDecoration: "underline",
                                    }}
                                    variant="h6"
                                  >
                                    RE : TRAVEL DOCUMENT
                                  </Typography>
                                </Grid>
                                <Grid md={6}>
                                  {section.fields[2].field_content ? (
                                    <Typography
                                      style={{
                                        background: "#f5f5f5",
                                        display: "inline-block",
                                        margin: "5px 0",
                                        padding: "6px",
                                      }}
                                    >
                                      Our ref: {section.fields[2].field_content}
                                    </Typography>
                                  ) : (
                                    <Typography
                                      style={{
                                        background: "#f5f5f5",
                                        display: "inline-block",
                                        margin: "5px 0",
                                        padding: "6px",
                                      }}
                                    >
                                      Our ref: /
                                    </Typography>
                                  )}
                                  <br></br>
                                  <br></br>
                                  {section.fields[3].field_content ? (
                                    <Typography
                                      style={{
                                        background: "#f5f5f5",
                                        display: "inline-block",
                                        margin: "5px 0",
                                        padding: "6px",
                                      }}
                                    >
                                      Home Office Unique Reference number:{" "}
                                      {section.fields[3].field_content}
                                    </Typography>
                                  ) : (
                                    <Typography
                                      style={{
                                        background: "#f5f5f5",
                                        display: "inline-block",
                                        margin: "5px 0",
                                        padding: "6px",
                                      }}
                                    >
                                      Home Office Unique Reference number: /
                                    </Typography>
                                  )}
                                  <br></br>
                                  <br></br>
                                  {section.fields[4].field_content ? (
                                    <Typography
                                      style={{
                                        background: "#f5f5f5",
                                        display: "inline-block",
                                        margin: "5px 0",
                                        padding: "6px",
                                      }}
                                    >
                                      Date: {section.fields[4].field_content}
                                    </Typography>
                                  ) : (
                                    <Typography
                                      style={{
                                        background: "#f5f5f5",
                                        display: "inline-block",
                                        margin: "5px 0",
                                        padding: "6px",
                                      }}
                                    >
                                      Date: /
                                    </Typography>
                                  )}
                                </Grid>
                              </Grid>
                              <br></br>
                              <br></br>
                              Dear Sir,
                              <br></br>
                              <br></br>
                              I,{" "}
                              {section.fields[0].field_content ? (
                                <Typography
                                  style={{
                                    background: "#f5f5f5",
                                    display: "inline-block",
                                    margin: "5px 0",
                                    padding: "6px",
                                  }}
                                >
                                  {section.fields[0].field_content}
                                </Typography>
                              ) : (
                                <Typography
                                  style={{
                                    background: "#f5f5f5",
                                    display: "inline-block",
                                    margin: "5px 0",
                                    padding: "6px",
                                  }}
                                >
                                  [ INSERT YOUR NAME ]
                                </Typography>
                              )}
                              hereby authorise{" "}
                              {section.fields[5].field_content ? (
                                <Typography
                                  style={{
                                    background: "#f5f5f5",
                                    display: "inline-block",
                                    margin: "5px 0",
                                    padding: "6px",
                                  }}
                                >
                                  {section.fields[5].field_content}
                                </Typography>
                              ) : (
                                <Typography
                                  style={{
                                    background: "#f5f5f5",
                                    display: "inline-block",
                                    margin: "5px 0",
                                    padding: "6px",
                                  }}
                                >
                                  [ INSERT OWNER NAME ]
                                </Typography>
                              )}
                              at{" "}
                              {section.fields[6].field_content ? (
                                <Typography
                                  style={{
                                    background: "#f5f5f5",
                                    display: "inline-block",
                                    margin: "5px 0",
                                    padding: "6px",
                                  }}
                                >
                                  {section.fields[6].field_content}
                                </Typography>
                              ) : (
                                <Typography
                                  style={{
                                    background: "#f5f5f5",
                                    display: "inline-block",
                                    margin: "5px 0",
                                    padding: "6px",
                                  }}
                                >
                                  [ INSERT COMPANY NAME ]
                                </Typography>
                              )}
                              for a renewal of Certificate of Sponsorship (COS)
                              on my behalf.This authorisation also allows{" "}
                              {section.fields[5].field_content ? (
                                <Typography
                                  style={{
                                    background: "#f5f5f5",
                                    display: "inline-block",
                                    margin: "5px 0",
                                    padding: "6px",
                                  }}
                                >
                                  {section.fields[5].field_content}
                                </Typography>
                              ) : (
                                <Typography
                                  style={{
                                    background: "#f5f5f5",
                                    display: "inline-block",
                                    margin: "5px 0",
                                    padding: "6px",
                                  }}
                                >
                                  [ INSERT OWNER NAME ]
                                </Typography>
                              )}{" "}
                              access to any other information you may hold in
                              relation to the nature of my stay in the{" "}
                              {section.fields[7].field_content ? (
                                <Typography
                                  style={{
                                    background: "#f5f5f5",
                                    display: "inline-block",
                                    margin: "5px 0",
                                    padding: "6px",
                                  }}
                                >
                                  {section.fields[7].field_content}.
                                </Typography>
                              ) : (
                                <Typography
                                  style={{
                                    background: "#f5f5f5",
                                    display: "inline-block",
                                    margin: "5px 0",
                                    padding: "6px",
                                  }}
                                >
                                  [ INSERT NAME OF PLACE].
                                </Typography>
                              )}{" "}
                              <br></br>I also confirm that{" "}
                              {section.fields[5].field_content ? (
                                <Typography
                                  style={{
                                    background: "#f5f5f5",
                                    display: "inline-block",
                                    margin: "5px 0",
                                    padding: "6px",
                                  }}
                                >
                                  {section.fields[5].field_content}
                                </Typography>
                              ) : (
                                <Typography
                                  style={{
                                    background: "#f5f5f5",
                                    display: "inline-block",
                                    margin: "5px 0",
                                    padding: "6px",
                                  }}
                                >
                                  [ INSERT OWNER NAME ]
                                </Typography>
                              )}{" "}
                              will act on my behalf until I cancel this
                              authorisation by a written confirmation.
                              <br></br>
                              <br></br>
                              {section.fields[4].field_content ? (
                                <Typography
                                  style={{
                                    background: "#f5f5f5",
                                    display: "inline-block",
                                    margin: "5px 0",
                                    padding: "6px",
                                  }}
                                >
                                  Date: {section.fields[4].field_content}
                                </Typography>
                              ) : (
                                <Typography
                                  style={{
                                    background: "#f5f5f5",
                                    display: "inline-block",
                                    margin: "5px 0",
                                    padding: "6px",
                                  }}
                                >
                                  Date: /
                                </Typography>
                              )}
                              <br></br>
                              <br></br>
                              <br></br>
                              Your sincerely,
                            </>
                          </p>
                          <br></br>
                        </Typography>
                      ))}
                  </>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default LetterOfAuthority;
