import React, { useState, useContext, useRef } from "react";
import { withSnackbar } from "notistack";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useForm } from "react-hook-form";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
} from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import {
  Typography,
  Button,
  makeStyles,
  AppBar,
  Dialog,
  DialogContent,
  DialogContentText,
  Box,
  Fab,
  Tooltip,
  TextareaAutosize,
  IconButton,
  TextField,
} from "@material-ui/core";

import axiosInstance from "../../../../../axios";
import { urls } from "../../../../../utils/urls";

import GroupModal from "../../modal/GroupModal";
import FieldModal from "../../modal/FieldModal";
import TemplateModal from "../../modal/TemplateModal";
import CustomRegularAccordion from "../../../../../components/CustomRegularAccordian";

const useStyles = makeStyles((theme) => ({
  left: {
    backgroundColor: "#fff",
    width: "25vw",
    boxShadow: "0 0 0.5cm #7b7d7d",
    height: "100%", // Set a fixed height
    overflowY: "auto", // Add a vertical scrollbar when content exceeds the height
    position: "fixed", // Make the sidebar fixed
    top: 120, // Adjust this based on your layout
    left: 0,
    paddingBottom: "100px", // Adjust this based on your layout
  },

  fab: {
    bottom: theme.spacing(2),
    right: theme.spacing(3),
    left: theme.spacing(15),
  },
  fab2: {
    top: theme.spacing(1),
    bottom: theme.spacing(2),
    right: theme.spacing(3),
    left: theme.spacing(14),
  },

  textarea: {
    width: "100%",
    padding: theme.spacing(2),
    fontSize: "16px",
    lineHeight: "1.6",
    borderRadius: "8px",
    border: "1px solid #ced4da",
    fontFamily: "Arial, sans-serif",
    transition: "border-color 0.2s",
    "&:hover": {
      borderColor: "#999",
    },
    "&:focus": {
      borderColor: theme.palette.primary.main,
      boxShadow: `0 0 0 0.2rem ${theme.palette.primary.light}`,
      outline: "none",
    },
  },
  formsSection: {
    margin: "15px",
  },
  hr: {
    width: "100%",
    border: 0,
    height: 0,
    borderTop: "1px solid rgba(0, 0, 0, 0.1)",
    borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
  },
  formStyle: {
    display: "flex",
    flexDirection: "column",
  },
}));

function GrievanceSidebar(props) {
  const classes = useStyles();
  const addDialog = useRef(null);
  const fieldAddDialog = useRef(null);
  const templateEditDialog = useRef(null);
  const { fieldContents } = props;

  const [fieldVal, setFieldVal] = useState({
    content: "",
  });
  // Use an object to store field content states dynamically
  const handleChangeField = async (event, fieldId) => {
    const fieldName = `field_content${fieldId}`;

    try {
      // Make a server request to update the content
      await axiosInstance.put(urls.updateTemplateField(fieldId), {
        field_content: event.target.value,
      });

      // Use a callback to ensure the correct update
      setFieldVal((prevFieldVal) => ({
        ...prevFieldVal,
        [fieldName]: event.target.value,
      }));

      // Display a success message or handle the response as needed
      props.enqueueSnackbar(`Field Content Updated Successfully`, {
        variant: "success",
        autoHideDuration: 5000,
      });
      props.templateUpdate();
    } catch (error) {
      // Handle errors, display error messages, etc.
      console.error("Error updating field content:", error.message);
      props.enqueueSnackbar(`Error updating field content`, {
        variant: "error",
        autoHideDuration: 5000,
      });
    }
  };

  // Helper function to capitalize the first letter of a string
  function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  // const handleFieldUpdate = (e) => {
  //   fieldAddDialog.current.handleEdit(e);
  // };

  // const { register, errors, handleEditField, setValue, reset } = useForm({
  //   defaultValues: useMemo(() => {
  //     return fieldVal;
  //   }, [setFieldVal]),
  // });

  const handleUpdate = (e) => {
    addDialog.current.handleEdit(e);
  };

  const handleSubmitField = async (
    e,
    edit,
    fieldId,
    sectionId,
    handleClose
  ) => {
    if (edit) {
      await axiosInstance
        .put(urls.updateTemplateField(fieldId), e)
        .then((res) => {
          props.enqueueSnackbar(`Field Updated Successfully`, {
            variant: "success",
            autoHideDuration: 5000,
          });
          handleClose();
          props.templateUpdate();
        });
    } else {
      await axiosInstance
        .post(urls.createTemplateField(sectionId), e)
        .then((res) => {
          props.enqueueSnackbar(`Field Created Successfully`, {
            variant: "success",
            autoHideDuration: 5000,
          });
          handleClose();
          props.templateUpdate();
        });
    }
  };

  const handleSubmitSection = async (
    e,
    edit,
    sectionId,
    templateId,
    handleClose
  ) => {
    if (edit) {
      await axiosInstance
        .put(urls.updateTemplateSection(sectionId), e)
        .then((res) => {
          props.enqueueSnackbar(`Section Updated Successfully`, {
            variant: "success",
            autoHideDuration: 5000,
          });
          handleClose();
          props.templateUpdate();
        });
    } else {
      await axiosInstance
        .post(urls.createTemplateSection(templateId), e)
        .then((res) => {
          props.enqueueSnackbar(`Section Created Successfully`, {
            variant: "success",
            autoHideDuration: 5000,
          });
          handleClose();
          props.templateUpdate();
        });
    }
  };

  const handleEditTemplate = async (e, edit, templateId, handleClose) => {
    if (edit) {
      await axiosInstance
        .put(urls.updateTemplate(templateId), e)
        .then((res) => {
          props.enqueueSnackbar(`Template Updated Successfully`, {
            variant: "success",
            autoHideDuration: 5000,
          });
          handleClose();
          props.templateUpdate();
        });
    }
  };

  const handleDeleteSection = async (sectionId) => {
    await axiosInstance
      .delete(urls.deleteTemplateSection(sectionId))
      .then((res) => {
        props.enqueueSnackbar(`Section Deleted Successfully`, {
          variant: "error",
          autoHideDuration: 5000,
        });

        props.templateUpdate();
      });
  };
  const handleDeleteField = async (fieldId) => {
    await axiosInstance
      .delete(urls.deleteTemplateField(fieldId))
      .then((res) => {
        props.enqueueSnackbar(`Field Deleted Successfully`, {
          variant: "error",
          autoHideDuration: 5000,
        });

        props.templateUpdate();
      });
  };

  const [formData, setFormData] = useState({
    user: "",
    template_name: props.template.id,
    employers_name: "",
    company_address_and_postcode: "",
    // date: "",
    name_of_employer_or_line_manager_or_hr_manager: "",
    summary_of_the_fact: "",
    how_has_impact_person: "",
    action_employer_to_take_situation: "",
    name: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    fetch(urls.postGrievanceLetter, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className={classes.left}>
      <Box
        style={{ marginTop: "10px" }}
        justifyContent="center"
        display="flex"
        textAlign="center"
      >
        <Typography
          style={{
            textAlign: "center",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
            color: "#ff5733",
            fontWeight: "bold",
            textTransform: "capitalize",
          }}
          variant="h5"
          component="h1"
        >
          {props.template.name}
        </Typography>
      </Box>

      <hr className={classes.hr} />
      <div className={classes.formsSection}>
        <>
          <CustomRegularAccordion
            title="Update Information"
            content={
              <>
                <TextField
                  fullWidth
                  label="Employers name"
                  name="employers_name"
                  onChange={handleChange}
                  value=""
                  variant="outlined"
                  style={{
                    marginTop: 20,
                  }}
                />
                <TextField
                  fullWidth
                  label="Company address and postcode"
                  name="company_address_and_postcode"
                  onChange={handleChange}
                  value=""
                  variant="outlined"
                  style={{
                    marginTop: 20,
                  }}
                />
                <TextField
                  fullWidth
                  label="Date"
                  name="date"
                  onChange={handleChange}
                  value=""
                  variant="outlined"
                  style={{
                    marginTop: 20,
                  }}
                  type="date"
                  InputProps={{
                    inputProps: { max: new Date().toISOString().split("T")[0] },
                  }}
                />
                <TextField
                  fullWidth
                  label="Name of employer/line manager/hr manager"
                  name="name_of_employer_or_line_manager_or_hr_manager"
                  onChange={handleChange}
                  value=""
                  variant="outlined"
                  style={{
                    marginTop: 20,
                  }}
                />
                <TextField
                  fullWidth
                  label="Summary of the fact"
                  name="summary_of_the_fact"
                  onChange={handleChange}
                  value=""
                  variant="outlined"
                  style={{
                    marginTop: 20,
                  }}
                />
                <TextField
                  fullWidth
                  label="How has impact person"
                  name="how_has_impact_person"
                  onChange={handleChange}
                  value=""
                  variant="outlined"
                  style={{
                    marginTop: 20,
                  }}
                />
                <TextField
                  fullWidth
                  label="Action employer to take situation"
                  name="action_employer_to_take_situation"
                  onChange={handleChange}
                  value=""
                  variant="outlined"
                  style={{
                    marginTop: 20,
                  }}
                />
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  onChange={handleChange}
                  value=""
                  variant="outlined"
                  style={{
                    marginTop: 20,
                  }}
                />

                {/* <TextareaAutosize
                                key={field.id}
                                id="outlined-basic"
                                fullWidth
                                label={field.name}
                                name={`field_content${field.id}`}
                                variant="outlined"
                                value={
                                  fieldContents &&
                                  fieldContents[`field_content${field.id}`]
                                    ? fieldContents[`field_content${field.id}`]
                                    : ""
                                }
                                maxRows={2}
                                className={classes.textarea}
                                onChange={(event) =>
                                  handleChangeField(event, field.id)
                                }
                              /> */}
              </>
            }
          ></CustomRegularAccordion>

          <br></br>
        </>
      </div>
    </div>
  );
}

export default withSnackbar(GrievanceSidebar);
