import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useContext,
  useEffect,
} from "react";
import { Link, useHistory } from "react-router-dom";
import AppContext from "../../../context/AppContext";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import {
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Container,
  Box,
  Card,
  CardContent,
  Typography,
  Tooltip,
  Fab,
  IconButton,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dialogCustomizedWidth: {
    width: "60%",
    height: "70%",
  },
  actionArea: {
    borderRadius: 16,
    transition: "0.2s",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  card: {
    MaxWidth: 200,
    background: "#f7f9fa",
    borderRadius: 16,
    boxShadow: "none",
    "&:hover": {
      boxShadow: `0 6px 12px 0 #fff
            .rotate(-12)
            .darken(0.2)
            .fade(0.5)}`,
      cursor: "pointer",
    },
  },
  content: {
    backgroundColor: "#fff",
  },
  // title: {
  //   fontSize: 24,
  //   color: "#002067",
  // },

  // new
  boxRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // minHeight: "35vh",
  },
  fab2: {
    margin: theme.spacing(1),
  },
  title: {
    textAlign: "center",

    marginTop: theme.spacing(1),
  },
  boxContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
}));

const NewDocumentDialog = forwardRef((props, ref) => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  //Context api
  const { templateStatus, changeTemplateStatus, setUserTemplates } = useContext(
    AppContext
  );

  const handleClickOpen = (e) => {
    setOpen(true);
  };
  const handleClose = (e) => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    handleClickOpen,
  }));

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        classes={{ paper: classes.dialogCustomizedWidth }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle style={{ textAlign: "center" }} id="form-dialog-title">
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h4"> Choose your document</Typography>
            <Tooltip title="close" aria-label="close">
              <Fab color="secondary" size="small" onClick={handleClose}>
                <CloseIcon />
              </Fab>
            </Tooltip>
          </Box>
        </DialogTitle>

        <DialogContent>
          <Grid style={{ marginTop: "10px" }} container spacing={5}>
            <Grid item lg={4} sm={6} xl={3} xs={12}>
              <Box className={classes.actionArea} style={{ borderRadius: 16 }}>
                <Card className={classes.card} style={{ borderRadius: 16 }}>
                  <CardContent className={classes.content}>
                    <Box className={classes.boxRoot}>
                      <Box className={classes.boxContainer} p={4}>
                        <Tooltip
                          title="Create New Docs"
                          aria-label="Create Docs"
                        >
                          <Fab
                            color="primary"
                            size="large"
                            className={classes.fab2}
                            onClick={() => {
                              changeTemplateStatus("Edit");
                              setUserTemplates(false);
                              history.push(`dashboard/documents#tab=Document`);
                            }}
                          >
                            <AddIcon />
                          </Fab>
                        </Tooltip>
                        {/* <Typography
                          style={{ color: "#002067", fontWeight: "bold" }}
                          className={classes.title}
                          variant="h6"
                        >
                          CREATE FROM TEMPLATE
                        </Typography> */}
                        <Typography className={classes.title} variant="h6">
                          Select from existing template
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid item lg={4} sm={6} xl={3} xs={12}>
              <Box className={classes.actionArea} style={{ borderRadius: 16 }}>
                <Card className={classes.card} style={{ borderRadius: 16 }}>
                  <CardContent className={classes.content}>
                    <Box className={classes.boxRoot}>
                      <Box className={classes.boxContainer} p={4}>
                        <Tooltip
                          title="Create New Docs"
                          aria-label="Create Docs"
                        >
                          <Fab
                            color="primary"
                            size="large"
                            className={classes.fab2}
                            onClick={() => {
                              changeTemplateStatus("Edit");
                              setUserTemplates(true);
                              history.push(
                                `dashboard/documents#tab=MyDocument`
                              );
                            }}
                          >
                            <AddIcon />
                          </Fab>
                        </Tooltip>
                        {/* <Typography
                          style={{ color: "#002067", fontWeight: "bold" }}
                          className={classes.title}
                          variant="h6"
                        >
                          MY TEMPLATES
                        </Typography> */}
                        <Typography className={classes.title} variant="h6">
                          Select from my templates
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid item lg={4} sm={6} xl={3} xs={12}>
              <Box className={classes.actionArea} style={{ borderRadius: 16 }}>
                <Card className={classes.card} style={{ borderRadius: 16 }}>
                  <CardContent className={classes.content}>
                    <Box className={classes.boxRoot}>
                      <Box className={classes.boxContainer} p={4}>
                        <Tooltip
                          title="Create New Docs"
                          aria-label="Create Docs"
                        >
                          <Fab
                            color="primary"
                            size="large"
                            className={classes.fab2}
                            onClick={() => {
                              changeTemplateStatus("Create");
                              history.push(
                                `dashboard/documents/create-scratch`
                              );
                            }}
                          >
                            <AddIcon />
                          </Fab>
                        </Tooltip>
                        {/* <Typography
                          style={{ color: "#002067", fontWeight: "bold" }}
                          className={classes.title}
                          variant="h6"
                        >
                          CREATE FROM SCRATCH
                        </Typography> */}
                        <Typography className={classes.title} variant="h6">
                          Create your own template from scratch!
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
});
export default NewDocumentDialog;
