import React, { useContext } from "react";
import AppContext from "../../../../../../../src/context/AppContext";

function CancelContractBreached({ template }) {
  const { signImgUrl, signText } = useContext(AppContext);
  return (
    <div style={{ padding: "5px" }} id="cancel-contract-breached">
      <div style={{ textAlign: "right" }}>
        {template.sections[0].fields[0].field_content ? (
          <p>{template.sections[0].fields[0].field_content}</p>
        ) : (
          <p>[Your Full Name]</p>
        )}
        {template.sections[0].fields[1].field_content ? (
          <p>{template.sections[0].fields[1].field_content}</p>
        ) : (
          <p>[Your Address]</p>
        )}
        {template.sections[0].fields[2].field_content ? (
          <p>{template.sections[0].fields[2].field_content}</p>
        ) : (
          <p>[Your Email]</p>
        )}
        {template.sections[0].fields[3].field_content ? (
          <p>{template.sections[0].fields[3].field_content}</p>
        ) : (
          <p>[Your Phone Number]</p>
        )}
      </div>
      <div>
        {template.sections[1].fields[0].field_content ? (
          <p>{template.sections[1].fields[0].field_content}</p>
        ) : (
          <p>[Trader's Name]</p>
        )}
        {template.sections[1].fields[1].field_content ? (
          <p>{template.sections[1].fields[1].field_content}</p>
        ) : (
          <p>[Trader's Address]</p>
        )}
      </div>
      <div style={{ textAlign: "right" }}>
        <p>{new Date().toDateString()}</p>
      </div>
      <div>
        {template.sections[1].fields[3].field_content ? (
          <p>Dear {template.sections[1].fields[3].field_content}</p>
        ) : (
          <p>To whom it may concern,</p>
        )}
      </div>
      <div>
        <p>
          I entered into an agreement with your company on{" "}
          {template.sections[2].fields[0].field_content ? (
            <span>{template.sections[2].fields[0].field_content}</span>
          ) : (
            <span>[When Did You Sign The Contract]</span>
          )}
          . At the time of purchase I was advised by your representative that
          the contract included:
        </p>
        <p>
          {template.sections[2].fields[1].field_content ? (
            // <span>{template.sections[2].fields[1].field_content}</span>
            <span>
              {template.sections[2].fields[1].field_content
                .split("\n")
                .map((item) => (
                  <span>
                    {item}
                    <br />
                  </span>
                ))}
            </span>
          ) : (
            <span>[What were the essential terms of the agreement?]</span>
          )}
        </p>
        <p>
          The above term(s) constitute an essential part of the contract and to
          date this has not been fulfilled. I am requesting your company to
          honour the contract within 14 days from the date of this letter.
          Otherwise I will have no option but to cancel the contract without
          incurring penalties.
        </p>
        <p>
          Therefore I would request a refund of costs incurred arising from your
          breach:{" "}
          {template.sections[2].fields[2].field_content ? (
            <span>{template.sections[2].fields[2].field_content}</span>
          ) : (
            <span>
              [If you've made any payments, which payments would you like
              refunded?]
            </span>
          )}
        </p>
        <p>
          I look forward to your response within the 14-day deadline in order to
          resolve this matter amicably.
        </p>
        <p>Yours faithfully,</p>
        <p>
          {template.sections[0].fields[0].field_content
            ? template.sections[0].fields[0].field_content
            : ""}
        </p>
        <p>
          {signImgUrl ? (
            <img alt="signature" src={signImgUrl} />
          ) : (
            <span>{signText}</span>
          )}
        </p>
      </div>
    </div>
  );
}

export default CancelContractBreached;
