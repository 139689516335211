import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useMemo,
  useEffect,
} from "react";
import { useForm } from "react-hook-form";
import queryString from "query-string";
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
  Grid,
  Card,
  Typography,
  CardHeader,
  Container,
  CardContent,
  CardActions,
} from "@material-ui/core";

import { urls } from "../../../../utils/urls";
import axiosInstance from "../../../../axios";
import SideMenu from "../../../../components/dashboard/SideMenu";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  dialogCustomizedWidth: {
    width: "50%",
    height: "40%",
  },
}));

const TemplateFirstStep = () => {
  const classes = useStyles();
  const history = useHistory();
  const [toolbar, SetToolbar] = useState(true);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [category, setCategory] = useState([]);

  useEffect(() => {
    // ReactGA.pageview(window.location.pathname + window.location.search);
    fetchCategory();
  }, []);

  const fetchCategory = async (search = null) => {
    await axiosInstance.get(urls.templateCategory).then((res) => {
      setCategory(res.data);
    });
  };

  const [val, setVal] = useState({
    name: "",
    category: "",
    subcategory: "",
  });
  const { register, errors, handleSubmit, setValue, reset } = useForm({
    defaultValues: useMemo(() => {
      return val;
    }, [setVal]),
  });
  const handleClickOpen = () => {
    // setOpen(true);
    setVal({
      name: "",
      category: "",
      subcategory: "",
    });
  };

  const handleSubmitCategory = async (e, edit, courseName) => {
    let response = null;
    // setLoading(true);
    // setUpdateLoading(true);

    await axiosInstance.post(urls.createTemplate, e).then((res) => {
      // setBtnLoading(false);
      // setLoading(false);
      // setLetter(res.data);
      console.log("ID : " + res.data.id);
      const templateId = res.data.id;
      history.push(`/dashboard/documents/my-template/${templateId}/`);
    });
    // if (response.status === 201) {
    //   handleClose();
    //   fetchCourseList();
    //   // setUpdateLoading(false);
    // } else {
    //   setUpdateLoading(false);

    // }
  };
  const onSubmit = (e) => {
    const payload = {
      ...e,
      ...val,
      user: `${userInfo.id}`,
    };
    handleSubmitCategory(payload);

    //   handleSubmitCategory(payload)
    //     .then((res) => {
    //       // Assuming the response contains the ID
    //       const templateId = res.data.id;

    //       // Redirect to a specific URL with the template ID
    //       // history.push(`/dashboard/documents/create-scratch/${templateId}/`);
    //     })
    //     .catch((error) => {
    //       console.error("Error submitting form:", error);
    //     });

    //   // history.push(`/dashboard/templates-list`);
  };
  const handleFieldChange = (event) => {
    const { name, value } = event.target;

    setVal({ ...val, [name]: value });
  };
  return (
    <SideMenu toolbar={toolbar}>
      <Container>
        <Grid
          style={{ marginTop: "100px" }}
          container
          alignItems="center"
          justifyContent="center"
        >
          <Grid item md={3}></Grid>
          <Grid item md={6}>
            <Grid
              container
              spacing={4}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item md={12}>
                <Card>
                  <CardHeader
                    subheader="Create any name for your own template!"
                    title="Template Name"
                    style={{ textAlign: "center" }}
                  />
                  <form onSubmit={handleSubmit((e) => onSubmit(e))}>
                    <CardContent>
                      <>
                        <Grid container spacing={3}>
                          <Grid item md={12} xs={12}>
                            <TextField
                              fullWidth
                              helperText="Please specify Template name"
                              label="name"
                              // margin="dense"

                              value={val.name}
                              onChange={handleFieldChange}
                              name="name"
                              required
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                        {/* <FormControl fullWidth>
                          <InputLabel>Category</InputLabel>
                          <Select
                            fullWidth
                            value={val.category}
                            onChange={handleFieldChange}
                            name="category"
                            // variant="outlined"
                            helperText="Please specify Template name"
                          >
                            {category &&
                              category.length > 0 &&
                              category.map((cat) => (
                                <MenuItem key={cat.id} value={cat.id}>
                                  {cat.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl> */}
                        {/* <br></br> */}
                        <br></br>
                        {/* <FormControl fullWidth>
                          <InputLabel>Sub Category</InputLabel>
                          <Select
                            fullWidth
                            value={val.subcategory}
                            onChange={handleFieldChange}
                            name="subcategory"
                          >
                            {category &&
                              category.length > 0 &&
                              category.map(
                                (cat) =>
                                  cat.sub_category &&
                                  cat.sub_category.length > 0 &&
                                  cat.sub_category.map((sub_cat) => (
                                    <MenuItem
                                      key={sub_cat.id}
                                      value={sub_cat.id}
                                    >
                                      {sub_cat.name}
                                    </MenuItem>
                                  ))
                              )}
                          </Select>
                        </FormControl> */}
                      </>
                    </CardContent>
                    <CardActions>
                      <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Create your own Template
                        {/* {updateLoading && (
            <CircularProgress style={{ marginLeft: '10px' }} size={15} />
          )} */}
                      </Button>
                    </CardActions>
                  </form>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={3}></Grid>
        </Grid>
      </Container>
    </SideMenu>
  );
};

export default TemplateFirstStep;
