import React from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Button,
  IconButton,
  Typography,
  Menu,
  Container,
  Stack,
  Link,
  MenuItem,
} from "@mui/material";
import { useHistory, NavLink } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../assets1/logo.jpg";
import docelerateLogo from "../assets1/Docelerate.svg";

const pages = ["Products", "Pricing", "Blog", "Testimonial"];

// Appbar component from mui..................................
function Navbar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const history = useHistory();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static" className="appbar">
      <Container maxWidth="xl" className="appbar-container">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontWeight: 700,
              fontSize: "20px",
              color: "inherit",
              marginRight: "20%",
              textDecoration: "none",
            }}
          >
            <NavLink to="/">
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{
                    objectFit: "cover",
                    width: "100px",
                    display: "block",
                    marginTop: "0px",
                    marginLeft: "3px",
                  }}
                  alt="logo"
                  src={docelerateLogo}
                />

                <p style={{ marginBottom: "0px", color: "#EE8F7B" }}>
                  Docelerate
                </p>
              </div>
            </NavLink>
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <Stack
                mx="10px"
                spacing={2}
                sx={{ display: { sm: "flex", md: "none" } }}
              >
                <Button variant="outlined">Sign In</Button>
                <Button
                  onClick={() => history.push("/login")}
                  variant="contained"
                >
                  Start Free
                </Button>
              </Stack>

              
              <MenuItem
                onClick={() => {
                  handleCloseNavMenu();
                }}
              >
                <NavLink
                  to="/pricing"
                  className="nav-link"
                  color="inherit"
                  textAlign="center"
                >
                  PRICING
                </NavLink>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <NavLink
                  to="/contactus"
                  className="nav-link"
                  color="inherit"
                  textAlign="center"
                >
                  FAQS
                </NavLink>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <NavLink
                  to="/testimonials"
                  className="nav-link"
                  color="inherit"
                  textAlign="center"
                >
                  TESTIMONIALS
                </NavLink>
              </MenuItem>
            </Menu>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontSize: "25px",
              fontWeight: 800,
              textDecoration: "none",
            }}
          >
            <NavLink to="/">Docelerate</NavLink>
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex", gap: "25px" },
            }}
          >
            {/* {pages.map((page) => (
              <Link className="nav-link" href="#" color="inherit">
                {page}
              </Link>
            ))} */}

            
            <NavLink className="nav-link" to="/pricing" color="inherit">
              PRICING
            </NavLink>
            <NavLink
              onClick={() => {
                const faqsSection = document.getElementById("faqs");
                try {
                  faqsSection?.scrollIntoView({ behavior: "smooth" });
                } catch (err) {
                  console.log(err);
                }
              }}
              className="nav-link"
              to="#"
              color="inherit"
            >
              FAQS
            </NavLink>
            <NavLink
              onClick={() => {
                const targetSection = document.getElementById("testimonials");
                targetSection?.scrollIntoView({ behavior: "smooth" });
              }}
              className="nav-link"
              to="#"
              color="inherit"
            >
              TESTIMONIALS
            </NavLink>
          </Box>
          <Stack
            direction="row"
            spacing={2}
            sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
          >
            <Button
              onClick={() => history.push("/login")}
              variant="outlined"
              sx={{ color: "#000000", borderColor: "#000" }}
            >
              Login
            </Button>
            <Button
              variant="contained"
              sx={{
                boxShadow: "none",
                backgroundColor: " #EE8F7B",
                color: "#fff",
              }}
              onClick={() => history.push("/signup")}
            >
              Start Free
            </Button>
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;
