import React, { useState, useEffect } from "react";
import ReactGA from "react-ga";
import axios from "axios";
import Skeleton from "@material-ui/lab/Skeleton";
import emtyCart from "../emty.png";
import { withSnackbar } from "notistack";

import {
  Grid,
  Typography,
  withStyles,
  Paper,
  Breadcrumbs,
  Divider,
  Button,
  TextField,
  Container,
  ButtonBase,
  Box,
} from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";

import { Link, useHistory, useParams } from "react-router-dom";
import StripeElementWrapper from "./StripeElementWrapper";
import { urls } from "../../../utils/urls";

const styles = (theme) => ({
  paper1: {
    padding: theme.spacing(1),
    margin: "auto",
    background: "#f7f9fa",
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 700,
  },

  newcardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  newcardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },

  link: {
    display: "flex",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
});

const CheckoutPage = (props) => {
  const { classes } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [cart, setCart] = useState([]);

  const [error, setError] = useState(null);
  const [msg, setMsg] = useState(null);
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    document.title = "Cart / Notting Hill Law";
    getCart();
  }, [dispatch, history]);

  const handleChange = (event) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  };
  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      Authorization: `Bearer ${userInfo && userInfo.access}`,
    },
  });

  const submit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (stripe) {
      stripe
        .createToken(elements.getElement(CardNumberElement))
        .then((result) => {
          if (result.error) {
            setError(result.error.message);
            setLoading(false);
          } else {
            setError(null);

            authAxios
              .post(urls.checkoutUrl, {
                stripeToken: result.token.id,
              })
              .then((res) => {
                setLoading(false);

                props.enqueueSnackbar(`Your order have done successfully!`, {
                  variant: "success",
                  autoHideDuration: 5000,
                });
                history.push(`/order-success`);
              })
              .catch((err) => {
                setLoading(false);
                setError(err);
                props.enqueueSnackbar(
                  `Cannot charge a customer that has no active card`,
                  {
                    variant: "warning",
                    autoHideDuration: 5000,
                  }
                );
              });
          }
        });
    } else {
      console.log("Stripe is not loaded");
    }
  };

  const getCart = () => {
    authAxios.get(urls.getCartUrl).then((res) => {
      setCart(res.data);
    });
  };

  return (
    <Container>
      <Breadcrumbs style={{ marginBottom: "20px" }} aria-label="breadcrumb">
        <Link color="inherit" to="/" className={classes.link}>
          <HomeIcon className={classes.icon} />
          HOME
        </Link>
        <Link color="inherit" to="/v2/pricing" className={classes.link}>
          BOOKS
        </Link>
        <Link color="inherit" to="/v2/pricing/cart" className={classes.link}>
          CART
        </Link>
        <Typography color="textPrimary" className={classes.link}>
          CHECKOUT
        </Typography>
      </Breadcrumbs>
      <Grid container spacing={4} justifyContent="center">
        <Grid style={{ marginBottom: "50px" }} item md={7} xs={12}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              Checkout
            </Typography>
            <br />
            <Typography variant="h6" gutterBottom>
              Payment Information
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  id="cardName"
                  label="Name on card"
                  fullWidth
                  autoComplete="cc-name"
                  style={{ marginTop: "15px" }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <StripeElementWrapper component={CardNumberElement} />
              </Grid>
              <Grid item xs={12} md={6}>
                <StripeElementWrapper component={CardExpiryElement} />
              </Grid>
              <Grid item xs={12} md={6}>
                <StripeElementWrapper
                  onFocus={(e) => (e.target.placeholder = "")}
                  component={CardCvcElement}
                />
              </Grid>
            </Grid>
          </Paper>
          <Button
            style={{ marginTop: "15px" }}
            fullWidth
            size="large"
            variant="contained"
            color="primary"
            onClick={submit}
          >
            Place Order
          </Button>
        </Grid>
        <Grid item md={5} xs={12}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              Order Summary
            </Typography>

            <>
              {cart.guides ? (
                cart.guides.map((v, i) => (
                  <>
                    <Paper
                      style={{ background: "#f7f9fa" }}
                      className={classes.paper}
                    >
                      <Grid container spacing={2}>
                        <Grid item>
                          <ButtonBase className={classes.image}>
                            <img
                              className={classes.img}
                              alt="no picture"
                              src={`http://127.0.0.1:8000${v.get_image}`}
                            />
                          </ButtonBase>
                        </Grid>

                        <Grid item xs={12} sm container>
                          <Grid
                            item
                            xs
                            container
                            direction="column"
                            spacing={2}
                          >
                            <Grid item xs>
                              <Typography gutterBottom variant="subtitle1">
                                {v.guide}
                              </Typography>
                              <Typography variant="body2" gutterBottom>
                                {v.get_heading}
                              </Typography>
                              <Typography
                                variant="subtitle1"
                                color="textSecondary"
                              >
                                £ {v.get_final_price}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                    <br></br>
                  </>
                ))
              ) : (
                <>
                  {/* // Array.from(Array(3).keys()).map((i) => ( //{" "}
                  <Box padding={3} key={i}>
                    // <Skeleton variant="rect" width={250} height={200} />
                    // <Skeleton animation="wave" />
                    //{" "}
                  </Box>
                  // )) */}

                  <Box
                    display="flex"
                    height="100%"
                    marginTop="1vh"
                    alignItems="center"
                    flexDirection="column"
                    justifyContent="center"
                  >
                    <img
                      style={{
                        maxWidth: "150px",
                      }}
                      src={emtyCart}
                    ></img>
                    <Typography variant="h4">Your Cart is Emty</Typography>
                  </Box>
                </>
              )}

              <br />
            </>

            <br />

            <Divider />
            <Grid container spacing={2}>
              <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>
                    <Typography gutterBottom variant="h6">
                      Total
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography style={{ fontWeight: "bold" }} variant="body1">
                    £ {cart.get_total}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <hr />
    </Container>
  );
};
export default withSnackbar(withStyles(styles)(CheckoutPage));
