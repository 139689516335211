import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useMemo,
  useEffect,
} from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { withSnackbar } from "notistack";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { urls } from "../../../../../utils/urls";
import axiosInstance from "../../../../../axios";

const GroupModal = forwardRef((props, ref) => {
  const { templateId, handleSubmitSection } = props;
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [sectionId, setSectionId] = useState(null);
  const [val, setVal] = useState({
    section_name: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setVal({ ...val, [name]: value });
  };

  const handleClickOpen = () => {
    setOpen(true);
    setVal({
      section_name: "",
    });
    reset({
      section_name: "",
    });
  };
  const handleClose = (e) => {
    setOpen(false);
    setEdit(false);
  };

  const { register, errors, handleSubmit, setValue, reset } = useForm({
    defaultValues: useMemo(() => {
      return val;
    }, [setVal]),
  });

  const handleEdit = (e) => {
    setEdit(true);
    setSectionId(e.id);
    setOpen(true);
    setVal({
      section_name: e.section_name,
    });
    reset({
      section_name: e.section_name,
    });
  };
  useImperativeHandle(ref, () => ({
    handleClickOpen,
    handleEdit,
  }));

  // const handleSubmitData = async (e, edit, templateId, sectionId) => {
  //   if (edit) {
  //     await axiosInstance
  //       .put(e, urls.updateTemplateSection(templateId))
  //       .then((res) => {
  //         props.enqueueSnackbar(`Section Updated Successfully`, {
  //           variant: "success",
  //           autoHideDuration: 5000,
  //         });
  //         handleClose();
  //         props.templateUpdate();
  //       });
  //   } else {
  //     await axiosInstance
  //       .post(e, urls.createTemplateSection(sectionId))
  //       .then((res) => {
  //         props.enqueueSnackbar(`Section Created Successfully`, {
  //           variant: "success",
  //           autoHideDuration: 5000,
  //         });
  //         handleClose();
  //         props.templateUpdate();
  //       });
  //   }
  // };

  const onSubmit = (e, edit, sectionId, templateId, handleClose) => {
    const payload = {
      ...e,
      ...val,
    };
    handleSubmitSection(payload, edit, sectionId, templateId, handleClose);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {edit ? `Edit Group` : `Add Group`}
        </DialogTitle>

        <form
          onSubmit={
            handleSubmit((e) =>
              onSubmit(e, edit, sectionId, templateId, handleClose)
            )
            // handleClose(e);
          }
        >
          <DialogContent>
            <>
              <TextField
                // id="outlined-basic"
                // fullWidth
                // margin="dense"
                label="Group"
                name="section_name"
                onChange={handleChange}
                value={val.section_name}
                variant="outlined"
                // defaultValue={content.professional.company1}
                // inputRef={register}

                style={{ marginTop: 12, marginLeft: 8, marginRight: 8 }}
              />
            </>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button variant="contained" type="submit" color="primary">
              {/* {edit ? 'Update' : 'Add'} */}

              {edit ? "Update" : "Create"}
              {/* {updateLoading && (
                <CircularProgress style={{ marginLeft: '10px' }} size={15} />
              )} */}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
});
export default withSnackbar(GroupModal);
