import React, { forwardRef, useState, useImperativeHandle } from "react";
import { Link, useHistory } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
} from "@material-ui/core";
import cv1 from "./cv1.jpg";

const TemplateModal = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (e) => {
    setOpen(false);
  };
  useImperativeHandle(ref, () => ({
    handleClickOpen,
    // handleEdit,
  }));
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Professional CV</DialogTitle>

        <DialogContent>
          <>
            <Card>
              <CardActionArea>
                {/* <CardMedia height="240" image={cv1} title={`hello`} /> */}
                <img
                  style={{ height: "400px", width: "350px" }}
                  src={props.tempImage}
                ></img>

                {/* <CardContent>
                  <Typography gutterBottom variant="h6" component="h4">
                    Professional CV
                  </Typography>
                </CardContent> */}
              </CardActionArea>
            </Card>
          </>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} color="primary">
            Close<CloseIcon></CloseIcon>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default TemplateModal;
