import React, { useContext, useState, useEffect } from "react";
import Divider from "@mui/material/Divider";
import { Box, Typography, Input, makeStyles } from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ModalEditField from "./ModalEditField";
import { fabric } from "fabric";
import AppContext from "../../../../../../context/AppContext";

const useStyles = makeStyles((theme) => ({
  previewBtn: {
    backgroundColor: "#3f94ed",
    color: "#fff",
  },
}));

function GroupField(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [fieldName, setFieldName] = useState("");

  //Context api
  const { handleInputChangeNew, handleAddInput } = useContext(AppContext);

  useEffect(() => {
    if (props.mode === "Create") {
      handleAddInput(props.id, props.canvas);
    }
  }, []);

  return (
    <>
      <ModalEditField
        open={open}
        handleClose={() => setOpen(false)}
        fieldName={props.fieldName}
        fieldNameEdit={props.fieldNameEdit}
        setFieldNameEdit={props.setFieldNameEdit}
        classes={classes}
      />
      <Divider />
      <Box mt={3}>
        <Box
          data-value={props.value}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography>{props.fieldName}</Typography>
          <Box>
            <Tooltip title={"Edit"}>
              <EditIcon
                onClick={() => {
                  props.setFieldNameEdit(props.fieldName);
                  setOpen(true);
                }}
                sx={{ color: "#4494ea" }}
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
            <Tooltip title={"Delete"}>
              <DeleteIcon
                onClick={() =>
                  props.removeChildFromParent &&
                  props.removeChildFromParent(props.parentId, props.id)
                }
                sx={{ color: "#d83864" }}
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Box mt={3}>
        {/* <textarea
            placeholder="Enter value here"
            style={{ width: "100%", padding: "5px" }}
          >
            {props.value}
          </textarea> */}
        {props.fieldType === "CharField" || props.fieldType === "text" ? (
          <input
            id={props.id}
            type="text"
            placeholder="Enter value here"
            style={{ width: "100%", padding: "5px" }}
            onChange={(e) => {
              if (props.mode === "Create") {
                props.onChange(props.parentId, props.id, e.target.value);
                handleInputChangeNew(props.id, e.target.value, props.canvas);
              } else {
                props.onChange2(props.section.id, props.qId, e.target.value);
              }
            }}
            value={props.value}
          />
        ) : props.fieldType === "TextField" ||
          props.fieldType === "textarea" ? (
          <textarea
            id={props.id}
            placeholder="Enter value here"
            style={{ width: "100%", padding: "5px" }}
            onChange={(e) => {
              if (props.mode === "Create") {
                props.onChange(props.parentId, props.id, e.target.value);
                handleInputChangeNew(props.id, e.target.value, props.canvas);
              } else {
                props.onChange2(props.section.id, props.qId, e.target.value);
              }
            }}
            value={props.value}
          />
        ) : props.fieldType === "IntegerField" ||
          props.fieldType === "number" ? (
          <Input
            type="number"
            onChange={(e) => {
              if (props.mode === "Create") {
                props.onChange(props.parentId, props.id, e.target.value);
                handleInputChangeNew(props.id, e.target.value, props.canvas);
              } else {
                props.onChange2(props.section.id, props.qId, e.target.value);
              }
            }}
            value={props.value}
          />
        ) : props.fieldType === "DateField" || props.fieldType === "date" ? (
          <Input
            type="date"
            onChange={(e) => {
              if (props.mode === "Create") {
                props.onChange(props.parentId, props.id, e.target.value);
                handleInputChangeNew(props.id, e.target.value, props.canvas);
              } else {
                props.onChange2(props.section.id, props.qId, e.target.value);
              }
            }}
            value={props.value}
          />
        ) : props.fieldType === "telephone" ? (
          <Input
            type="tel"
            onChange={(e) => {
              if (props.mode === "Create") {
                props.onChange(props.parentId, props.id, e.target.value);
                handleInputChangeNew(props.id, e.target.value, props.canvas);
              } else {
                props.onChange2(props.section.id, props.qId, e.target.value);
              }
            }}
            value={props.value}
          />
        ) : props.fieldType === "mcq" ? (
          <select></select>
        ) : null}
      </Box>
    </>
  );
}

export default GroupField;
