import { useState, useContext, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import { fabric } from "fabric";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useDrop, useDrag } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import html2pdf from "html2pdf.js";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { green, pink } from "@material-ui/core/colors";
import {
  Typography,
  Paper,
  Grid,
  makeStyles,
  Divider,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Link,
  Tooltip,
  CardMedia,
  Card,
  Container,
  Button,
  Fab,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  IconButton,
} from "@material-ui/core";

import { DragHandle, Toys } from "@material-ui/icons";

import FormatBoldIcon from "@material-ui/icons/FormatBold";
import FormatColorTextIcon from "@material-ui/icons/FormatColorText";
import FormatItalicIcon from "@material-ui/icons/FormatItalic";
import FormatUnderlinedIcon from "@material-ui/icons/FormatUnderlined";
import FormatAlignCenterIcon from "@material-ui/icons/FormatAlignCenter";
import FormatAlignJustifyIcon from "@material-ui/icons/FormatAlignJustify";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import FormatAlignRightIcon from "@material-ui/icons/FormatAlignRight";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import PhoneIcon from "@material-ui/icons/Phone";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import EmailIcon from "@material-ui/icons/Email";
import LocationOnIcon from "@material-ui/icons/LocationOn";
//import profileImg from "./profile.jpg";
import profileImg from "../../../../document/createScratchDocument/profile.jpg";
import PaperContent from "./PaperContent";
import BasicSelect from "./Select";
import LegalDocumentTemplate from "./LegalDocumentTemplate";
import TenancyAgreementTemplate from "./TenancyAgreementTemplate";
import GrievanceLetter from "../../mainContent/component/GrievanceLetter";
import DelayToAService from "./DelayToAService";
import PoorStandardOfService from "./PoorStandardOfService";
import CancelContractBreached from "./CancelContractBreached";
import AppContext from "../../../../../../context/AppContext";
import PreviewModal from "./PreviewModal";

const useStyles = makeStyles((theme) => ({
  widgetContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px",
    backgroundColor: "#fff",
    margin: "0 auto",
    marginTop: "20px",
    width: "85%",
    gap: "40px",
    position: "sticky",
    top: 0,
    border: "1px solid #696969",
    borderRadius: "10px",
    zIndex: "2000",
  },

  widgetsLeft: {
    display: "flex",
    alignItems: "center",
    gap: "30px",
    flex: "50%",
  },

  widgetsRight: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    gap: "4px",
    flex: "50%",
    flexWrap: "nowrap",
    overflowX: "auto",
  },

  txtStyleSelect: {
    width: "100px",
    border: "1px solid #bdbdbd",
  },

  selectTxt: {
    fontSize: "12px",
  },

  widgetIcons: {
    backgroundColor: "#e1e1e1",
    padding: "7px 10px 7px 10px",
    borderRadius: "50%",
    cursor: "pointer",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    color: "black",
  },

  canvasContainer: {
    backgroundColor: "#fff",
    margin: "0 auto",
    marginTop: "10px",
    minWidth: "80%",
    // maxWidth: "85%",
    minHeight: "80vh",
    // maxHeight: "80vh",

    "&::-webkit-scrollbar": {
      display: "none",
    },
  },

  canvas: {
    width: "100%",
    height: "100%",

    // maxHeight: "80vh",
    // overflowY: "auto",
  },
}));

function TextEditor(props) {
  const classes = useStyles();
  const modalRef = useRef(null);
  const canvasMRef = useRef(null);
  const canvasCloneRef = useRef(null);
  const modalRefCanvas = useRef(null);
  const {
    showPaperContent,
    scaleDown,
    setScaleDown,
    targetTemplate,
    setTargetEle,
    showCanvas,
    setShowCanvas,
    openPreviewModalCanvas,
    setOpenPreviewModalCanvas,
    openPreviewModal,
    setOpenPreviewModal,
  } = useContext(AppContext);

  const [txtStyle, setTxtStyle] = useState("");

  //state for toolbar icons
  const [isUpperCase, setIsUpperCase] = useState(false);
  const [originalText, setOriginalText] = useState("");
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isAlignLeft, setIsAlignLeft] = useState(false);
  const [isAlignRight, setIsAlignRight] = useState(false);
  const [isAlignCenter, setIsAlignCenter] = useState(false);

  const [imgSrc, setImgSrc] = useState("");

  //For toolbar icons
  useEffect(() => {
    handleToolBar(props.canvas, {
      fontWeight: isBold ? "bold" : "normal",
    });
  }, [isBold]);

  useEffect(() => {
    isUpperCase && setOriginalText(props.canvas?.getActiveObject()?.text);
    handleToolBar(props.canvas, {
      text: isUpperCase
        ? props.canvas?.getActiveObject()?.text?.toUpperCase()
        : originalText,
    });
  }, [isUpperCase]);

  useEffect(() => {
    handleToolBar(props.canvas, {
      fontStyle: isItalic ? "italic" : "normal",
    });
  }, [isItalic]);

  useEffect(() => {
    handleToolBar(props.canvas, { underline: isUnderline ? true : false });
  }, [isUnderline]);

  useEffect(() => {
    handleToolBar(props.canvas, {
      textAlign: isAlignLeft ? "left" : "center",
    });
  }, [isAlignLeft]);

  useEffect(() => {
    handleToolBar(props.canvas, {
      textAlign: isAlignRight ? "right" : "center",
    });
  }, [isAlignRight]);

  useEffect(() => {
    handleToolBar(props.canvas, {
      textAlign: isAlignCenter ? "center" : "center",
    });
  }, [isAlignCenter]);

  useEffect(() => {
    props.canvas?.on("object:added", () => {
      props.canvas.renderAll();
      //setTimeout(() => convertToHTML(props.canvas), 0);
      convertToHTML(props.canvas);
    });
    props.canvas?.on("mouse: down", () => {
      convertToHTML(props.canvas);
    });
  }, [props.canvas]);

  

  const handleChange = (event) => {
    setTxtStyle(event.target.value);
  };

  const handleToolBar = (canvas, objStyle) => {
    var activeObj = canvas?.getActiveObject();

    // props?.setValue(event.target.value);

    if (activeObj?.type === "i-text") {
      activeObj?.set(objStyle);
      // props?.setValue(event.target.value);
      canvas?.renderAll();
    }
  };

  const preview = () => {
    setScaleDown(true);
    setOpenPreviewModal(true);
    setTargetEle(
      targetTemplate.name === "Non Disclosure Agreement" ? (
        <LegalDocumentTemplate id={"nda"} />
      ) : showPaperContent && targetTemplate.name === "Tenancy Agreement" ? (
        <TenancyAgreementTemplate id={"tenancy-agreement"} />
      ) : showPaperContent &&
        targetTemplate.name === "Complaint About Faulty Goods" ? (
        <PaperContent />
      ) : showPaperContent && targetTemplate.name === "Grievance Letter" ? (
        <GrievanceLetter template={targetTemplate} onDragEnd={false} />
      ) : showPaperContent &&
        targetTemplate.name ===
          "Letter to cancel a letter that's been breached" ? (
        <CancelContractBreached template={targetTemplate} />
      ) : null
    );
  };

  const convertToHTML = (canvas) => {
    var htmlOutput = document.getElementById("htmlOutput");
    htmlOutput.style.position = "relative";

    htmlOutput.innerHTML = "";
    htmlOutput.style.backgroundColor = canvas.backgroundColor;
    canvas.getObjects().forEach((obj) => {
      if (obj.type === "i-text") {
        var span = document.createElement("span");
        span.innerText = obj.text;

        span.style.transform = "rotate(" + props.rotationAngle + "deg)";

        span.style.position = "absolute";
        span.style.display = "inline-block";
        span.style.fontSize = obj.fontSize + "px";
        span.style.color = obj.fill;
        span.style.fontWeight = obj.fontWeight;
        span.style.fontStyle = obj.fontStyle;
        span.style.fontFamily = obj.fontFamily;
        span.style.textDecoration = obj.underline ? "underline" : "none";
        span.style.left = obj.left + "px";
        // span.style.right = obj.left + "px";
        // span.style.bottom = obj.bottom + "px";
        span.style.top = obj.top + "px";
        htmlOutput.appendChild(span);
      } else if (obj.type === "image") {
        var img = document.createElement("img");
        img.style.position = "absolute";
        img.src = obj.getSrc();
        img.style.width = obj.width * obj.scaleX + "px";
        img.style.height = obj.height * obj.scaleY + "px";
        img.style.left = obj.left + "px";
        img.style.right = obj.right + "px";
        img.style.bottom = obj.bottom + "px";
        img.style.top = obj.top + "px";
        htmlOutput.appendChild(img);
      }
    });
  };

  return (
    <>
      <div id="editor">
        <PreviewModal
          id={"previewModal"}
          fromCanvas={false}
          modalContent={
            targetTemplate.name === "Non Disclosure Agreement" ? (
              <LegalDocumentTemplate id={"nda"} scaleDown={scaleDown} />
            ) : targetTemplate.name === "Complaint About Faulty Goods" ? (
              <PaperContent id={"faulty-goods"} scaleDown={scaleDown} />
            ) : targetTemplate.name === "Grievance Letter" ? (
              <GrievanceLetter
                scaleDown={scaleDown}
                template={targetTemplate}
                id={"pdf-content"}
              />
            ) : targetTemplate.name === "Tenancy Agreement" ? (
              <TenancyAgreementTemplate
                id={"tenancy-agreement"}
                scaleDown={scaleDown}
              />
            ) : targetTemplate.name ===
              "Letter to complain about a delay to a service" ? (
              <DelayToAService
                template={targetTemplate}
                id="delay-to-service"
                scaleDown={scaleDown}
              />
            ) : targetTemplate.name ===
              "Letter to complain about the poor standard of a service" ? (
              <PoorStandardOfService
                template={targetTemplate}
                id="poor-standard-of-service"
                scaleDown={scaleDown}
              />
            ) : targetTemplate.name ===
              "Letter to cancel a contract that's been breached" ? (
              <CancelContractBreached
                template={targetTemplate}
                id="cancel-contract-breached"
                scaleDown={scaleDown}
              />
            ) : null
          }
          openPreviewModal={openPreviewModal}
          handleClosePreviewModal={() => setOpenPreviewModal(false)}
          innerBox={"innerBox"}
          modalRef={modalRef}
          setShowBackBtn={props.setShowBackBtn}
          showBackBtn={props.showBackBtn}
        />

        <PreviewModal
          fromCanvas={true}
          id={"previewModal"}
          targetId={"canvas"}
          openPreviewModal={openPreviewModalCanvas}
          handleClosePreviewModal={() => setOpenPreviewModalCanvas(false)}
          modalContent={props.modalContent}
          innerBox={"innerBox"}
          modalRef={modalRefCanvas}
          modalStyles={props.modalStyles}
          setShowBackBtn={props.setShowBackBtn}
          showBackBtn={props.showBackBtn}
        />
        <div>
          <Box className={classes.widgetContainer}>
            <Box className={classes.widgetsLeft}>
              <Box sx={{ minWidth: 120 }}>
                <BasicSelect
                  flex={"40%"}
                  width={"70px"}
                  label={"Font Family"}
                  value={props?.widgetTextSize}
                  values={["Roboto", "Reddit Serif", "Rouge Script"]}
                  setValue={props?.setWidgetTextSize}
                  canvas={props?.canvas}
                />
              </Box>

              <Box sx={{ minWidth: 120 }}>
                <BasicSelect
                  flex={"40%"}
                  width={"70px"}
                  label={"Font Size"}
                  value={props?.widgetTextSize}
                  values={[...Array(400).keys()].map((i) => i + 1)}
                  setValue={props?.setWidgetTextSize}
                  canvas={props?.canvas}
                />
              </Box>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                style={{
                  padding: "10px",
                  backgroundColor: "#39b055",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  marginTop: "0px",
                  color: "#fff",
                  cursor: "pointer",
                  display: showPaperContent ? "block" : "none",
                }}
                onClick={() => preview()}
              >
                Preview
              </Box>
            </Box>
            <Box className={classes.widgetsRight}>
              <Box
                onClick={() => {
                  props.clearUpperCase &&
                    props.canvas?.getActiveObject()?.text?.trim()?.length >=
                      1 &&
                    setIsUpperCase((prev) => !prev);
                }}
                className={classes.widgetIcons}
              >
                <FormatColorTextIcon style={{ color: isUpperCase && "blue" }} />
              </Box>
              <Box
                onClick={() => {
                  setIsBold((prev) => !prev);
                }}
                className={classes.widgetIcons}
              >
                <FormatBoldIcon style={{ color: isBold && "blue" }} />
              </Box>
              <Box
                onClick={() => setIsItalic((prev) => !prev)}
                className={classes.widgetIcons}
              >
                <FormatItalicIcon style={{ color: isItalic && "blue" }} />
              </Box>
              <Box
                onClick={() => setIsUnderline((prev) => !prev)}
                className={classes.widgetIcons}
              >
                <FormatUnderlinedIcon
                  style={{ color: isUnderline && "blue" }}
                />
              </Box>
              <Box
                onClick={() => {
                  setIsAlignLeft((prev) => !prev);
                }}
                className={classes.widgetIcons}
              >
                <FormatAlignLeftIcon style={{ color: isAlignLeft && "blue" }} />
              </Box>
              <Box
                onClick={() => setIsAlignCenter((prev) => !prev)}
                className={classes.widgetIcons}
              >
                <FormatAlignJustifyIcon
                  style={{ color: isAlignCenter && "blue" }}
                />
              </Box>
              <Box
                onClick={() => setIsAlignRight((prev) => !prev)}
                className={classes.widgetIcons}
              >
                <FormatAlignRightIcon
                  style={{ color: isAlignRight && "blue" }}
                />
              </Box>
            </Box>
          </Box>

          <Box className={classes.canvasContainer}>
            <Box
              style={{ padding: "10px" }}
              ref={props.canvasRef}
              className={classes.canvas}
            >
              {/* {props.groupList.map((group) => (
                <Box key={group.id}>
                  <Typography component="h2" variant="h3">
                    {group.groupName}
                  </Typography>
                  <ul>
                    {group.children.map((child) => (
                      <>
                        <li key={child.id}>
                          <span style={{ fontWeight: 600, fontSize: "22px" }}>
                            {child.props.fieldName}:
                          </span>
                          <Typography>{child.props.value}</Typography>
                        </li>
                        <br />
                      </>
                    ))}
                  </ul>
                </Box>
              ))} */}

              <center
                id="canvasContainer"
                style={{ display: showPaperContent ? "none" : "block" }}
              >
                <canvas
                  id="canvas"
                  ref={canvasMRef}
                  style={{
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                ></canvas>
              </center>
              <div
                style={{ width: "100%", display: "none" }}
                id="htmlOutput"
              ></div>

              {showPaperContent &&
              targetTemplate.name === "Non Disclosure Agreement" ? (
                <LegalDocumentTemplate id={"nda"} />
              ) : showPaperContent &&
                targetTemplate.name === "Tenancy Agreement" ? (
                <TenancyAgreementTemplate id={"tenancy-agreement"} />
              ) : showPaperContent &&
                targetTemplate.name === "Complaint About Faulty Goods" ? (
                <PaperContent />
              ) : showPaperContent &&
                targetTemplate.name === "Grievance Letter" ? (
                <GrievanceLetter template={targetTemplate} onDragEnd={false} />
              ) : showPaperContent &&
                targetTemplate.name ===
                  "Letter to complain about a delay to a service" ? (
                <DelayToAService template={targetTemplate} />
              ) : showPaperContent &&
                targetTemplate.name ===
                  "Letter to complain about the poor standard of a service" ? (
                <PoorStandardOfService template={targetTemplate} />
              ) : showPaperContent &&
                targetTemplate.name ===
                  "Letter to cancel a contract that's been breached" ? (
                <CancelContractBreached template={targetTemplate} />
              ) : null}
            </Box>
          </Box>
        </div>
       
      </div>
    </>
  );
}

export default TextEditor;
