import React from "react";
import { Stack, Typography, Box, Button } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import heroImage from "../assets1/hero.png";
import girlHero from "../assets1/girl2.png";
import HeroAvatars from "./HeroAvatars";
// import "../Home.css";

function Hero() {
  return (
    <Stack
      direction={{ sm: "column", md: "row" }}
      px={{ xs: "2%", sm: "7%" }}
      pt="20px"
      pb="50px"
    >
      <Box flex={0.4} pt={{ xs: "15px", sm: "30px" }}>
        <Typography
          variant="h1"
          fontWeight="800 !important"
          fontSize={{ xs: "40px !important", sm: "50px !important", md: "66px !important" }}
        >
          Save time writing documents using automation
        </Typography>
        <Typography mt="15px" variant="body1" fontWeight="500">
          Need to write an important document? Now you can save time by using
          Docelerate. It is simple. Choose a template, answer the questions and
          we generate your document, ready for download.
        </Typography>
        <Button
          sx={{ mt: "20px", backgroundColor: " #EE8F7B", color: "#ffffff" }}
          variant="contained"
          endIcon={<ArrowRightAltIcon />}
        >
          Get started
        </Button>
        <HeroAvatars />
        <Typography mt="10px" variant="body1" fontWeight="500">
          12,000+ people already joined the AKP's increase followers plan. Get
          started today!
        </Typography>
      </Box>
      <Box flex={0.6}>
        <img className="hero-image" src={girlHero} alt="graphic" />
      </Box>
    </Stack>
  );
}

export default Hero;
