import React, { useState, useEffect } from "react";
// import PropTypes from 'prop-types';
// import styled from 'styled-components';

import Paper from "@material-ui/core/Paper";
import Icon from "@material-ui/core/Icon";
import classNames from "classnames";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import {
  Button,
  Accordion,
  AccordionSummary,
  makeStyles,
} from "@material-ui/core";
// import InputRange from "react-input-range";

import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";

import styles from "./styles.css";

const useStyles = makeStyles((theme) => ({
  Root: {
    marginLeft: 0,
    marginRight: 0,
  },
  Paper: {
    padding: "20px",
    marginBottom: "15px",
    background: "#f7f9fa",
  },
  Accordion: {
    boxShadow: "none",
    backgroundColor: "transparent",
  },
  Margin0: {
    margin: 0,
  },
  AccordionSummary: {
    padding: 0,
    minHeight: "48px !important",
  },
  ExpandIcon: {
    paddingRight: 0,
    paddingLeft: 0,
    right: 0,
  },
  SummaryContent: {
    margin: "12px 0 0 0 !important",
  },
  Title: {
    marginTop: 0,
    position: "relative",
    marginBottom: 0,
  },
  AccordionContent: {
    maxHeight: "300px",
    overflow: "auto",
    /* overflow-y: scroll; */
    padding: "5px 15px 5px 0",
  },
  List: {
    height: "max-content",
    width: "100%",
  },
}));

const Filter = (props) => {
  const classes = useStyles();
  const [subjectFilter, setSubjectFilter] = useState([]);

  const handleSubjectFilterChange = (e) => {
    const { value, checked } = e.target;
    let subjectFilters = [...subjectFilter];
    const intValue = parseInt(value, 10);
    if (!checked) {
      subjectFilters = subjectFilters.filter((v) => v !== intValue);
    } else if (checked) {
      if (subjectFilters.indexOf(intValue) === -1)
        subjectFilters.push(intValue);
    }
    setSubjectFilter(subjectFilters);
  };
  const getQueryParams = () => ({
    subjectFilters: subjectFilter,
  });
  return (
    <React.Fragment>
      <Paper
        tour={"Filters"}
        elevation={0}
        className={`${classes.Paper} ${styles.Filters}`}
      >
        <Grid container spacing={2}>
          <Grid item md={12} xs={6}>
            {/* <ExpansionPanel
              defaultExpanded
              classes={{
                root: styles.Accordion,
                expanded: styles.Margin0,
              }}
              style={{ backgroundColor: " #f7f9fa" }}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                classes={{
                  root: styles.AccordionSummary,
                  expandIcon: styles.ExpandIcon,
                  content: styles.SummaryContent,
                }}
              >
                <h4 textAlign="center" className={styles.Title}>
                  Visa Types
                </h4>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails
                classes={{ root: styles.AccordionContent }}
              >
                <FormGroup classes={{ root: styles.List }}>
                  {props.visaList &&
                    props.visaList.map((sub) => (
                      <FormControlLabel
                        key={sub.id}
                        control={
                          <Checkbox
                            value={`${sub.id}`}
                            color="primary"
                            // onChange={this.handleSubjectFilterChange}
                            // checked={this.state.subjectFilters.includes(
                            //   sub.id,
                            // )}
                          />
                        }
                        label={sub.visa_title}
                        name="subject"
                        classes={{ root: styles.Root }}
                      />
                    ))}
                </FormGroup>
              </ExpansionPanelDetails>
            </ExpansionPanel> */}
            <Accordion
              defaultExpanded
              classes={{
                root: classes.Accordion,
                expanded: classes.Margin0,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                classes={{
                  root: classes.AccordionSummary,
                  expandIcon: classes.ExpandIcon,
                  content: classes.SummaryContent,
                }}
              >
                <h4 textAlign="center" className={classes.Title}>
                  Visa Types
                </h4>
              </AccordionSummary>
              <AccordionSummary classes={{ root: classes.AccordionContent }}>
                <FormGroup classes={{ root: classes.List }}>
                  {props.visaList &&
                    props.visaList.map((sub) => (
                      <FormControlLabel
                        key={sub.id}
                        control={
                          <Checkbox
                            value={`${sub.id}`}
                            color="primary"
                            onChange={handleSubjectFilterChange}
                            checked={subjectFilter.includes(sub.id)}
                          />
                        }
                        label={sub.visa_title}
                        name="subject"
                        classes={{ root: classes.Root }}
                      />
                    ))}
                </FormGroup>
              </AccordionSummary>
            </Accordion>
          </Grid>
        </Grid>
      </Paper>
      <Box mt={3}>
        <Button
          fullWidth
          size="large"
          variant="contained"
          color="primary"
          onClick={() => {
            console.log("hello");
          }}
        >
          Apply
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default Filter;
